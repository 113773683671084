import styles from "./index.module.css"
import { useContext, useEffect, useRef, useState } from "react"
import loadingImage from "./../../assets/loadingImage.png"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { AppContext, firestoreDB, setAppConfig } from "../../App"
import { Poster } from "schema/dist/src/poster"
import React from "react"
import { collection, deleteDoc, doc, getDocs, onSnapshot, setDoc, Timestamp } from "firebase/firestore"
import { CreateDefaultProduct, CreateProductVariant, Product, ProductTypeID, ProductVariant } from "schema/dist/src/product"
import {
  deleteObject,
  getDownloadURL,
  getMetadata,
  getStorage,
  ref,
  uploadBytesResumable,
  UploadTaskSnapshot,
} from "firebase/storage"
import { Category } from "schema/dist/src/category"
import { GetServerUrl } from "../../create/createRevivart"
import axios from "axios"
import { Orientation, SessionType } from "schema/dist/src/studio"
import { CompressImage } from "../event/create"
import { uuidv4 } from "@firebase/util"
import { calculateImageContrastActual } from "../../create/poster"
import { DrawSelectedResource, GetCropInformation, MIN_IMAGE_VARIANCE } from "../../create/polaroid/helper"
import { SizeToDimensions } from "../../shop/product"
import { AdminDefaultsType } from "../defaults/create"
import { returnPageKey } from "../../auth"
import { Discount } from "schema/dist/src/discount"
import { GetDiscount } from "../discount/discount"

type RouteParams = {
  productID: string
}

export const CreateProduct: React.FC<{}> = (): JSX.Element => {
  const { productID } = useParams<RouteParams>()

  const [selectedProduct, setSelectedProduct] = useState<Product>()

  useEffect(() => {
    if (productID === undefined) return

    const orderDetailsRef = doc(firestoreDB, "shopProducts", productID)

    const done = onSnapshot(orderDetailsRef, (response) => {
      const orderFinal = response.data() as Product
      setSelectedProduct(orderFinal)
    })

    return () => {
      done()
    }
  }, [productID])

  return (
    <>
      <Menu productSelected={selectedProduct} />
      <SetProductType productSelected={selectedProduct} />
      <UploadMainContainer productSelected={selectedProduct} />
      <SetNFTLink productSelected={selectedProduct} />
      <SetProductCategory productSelected={selectedProduct} />
      <SelectProductVariant productSelected={selectedProduct} />
      <SelectProductImages productSelected={selectedProduct} />
      <SetProductAction productSelected={selectedProduct} />
    </>
  )
}

export const SetProductType: React.FC<{
  productSelected: Product | undefined
}> = ({ productSelected }): JSX.Element => {
  const OrderTypeListString: String[] = ["REGULAR POSTER", "AR POSTER", "OTHER"]
  const OrderTypeList: ProductTypeID[] = [1, 2, 3]

  if (productSelected === undefined) return <></>

  return (
    <div className={styles.UpdateOrderDetails}>
      <div className={styles.UpdateOrderDetailsList}>
        {OrderTypeList.map((orderType, i) => {
          let className = styles.UpdateOrderStatusButton
          if (orderType === productSelected.typeId) {
            className += " " + styles.UpdateOrderStatusButtonSelected
          }
          return (
            <div
              key={i}
              className={className}
              onClick={() => {
                productSelected.typeId = orderType
                SetUpdateShopProducts(productSelected)
              }}
            >
              {OrderTypeListString[i]}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const Menu: React.FC<{
  productSelected: Product | undefined
}> = ({ productSelected }): JSX.Element => {
  const nav = useNavigate()
  const loc = useLocation()

  return (
    <div className={styles.UpdateOrderDetails}>
      <div className={styles.UpdateOrderDetailsList}>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            nav("/shop/product/" + productSelected?.uid + `?${returnPageKey}=` + loc.pathname)
          }}
        >
          View Live Page
        </div>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            nav("../")
          }}
        >
          Back
        </div>
      </div>
    </div>
  )
}

export const SetNFTLink: React.FC<{
  productSelected: Product | undefined
}> = ({ productSelected }): JSX.Element => {
  const [title, setTitle] = useState(productSelected?.title ?? "")
  const [description, setDescription] = useState(productSelected?.productDescription ?? "")
  const [studioID, setStudioID] = useState(productSelected?.studioID ?? "")

  const [link, setLink] = useState(productSelected?.nftLink ?? "")
  const [tag, setTag] = useState(productSelected?.tag)
  const [discount, setDiscount] = useState(productSelected?.discountID)
  const [discountValue, setDiscountValue] = useState<Discount>()

  useEffect(() => {
    if (productSelected === undefined) return
    setLink(productSelected.nftLink)
    setTag(productSelected.tag)
    setDiscount(productSelected.discountID)
    setDescription(productSelected.productDescription)
    setTitle(productSelected.title)
    setStudioID(productSelected.studioID ?? "")
  }, [productSelected])

  useEffect(() => {
    if (discount === undefined || discount === "") return
    GetDiscount(discount).then((discount) => {
      setDiscountValue(discount)
    })
  }, [discount])

  if (productSelected === undefined) return <></>
  console.log(productSelected)

  return (
    <div className={styles.UpdateOrderDetails}>
      <div className={styles.UpdateOrderDetailsList}>
        <input
          className={styles.UpdateOrderStatusInput}
          onInput={(e) => {
            setTitle(e.currentTarget.value)
          }}
          value={title}
        ></input>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            productSelected.title = title
            SetUpdateShopProducts(productSelected)
          }}
        >
          Update Title
        </div>
      </div>
      <div className={styles.UpdateOrderDetailsList}>
        <textarea
          placeholder={description}
          onInput={(e) => {
            setDescription(e.currentTarget.value)
          }}
          value={description}
          autoComplete="off"
          className={styles.UpdateOrderStatusInput}
        />

        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            productSelected.productDescription = description
            SetUpdateShopProducts(productSelected)
          }}
        >
          Update Desc
        </div>
      </div>
      <div className={styles.UpdateOrderDetailsList}>
        <input
          className={styles.UpdateOrderStatusInput}
          onInput={(e) => {
            setLink(e.currentTarget.value)
          }}
          value={link}
        ></input>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            productSelected.nftLink = link
            SetUpdateShopProducts(productSelected)
          }}
        >
          Update NFT
        </div>
      </div>
      <div className={styles.UpdateOrderDetailsList}>
        <input
          className={styles.UpdateOrderStatusInput}
          onInput={(e) => {
            setTag(e.currentTarget.value)
          }}
          value={tag ?? ""}
        ></input>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            productSelected.tag = tag
            SetUpdateShopProducts(productSelected)
          }}
        >
          Set Tag
        </div>
      </div>

      <div className={styles.UpdateOrderDetailsList}>
        <input
          className={styles.UpdateOrderStatusInput}
          onInput={(e) => {
            setDiscount(e.currentTarget.value)
          }}
          value={discount ?? ""}
        ></input>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            productSelected.discountID = discount
            SetUpdateShopProducts(productSelected)
          }}
        >
          Set Discount
        </div>

        {discountValue !== undefined && (
          <>
            Discount Name: {discountValue?.title} -
            {discountValue?.type === "amount" ? (
              <> Amount Off ${(discountValue.amountOff ?? 0) / 100}</>
            ) : (
              <> Percent Off: {discountValue?.percentOff} %</>
            )}
          </>
        )}
      </div>

      {productSelected.typeId === 3 && (
        <div className={styles.UpdateOrderDetailsList}>
          <input
            className={styles.UpdateOrderStatusInput}
            onInput={(e) => {
              setStudioID(e.currentTarget.value)
            }}
            value={studioID}
          ></input>
          <div
            className={styles.UpdateOrderStatusButton}
            onClick={() => {
              productSelected.studioID = studioID
              SetUpdateShopProducts(productSelected)
            }}
          >
            set studio
          </div>
        </div>
      )}
    </div>
  )
}

export const SetProductCategory: React.FC<{
  productSelected: Product | undefined
}> = ({ productSelected }): JSX.Element => {
  const [categoryList, setCategoryList] = useState<Category[]>([])

  useEffect(() => {
    if (productSelected === undefined) return

    const orderDetailsRef = collection(firestoreDB, "shopCategories")

    getDocs(orderDetailsRef)
      .then((response) => {
        const categoryList = response.docs.map((doc) => {
          return doc.data() as Category
        })
        setCategoryList(categoryList)
      })

      .catch((err) => {
        console.error(err)
      })
  }, [productSelected, firestoreDB])

  if (productSelected === undefined) return <></>

  return (
    <div className={styles.UpdateOrderDetails}>
      CATEGORIES
      <div className={styles.UpdateOrderDetailsList}>
        {categoryList.map((category) => {
          let className = styles.UpdateOrderStatusButton

          if (category.uid === productSelected.category) {
            className += " " + styles.UpdateOrderStatusButtonSelected
          }
          return (
            <div
              key={category.uid}
              className={className}
              onClick={() => {
                productSelected.category = category.uid
                SetUpdateShopProducts(productSelected)
              }}
            >
              {category.title}
            </div>
          )
        })}
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            productSelected.category = ""
            SetUpdateShopProducts(productSelected)
          }}
        >
          RESET
        </div>
      </div>
    </div>
  )
}

export function ValidateImageContrast(objectUrl: string) {
  var image = new Image()

  return new Promise<number>((resolve, reject) => {
    image.onload = function () {
      const contrast = calculateImageContrastActual(image)
      resolve(contrast)

      URL.revokeObjectURL(objectUrl)
    }

    image.onerror = () => {
      reject("did not load vide")
    }
    image.src = objectUrl
  })
}

export function GetImageFromURL(objectUrl: string) {
  var image = new Image()
  image.crossOrigin = "anonymous"
  const storage = getStorage()

  return getDownloadURL(ref(storage, objectUrl)).then((url) => {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      image.onload = function () {
        resolve(image)
      }

      image.onerror = () => {
        reject("did not load vide")
      }
      image.src = url
    })
  })
}

const GetURLData = (url: string): Promise<Blob> => {
  if (url === "")
    return new Promise((resolve, reject) => {
      reject()
    })

  const storage = getStorage()
  return getDownloadURL(ref(storage, url))
    .then((url) => {
      return fetch(url)
    })
    .then((res) => {
      return res.blob()
    })
}

const GetURLDataSize = (url: string): Promise<number> => {
  if (url === "")
    return new Promise((resolve, reject) => {
      reject()
    })

  const storage = getStorage()

  return getMetadata(ref(storage, url)).then((data) => {
    return data.size
  })
}

export const UploadMainContainer: React.FC<{
  productSelected: Product | undefined
}> = ({ productSelected }): JSX.Element => {
  return (
    <div className={styles.UploadMainContainer}>
      <UploadMainSection productSelected={productSelected} uploadLD />
      <UploadMainSection productSelected={productSelected} isVideo visible={productSelected?.typeId === 2} />
    </div>
  )
}

export const UploadMainSection: React.FC<{
  productSelected: Product | undefined
  isVideo?: boolean
  uploadLD?: boolean
  visible?: boolean
}> = ({ productSelected, isVideo = false, uploadLD = false, visible = true }): JSX.Element => {
  const [resourceURL, setResourceURL] = useState(loadingImage)
  const [resourceSizeHD, setResourceSizeHD] = useState(0)

  const [resourceBlob, setResourceBlob] = useState<File | Blob>()
  const [calculatedContrast, setCalculatedContrast] = useState<number>(0)
  const [reset, setReset] = useState(false)
  const [uploadTitle, setUploadTitle] = useState("UPLOAD")
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  function handleResourceClicked(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files !== null) {
      const file = e.target.files[0]
      setResourceBlob(file)
      const imageURL = URL.createObjectURL(file)
      setResourceURL(imageURL)
    }
  }

  function uploadResource() {
    if (resourceBlob === undefined || productSelected === undefined) {
      setUploadTitle("SELECT FILE")
      return
    }
    const location = `/productImages/${productSelected?.uid}/`

    if (isVideo) {
      const fileName = "ARvideo.mp4"

      uploadFile(
        fileName,
        location,
        resourceBlob,
        (a: number) => {
          setUploadTitle(a.toPrecision(2) + "%")
        },
        (a, ref) => {
          productSelected.videoURL = a
          SetUpdateShopProducts(productSelected)
        }
      )
        .catch(() => {})
        .finally(() => {
          setUploadTitle("UPLOAD")
        })
    }

    if (uploadLD) {
      const fileHDName = productSelected.uid + "HDimage"
      const fileLDName = productSelected.uid + "LDimage"

      CompressImage(resourceBlob, 0.5)
        .then((b) => {
          return Promise.all([
            uploadFile(fileHDName, location, resourceBlob, (a: number) => {
              setUploadTitle(a.toPrecision(2) + "%")
            }),
            uploadFile(fileLDName, location, b),
          ])
        })
        .then((ref) => {
          productSelected.imageHDURL = ref[0].dest
          productSelected.imageLDURL = ref[1].dest
          return SetUpdateShopProducts(productSelected)
        })
        .finally(() => {
          setUploadTitle("UPLOAD")
        })
    }
  }

  useEffect(() => {
    if (productSelected === undefined) return
    const linkRef = isVideo ? productSelected.videoURL : productSelected.imageLDURL

    GetURLData(linkRef)
      .then((data) => {
        setResourceBlob(data)
        return URL.createObjectURL(data)
      })
      .then((data) => {
        setResourceURL(data ?? "")
        return ValidateImageContrast(data ?? "")
      })
      .then((contrast) => {
        setCalculatedContrast(contrast)
      })
      .catch((error) => {
        console.error(error)
      })

    if (!isVideo) {
      GetURLDataSize(productSelected.imageHDURL).then((dataSize) => {
        setResourceSizeHD(dataSize)
      })
    }

    return () => {
      if (resourceURL === loadingImage) return
      URL.revokeObjectURL(resourceURL)
    }
  }, [productSelected, reset])

  if (productSelected === undefined) return <></>

  if (!visible) {
    return <></>
  }

  return (
    <div className={styles.UploadMainSection}>
      <input
        style={{ display: "none" }}
        ref={hiddenFileInput}
        type="file"
        accept={isVideo ? ".mp4" : ".png, .jpeg, .png"}
        onChange={handleResourceClicked}
      />
      <div
        className={styles.UploadMainSectionTop}
        onClick={() => {
          hiddenFileInput.current?.click()
        }}
      >
        {!isVideo ? <img src={resourceURL} alt={"img"}></img> : <video src={resourceURL} loop autoPlay muted controls></video>}
      </div>
      {!isVideo ? (
        <div className={styles.UpdateOrderDetails}>
          <div className={styles.UpdateOrderDetailsList}>
            CONTRAST: {calculatedContrast.toFixed(0)}/{MIN_IMAGE_VARIANCE * 3} <br />
            HD SIZE: {(resourceSizeHD / 1024).toFixed(0)} KB - {(resourceSizeHD / (1024 * 1024)).toFixed(3)} MB <br />
            LD SIZE: {((resourceBlob?.size ?? 0) / 1024).toFixed(0)} KB - {((resourceBlob?.size ?? 0) / (1024 * 1024)).toFixed(3)}{" "}
            MB
          </div>
        </div>
      ) : (
        <div className={styles.UpdateOrderDetails}>
          <div className={styles.UpdateOrderDetailsList}>
            VIDE SIZE: {((resourceBlob?.size ?? 0) / 1024).toFixed(0)} KB -{" "}
            {((resourceBlob?.size ?? 0) / (1024 * 1024)).toFixed(3)} MB
          </div>
        </div>
      )}
      <div className={styles.UploadMainSectionBottom}>
        <div className={styles.UpdateOrderDetails}>
          <div className={styles.UpdateOrderDetailsList}>
            <div className={styles.UpdateOrderStatusButton} onClick={uploadResource}>
              {uploadTitle}
            </div>
            <div
              className={styles.UpdateOrderStatusButton}
              onClick={() => {
                setReset(!reset)
              }}
            >
              RESET
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const SelectProductVariant: React.FC<{
  productSelected: Product | undefined
  isVideo?: boolean
}> = ({ productSelected, isVideo = false }): JSX.Element => {
  const [variantList, setVariantList] = useState<ProductVariant[]>(productSelected?.productVariantList ?? [])
  useEffect(() => {
    if (productSelected === undefined) return
    setVariantList(productSelected?.productVariantList)
  }, [productSelected])

  if (productSelected === undefined) return <></>

  return (
    <div className={styles.UpdateOrderDetails}>
      VARIANT
      <div className={styles.SelectProductVariant}>
        {variantList.map((variant, i) => {
          return (
            <SelectProductVariantView
              key={variant.uid}
              productSelected={productSelected}
              variant={variant}
              onVariantUpdate={(newVar) => {
                variantList[i] = newVar
                setVariantList([...variantList])
              }}
              onVariantDelete={() => {
                var filtered = variantList.filter(function (value) {
                  return value !== variant
                })
                setVariantList([...filtered])
              }}
            />
          )
        })}
      </div>
      <div className={styles.UpdateOrderDetailsList}>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            GetDefaultProductVariants("defaults")
              .then((variants) => {
                setVariantList(variants)
                console.log(variants)
              })
              .catch((error) => {
                console.error(error)
              })
          }}
        >
          AR DEFAULTS
        </div>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            GetDefaultProductVariants("regular")
              .then((variants) => {
                setVariantList(variants)
                console.log(variants)
              })
              .catch((error) => {
                console.error(error)
              })
          }}
        >
          REG DEFAULTS
        </div>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            GetDefaultProductVariants("polaroids")
              .then((variants) => {
                setVariantList(variants)
                console.log(variants)
              })
              .catch((error) => {
                console.error(error)
              })
          }}
        >
          POLAROIDS
        </div>
      </div>
      <div className={styles.UpdateOrderDetailsList}>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            productSelected.productVariantList = variantList
            SetUpdateShopProducts(productSelected)
          }}
        >
          UPDATE
        </div>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            setVariantList(productSelected.productVariantList)
          }}
        >
          RESET
        </div>

        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            const newList = [...variantList]
            newList.push(CreateProductVariants("defaults"))
            setVariantList(newList)
          }}
        >
          ADD
        </div>
      </div>
    </div>
  )
}

export const SelectProductVariantView: React.FC<{
  variant: ProductVariant
  onVariantUpdate: (variant: ProductVariant) => void
  onVariantDelete: () => void
  productSelected?: Product
}> = ({ variant, onVariantUpdate, onVariantDelete, productSelected }): JSX.Element => {
  const typeList: (SessionType | "none")[] = ["poster", "polaroid", "postcard", "greeting", "none"]
  const orientationList: (Orientation | "none")[] = ["vertical", "horizontal", "none"]

  const [stateTag, setStateTag] = useState("CREATE POSTER IMAGE")

  function setUploadPercentage(percentage: number) {
    setStateTag(percentage.toFixed(2))
  }

  const uploadedVariants = (variant.imageHDURL ?? "") !== "" && (variant.imageLDURL ?? "")
  let variantButtonClassName = styles.UpdateOrderStatusButton

  if (uploadedVariants) {
    variantButtonClassName += " " + styles.UpdateOrderStatusButtonGood
  } else {
    variantButtonClassName += " " + styles.UpdateOrderStatusButtonBad
  }

  let mainClassName = styles.SelectProductVariantView
  const completeVariant = ((variant.title ?? "") !== "" || variant.size !== "") && variant.price !== 0 && variant.uid !== ""

  if (!completeVariant) {
    mainClassName += " " + styles.SelectProductVariantViewBad
  } else {
    mainClassName += " " + styles.SelectProductVariantViewGood
  }

  return (
    <div className={mainClassName}>
      <div className={styles.SelectProductVariantViewItem}>UID: {variant.uid}</div>
      <div className={styles.SelectProductVariantViewItem}>
        title
        <input
          value={variant.title ?? ""}
          onInput={(e) => {
            const value = e.currentTarget.value
            if (value === "") {
              delete variant.title
            } else {
              variant.title = value
            }

            onVariantUpdate(variant)
          }}
        />
      </div>
      <div className={styles.SelectProductVariantViewItem}>
        size
        <input
          value={variant.size}
          onInput={(e) => {
            variant.size = e.currentTarget.value
            onVariantUpdate(variant)
          }}
        />
      </div>
      <div className={styles.SelectProductVariantViewItem}>
        Max Order
        <input
          value={variant.orderLimit ?? ""}
          onInput={(e) => {
            const numString = parseFloat(e.currentTarget.value)
            if (isNaN(numString)) {
              variant.orderLimit = null
            } else {
              variant.orderLimit = numString
            }
            onVariantUpdate(variant)
          }}
        />
      </div>
      <div className={styles.SelectProductVariantViewItem}>
        price
        <input
          value={variant.price}
          onInput={(e) => {
            const numString = parseFloat(e.currentTarget.value)
            if (isNaN(numString)) return
            variant.price = numString
            onVariantUpdate(variant)
          }}
        />
      </div>
      <div className={styles.SelectProductVariantViewItem}>
        count
        <input
          value={variant.count ?? ""}
          onInput={(e) => {
            const numString = parseFloat(e.currentTarget.value)
            if (isNaN(numString)) {
              variant.count = null
            } else {
              variant.count = numString
            }
            onVariantUpdate(variant)
          }}
        />
      </div>
      <div className={styles.SelectProductVariantViewItem}>
        PrintFull ID
        <input
          value={variant.printfulVariantID ?? ""}
          onInput={(e) => {
            const numString = parseFloat(e.currentTarget.value)
            if (isNaN(numString)) {
              variant.printfulVariantID = null
            } else {
              variant.printfulVariantID = numString
            }
            onVariantUpdate(variant)
          }}
        />
      </div>
      <div className={styles.SelectProductVariantViewItem}>
        P.WarehouseID
        <input
          value={variant.printfulWarehouseID ?? ""}
          onInput={(e) => {
            const numString = parseFloat(e.currentTarget.value)
            if (isNaN(numString)) {
              variant.printfulWarehouseID = null
            } else {
              variant.printfulWarehouseID = numString
            }
            onVariantUpdate(variant)
          }}
        />
      </div>
      <div className={styles.SelectProductVariantViewItem}>
        Session Type
        <select
          value={variant.sessionType}
          onChange={(e) => {
            variant.sessionType = e.target.value as SessionType
            onVariantUpdate(variant)
          }}
          defaultValue={"none"}
        >
          {typeList.map((number) => {
            return (
              <option key={number} value={number}>
                {number.toUpperCase()}
              </option>
            )
          })}
        </select>
      </div>
      <div className={styles.SelectProductVariantViewItem}>
        Orientation
        <select
          value={variant.orientation}
          onChange={(e) => {
            const value = e.target.value as Orientation | "none"

            if (value === "none") {
              delete variant.orientation
            } else {
              variant.orientation = value
            }

            onVariantUpdate(variant)
          }}
          defaultValue={"none"}
        >
          {orientationList.map((number) => {
            return (
              <option key={number} value={number}>
                {number.toUpperCase()}
              </option>
            )
          })}
        </select>
      </div>

      {productSelected && productSelected.typeId !== 3 && (
        <div
          className={variantButtonClassName}
          onClick={() => {
            // THIS IMPLEMENTATION MEANS THAT WE WILL HAVE DANGLING IMAGES UNLESS ALL PRODUCT IS DELETED
            createProductVariantImage(productSelected, variant, "imageHDURL", setUploadPercentage, (a) => {
              variant.imageHDURL = a
              setStateTag("HD DONE")
              createProductVariantImage(productSelected, variant, "imageLDURL", setUploadPercentage, (a) => {
                variant.imageLDURL = a
                onVariantUpdate(variant)
                setStateTag("LD DONE")
              })
            })
          }}
        >
          {stateTag}
        </div>
      )}

      {/* TODO:: ALLOW UPLOAD FOR POSTER VARIANTS */}
      {/* <div className={styles.UpdateOrderStatusButton}>UPLOAD POSTER IMAGE</div> */}
      <div className={styles.UpdateOrderStatusButton} onClick={onVariantDelete}>
        DELETE
      </div>
    </div>
  )
}

export function CropSelectedVideoFrame(
  canvasRef: HTMLCanvasElement,
  imageResource: CanvasImageSource,
  ratio: number,
  videoImageWith: number,
  videoImageHeight: number
): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const info = GetCropInformation(ratio, videoImageWith, videoImageHeight)

    DrawSelectedResource(canvasRef, imageResource, videoImageWith, videoImageHeight, info.actualWidth, info.actualHeight).toBlob(
      (blob: Blob | null) => {
        if (blob === null) {
          reject("Invalid blob")
          return
        }

        resolve(blob)
      }
    )
  })
}

function createProductVariantImage(
  productSelected: Product,
  variant: ProductVariant,
  id: "imageHDURL" | "imageLDURL",
  onProgress: (a: number) => void,
  onCompleted: (a: string) => void
) {
  const imageURL = productSelected[id]

  GetImageFromURL(imageURL)
    .then((image) => {
      const canvas = document.createElement("canvas")
      canvas.width = image.width
      canvas.height = image.height
      const posterRatio = SizeToDimensions(variant?.size ?? "") ?? 0
      return CropSelectedVideoFrame(canvas, image, posterRatio, image.width, image.height)
    })
    .then((file) => {
      const fileName = "productImage_" + variant.uid + "_" + id
      const filePath = "/productImages/" + productSelected.uid + "/"
      uploadFile(fileName, filePath, file, onProgress, onCompleted)
    })
}

export const SetProductAction: React.FC<{
  productSelected: Product | undefined
}> = ({ productSelected }): JSX.Element => {
  const nav = useNavigate()
  const { userToken, user } = useContext(AppContext)

  if (productSelected === undefined) return <></>

  let classNameEnabled = styles.UpdateOrderStatusButton
  let classNameDisEnabled = classNameEnabled + " " + styles.UpdateOrderStatusButtonSelected

  return (
    <div className={styles.UpdateOrderDetails}>
      <div className={styles.UpdateOrderDetailsList}>
        <div
          className={!productSelected.isActive ? classNameEnabled : classNameDisEnabled}
          onClick={() => {
            productSelected.isActive = true
            SetUpdateShopProducts(productSelected)
          }}
        >
          ENABLED
        </div>
        <div
          className={productSelected.isActive ? classNameEnabled : classNameDisEnabled}
          onClick={() => {
            productSelected.isActive = false
            SetUpdateShopProducts(productSelected)
          }}
        >
          DISABLED
        </div>

        {productSelected.typeId === 2 && (
          <div
            className={classNameEnabled}
            onClick={() => {
              if (productSelected.posterUid !== "") {
                console.log("Poster already Exist")
                return
              }
              console.log("not needed rn")

              PasteurizeProduct(productSelected, userToken, user?.uid ?? "")
            }}
          >
            POSTERIZE
          </div>
        )}

        <div
          className={classNameEnabled}
          onClick={() => {
            DeleteUpdateShopCategory(productSelected)
              .then(() => {
                nav("../")
              })
              .catch(() => {})
              .finally(() => {})
          }}
        >
          DELETE
        </div>
        {productSelected.posterUid !== "" ? (
          <div
            className={classNameEnabled}
            onClick={() => {
              nav("/asset/" + productSelected.posterUid)
            }}
          >
            VIEW POSTER
          </div>
        ) : null}
      </div>
    </div>
  )
}

function PasteurizeProduct(productSelected: Product, token: string, userID: string) {
  const posturizeEndPoint = "/api/api/generate/poster"
  let posterAPI = GetServerUrl(posturizeEndPoint)
  const endPoint = new URL(posterAPI)
  endPoint.searchParams.append("productID", productSelected.uid)
  endPoint.searchParams.append("customerID", userID ?? "")
  endPoint.searchParams.append("sameID", "true")

  return axios
    .get(endPoint.toString(), {
      headers: { Authorization: "Bearer " + token },
    })
    .then((response) => {
      const posterData = response.data as Poster

      productSelected.posterUid = posterData.uid
      return SetUpdateShopProducts(productSelected)
    })
    .then((response) => {
      console.log("DONE PASTEURIZING")
    })
    .catch((error) => {
      console.error(error)
    })
}

export const SelectProductImages: React.FC<{
  productSelected: Product | undefined
  isVideo?: boolean
}> = ({ productSelected, isVideo = false }): JSX.Element => {
  const [imagesList, setImagesList] = useState<string[]>(productSelected?.productImagesURL ?? [])

  const [stateTag, setStateTag] = useState("ADD")
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (productSelected === undefined) return
    setImagesList(productSelected?.productImagesURL)
  }, [productSelected])

  function handleResourceClicked(e: React.ChangeEvent<HTMLInputElement>) {
    if (productSelected === undefined) return

    if (e.target.files !== null) {
      const file = e.target.files[0]
      uploadFile(
        "productImage_" + uuidv4(),
        "/productImages/" + productSelected.uid + "/",
        file,
        (a) => {
          setStateTag(a.toPrecision(3) + "%")
        },
        (a, ref) => {
          const newList = [...productSelected.productImagesURL]
          newList.push(a)
          productSelected.productImagesURL = newList
          SetUpdateShopProducts(productSelected)
        }
      )
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          setStateTag("ADD")
        })
    }
  }

  if (productSelected === undefined) return <></>

  return (
    <div className={styles.UpdateOrderDetails}>
      PRODUCT IMAGES
      <input
        style={{ display: "none" }}
        ref={hiddenFileInput}
        type="file"
        accept={".png, .jpeg, .png"}
        onChange={handleResourceClicked}
      />
      <div className={styles.SelectProductVariant}>
        {imagesList.map((imageURL, i) => {
          return (
            <SelectProductImageItem
              key={imageURL}
              pictureURL={imageURL}
              onDeleteClicked={() => {
                var filtered = imagesList.filter(function (value) {
                  return value !== imageURL
                })
                productSelected.productImagesURL = filtered
                SetUpdateShopProducts(productSelected)
                  .then(() => {
                    const storage = getStorage()
                    return deleteObject(ref(storage, imageURL))
                  })
                  .then(() => {
                    console.log("DONE")
                  })
                  .catch(() => {
                    setStateTag("ADD")
                  })
              }}
            />
          )
        })}
      </div>
      <div className={styles.UpdateOrderDetailsList}>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            hiddenFileInput.current?.click()
          }}
        >
          {stateTag}
        </div>
      </div>
    </div>
  )
}

export const SelectProductImageItem: React.FC<{
  pictureURL: string
  onDeleteClicked: () => void
}> = ({ pictureURL, onDeleteClicked }): JSX.Element => {
  const [imagesURL, setImagesURL] = useState(loadingImage)

  useEffect(() => {
    const storage = getStorage()
    getDownloadURL(ref(storage, pictureURL))
      .then((url) => {
        setImagesURL(url)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [pictureURL])

  return (
    <div className={styles.SelectProductVariantView}>
      <img className={styles.SelectProductImageItem} src={imagesURL} alt={"img"}></img>
      <div className={styles.SelectProductVariantViewItem}>
        <div className={styles.UpdateOrderStatusButton} onClick={onDeleteClicked}>
          DELETE
        </div>
      </div>
    </div>
  )
}

/**
 *
 * @param fileName the name of the file to write to
 * @param location the path location. should always start with a slash and end with a slash
 * @example /productImages/category/ not /productImages
 * @param file the file to write to
 * @param onUpdate this function will be called on change of state of upload
 * @param onCompleted
 * @returns
 */

export type UploadCallBackFunction = (a: number) => void
export function uploadFile(
  fileName: string,
  location: string,
  file: File | Blob,
  onUpdate?: UploadCallBackFunction,
  onCompleted?: (a: string, ref: UploadTaskSnapshot) => void
) {
  const Video = `${location}${fileName}`

  let VideoDestination = `gs://${setAppConfig.storageBucket}${Video}`

  const storage = getStorage()

  const VideoRequest = uploadBytesResumable(ref(storage, VideoDestination), file)

  let VideoRequestProgress = 0
  VideoRequest.on("state_changed", (snapshot) => {
    VideoRequestProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

    if (onUpdate !== undefined) {
      onUpdate(VideoRequestProgress)
    }
  })

  return new Promise<{ dest: string; ref: UploadTaskSnapshot }>((resolve, reject) => {
    return VideoRequest.then((ref) => {
      if (onCompleted !== undefined) {
        onCompleted(VideoDestination, ref)
      }
      resolve({ dest: VideoDestination, ref: ref })
    }).catch((err) => {
      reject(err)
    })
  })
}

export function CreateNewShopProducts() {
  const ref = doc(collection(firestoreDB, "shopProducts"))

  const newProduct = CreateDefaultProduct(ref.id, Timestamp.now())

  return SetUpdateShopProducts(newProduct).then((result) => {
    return ref.id
  })
}

function SetUpdateShopProducts(productSelected: Product) {
  const orderDetailsRef = doc(firestoreDB, "shopProducts", productSelected.uid)

  return setDoc(orderDetailsRef, productSelected).catch((err) => {
    console.error(err)
  })
}

export function GetDefaultProductVariants(type: AdminDefaultsType) {
  const orderDetailsRef = collection(doc(firestoreDB, "appData", "posterVariants"), type)

  return getDocs(orderDetailsRef).then((response) => {
    return response.docs.map((doc) => {
      const data = doc.data() as ProductVariant
      data.uid = doc.id
      return data
    })
  })
}

export function CreateProductVariants(type: AdminDefaultsType) {
  const orderDetailsRef = doc(collection(doc(firestoreDB, "appData", "posterVariants"), type))

  return CreateProductVariant(orderDetailsRef.id)
}

export function DeleteUpdateShopCategory(product: Product) {
  const docRef = doc(firestoreDB, "shopProducts", product.uid)

  return deleteDoc(docRef).then(() => {
    const storage = getStorage()

    return Promise.allSettled([
      deleteObject(ref(storage, product.videoURL)),
      deleteObject(ref(storage, product.imageHDURL)),
      deleteObject(ref(storage, product.imageLDURL)),
    ])
  })
}
