import React, { useEffect, useRef, useState } from "react"
import { PosterType } from "schema/dist/src/poster"
import styles from "./index.module.css"
import loadingImage from "../../assets/loadingImage.png"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { Orientation } from "schema/dist/src/studio"

interface Revivart2DProps {
  imageURL: string
  videoURL: string
  ratio: number
  type: PosterType
  onClick: () => void
  imageOnly?: boolean
  orientation?: Orientation | undefined
}

export const Revivart2D: React.FC<Revivart2DProps> = ({
  imageURL,
  videoURL,
  ratio,
  type,
  onClick,
  imageOnly = false,
  orientation,
  children,
}): JSX.Element => {
  const vidRef = useRef<HTMLVideoElement>(null)
  const [actualImageURL, setActualImageURL] = useState(loadingImage)
  const [actualVideoURL, setActualVideoURL] = useState("")
  const [_, setIsLoaded] = useState(true)
  const [play, setPlay] = useState(false)
  const hasNoImage = imageURL === ""

  useEffect(() => {
    if (imageURL === "") return

    if (imageURL.includes("gs://")) {
      const storage = getStorage()
      getDownloadURL(ref(storage, imageURL))
        .then((url) => {
          setActualImageURL(url)
        })
        .catch((error) => {
          console.error(error)
        })
    } else {
      setActualImageURL(imageURL)
    }
  }, [imageURL])

  useEffect(() => {
    if (videoURL === "") return

    if (videoURL.includes("gs://")) {
      const storage = getStorage()
      getDownloadURL(ref(storage, videoURL))
        .then((url) => {
          setActualVideoURL(url)
        })
        .catch((error) => {
          console.error(error)
        })
    } else {
      setActualVideoURL(videoURL)
    }
  }, [videoURL])

  useEffect(() => {
    if (vidRef.current === null || actualVideoURL === "") return
    const sourceEmpty = vidRef.current.src === ""

    if (play) {
      if (sourceEmpty) {
        vidRef.current.src = actualVideoURL
      }
      vidRef.current.play()
    } else if (hasNoImage) {
      if (sourceEmpty) {
        vidRef.current.src = actualVideoURL
      }
      vidRef.current.play()
    } else {
      vidRef.current.pause()
      vidRef.current.currentTime = 0
      setIsLoaded(true)
    }
  }, [play, hasNoImage, actualVideoURL, vidRef.current])

  let overallClassName = styles.Revivart2D
  if (orientation === "horizontal") {
    overallClassName += " " + styles.PosterContentHorizontal
  }

  let posterClassName = styles.PosterContainer

  if (type === "polaroid") {
    posterClassName = styles.PolaroidType
  } else if (type === "greeting") {
    posterClassName = styles.GreetingCardType
  } else if (type === "postcard") {
    posterClassName = styles.PostCardType
  } else if (type === "poster") {
    posterClassName = styles.PosterType
    if (ratio === 0.67) {
      posterClassName += " " + styles.PosterFrame067
    } else if (ratio === 0.75) {
      posterClassName += " " + styles.PosterFrame075
    } else if (ratio === 0.8) {
      posterClassName += " " + styles.PosterFrame08
    } else if (ratio === 1) {
      posterClassName += " " + styles.PosterFrame1
    }
  }

  const showVideo = !play && imageURL !== ""

  return (
    <div
      className={overallClassName}
      onMouseEnter={() => {
        if (imageURL === "" || imageOnly) return
        setPlay(true)
      }}
      onMouseLeave={() => {
        if (imageURL === "" || imageOnly) return
        setPlay(false)
      }}
      onClick={onClick}
    >
      <div className={posterClassName}>
        <div className={styles.PosterContent}>
          {imageURL !== "" ? (
            <div className={styles.PosterContentImage} style={{ backgroundImage: "url(" + actualImageURL + ")" }} />
          ) : null}

          <video
            loop
            autoPlay
            muted
            playsInline
            controls={false}
            ref={vidRef}
            hidden={showVideo}
            onLoadedData={() => {
              setIsLoaded(true)
            }}
            onWaiting={(e) => {
              setIsLoaded(false)
            }}
            onPlaying={(e) => {
              setIsLoaded(true)
            }}
            onTimeUpdate={(e) => {
              if (e.currentTarget.currentTime > 3) {
                e.currentTarget.currentTime = 0
                setPlay(false)
              }
            }}
          />
          <div className={styles.PosterContentExtra}></div>
        </div>
      </div>
      {children}
    </div>
  )
}
