import React, { useContext, useEffect, useRef, useState } from "react"
import styles from "./../poster/index.module.css"
import { AppContext, firestoreDB } from "../../App"
import { useNavigate, useParams } from "react-router-dom"
import ImagePlaceHolder from "../../assets/POL2.jpg"
import { CreateStudioSession, FrequentList, IncludedList, IntroBody, IntroVideo, VariantContainer } from "../shared/shared"
import { PosterPreview, useAnchorScroll, useSession, useStudioProduct } from "../poster"
import { StudioSession } from "schema/dist/src/studio"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { collection, doc, onSnapshot } from "firebase/firestore"
const MonaVideo = ""
const studioID = "polaroid"

interface SelectSessionDataProps {
  selected: number
  setSelected: (a: number) => void
  sessionID: string | undefined
}

export const SelectSessionData: React.FC<SelectSessionDataProps> = ({ selected, setSelected, sessionID }): JSX.Element | null => {
  const [session, setSession] = useState<StudioSession>()

  useEffect(() => {
    if (sessionID === undefined || sessionID === "") return
    const sessionCollRef = collection(firestoreDB, "studioSessions")
    let sessionRef = doc(sessionCollRef, sessionID)

    //fetch the event
    const done = onSnapshot(sessionRef, (response) => {
      const newSession = response.data() as StudioSession
      setSession(newSession)
    })

    return () => {
      done()
    }
  }, [sessionID])

  if (sessionID === undefined || sessionID === "") return null
  return (
    <div className={styles.SelectSessionData}>
      {session?.sessionData.map((sessionData, i) => {
        const imageURL = sessionData.imageURL
        return (
          <SelectSessionDataIcon
            imageURL={imageURL}
            title={i + 1}
            isSelected={selected === i}
            onClick={() => {
              setSelected(i)
            }}
            key={sessionData.posterUID}
          />
        )
      })}
    </div>
  )
}

export const SelectSessionDataIcon: React.FC<{
  imageURL: string
  title: string | number
  isSelected: boolean
  onClick: () => void
}> = ({ imageURL, title, isSelected, onClick }): JSX.Element => {
  const [actualURL, setActualURL] = useState("")

  useEffect(() => {
    if (imageURL === "") return
    const storage = getStorage()
    const backImageStorageRef = ref(storage, imageURL)
    getDownloadURL(backImageStorageRef)
      .then((downloadURL) => {
        setActualURL(downloadURL)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [imageURL])

  let className = styles.SelectSessionDataIconBack

  if (isSelected) {
    className += " " + styles.SelectSessionDataIconSelected
  }

  return (
    <div className={styles.SelectSessionDataIcon} onClick={onClick} style={{ backgroundImage: "url(" + actualURL + ")" }}>
      <div className={className}>{title}</div>
    </div>
  )
}

export const Main: React.FC<{}> = (): JSX.Element => {
  const { user } = useContext(AppContext)
  const navigate = useNavigate()
  const { sessionID } = useParams<{ sessionID: string }>()
  const anchorRef = useAnchorScroll(sessionID)
  const studioProduct = useStudioProduct(studioID)
  const [selectedSessionData, setSelectedSessionData] = useState(0)
  const [session, setSession] = useSession(sessionID)

  return (
    <div className={styles.root}>
      <div className={styles.VideoTop}>
        <IntroVideo videoURL={MonaVideo} backdrop={ImagePlaceHolder}>
          <IntroBody
            title="Create your Revivar Polaroid"
            subTitle="Custom Live Polaroid Prints with an AR Video"
            session={session}
            onClick={() => {
              if (studioProduct?.productVariantList[0] === undefined) return
              CreateStudioSession(studioProduct?.productVariantList[0], user?.uid ?? "", studioID).then((session) => {
                setSession(session)
                navigate({
                  pathname: "/studio/" + studioID + "/" + session.uid,
                })
                anchorRef.current?.scrollIntoView({ behavior: "smooth", block: "start" })
              })
            }}
          />
        </IntroVideo>
      </div>
      <a ref={anchorRef}></a>
      <br />
      <PosterPreview
        session={session}
        setSession={setSession}
        product={studioProduct}
        sessionID={sessionID}
        studioID={studioID}
        posterType={"polaroid"}
        sessionPosition={selectedSessionData}
        setSessionPosition={setSelectedSessionData}
        grandChildren={
          <SelectSessionData selected={selectedSessionData} setSelected={setSelectedSessionData} sessionID={sessionID} />
        }
      >
        <VariantContainer
          product={studioProduct}
          setStudioSession={(a) => {
            setSelectedSessionData(0)
            setSession(a)
          }}
          studioSession={session}
        />
      </PosterPreview>
      <IncludedList />
      <br />
      <FrequentList />
    </div>
  )
}
export default Main
