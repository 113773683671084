import React from "react"
import styles from "./create.module.css"
import { setDoc } from "firebase/firestore"
import { doc, collection } from "firebase/firestore"
import { firestoreDB } from "../../App"
import { Product, ProductVariant } from "schema/dist/src/product"
import { StudioSession, StudioSessionAssets, SessionType, Orientation } from "schema/dist/src/studio"
import { CloseStudioSession } from "./schema"
import { IncludedListItem } from "../../shop/product"
import { AddToCart } from "../../cart/cart"
import { CreationControllerStart } from "../polaroid/helper"
import { addProductIDToSession } from "../poster"
interface VariantContainerProps {
  product: Product | undefined
  studioSession: StudioSession | undefined
  setStudioSession: (a: StudioSession | undefined) => void
  showType?: boolean
}
/**
 * VariantContainer get the product of a related to a session
 */
export const VariantContainer: React.FC<VariantContainerProps> = ({
  product,
  studioSession,
  setStudioSession,
  showType,
}): JSX.Element => {
  const selectedVariant = studioSession?.productVariant

  if (studioSession === undefined) return <></>

  return (
    <div className={styles.VariantContainer}>
      {product?.productVariantList?.length === 1 ? "Dimensions" : "Select a Size:"}
      <div className={styles.VariantContainerList}>
        {product?.productVariantList?.map((val) => {
          const isSelected = val.uid === selectedVariant?.uid

          let containerClass = styles.VariantButton

          if (isSelected) {
            containerClass += " " + styles.VariantButtonSelected
          }

          return (
            <div
              key={val.uid + val.title + val.size}
              className={containerClass}
              onClick={() => {
                if (studioSession?.editable) {
                  const newSession = addProductIDToSession(studioSession, val)
                  setStudioSession(newSession)
                }
              }}
            >
              {showType ? <div> {val.sessionType} </div> : null}
              <div> {val.title ?? val.size} </div>
              <div> ${(val.price / 100).toFixed(2)} </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

interface VariantOrientationProps {
  setOrientation: (value: Orientation) => void
  orientation?: Orientation
}

export const VariantOrientation: React.FC<VariantOrientationProps> = ({
  setOrientation,
  orientation = "vertical",
}): JSX.Element => {
  const orientationList: Orientation[] = ["vertical", "horizontal"]
  return (
    <div className={styles.VariantContainer}>
      Orientation
      <div className={styles.VariantOrientationContainer}>
        {orientationList.map((a) => {
          let className = styles.VariantOrientationButton

          if (a === orientation) {
            className += " " + styles.VariantOrientationButtonSelected
          }
          return (
            <div
              className={className}
              onClick={() => {
                setOrientation(a)
              }}
              key={a}
            >
              {a}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const IntroVideo: React.FC<{ videoURL: string; backdrop?: string }> = ({
  videoURL,
  children,
  backdrop = "",
}): JSX.Element => {
  return (
    <div className={styles.IntroVideoContainer} style={{ backgroundImage: "url(" + backdrop + ")" }}>
      <div className={styles.IntroVideoContainerMain}>{children}</div>
      <video loop autoPlay muted playsInline src={videoURL}></video>
    </div>
  )
}

// ValidateStudioSession checks there is a valid session else it creates one and returns the session object
export function ValidateStudioSession(
  session: StudioSession | undefined,
  selectedVariant: ProductVariant,
  userID: string,
  sessionType: SessionType
): Promise<{ session: StudioSession; new: boolean }> {
  return new Promise<{ session: StudioSession; new: boolean }>((resolve, reject) => {
    if (session !== undefined) {
      resolve({ session, new: false })
    } else {
      CreateStudioSession(selectedVariant, userID, sessionType)
        .then((session) => {
          resolve({ session, new: true })
        })
        .catch((err) => reject(err))
    }
  })
}
export function CreateStudioSession(selectedVariant: ProductVariant, userID: string, sessionType: SessionType) {
  const sessionCollRef = collection(firestoreDB, "studioSessions")
  let sessionRef = doc(sessionCollRef)
  const sessionData: StudioSessionAssets[] = []

  // there must be at least one sessionData
  let count = selectedVariant.count ?? 0
  if (count === 0) {
    count = 1
  }

  for (let index = 0; index < count; index++) {
    const posterRef = doc(collection(firestoreDB, "userPosters"))
    sessionData.push({
      videoURL: "",
      imageURL: "",
      posterUID: posterRef.id,
    })
  }

  const newSession: StudioSession = {
    uid: sessionRef.id,
    sessionType: sessionType,
    sessionData: sessionData,
    customerID: userID,
    productVariant: selectedVariant,
    editable: true,
  }

  newSession.productVariant.orientation = "vertical"

  return setDoc(sessionRef, newSession).then(() => {
    return newSession
  })
}

// AddSessionToCart updates the user ID and closes the session so it is no longer editable
// we also add the studio ID so the user can access the studio used in creating the session
export function AddSessionToCart(
  userID: string,
  session: StudioSession,
  studioID: string,
  sessionID: string,
  product: Product,
  count?: number
) {
  session.productVariant.studioID = studioID
  session.productVariant.studioSessionID = sessionID
  session.customerID = userID
  return CloseStudioSession(session).then(() => {
    return AddToCart(userID, product, session.productVariant, count)
  })
}

export const IncludedList: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.PosterInfoAttributes}>
      <b>Whats included </b>
      <div className={styles.PosterInfoActionsIncludedContainer}>
        <IncludedListItem imageClass={styles.FilePictureIcon} title={"Print of the picture you ordered"} isVisible={true} />
        <IncludedListItem imageClass={styles.TextureIcon} title={"Premium Matte Texture"} isVisible={true} />
        <IncludedListItem imageClass={styles.NotNopeIcon} title={"Frames are NOT included"} isVisible={true} />
        <IncludedListItem imageClass={styles.QRCodeIcon} title={"Revivar NFC sticker"} isVisible={true} />
      </div>
    </div>
  )
}

export const FrequentList: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.PosterInfoAttributes}>
      <b>Frequently Asked Question</b>
      <div className={styles.FrequentListContainer}>
        <b>Is this an NFT?</b>
        <span>No this is not an NFT but can be used to show host your NFT image and picture</span>
        {/* <b>How does it work?</b>
        <span>Its not easy</span> */}
      </div>
    </div>
  )
}

export const CreationSteps: React.FC<{ steps: number }> = ({ steps }): JSX.Element => {
  return (
    <div className={styles.CreationSteps}>
      <b> Revivar Creation Steps</b>
      <span className={steps === -1 ? styles.CreationStepsBold : ""}>Step 0: Select a size</span>
      <span className={steps === 0 ? styles.CreationStepsBold : ""}>
        Step 1: Upload the video that will be visible through AR
      </span>
      <span className={steps === 1 ? styles.CreationStepsBold : ""}>
        Step 2: Move the slider select a frame and or upload the image that will be printed
      </span>
      <span className={steps === 2 ? styles.CreationStepsBold : ""}>Step 2: (optional) Add a Note</span>
      <span className={steps === 3 ? styles.CreationStepsBold : ""}>Step 3: Click "Add to Cart"</span>
    </div>
  )
}

export const CreateExternalVideo: React.FC<{ id: string }> = ({ id }): JSX.Element => {
  let canvaWebsite = "https://www.canva.com/video-messages/templates/"

  if (id === "birthday") {
    canvaWebsite = "https://www.canva.com/create/birthday-videos/"
  } else if (id === "christmas") {
    canvaWebsite = "https://www.canva.com/templates/?query=christmas&fFeature=FEATURE_VIDEO&fFeature=FEATURE_ANIMATION"
  } else if (id === "postcard") {
    canvaWebsite = "https://www.canva.com/create/wedding-invitation-videos/"
  }

  return (
    <div className={styles.CreateExternalVideoContainer}>
      or create with
      <a className={styles.CreateExternalVideo} href={canvaWebsite} target="_blank">
        <div className={styles.CreateCanvasVideo}>
          <div className={styles.CreateCanvasImage} />
        </div>
      </a>
    </div>
  )
}

export function ValidateAssetSize(a: File | Blob, type?: "video" | "image"): { valid: boolean; reason: string } {
  const size = a.size / (1024 * 1024)
  let maxSize: number
  switch (type) {
    case "video":
      maxSize = 40
      break
    case "image":
      maxSize = 4
      break
    default:
      maxSize = 30
  }
  const isOverSized = size > maxSize

  if (isOverSized) {
    return { valid: false, reason: `Error: File size is ${size.toFixed(1)}MB, Max is ${maxSize}MB` }
  }

  return { valid: true, reason: "" }
}

interface IntroBodyProps {
  session: StudioSession | undefined
  onClick: () => void
  title: string
  subTitle: string
}

export const IntroBody: React.FC<IntroBodyProps> = ({ session, onClick, title, subTitle }): JSX.Element => {
  return (
    <div className={styles.IntroBodyProps}>
      <b>{title} </b>
      <span> {subTitle}</span>
      <CreationControllerStart onClick={onClick} isHidden={session !== undefined} text={"Start Designing"} />
    </div>
  )
}
