import styles from "./index.module.css"
import { useEffect, useState, useRef } from "react"
import { useParams, useNavigate, useSearchParams } from "react-router-dom"
import { firestoreDB } from "../../App"
import React from "react"
import { getStorage, ref, deleteObject } from "firebase/storage"
import { collection, deleteDoc, doc, getDocs, onSnapshot, setDoc, Timestamp } from "firebase/firestore"
import {
  CreateDefaultEvent,
  Event,
  CreateDefaultEventMedia,
  EventMedia,
  EventRelatedImage,
  CreateDefaultEventRelatedImage,
} from "schema/dist/src/event"
import { UploadCallBackFunction, uploadFile } from "../product/create"
import { EditActions, EditEventMainImage, EditInputDate, EditInputText, EditMediaProduct, EditRelatedImage } from "./edit"
import {
  eventMediaAssetsFolderName,
  eventMediaDefaultImagesFolderName,
  eventMediaFolderName,
  eventMediaRelatedImagesFolderName,
} from "./media"
import { Revivart2D } from "../../events/revivart"
import { Orientation, SessionType } from "schema/dist/src/studio"
import { SizeToDimensions } from "../../shop/product"
import { Product } from "schema/dist/src/product"
import { ValidateAssetSize } from "../../create/shared/shared"
import plusIcon from "../../assets/addRounded.svg"
import { AdminActions, AdminActionsButton } from "../shared"
import { GetCoupon, GetDiscount } from "../discount/discount"
import { Coupon, Discount } from "schema/dist/src/discount"
import { viewPendingString } from "../../events/event"
import { CreateEventSticker, EventSticker } from "schema/dist/src/sticker"

type RouteParams = {
  eventID: string
}

export const EventDetails: React.FC<{}> = (): JSX.Element => {
  //get the event id from the params of the page/route
  const { eventID } = useParams<RouteParams>()
  const [selectedEvent, setSelectedEvent] = useState<Event>()
  const [eventMedias, setEventMedias] = useState<EventMedia[]>()
  const [editDetails, setEditDetails] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [studioProduct, setStudioProduct] = useState<Product>()
  const [uploadingText, setUploadingText] = useState("UPLOAD VIDEO")
  const [uploadCount, setUploadCount] = useState(1)
  const [uploadTotalCount, setUploadTotalCount] = useState(0)
  const nav = useNavigate()
  const [params, setParams] = useSearchParams()
  const viewPending = params.get(viewPendingString)

  //add onSnapshot listener to update the event and eventmedias if there are changes
  useEffect(() => {
    if (eventID === undefined) return

    const eventDetailsRef = doc(firestoreDB, "events", eventID)
    let eventMediaCol = collection(eventDetailsRef, "medias")

    if (viewPending === "true") {
      eventMediaCol = collection(eventDetailsRef, "pending")
    }

    //fetch the event
    const done = onSnapshot(eventDetailsRef, (response) => {
      const event = response.data() as Event

      const showEdit =
        event.title === "" ||
        event.selectedVariants === undefined ||
        event.selectedVariants.length === 0 ||
        event.defaultSelectedVariant === undefined ||
        event.defaultSelectedVariant === ""

      if (showEdit) {
        setEditDetails(true)
      }
      setSelectedEvent(event)
    })

    //fetch the medias related to the event
    const videosDone = onSnapshot(eventMediaCol, (response) => {
      const eventMedias = response.docs.map((doc) => {
        return doc.data() as EventMedia
      })
      setEventMedias(eventMedias)
    })

    return () => {
      done()
      videosDone()
    }
  }, [eventID, viewPending])

  useEffect(() => {
    if (selectedEvent === undefined) return
    if (selectedEvent.productUID === "") return

    const productVariantsRef = doc(firestoreDB, "shopProducts", selectedEvent.productUID)

    const done = onSnapshot(productVariantsRef, (response) => {
      const product = response.data() as Product
      setStudioProduct(product)
    })

    return () => {
      done()
    }
  }, [selectedEvent])

  useEffect(() => {
    if (uploadTotalCount === 0) {
      setUploadingText("UPLOAD VIDEO")
    } else {
      setUploadingText(`Uploading ${uploadCount} / ${uploadTotalCount}`)
    }
  }, [uploadCount, uploadTotalCount])

  if (!selectedEvent) return <></>

  const defaultVariantID = selectedEvent.defaultSelectedVariant
  const defaultVariant = studioProduct?.productVariantList.find((variant) => {
    return variant.uid === defaultVariantID
  })

  const posterRatio = SizeToDimensions(defaultVariant?.size ?? "") ?? 0
  let posterType: SessionType = "poster"
  const orientation = defaultVariant?.orientation
  if (defaultVariant?.sessionType !== undefined && defaultVariant?.sessionType !== "none") {
    posterType = defaultVariant?.sessionType
  }

  return (
    <>
      <AdminActions>
        <AdminActionsButton
          onClick={() => {
            setEditDetails(!editDetails)
          }}
          title={editDetails ? "VIEW GALLERY" : "EDIT EVENT"}
        />
        <AdminActionsButton
          onClick={() => {
            hiddenFileInput.current?.click()
          }}
          title={uploadingText}
        >
          <input
            style={{ display: "none" }}
            ref={hiddenFileInput}
            type="file"
            accept=".mp4"
            multiple
            onChange={(e) => {
              if (selectedEvent === undefined || e.target.files === null) {
                return
              }

              setUploadTotalCount(e.target.files.length)
              const uploadsPromiseList: Promise<string>[] = []
              for (var i = 0; i < e.target.files.length; i++) {
                setIsUploading(true)
                uploadsPromiseList.push(onVideoSelected(e.target.files[i], selectedEvent, uploadCount, setUploadCount))
              }

              Promise.all(uploadsPromiseList)
                .then((values) => {
                  setEditDetails(false)
                })
                .catch((values) => {
                  console.log(values)
                })
                .finally(() => {
                  setIsUploading(false)
                  setUploadCount(1)
                  setUploadTotalCount(0)
                })

              e.target.files = null
              e.target.value = ""
            }}
          />
        </AdminActionsButton>
        <AdminActionsButton
          onClick={() => {
            window.open(`/events/${selectedEvent.uid}`, "_blank")
          }}
          title={"LIVE PAGE"}
        />
        <AdminActionsButton
          onClick={() => {
            if (viewPending === "true") {
              params.delete(viewPendingString)
              setParams(params)
            } else {
              setParams([[viewPendingString, "true"]])
            }
          }}
          title={"PENDING"}
        />
        <AdminActionsButton
          onClick={() => {
            nav(`./../`)
          }}
          title={"back"}
        />
      </AdminActions>

      <h3>{isUploading ? "Uploading Videos" : selectedEvent?.title !== "" ? selectedEvent?.title : "Untitled Event"}</h3>
      <div className={styles.ProductListContainer}>
        {editDetails ? (
          <EventEdit selectedEvent={selectedEvent} />
        ) : (
          eventMedias?.map((val) => (
            <EventMediaAssetItem
              media={val}
              key={val.uid}
              type={posterType}
              posterRatio={posterRatio}
              orientation={orientation}
            />
          ))
        )}
      </div>
    </>
  )
}

//when a video is uploaded
export function onVideoSelected(
  a: File | Blob,
  event: Event,
  uploadCount: number,
  setUploadCount: (b: number) => void
): Promise<string> {
  return new Promise((resolve, reject) => {
    const valid = ValidateAssetSize(a, "video")
    if (!valid.valid) {
      console.log("Try again." + valid.reason)
      reject(valid.reason)
      return
    }

    CreateNewEventMedia(event.uid, event.title, event.description)
      .then((media) => {
        const fileName = media.uid + "video"
        const location = `/${eventMediaFolderName}/${media.eventUid}/${eventMediaAssetsFolderName}/${media.uid}/`
        return uploadFile(
          fileName,
          location,
          a,
          () => {},
          (a) => {
            setUploadCount(uploadCount + 1)
          }
        )
          .then((ref) => {
            media.videoURL = ref.dest

            return UpdateEventMedia(media, media.eventUid)
          })
          .then((a) => {
            resolve(media.uid)
          })
      })
      .catch((err) => {
        reject(err)
      })
  })
}

interface EventEditProps {
  selectedEvent: Event
}

const onRelatedFileSelected = (
  a: File | Blob,
  image: EventRelatedImage,
  event: Event,
  field: "relatedImages" | "defaultPrints"
) => {
  const size = a.size / (1024 * 1024)
  const ratio = size < 1 ? 0.9 : size < 5 ? 0.7 : size < 10 ? 0.3 : 0.2
  CompressImage(a, ratio).then((b) => {
    const valid = ValidateAssetSize(b, "image")
    if (!valid.valid) {
      console.log("Try again." + valid.reason)
      return
    }

    const folderLocation = field === "defaultPrints" ? eventMediaDefaultImagesFolderName : eventMediaRelatedImagesFolderName
    const fileName = image.uid + "LDimage"
    const location = `/${eventMediaFolderName}/${event.uid}/${folderLocation}/`
    uploadFile(fileName, location, b, (a) => {
      console.log(a)
    }).then((ref) => {
      image.imageLDURL = ref.dest

      if (event[field] === undefined) {
        event[field] = []
      }

      event[field]?.push(image)

      UpdateEvent(event)
    })
  })
}

export const EventEdit: React.FC<EventEditProps> = ({ selectedEvent }): JSX.Element => {
  const expiry = selectedEvent.expirationDate.toDate().toLocaleDateString()
  return (
    <>
      <div className={styles.EditEventContainer}>
        <EditEventMainImage event={selectedEvent} />
        <div className={styles.EditEventFieldsContainer}>
          <EditInputText event={selectedEvent} placeholder="Event Name" fieldType="title" actionButton="Save Title" />
          <EditInputText event={selectedEvent} placeholder="Event Description" fieldType="description" actionButton="Save Desc" />
          <EditInputText event={selectedEvent} placeholder="keywords" fieldType="keywords" actionButton="Save Keywords" />
          <EditInputText event={selectedEvent} placeholder="Learn More Link" fieldType="url" actionButton="Save Link" />
          <EditInputText event={selectedEvent} placeholder="Event password" fieldType="passHash" actionButton="Save Pass" />
          <EditMediaProduct event={selectedEvent} />
          <EditInputDate event={selectedEvent} actionButton={"Save Date"} />
          <EditInputText event={selectedEvent} placeholder="Coupon ID" fieldType="couponID" actionButton="Enter Coupon ID" />
          <EditInputText
            event={selectedEvent}
            placeholder="Discount ID"
            fieldType="discountID"
            actionButton="Enter Discount ID"
          />
          <EventMediaCouponData event={selectedEvent} />
        </div>
      </div>
      <div className={styles.ExtraPicturesContainer}>
        <p>Images Related to {selectedEvent.title !== "" ? selectedEvent.title : "this event"}</p>
        <UploadButton
          maxLength={4}
          currentLength={selectedEvent.relatedImages.length}
          onSelect={(file) => {
            const relatedImage = CreateDefaultEventRelatedImage()
            onRelatedFileSelected(file, relatedImage, selectedEvent, "relatedImages")
          }}
        />
        {selectedEvent.relatedImages.map((image) => (
          <EditRelatedImage image={image} event={selectedEvent} key={image.uid} imageType="relatedImages" />
        ))}
      </div>

      <div className={styles.ExtraPicturesContainer}>
        <p>Add Pre-Printed Images</p>
        {selectedEvent.defaultPrints?.map((image) => (
          <EditRelatedImage image={image} event={selectedEvent} key={image.uid} imageType="defaultPrints" />
        ))}
        <UploadButton
          maxLength={4}
          currentLength={selectedEvent.defaultPrints?.length ?? 0}
          onSelect={(file) => {
            const relatedImage = CreateDefaultEventRelatedImage()
            onRelatedFileSelected(file, relatedImage, selectedEvent, "defaultPrints")
          }}
        />
      </div>
      <p className={styles.EventExpiry}>
        This Event will expire on {expiry}.<a href="mailto:tobenna@revivar.app">Extend Expiration</a>
      </p>
      <EditActions event={selectedEvent} />
    </>
  )
}

interface UploadButtonProps {
  maxLength: number
  onSelect: (file: File) => void
  currentLength: number
}

export const UploadButton: React.FC<UploadButtonProps> = ({ maxLength, currentLength, onSelect }): JSX.Element | null => {
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  if (currentLength >= maxLength) return null

  return (
    <div className={styles.AddRelatedImage} onClick={() => hiddenFileInput.current?.click()}>
      <img src={plusIcon} alt="" />
      <input
        style={{ display: "none" }}
        ref={hiddenFileInput}
        type="file"
        accept={".png, .jpeg, .jpg, .png"}
        multiple
        onChange={(e) => {
          if (e.target.files !== null) {
            const itemsRemaining = maxLength - currentLength
            const iteration = Math.min(itemsRemaining, e.target.files.length)
            for (let i = 0; i < iteration; i++) {
              const file = e.target.files[i]
              onSelect(file)
            }
          }
          e.target.files = null
          e.target.value = ""
        }}
      />
    </div>
  )
}

interface DownloadStickerTagProps {
  event: Event
}

/**
 * Deprecated / never used
 * @param param0
 * @returns
 */
export const DownloadStickerTag: React.FC<DownloadStickerTagProps> = ({ event }): JSX.Element | null => {
  return (
    <div
      className={styles.DownloadStickerTag}
      onClick={() => {
        const ref = doc(collection(firestoreDB, "eventStickers"))
        const eventSticker = CreateEventSticker(ref.id, event, Timestamp.now())

        UpdateEventSticker(eventSticker).then((a) => {
          console.log(a)
        })
      }}
    >
      Download Sticker Code
    </div>
  )
}

export function UpdateEventSticker(sticker: EventSticker) {
  const eventCol = collection(firestoreDB, "eventStickers")
  const ref = doc(eventCol, sticker.uid)

  return setDoc(ref, sticker).then(() => {
    return sticker
  })
}

interface EventMediaBlockProps {
  media: EventMedia
  posterRatio: number
  type: SessionType
  orientation: Orientation | undefined
}

export const EventMediaAssetItem: React.FC<EventMediaBlockProps> = ({ media, posterRatio, type, orientation }): JSX.Element => {
  const nav = useNavigate()

  return (
    <div className={styles.EventMediaAssetItem}>
      <Revivart2D
        key={media.uid}
        imageURL={media.imageLDURL}
        videoURL={media.videoURL}
        ratio={posterRatio}
        type={type}
        onClick={() => {
          nav(`./asset/${media.uid}/edit`)
        }}
        orientation={orientation}
      />
    </div>
  )
}

export const EventMediaCouponData: React.FC<{ event: Event }> = ({ event }): JSX.Element => {
  // TODO: NAV TO COUPON PAGE
  // const nav = useNavigate()

  const [couponItem, setCouponItem] = useState<Coupon>()
  const [discountItem, setDiscountItem] = useState<Discount>()
  const [mainDiscountItem, setMainDiscountItem] = useState<Discount>()

  useEffect(() => {
    if (event.couponID === undefined || event.couponID === "") return

    GetCoupon(event.couponID).then((coupon) => {
      setCouponItem(coupon)
      if (coupon === undefined) return
      setDiscountItem(coupon.discount)
    })
  }, [event.couponID])

  useEffect(() => {
    if (event.discountID === undefined || event.discountID === "") return

    GetDiscount(event.discountID).then((discount) => {
      setMainDiscountItem(discount)
    })
  }, [event.discountID])

  return (
    <>
      <div className={styles.EventMediaCouponData}>
        Coupon Name: {couponItem?.code} -
        {discountItem?.type === "amount" ? (
          <> Amount Off ${(discountItem.amountOff ?? 0) / 100}</>
        ) : (
          <> Percent Off: {discountItem?.percentOff} %</>
        )}
        <br />
        Max Redemptions: {couponItem?.maxRedemptions}
      </div>

      <br />

      {mainDiscountItem !== undefined && (
        <div className={styles.EventMediaCouponData}>
          Discount Name: {mainDiscountItem?.title} -
          {discountItem?.type === "amount" ? (
            <> Amount Off ${(mainDiscountItem.amountOff ?? 0) / 100}</>
          ) : (
            <> Percent Off: {mainDiscountItem?.percentOff} %</>
          )}
          <br />
          Active: {mainDiscountItem?.isActive}
        </div>
      )}
    </>
  )
}

export function CreateNewEvent(userID: string) {
  const ref = doc(collection(firestoreDB, "events"))
  const date = new Date()
  date.setMonth(date.getMonth() + 2)

  const newEvent = CreateDefaultEvent(ref.id, userID, Timestamp.now(), Timestamp.fromDate(date))

  return UpdateEvent(newEvent).then(() => {
    return ref.id
  })
}

export function UpdateEvent(eventSelected: Event) {
  const eventDetailsRef = doc(firestoreDB, "events", eventSelected.uid)

  return setDoc(eventDetailsRef, eventSelected)
}

export function DeleteEvent(eventSelected: Event) {
  const storage = getStorage()

  const eventDetailsRef = doc(firestoreDB, "events", eventSelected.uid)
  const eventMediaCol = collection(eventDetailsRef, "medias")
  const eventMediaAnalyticsCol = collection(eventDetailsRef, "analytics")

  const deleteMediaDocs = getDocs(eventMediaCol).then((results) => {
    const list = results.docs.flatMap((doc) => {
      const asset = doc.data() as EventMedia

      return DeleteEventMedia(asset, asset.eventUid)
    })

    return Promise.all(list)
  })

  const deleteAnalyticsDocs = getDocs(eventMediaAnalyticsCol).then((results) => {
    const list = results.docs.flatMap((doc) => {
      return deleteDoc(doc.ref)
    })

    return Promise.all(list)
  })

  const promiseList: Promise<void>[] = []

  eventSelected.relatedImages.forEach((relatedImage) => {
    if (relatedImage.imageLDURL !== "") {
      promiseList.push(deleteObject(ref(storage, relatedImage.imageLDURL)))
    }
  })

  if (eventSelected.imageLDURL !== "") {
    promiseList.push(deleteObject(ref(storage, eventSelected.imageLDURL)))
  }

  if (eventSelected.imageHDURL !== "") {
    promiseList.push(deleteObject(ref(storage, eventSelected.imageHDURL)))
  }

  return Promise.all([deleteMediaDocs, deleteDoc(eventDetailsRef), ...promiseList, deleteAnalyticsDocs])
}

//when a video is uploaded
export function onPendingVideoSelected(
  a: File | Blob,
  event: Event,
  uploaderID: string,
  callback: UploadCallBackFunction
): Promise<string> {
  return new Promise((resolve, reject) => {
    const valid = ValidateAssetSize(a, "video")

    if (!valid.valid) {
      console.log("Try again." + valid.reason)
      reject(valid.reason)
      return
    }

    CreatePendingEventMedia(event, uploaderID)
      .then((media) => {
        const fileName = media.uid + "video"
        const location = `/${eventMediaFolderName}/${media.eventUid}/${eventMediaAssetsFolderName}/${media.uid}/`
        return uploadFile(fileName, location, a, callback, (a) => {
          console.log(a)
        })
          .then((ref) => {
            media.videoURL = ref.dest
            return UpdatePendingEventMedia(media, media.eventUid)
          })
          .then((a) => {
            resolve(media.uid)
          })
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function CreatePendingEventMedia(event: Event, uploaderID: string): Promise<EventMedia> {
  const eventMediaRef = doc(collection(firestoreDB, "events", event.uid, "pending"))

  const newMedia = CreateDefaultEventMedia(eventMediaRef.id, Timestamp.now(), event.uid)
  newMedia.title = event.title
  newMedia.description = event.description
  newMedia.uploaderID = uploaderID
  newMedia.pendingApproval = true
  return UpdatePendingEventMedia(newMedia, event.uid)
}

export function UpdatePendingEventMedia(media: EventMedia, eventID: string = media.eventUid) {
  const eventCol = collection(firestoreDB, "events")
  const eventMediaRef = doc(eventCol, eventID, "pending", media.uid)

  return setDoc(eventMediaRef, media).then(() => {
    return media
  })
}

export function CreateNewEventMedia(eventID: string, title = "", description = ""): Promise<EventMedia> {
  const eventMediaRef = doc(collection(firestoreDB, "events", eventID, "medias"))

  const newMedia = CreateDefaultEventMedia(eventMediaRef.id, Timestamp.now(), eventID)
  newMedia.title = title
  newMedia.description = description

  return UpdateEventMedia(newMedia, eventID)
}

export function UpdateEventMedia(media: EventMedia, eventID: string = media.eventUid) {
  const eventCol = collection(firestoreDB, "events")
  const eventMediaRef = doc(eventCol, eventID, "medias", media.uid)

  return setDoc(eventMediaRef, media).then(() => {
    return media
  })
}

export function DeleteEventMedia(
  media: EventMedia,
  eventID: string = media.eventUid,
  collectionName: "medias" | "pending" = "medias"
) {
  const storage = getStorage()

  const eventCol = collection(firestoreDB, "events")

  const eventMediaRef = doc(eventCol, eventID, collectionName, media.uid)

  const promiseList: Promise<void>[] = []

  if (media.imageLDURL !== "") {
    promiseList.push(deleteObject(ref(storage, media.imageLDURL)))
  }

  if (media.imageHDURL !== "") {
    promiseList.push(deleteObject(ref(storage, media.imageHDURL)))
  }

  if (media.videoURL !== "") {
    promiseList.push(deleteObject(ref(storage, media.videoURL)))
  }

  return Promise.all([...promiseList, deleteDoc(eventMediaRef)])
}

export function DeleteEventRelatedImage(image: EventRelatedImage, eventSelected: Event, save = true) {
  const storage = getStorage()
  eventSelected.relatedImages = eventSelected.relatedImages.filter((relatedImage) => relatedImage.uid !== image.uid)
  return Promise.all([deleteObject(ref(storage, image.imageLDURL)), UpdateEvent(eventSelected)])
}

export function DeleteEventDefaultImage(image: EventRelatedImage, eventSelected: Event, save = true) {
  const storage = getStorage()
  eventSelected.defaultPrints = eventSelected.defaultPrints?.filter((defaultPrint) => defaultPrint.uid !== image.uid)
  return Promise.all([deleteObject(ref(storage, image.imageLDURL)), UpdateEvent(eventSelected)])
}

export function CompressImage(file: File | Blob, quality: number): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const imgsrc = URL.createObjectURL(file)
    const img = new Image()
    img.src = imgsrc
    img.onload = () => {
      const width = img.naturalWidth * quality
      const height = img.naturalHeight * quality
      const canvas = document.createElement("canvas")
      canvas.width = width
      canvas.height = height
      const context = canvas.getContext("2d")

      context?.drawImage(img, 0, 0, width, height)

      canvas.toBlob(
        (blob) => {
          URL.revokeObjectURL(imgsrc)
          resolve(blob || file)
        },
        "image/jpeg",
        quality
      )
    }
  })
}
