import React, { useContext, useEffect, useState } from "react"
import styles from "./index.module.css"
import headset from "../assets/headset.svg"
import cardboardHeadset from "../assets/cardboardHeadset.png"
import regularHeadset from "../assets/regularHeadset.png"
import immersedVideo from "../assets/video/museum.mp4"
import { AppContext, firestoreDB } from "../App"
import { Product } from "schema/dist/src/product"
import { collection, getDocs, query, where } from "firebase/firestore"
import { useLocation, useNavigate } from "react-router-dom"
import { returnPageKey } from "../auth"

// https://mailtolinkgenerator.com/

const Mail =
  "mailto:hello@reivar.app?cc=tobenna@reivar.app&subject=Revivar%20Immersed%20&body=Hey%20Revivar%2C%20%0A%0AI%20would%20love%20to%20use%20Revivar%20Immersed%20and%20I%20would%20your%20help%20with%20this!%20"

export const Main: React.FC<{}> = (): JSX.Element => {
  const { setNavType } = useContext(AppContext)

  const [cardboardProduct, setCardboardProduct] = useState<Product>()
  useEffect(() => {
    setNavType("home")
    return () => {
      setNavType("bar")
    }
  }, [])

  const nav = useNavigate()
  const loc = useLocation()

  useEffect(() => {
    const posterVariantsRef = collection(firestoreDB, "shopProducts")
    const qu = query(posterVariantsRef, where("tag", "==", "cardboard"))
    getDocs(qu)
      .then((responses) => {
        const products: Product[] = []
        responses.docs.forEach((doc) => {
          const variant = doc.data() as Product
          products.push(variant)
        })
        const selectedProd = products[0]

        setCardboardProduct(selectedProd)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  return (
    <div className={styles.root}>
      <div className={styles.HeadTop}>
        <div className={styles.HeadTopMin}>
          <div className={styles.LogoImage}></div>
          <div className={styles.LogoText}></div>
        </div>
      </div>
      <div className={styles.ImmersedExamples}>
        <div className={styles.SubMessage}>Introducing</div>
        <div className={styles.Title}>Re-Immersed </div>
        <div className={styles.SubMessage}>Beta</div>
        <img className={styles.headSet} src={headset} alt="" />
      </div>
      <div className={styles.ImmersedExamples}>
        <div className={styles.SubTitle}>Affordable Mixed Reality </div>
        <div className={styles.SubMessageSmall}>
          Revivar Immersed is a new way to experience your live art on you Phone. By reviving long forgotten technology, you can
          now have a mixed reality experience, directly on you device
        </div>
        <div className={styles.SubTitle}>Try different headsets</div>
        <div className={styles.SubMessageSmall}>Revivar Immersed works on different headsets types</div>
        <div className={styles.headSets}>
          <div className={styles.headSetsType}>
            <span> Cardboard</span>
            <div className={styles.headSetsImage} style={{ backgroundImage: "url(" + cardboardHeadset + ")" }} />
            <button
              className={styles.headSetsButton}
              onClick={() => {
                nav("/shop/product/" + cardboardProduct?.uid + `?${returnPageKey}=` + encodeURIComponent(loc.pathname))
              }}
            >
              Order
            </button>
          </div>
          <div className={styles.headSetsType}>
            <span> Regular / Apple</span>
            <div className={styles.headSetsImage} style={{ backgroundImage: "url(" + regularHeadset + ")" }} />
            <button className={styles.headSetsButton}>Coming Soon</button>
          </div>
        </div>
        <div className={styles.SubTitle}>Perfect for Galleries</div>
        <div className={styles.SubMessageSmall}>You can now have an Immersive gallery where all photos are AR enabled</div>
      </div>
      <video loop muted playsInline className={styles.immersedPhoto} src={immersedVideo} controls autoPlay />
      <a className={styles.ContactUsButton} href={Mail}>
        Contact us
      </a>
    </div>
  )
}
export default Main
