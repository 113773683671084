import styles from "./index.module.css"
import { useEffect, useRef, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { firestoreDB } from "../../App"
import React from "react"
import { doc, onSnapshot } from "firebase/firestore"
import { vendorsCollectionName, DeleteVendor, UpdateVendor } from "./create"
import { AdminActions, AdminActionsButton } from "../shared"
import { Vendor, VendorTypes } from "schema/dist/src/user"
import { CompressImage } from "../event/create"
import { uploadFile } from "../product/create"
import { getDownloadURL, getStorage, ref } from "firebase/storage"

type RouteParams = {
  vendorID: string
}

export const ratingsRange = [1, 2, 3, 4, 5] as const

export const VendorDetails: React.FC<{}> = (): JSX.Element => {
  const { vendorID } = useParams<RouteParams>()

  const [vendorData, setVendorData] = useState<Vendor>()
  const nav = useNavigate()

  useEffect(() => {
    if (vendorID === undefined) return

    const vendorRef = doc(firestoreDB, vendorsCollectionName, vendorID)

    const done = onSnapshot(vendorRef, (response) => {
      const data = response.data() as Vendor
      setVendorData(data)
    })

    return () => {
      done()
    }
  }, [vendorID])

  if (vendorData === undefined) {
    return <>not available</>
  }

  return (
    <>
      <AdminActions>
        <AdminActionsButton
          onClick={() => {
            nav(`../`)
          }}
          title={"BACK"}
        />
        <AdminActionsButton
          onClick={() => {
            nav(`/vendors`)
          }}
          title={"VIEW LIVE PAGE"}
        />
      </AdminActions>
      <CreateVendorDetails vendor={vendorData} />
    </>
  )
}

export const CreateVendorDetails: React.FC<{ vendor: Vendor }> = ({ vendor }): JSX.Element => {
  const [vendorDetails, setVendorDetails] = useState<Vendor>(vendor)
  const nav = useNavigate()

  useEffect(() => {
    setVendorDetails(vendor)
  }, [vendor])

  return (
    <div className={styles.CreateVendorDetails}>
      VENDOR DETAILS
      <EditVendorField vendor={vendorDetails} setVendor={setVendorDetails} field="userName" text="Name" />
      <EditVendorField vendor={vendorDetails} setVendor={setVendorDetails} field="userEmail" text="Email" />
      <EditVendorField vendor={vendorDetails} setVendor={setVendorDetails} field="location" text="Location" />
      <EditVendorField vendor={vendorDetails} setVendor={setVendorDetails} field="link" text="Link" />
      <div className={styles.VendorEditField}>
        <p>Type</p>
        <div className={styles.VendorButtons}>
          <EditVendorButton
            vendor={vendorDetails}
            setVendor={setVendorDetails}
            field="type"
            className={styles.VendorButtonSelected}
            value="vendor"
          />
          <EditVendorButton
            vendor={vendorDetails}
            setVendor={setVendorDetails}
            field="type"
            className={styles.VendorButtonSelected}
            value="photographer"
          />
          <EditVendorButton
            vendor={vendorDetails}
            setVendor={setVendorDetails}
            field="type"
            className={styles.VendorButtonSelected}
            value="event planner"
          />
        </div>
      </div>
      <div className={styles.VendorEditField}>
        <p>Rating</p>
        <div className={styles.VendorTypeButtons}>
          {ratingsRange.map((rating) => (
            <EditVendorButton
              vendor={vendorDetails}
              setVendor={setVendorDetails}
              field="rating"
              className={styles.VendorButtonSelected}
              rating={rating}
            />
          ))}
        </div>
      </div>
      <UploadVendorImage vendor={vendorDetails} setVendor={setVendorDetails} />
      <div className={styles.ActionButtons}>
        <div
          className={styles.DeleteActionButton}
          onClick={() => {
            DeleteVendor(vendorDetails).then(() => {
              nav(`./../`)
            })
          }}
        >
          DELETE
        </div>
        <div
          className={styles.ActionButton}
          onClick={() => {
            UpdateVendor(vendorDetails).then(() => {
              nav(`./../`)
            })
          }}
        >
          SAVE
        </div>
      </div>
    </div>
  )
}

interface EditVendorFieldProps {
  vendor: Vendor
  setVendor: (a: Vendor) => void
  field: "userName" | "userEmail" | "location" | "link"
  text: string
}
export const EditVendorField: React.FC<EditVendorFieldProps> = ({ vendor, setVendor, field, text }): JSX.Element => {
  return (
    <div className={styles.VendorEditField}>
      <p>{text}</p>
      <input
        type="text"
        onChange={(e) => {
          let newVendor: Vendor = { ...vendor }
          newVendor[field] = e.currentTarget.value
          setVendor(newVendor)
        }}
        value={vendor[field]}
      />
    </div>
  )
}

interface UploadVendorImageProps {
  vendor: Vendor
  setVendor: (a: Vendor) => void
}

export const UploadVendorImage: React.FC<UploadVendorImageProps> = ({ vendor, setVendor }): JSX.Element => {
  const location = `/vendorImages/${vendor.uid}/`
  const imageName = `profileImage`
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [actualURL, setActualURL] = useState("")
  const [title, setTitle] = useState("Upload Image")
  useEffect(() => {
    const storage = getStorage()

    if (vendor.profilePictureUrl.includes("gs://")) {
      const imageStorageRef = ref(storage, vendor.profilePictureUrl)
      getDownloadURL(imageStorageRef).then((downloadURL) => {
        setActualURL(downloadURL)
      })
    } else {
      setActualURL(vendor.profilePictureUrl)
    }
  }, [vendor])

  return (
    <div className={styles.VendorButtons}>
      <div className={styles.vendorImage} style={{ backgroundImage: "url(" + actualURL + ")" }}></div>
      <input
        style={{ display: "none" }}
        ref={hiddenFileInput}
        type="file"
        accept={".png, .jpeg, .png"}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.files !== null) {
            const file = e.target.files[0]
            CompressImage(file, 0.9)
              .then((b) => {
                return uploadFile(
                  imageName,
                  location,
                  b,
                  (e) => {
                    setTitle(e.toFixed(0) + "%")
                  },
                  () => {
                    setTitle("done")
                  }
                )
              })
              .then((ref) => {
                console.log(ref.dest)

                vendor.profilePictureUrl = ref.dest
                return setVendor(vendor)
              })
          }
        }}
      />
      <button
        className={styles.VendorButtonSelected}
        onClick={() => {
          hiddenFileInput.current?.click()
        }}
      >
        {title}
      </button>
    </div>
  )
}

interface EditVendorButtonProps {
  vendor: Vendor
  setVendor: (a: Vendor) => void
  field: "type" | "rating"
  className: string
  rating?: 1 | 2 | 3 | 4 | 5
  value?: VendorTypes
}

export const EditVendorButton: React.FC<EditVendorButtonProps> = ({
  vendor,
  setVendor,
  field,
  className,
  value,
  rating = 1,
}): JSX.Element => {
  return (
    <button
      className={vendor[field] === value || (field === "rating" && vendor[field] >= rating) ? className : ""}
      onClick={() => {
        let newVendor: Vendor = { ...vendor }
        if (field === "rating") {
          newVendor[field] = rating
        } else {
          newVendor[field] = value ?? "vendor"
        }
        setVendor(newVendor)
      }}
    >
      {value}
    </button>
  )
}
