import styles from "./index.module.css"
import mainStyles from "../../styles/App.module.css"
import { Route, Routes } from "react-router-dom"
import React from "react"
import { ApplicationsList } from "./application"

export const Main: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.root}>
      <div className={mainStyles.MainLogo} />
      <div className={styles.AccountContentContainer}>
        <Routes>
          <Route path="/" element={<ApplicationsList />} />
        </Routes>
      </div>
    </div>
  )
}
export default Main
