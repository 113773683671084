import React from "react"
import styles from "./styles/home.module.css"
import { useEffect, useState } from "react"
import { collection, query, getDocs, limit, startAfter, DocumentData, Query, orderBy } from "firebase/firestore"
import { getStorage, ref, getDownloadURL } from "firebase/storage"

import { firestoreDB } from "../../App"
import { Poster } from "schema/dist/src/poster"

interface PostersViewProps {
  onClick: (poster: Poster | undefined) => void
  revivart: Poster
}

const PostersView: React.FC<PostersViewProps> = ({ revivart }): JSX.Element => {
  const [imageUrl, setImageUrl] = useState("")
  const [videoUrl, setVideoUrl] = useState("")
  const storage = getStorage()
  const imageStorageRef = ref(storage, revivart.imageLDURL)
  getDownloadURL(imageStorageRef)
    .then((url) => {
      setImageUrl(url)
    })
    .catch((error) => {
      console.log("Error getting Image url", error)
    })

  const videoStorageRef = ref(storage, revivart.videoURL)
  getDownloadURL(videoStorageRef)
    .then((url) => {
      setVideoUrl(url)
    })
    .catch((error) => {
      console.log("Error getting Video url", error)
    })

  return (
    <div
      onClick={(e) => {
        window.open(videoUrl, "_blank")
      }}
      className={styles.PostersView}
      style={{ backgroundImage: "url(" + imageUrl + ")" }}
    />
  )
}

interface AllPostersProps {
  onRevivartClick: (poster: Poster | undefined) => void
  pageNumber: number
}

const AllPosters: React.FC<AllPostersProps> = ({ onRevivartClick, pageNumber }): JSX.Element => {
  const [userRevivart, setUserRevivart] = useState<Poster[]>([])

  const [posterQuery, setPosterQuery] = useState<Query<DocumentData>>(
    query(collection(firestoreDB, "userPosters"), orderBy("timestamp", "desc"), limit(24))
  )

  const [nextPosterQuery, setNextPosterQuery] = useState<Query<DocumentData>>(
    query(collection(firestoreDB, "userPosters"), orderBy("timestamp", "desc"), limit(24))
  )

  const [prevPosterQuery, setPrevPosterQuery] = useState<Query<DocumentData>>(
    query(collection(firestoreDB, "userPosters"), orderBy("timestamp", "desc"), limit(24))
  )

  const [prevPageNumber, setPrevPageNumber] = useState(1)
  const [posterQueryTrigger, setPosterQueryTrigger] = useState(0)

  useEffect(() => {
    getDocs(posterQuery)
      .then((usersDocs) => {
        const lastVisible = usersDocs.docs[usersDocs.docs.length - 1]

        const next = query(
          collection(firestoreDB, "userPosters"),
          orderBy("timestamp", "desc"),
          startAfter(lastVisible),
          limit(24)
        )

        setNextPosterQuery(next)

        let userPosters: Poster[] = []
        usersDocs.forEach((posterDocument) => {
          const posterData = posterDocument.data() as Poster
          userPosters.push(posterData)
        })

        setUserRevivart(userPosters)
      })
      .catch((error) => {
        console.log("Error getting document:", error)
      })
  }, [posterQueryTrigger])

  useEffect(() => {
    if (pageNumber === 0) {
      return
    }

    setPrevPosterQuery(posterQuery)

    if (prevPageNumber < pageNumber) {
      setPosterQuery(nextPosterQuery)
    } else {
      setPosterQuery(prevPosterQuery)
    }

    setPosterQueryTrigger(posterQueryTrigger + 1)
    setPrevPageNumber(pageNumber - 1)
  }, [pageNumber])

  return (
    <div className={styles.AllPostersView}>
      {userRevivart.map((value) => {
        return <PostersView key={value.uid} revivart={value} onClick={onRevivartClick} />
      })}
    </div>
  )
}

export const PreviewComponent: React.FC<{}> = (): JSX.Element => {
  const [pageNumber, setPageNumber] = useState(1)

  return (
    <div className={styles.PreviewComponent}>
      <div className={styles.Pagination}>
        <div
          className={styles.PaginationButton}
          onClick={(e) => {
            if (pageNumber - 1 === 0) {
              return
            }
            setPageNumber(pageNumber - 1)
          }}
        >
          Prev
        </div>
        <div> {pageNumber}</div>
        <div
          className={styles.PaginationButton}
          onClick={() => {
            setPageNumber(pageNumber + 1)
          }}
        >
          Next
        </div>
      </div>
      <AllPosters onRevivartClick={(e) => {}} pageNumber={pageNumber} />
    </div>
  )
}
