import { collection, doc, onSnapshot } from "firebase/firestore"
import React, { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { alias, firestoreDB, previewAppWebsite } from "../App"
import { returnPageKey } from "../auth"
import { CartItem } from "schema/dist/src/cart"
import styles from "./NavBar.module.css"
import { AppContext } from "../App"
import { User } from "firebase/auth"
import { PromoBar } from "../promo"

export const Main: React.FC<{}> = (): JSX.Element => {
  const nav = useNavigate()
  const { user, navType } = useContext(AppContext)
  const [lower, setLower] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const loc = useLocation()

  useEffect(() => {
    return () => {
      setShowMenu(false)
    }
  }, [loc.pathname])

  function ChangeOnScroll() {
    if (window.scrollY >= 200) {
      setLower(true)
    } else {
      setLower(false)
    }
  }

  let className = styles.root
  if (navType === "sleek") {
    className += " " + styles.SleekNavBar
  } else if (navType === "sticky") {
    className += " " + styles.StickerBar

    if (lower) {
      className += " " + styles.BlurBar
    }
  } else if (navType === "home") {
    className += " " + styles.HomeBar
  }

  window.addEventListener("scroll", ChangeOnScroll)

  if (navType === "home") {
    return (
      <>
        <PromoBar />
        <div className={className}>
          <div className={styles.MainMenu}>
            <div
              className={styles.PhoneMenuClosed}
              onClick={() => {
                setShowMenu(!showMenu)
              }}
            ></div>
          </div>
          <MenuScreen
            showMenu={showMenu}
            onSelect={() => {
              setShowMenu(false)
            }}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <div className={className}>
        <PromoBar />

        <div className={styles.FullScreen}>
          <div
            className={styles.Logo}
            onClick={() => {
              nav("/")
            }}
          ></div>
          {alias === "prod" ? null : alias}
          <LogoText />

          <CartButton user={user} />
          <ProfileButton user={user} />
          <SignInButton user={user} />
          <div
            className={styles.PhoneMenuClosed}
            onClick={() => {
              setShowMenu(!showMenu)
            }}
          ></div>
        </div>
        <div className={styles.MobileScreen}>
          <div
            className={styles.PhoneMenuClosed}
            onClick={() => {
              setShowMenu(!showMenu)
            }}
          ></div>
          <span> {alias === "prod" ? null : alias}</span>
          <LogoText />
          <CartButton user={user} />
        </div>
        <MenuScreen
          showMenu={showMenu}
          onSelect={() => {
            setShowMenu(false)
          }}
        />
      </div>
    </>
  )
}
export default Main

export const SignInButton: React.FC<{ user: User | null }> = ({ user }): JSX.Element => {
  const nav = useNavigate()
  const currentPath = useLocation().pathname
  let ButtonText = "Login"

  if (user !== null && !user.isAnonymous) {
    ButtonText = "Logout"
  }

  function HandleClick() {
    if (user !== null && !user.isAnonymous) {
      nav(`/signout?${returnPageKey}=${currentPath}`)
    } else {
      nav(`/signin?${returnPageKey}=${currentPath}`)
    }
  }

  return (
    <div className={styles.SignInButton} onClick={HandleClick}>
      {ButtonText}
    </div>
  )
}

export const CartButton: React.FC<{ user: User | null }> = ({ user }): JSX.Element => {
  const nav = useNavigate()

  const [cartList, setCartList] = useState<CartItem[]>([])

  useEffect(() => {
    if (user === null) {
      return
    }

    const posterRefParent = doc(firestoreDB, "userData", user.uid)
    const newPosterCol = collection(posterRefParent, "cartItems")
    const done = onSnapshot(newPosterCol, (snap) => {
      const CartItem = snap.docs.map((doc) => {
        return doc.data() as CartItem
      })

      setCartList([...CartItem])
    })

    return () => {
      done()
    }
  }, [user])

  let totalItems = 0
  cartList.forEach((item) => {
    totalItems += item.count
  })

  if (user === null) {
    return <></>
  }

  return (
    <div
      className={styles.CartButton}
      onClick={() => {
        nav("/cart")
      }}
    >
      <div className={styles.CartButtonIcon} />
      {totalItems > 0 ? <div className={styles.CartButtonCount}>{totalItems}</div> : null}
    </div>
  )
}

export const ProfileButton: React.FC<{
  user: User | null
}> = ({ user }): JSX.Element => {
  const nav = useNavigate()

  if (user === null || user.isAnonymous) {
    return <></>
  }

  return (
    <div
      className={styles.CartButton}
      onClick={() => {
        nav("/account")
      }}
    >
      <div className={styles.ProfileButtonIcon} />
    </div>
  )
}

export const LogoText: React.FC<{}> = ({}): JSX.Element => {
  const nav = useNavigate()

  return (
    <div
      className={styles.LogoText}
      onClick={() => {
        nav("/")
      }}
    ></div>
  )
}

interface MenuScreenProps {
  showMenu: boolean
  onSelect: () => void
}
export const MenuScreen: React.FC<MenuScreenProps> = ({ showMenu, onSelect }): JSX.Element => {
  const nav = useNavigate()

  let className = styles.MenuScreen
  const [showCreateChildren, setShowCreateChildren] = useState(false)

  if (showMenu) {
    className += " " + styles.showMenu
  }
  return (
    <div
      className={className}
      onScroll={(e) => {
        e.stopPropagation()
      }}
    >
      <span
        onClick={() => {
          setShowCreateChildren(!showCreateChildren)
        }}
      >
        Create
      </span>

      {showCreateChildren ? (
        <>
          <span
            className={styles.MenuItemChildren}
            onClick={() => {
              nav("./studio/greeting?greetingID=christmas")
              onSelect()
              setShowCreateChildren(false)
            }}
          >
            Create Christmas Card
          </span>
          <span
            className={styles.MenuItemChildren}
            onClick={() => {
              nav("./studio/greeting?greetingID=birthday")
              onSelect()
              setShowCreateChildren(false)
            }}
          >
            Create Birthday Card
          </span>
          <span
            className={styles.MenuItemChildren}
            onClick={() => {
              nav("./studio/polaroid")
              onSelect()
              setShowCreateChildren(false)
            }}
          >
            Create Polaroid
          </span>
          <span
            className={styles.MenuItemChildren}
            onClick={() => {
              nav("./studio/poster")
              onSelect()
              setShowCreateChildren(false)
            }}
          >
            Create Poster
          </span>
          <span
            className={styles.MenuItemChildren}
            onClick={() => {
              nav("./studio/postcard")
              onSelect()
              setShowCreateChildren(false)
            }}
          >
            Create PostCard
          </span>
        </>
      ) : null}
      <span
        onClick={() => {
          nav("/shop")
          onSelect()
        }}
      >
        Shop
      </span>
      <span
        onClick={() => {
          nav("/events")
          onSelect()
        }}
      >
        Recap
      </span>
      <span
        onClick={() => {
          nav("/immersed")
          onSelect()
        }}
      >
        ReImmersed
      </span>

      <span
        onClick={() => {
          window.open("https://apps.apple.com/us/app/revivar/id1559129541")
          onSelect()
        }}
      >
        Download
      </span>
      <span
        onClick={() => {
          window.open(previewAppWebsite)
          onSelect()
        }}
      >
        Preview
      </span>
      <span></span>
      <span
        className={styles.AccountMenuItem}
        onClick={() => {
          nav("/account")

          onSelect()
        }}
      >
        Account
      </span>
    </div>
  )
}
