import styles from "./index.module.css"
import { useEffect, useState } from "react"
import * as THREE from "three"
import { Suspense } from "react"
import React, { useRef } from "react"
import { Canvas, RootState, useFrame } from "@react-three/fiber"
import { Html, Image, OrbitControls, Text, useTexture } from "@react-three/drei"
import RevivarLogo from "../../assets/logoBlack.png"
import RevivarLogoInvert from "../../assets/logowhite.png"
import defaultImageFrame from "../../assets/Frame08.png"
import ImageFrame1 from "../../assets/Frame1.png"
import ImageFrame08 from "../../assets/Frame08.png"
import ImageFrame08Rotated from "../../assets/Frame08-Rotated.png"
import ImageFrame075 from "../../assets/Frame075.png"
import ImageFrame075Rotated from "../../assets/Frame075-Rotated.png"
import ImageFrame067 from "../../assets/Frame067.png"
import ImageFrame067Rotated from "../../assets/Frame067-Rotated.png"
import RevivarStickerLogo from "../../assets/stickerLogo.png"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { PosterType } from "schema/dist/src/poster"
import ClearBack from "../../assets/CLEARPOSTCARDBACK.png"
import { Orientation } from "schema/dist/src/studio"

export type PosterViewState = "normal" | "expand" | "compare" | "showMessage" | "preview"
export interface ImageGalleryProps {
  videoURL: string
  imageURL: string
  ratio: number
  play: boolean
  setPlay: (a: boolean) => void
  rotate?: boolean
  previewTime?: number
  showVideo?: boolean
  viewState?: PosterViewState
  type?: PosterType | undefined
  insideBackImageURL?: string
  muted?: boolean
  orientation?: Orientation
}

export const ImageGallery: React.FC<ImageGalleryProps> = ({
  videoURL,
  imageURL,
  ratio,
  play,
  rotate = false,
  previewTime,
  showVideo,
  viewState = "normal",
  setPlay,
  type,
  muted,
  orientation = "vertical",
}): JSX.Element => {
  return (
    <div className={styles.ImageGalleryCanvas}>
      <Canvas dpr={[1, 2]} camera={{ fov: 400, position: [0, 0, 180] }}>
        <directionalLight intensity={1} />
        <ambientLight intensity={1} />
        <OrbitControls makeDefault enableZoom={true} />
        <Suspense fallback={null}>
          <PosterContainer
            imageURL={imageURL}
            videoURL={videoURL}
            ratio={ratio}
            play={play}
            rotate={rotate}
            previewTime={previewTime}
            showVideo={showVideo}
            viewState={viewState}
            setPlay={setPlay}
            type={type}
            muted={muted}
            orientation={orientation}
          />
        </Suspense>
      </Canvas>
    </div>
  )
}

export const PosterContainer: React.FC<ImageGalleryProps> = ({
  videoURL,
  imageURL,
  ratio,
  play,
  rotate,
  previewTime,
  showVideo,
  viewState,
  type,
  insideBackImageURL,
  muted,
  orientation = "vertical",
}): JSX.Element => {
  const posterRef = useRef<THREE.Mesh>(null)
  const videoRef = useRef<THREE.Mesh>(null)
  const adjustedRatio = orientation === "vertical" ? ratio : 1 / ratio

  const defPosition = new THREE.Vector3(0, 0, 0)
  const [positionVe] = useState<THREE.Vector3>(defPosition)

  const [changeView, setChangeView] = useState(false)
  const speed = 0.03
  const imageScale = 8
  const demoImageScale = imageScale - 1

  useEffect(() => {
    setChangeView(true)
  }, [viewState])

  // this reset the view to the initial state after the view state has been set
  // this is necessary so the animation start from the initial state
  function resetView(state: RootState) {
    state.camera.position.setX(0)
    state.camera.position.setY(1.1)
    state.camera.position.setZ(200)
    setChangeView(false)
  }

  /**
   * setOrientation sets the orientation of the mesh but modify its x and z axis
   * while also modifying the rotation
   */
  function setOrientation(
    mesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material | THREE.Material[]>,
    zPosition: number,
    xPosition: number,
    yRotation: number,
    zRotation: number = 0
  ) {
    mesh.position.z = THREE.MathUtils.lerp(mesh.position.z, zPosition, speed)
    mesh.position.x = THREE.MathUtils.lerp(mesh.position.x, xPosition, speed)
    mesh.rotation.y = THREE.MathUtils.lerp(mesh.rotation.y, yRotation, speed)
    mesh.rotation.z = THREE.MathUtils.lerp(mesh.rotation.z, zRotation, speed)
  }

  /**
   * setScale reduces the scale of the mesh. Used for when in an orthographic view to appear more perspective
   * @param mesh
   * @param scale
   */
  function setScale(mesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material | THREE.Material[]>, scale: number) {
    mesh.scale.x = THREE.MathUtils.lerp(mesh.scale.x, scale, speed)
    mesh.scale.y = THREE.MathUtils.lerp(mesh.scale.y, scale, speed)
    mesh.scale.z = THREE.MathUtils.lerp(mesh.scale.z, scale, speed)
  }

  /**
   * swayMesh moves the mesh from left to right. To indicate that the mess is 3D
   */
  function swayMesh(mesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material | THREE.Material[]>, state: RootState) {
    mesh.rotation.y = THREE.MathUtils.lerp(mesh.rotation.y, Math.cos(state.clock.elapsedTime) / 3, 0.05)
  }

  const height = orientation === "vertical" ? 100 : 70

  useFrame((state, ca) => {
    if (posterRef.current === null) return
    if (videoRef.current === null) return

    if (changeView) {
      resetView(state)
    }

    if (viewState === "expand") {
      if (type === "greeting") {
        setOrientation(posterRef.current, -40, 10, 0.7)
      } else {
        setOrientation(posterRef.current, -80, -10, 1.1)
      }
      setOrientation(videoRef.current, 30, 0, 0)
      setScale(videoRef.current, demoImageScale)
    } else if (viewState === "showMessage" && type === "greeting") {
      setOrientation(videoRef.current, 0, 0, 0)
      setScale(videoRef.current, demoImageScale)
      let zRotation = 0
      // in order to see the message in horizontal mode we have to modify the z rotation
      if (orientation === "horizontal") {
        zRotation = Math.PI / 2
      }

      setOrientation(posterRef.current, 100, -15, -Math.PI / 2, zRotation)
    } else if (viewState === "compare") {
      setOrientation(posterRef.current, -50, -10, Math.PI / 5)
      setOrientation(videoRef.current, 1, 120, 0)
      setScale(videoRef.current, imageScale)
    } else if (viewState === "preview") {
      setOrientation(posterRef.current, -20, 0, 0)
      setOrientation(videoRef.current, 1, 0, 0)
      setScale(videoRef.current, imageScale)
    } else {
      if (type === "greeting") {
        setOrientation(posterRef.current, 0, 0, Math.PI / 4)
      } else {
        setOrientation(posterRef.current, 0, 0, 0)
      }
      setOrientation(posterRef.current, 0, 0, 0)
      setOrientation(videoRef.current, 1, 0, 0)
      setScale(videoRef.current, imageScale)
      if (rotate) {
        swayMesh(posterRef.current, state)
      }
    }
  })

  return (
    <group
      position={positionVe}
      ref={posterRef}
      onPointerOver={(e) => {
        e.stopPropagation()
      }}
      onPointerOut={(e) => {
        e.stopPropagation()
      }}
      scale={0.8}
    >
      <group>
        {/* BACKGROUND PAPER*/}
        <mesh scale={1} visible={type !== "polaroid"}>
          <boxGeometry args={[height * adjustedRatio, height, 0.1]} />
          <meshStandardMaterial color="white" />
          <PosterPlaceHolder show ratio={adjustedRatio} height={height} />
        </mesh>
        <PosterImageHTML
          position={[0, 0, 2]}
          imageURL={imageURL}
          hideImage={(showVideo || play) && viewState === "normal"}
          ratio={adjustedRatio}
          height={height}
          scale={imageScale}
        />
        <PosterVideoHTML
          position={[0, 0, 1]}
          videoURL={videoURL}
          playVideo={play}
          ratio={adjustedRatio}
          previewTime={previewTime}
          videoPosterRef={videoRef}
          muted={muted ?? true}
          height={height}
          scale={imageScale}
        />
      </group>

      <FrameImageHTML
        imageURL={""}
        hideImage={type !== "poster"}
        ratio={adjustedRatio}
        orientation={orientation}
        height={height}
      />
      <PolaroidContainer show={type === "polaroid"} ratio={adjustedRatio} height={height} />

      <GreetingCardFlap
        orientation={orientation}
        show={type === "greeting"}
        ratio={ratio}
        insideBackImageURL={insideBackImageURL}
        height={height}
      />
    </group>
  )
}

interface PosterImageProps {
  hideImage: boolean
  ratio: number
  imageURL: string
  orientation?: Orientation
  height: number
  position?: THREE.Vector3 | undefined | [number, number, number]
  scale?: number
}

export const PosterImageHTML: React.FC<PosterImageProps> = ({
  hideImage,
  ratio,
  imageURL,
  height,
  position,
  scale,
}): JSX.Element => {
  const [actualURL, setActualURL] = useState("")

  useEffect(() => {
    const storage = getStorage()

    if (imageURL.includes("gs://")) {
      const imageStorageRef = ref(storage, imageURL)
      getDownloadURL(imageStorageRef).then((downloadURL) => {
        setActualURL(downloadURL)
      })
    } else {
      setActualURL(imageURL)
    }
  }, [imageURL])

  if (hideImage) {
    return <group></group>
  }

  let internalHeight = height * 5

  return (
    <group position={position} scale={scale} visible={true}>
      {/* <Image
        url={actualURL}
        raycast={() => null}
        rotation={new THREE.Euler(0, 3.14)}
        scale={8.5}
        toneMapped={false}
        zoom={1}
        onUpdate={(e) => {
          const material = e.material as any
          material.scale = [40, 60]
          material.uniformsNeedUpdate = true
        }}
      >
        <boxGeometry args={[1, 6 / 4, 0.01]} />
      </Image> */}

      <Html
        scale={1}
        transform
        occlude
        pointerEvents="none"
        center
        visible={false}
        style={{ height: `${internalHeight}px`, width: `${ratio * internalHeight}px` }}
      >
        <div className={styles.ImageFibre} style={{ backgroundImage: "url(" + actualURL + ")", opacity: hideImage ? 0 : 1 }} />
      </Html>
    </group>
  )
}

interface PosterFrameRatio {
  ratio: number
  imageURL: string
}

export function FindPosterFrame(frames: PosterFrameRatio[], ratio: number): string {
  const defaultFrame = defaultImageFrame
  const frameRatio = frames.find((val) => {
    return val.ratio === ratio
  })

  if (frameRatio !== undefined) {
    return frameRatio.imageURL
  }

  return defaultFrame
}

export const FrameImageHTML: React.FC<PosterImageProps> = ({ hideImage, ratio, height }): JSX.Element => {
  const Frames: PosterFrameRatio[] = [
    {
      ratio: 0.8,
      imageURL: ImageFrame08,
    },
    {
      ratio: 0.75,
      imageURL: ImageFrame075,
    },
    {
      ratio: 0.67,
      imageURL: ImageFrame067,
    },
    {
      ratio: 1 / 0.8,
      imageURL: ImageFrame08Rotated,
    },
    {
      ratio: 1 / 0.75,
      imageURL: ImageFrame075Rotated,
    },
    {
      ratio: 1 / 0.67,
      imageURL: ImageFrame067Rotated,
    },
    {
      ratio: 1,
      imageURL: ImageFrame1,
    },
  ]

  const imageURL = FindPosterFrame(Frames, ratio)

  if (imageURL === "" || hideImage) {
    return <group></group>
  }

  let internalHeight = height * 5
  return (
    <group visible={!hideImage} scale={1}>
      <mesh onClick={() => {}} scale={1.2} position={[0, 0, -2]}>
        <boxGeometry args={[height * ratio, height, 1]} />
        <meshBasicMaterial color={"#333333"} />
      </mesh>
      <group position={[0, 0, 2.5]} scale={9.3}>
        <Html
          scale={1}
          transform
          occlude
          pointerEvents="none"
          center
          style={{ height: `${internalHeight}px`, width: `${ratio * internalHeight}px` }}
        >
          <div className={styles.FrameImageFibre} style={{ backgroundImage: "url(" + imageURL + ")" }}></div>
        </Html>
      </group>
    </group>
  )
}

interface PosterVideoProps {
  videoURL: string
  playVideo: boolean
  ratio: number
  previewTime?: number
  videoPosterRef: React.RefObject<THREE.Mesh>
  muted: boolean
  height: number
  position?: THREE.Vector3 | undefined | [number, number, number]
  scale?: number
}

export const PosterVideoHTML: React.FC<PosterVideoProps> = ({
  videoPosterRef,
  videoURL,
  ratio,
  playVideo,
  previewTime,
  muted,
  height,
  position,
  scale,
}): JSX.Element => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [actualURL, setActualURL] = useState("")

  useEffect(() => {
    const storage = getStorage()

    if (videoURL.includes("gs://")) {
      const imageStorageRef = ref(storage, videoURL)
      getDownloadURL(imageStorageRef).then((downloadURL) => {
        setActualURL(downloadURL)
      })
    } else {
      setActualURL(videoURL)
    }
  }, [videoURL])

  useEffect(() => {
    if (videoRef.current === null || actualURL === "") {
      return
    }

    videoRef.current.currentTime = 0

    if (playVideo) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
      videoRef.current.currentTime = 0
    }
  }, [playVideo, actualURL])

  useEffect(() => {
    if (videoRef.current === null) {
      return
    }

    if (previewTime === undefined) return
    videoRef.current?.pause()
    videoRef.current!.currentTime = previewTime
  }, [previewTime, actualURL])

  if (actualURL === "") {
    return <group ref={videoPosterRef}></group>
  }

  let internalHeight = height * 5

  return (
    <group position={position} scale={scale} visible={true} ref={videoPosterRef}>
      <Html
        scale={1}
        transform
        occlude
        pointerEvents="none"
        center
        visible={false}
        style={{ height: `${internalHeight}px`, width: `${ratio * internalHeight}px` }}
      >
        <div className={styles.VideoFibre}>
          <video width="100%" height="100%" ref={videoRef} src={actualURL} loop autoPlay muted={muted} playsInline />
        </div>
      </Html>
    </group>
  )
}

interface PosterPlaceHolderProps {
  show: boolean
  ratio: number
  height: number
}

export const PosterPlaceHolder: React.FC<PosterPlaceHolderProps> = ({ show, ratio, height }): JSX.Element => {
  let texture = useTexture(RevivarLogo)

  const scale = 0.15
  return (
    <mesh position={[0, 0, 0.1]} visible={show}>
      <boxGeometry args={[height * scale, height * scale, 0]} />
      <meshStandardMaterial
        attach="material"
        map={texture}
        color={"#ffffff"}
        metalness={0.5}
        emissive={new THREE.Color(0xffffff)}
        emissiveMap={texture}
        transparent={true}
      />
    </mesh>
  )
}

interface GreetingCardFlapProps extends PosterPlaceHolderProps {
  insideBackImageURL?: string
  orientation: Orientation
}

export const GreetingCardFlap: React.FC<GreetingCardFlapProps> = ({
  show,
  insideBackImageURL = "",
  ratio,
  height,
  orientation,
}): JSX.Element => {
  const [actualURL, setActualURL] = useState(ClearBack)
  const [insideImageURL, setInsideImageURL] = useState(ClearBack)
  const [backImageURL, setBackImageURL] = useState(ClearBack)

  const backImageFileName = `adminFiles/qrCodeFiles/POSTCARDBACKIMAGE.png`
  const insideImageName = `adminFiles/qrCodeFiles/GREETINGCARDSCHEMA.png`

  useEffect(() => {
    const storage = getStorage()
    const backImageStorageRef = ref(storage, backImageFileName)
    getDownloadURL(backImageStorageRef)
      .then((downloadURL) => {
        setBackImageURL(downloadURL)
      })
      .catch((err) => {
        console.log(err)
      })

    const insideImageStorageRef = ref(storage, insideImageName)
    getDownloadURL(insideImageStorageRef)
      .then((downloadURL) => {
        setInsideImageURL(downloadURL)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    const storage = getStorage()
    if (insideBackImageURL.includes("gs://")) {
      const imageStorageRef = ref(storage, insideBackImageURL)
      getDownloadURL(imageStorageRef).then((downloadURL) => {
        setActualURL(downloadURL)
      })
    } else {
      setActualURL(ClearBack)
    }
  }, [insideBackImageURL])

  if (orientation === "horizontal") {
    height = height / ratio
  }

  let width = ratio * height

  let rotation: THREE.Euler
  let position: THREE.Vector3

  if (orientation === "horizontal") {
    position = new THREE.Vector3(0, width / 2, -width / 2)
    rotation = new THREE.Euler(-Math.PI / 2, 0, -Math.PI / 2)
  } else {
    position = new THREE.Vector3(-width / 2, 0, -width / 2)
    rotation = new THREE.Euler(0, 55)
  }

  return (
    <group position={position} visible={show} rotation={rotation}>
      {/* <boxGeometry args={[width, height, 0.01]} />
      <meshBasicMaterial color={"white"} /> */}
      <group position={[-1, 0, 0]}>
        {actualURL !== "" ? (
          <Image
            url={actualURL}
            raycast={() => null}
            rotation={new THREE.Euler(0, Math.PI)}
            position={[0, 0, -0.5]}
            toneMapped={false}
            zoom={1}
            onUpdate={(e) => {
              const material = e.material as any
              material.scale = [40, 60]
              material.uniformsNeedUpdate = true
            }}
          >
            <boxGeometry args={[height * ratio, height, 0.5]} />
          </Image>
        ) : null}

        <Image
          url={insideImageURL}
          raycast={() => null}
          rotation={new THREE.Euler(0, Math.PI / 2)}
          position={[width / 2, 0, (-height / 2) * ratio]}
          toneMapped={false}
          zoom={1}
          onUpdate={(e) => {
            const material = e.material as any
            material.scale = [40, 60]
            material.uniformsNeedUpdate = true
          }}
        >
          <boxGeometry args={[height * ratio, height, 0.1]} />
        </Image>

        <Image
          url={backImageURL}
          raycast={() => null}
          rotation={new THREE.Euler(0, -Math.PI)}
          position={[0, 0, 0.5]}
          toneMapped={false}
          zoom={1}
          onUpdate={(e) => {
            const material = e.material as any
            material.scale = [40, 60]
            material.uniformsNeedUpdate = true
          }}
        >
          <boxGeometry args={[height * ratio, height, 0.01]} />
        </Image>
      </group>
    </group>
  )
}

export const PolaroidContainer: React.FC<PosterPlaceHolderProps> = ({ show, ratio, height }): JSX.Element => {
  let texture = useTexture(RevivarLogoInvert)

  if (!show) {
    return <group></group>
  }

  return (
    <group scale={1.15} position={[0, -10, -2]}>
      <Image
        url={ClearBack}
        raycast={() => null}
        toneMapped={false}
        zoom={1}
        onUpdate={(e) => {
          const material = e.material as any
          material.scale = [40, 60]
          material.uniformsNeedUpdate = true
        }}
      >
        <boxGeometry args={[100, 120, 0.1]} />
      </Image>
      <RevivarSticker />
      <mesh scale={0.87} position={[0, 9, 1.1]}>
        <boxGeometry args={[height * ratio, height, 0.5]} />
        <meshBasicMaterial color={"black"} />
        <mesh position={[0, 0, 1]}>
          <boxGeometry args={[30, 30, 0]} />
          <meshStandardMaterial
            attach="material"
            map={texture}
            color={"#ffffff"}
            metalness={0.5}
            emissive={new THREE.Color(0xffffff)}
            emissiveMap={texture}
            transparent={true}
          />
        </mesh>
      </mesh>
    </group>
  )
}

export const RevivarSticker: React.FC<{}> = (): JSX.Element => {
  let texture = useTexture(RevivarStickerLogo)
  const [hovered, setHovered] = useState(false)
  const c = new THREE.Color("#000000")

  const textRef = useRef<any>()

  useFrame((state) => {
    if (textRef === null || textRef === undefined) {
      return
    }

    textRef.current!.scale.y = THREE.MathUtils.lerp(textRef.current!.scale.y, hovered ? 2 : 0, 0.1)

    textRef.current!.scale.x = THREE.MathUtils.lerp(textRef.current!.scale.x, hovered ? 2 : 0, 0.1)
  })

  return (
    <mesh
      position={[0, 10, -0.5]}
      onPointerOver={(e) => {
        e.stopPropagation()
        setHovered(true)
      }}
      onPointerOut={() => {
        setHovered(false)
      }}
    >
      <boxGeometry args={[40, 40, 0]} />
      <meshStandardMaterial
        attach="material"
        map={texture}
        color={"black"}
        metalness={0.5}
        emissive={new THREE.Color(0xffffff)}
        emissiveMap={texture}
        transparent={true}
      />

      <Text
        maxWidth={0.6}
        anchorX="left"
        anchorY="top"
        position={[-10, -20, -0.1]}
        fontSize={5}
        rotation={[0, Math.PI, 0]}
        color={c}
        ref={textRef}
      >
        Sticker Tag
      </Text>
    </mesh>
  )
}
