import styles from "./index.module.css"
import { useState, useRef, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import CryptoJS from "crypto-js"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { Event, EventMedia, EventRelatedImage } from "schema/dist/src/event"
import {
  DeleteEvent,
  UpdateEvent,
  UpdateEventMedia,
  DeleteEventMedia,
  CompressImage,
  DeleteEventRelatedImage,
  DeleteEventDefaultImage,
} from "./create"
import loadingImage from "./../../assets/loadingImage.png"
import cancelIcon from "../../assets/cancelRounded.svg"
import uploadIcon from "../../assets/editUpload.svg"
import { uploadFile } from "../product/create"
import { firestoreDB } from "../../App"
import { collection, deleteDoc, doc, getDocs, query, where } from "firebase/firestore"
import { Product } from "schema/dist/src/product"
import { eventMediaAssetsFolderName, eventMediaFolderName } from "./media"
import { Timestamp } from "firebase/firestore"
import DatePicker from "react-date-picker"
import { ValidateAssetSize } from "../../create/shared/shared"
import { useLDImage } from "../../hooks/useLDImage"
interface EditCoverImageProps {
  event: Event
  useIcon?: boolean
}

export const EditEventMainImage: React.FC<EditCoverImageProps> = ({ event }): JSX.Element => {
  const [imageURL, setImageURL] = useState(loadingImage)
  const [isUploading, setIsUploading] = useState(false)
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [LDUploadPercentage, setLDUploadPercentage] = useState(0)
  const [HDUploadPercentage, setHDUploadPercentage] = useState(0)
  const [uploadingText, setUploadingText] = useState("")

  useEffect(() => {
    if (isUploading) return
    if ((event.imageLDURL ?? "") === "") {
      setImageURL("")
    } else {
      const storage = getStorage()
      getDownloadURL(ref(storage, event.imageLDURL))
        .then((url) => {
          setImageURL(url)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [event, isUploading])

  useEffect(() => {
    const percentage = (LDUploadPercentage + HDUploadPercentage) / 2
    setUploadingText(`Uploading ${percentage.toFixed(1)}%`)
  }, [LDUploadPercentage, HDUploadPercentage])

  function onFileSelected(a: File | Blob) {
    const valid = ValidateAssetSize(a, "image")
    if (!valid.valid) {
      console.log("Try again." + valid.reason)
      return
    }
    //create editable event object from event prop
    const eventObj = event

    setIsUploading(true)

    const fileLDName = eventObj.uid + "HDimage"
    const fileHDName = eventObj.uid + "LDimage"

    const location = `/${eventMediaFolderName}/${event.uid}/`

    CompressImage(a, 0.5).then((b) => {
      Promise.all([
        uploadFile(fileLDName, location, a, (a) => {
          setHDUploadPercentage(a)
        }),
        uploadFile(fileHDName, location, b, (b) => {
          setLDUploadPercentage(b)
        }),
      ])
        .then((ref) => {
          eventObj.imageHDURL = ref[0].dest
          eventObj.imageLDURL = ref[1].dest
          return UpdateEvent(eventObj)
        })
        .then((a) => {
          setIsUploading(false)
          setUploadingText("Uploading 0.0%")
        })
    })
  }

  return (
    <div className={styles.EditEventImageContainer} style={{ backgroundImage: "url(" + imageURL + ")" }}>
      <input
        style={{ display: "none" }}
        ref={hiddenFileInput}
        type="file"
        accept={".png, .jpeg, .jpg, .png"}
        onChange={(e) => {
          if (e.target.files !== null) {
            const file = e.target.files[0]
            onFileSelected(file)
          }
          e.target.files = null
          e.target.value = ""
        }}
      />
      <img
        src={uploadIcon}
        alt=""
        className={styles.UploadIcon}
        onClick={() => {
          hiddenFileInput.current?.click()
        }}
        hidden={isUploading}
      />
      <div hidden={!isUploading}>{uploadingText}</div>
    </div>
  )
}

interface EditVideoCoverProps {
  media: EventMedia
}

//Component for uploading a different cover for the video
export const EditVideoCover: React.FC<EditVideoCoverProps> = ({ media }): JSX.Element => {
  const [imageURL, setImageURL] = useState<string>()
  const [videoURL, setVideoURL] = useState(loadingImage)
  const [hidden, setHidden] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  //get the cover imageURl of the video if it exists
  useEffect(() => {
    if ((media.imageLDURL ?? "") === "" || isUploading) return
    const storage = getStorage()
    getDownloadURL(ref(storage, media.imageLDURL))
      .then((url) => {
        setImageURL(url)
        setHidden(true)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [media, isUploading])

  //get the videoUrl of the video
  useEffect(() => {
    if ((media.videoURL ?? "") === "") return
    const storage = getStorage()
    if (media.videoURL.includes("gs://")) {
      getDownloadURL(ref(storage, media.videoURL))
        .then((url) => {
          setVideoURL(url)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [media])

  //when a file is selected for upload
  const onFileSelected = (a: File | Blob) => {
    const valid = ValidateAssetSize(a, "image")
    if (!valid.valid) {
      console.log("Try again." + valid.reason)
      return
    }
    //create editable event object from event prop
    const eventObj = media

    setIsUploading(true)
    setHidden(true)

    const fileLDName = eventObj.uid + "HDimage"
    const fileHDName = eventObj.uid + "LDimage"

    const location = `/${eventMediaFolderName}/${media.eventUid}/${eventMediaAssetsFolderName}/${media.uid}/`

    CompressImage(a, 0.5).then((b) => {
      Promise.all([uploadFile(fileHDName, location, a), uploadFile(fileLDName, location, b)]).then(([HDref, LDref]) => {
        eventObj.imageLDURL = LDref.dest
        eventObj.imageHDURL = LDref.dest

        UpdateEventMedia(eventObj).then((a) => {
          setIsUploading(false)
        })
      })
    })
  }

  return (
    <div className={styles.EditVideCoverContainer}>
      <div className={styles.EditVideoCoverImage} style={{ backgroundImage: "url(" + imageURL + ")" }}>
        <video loop muted playsInline src={videoURL} className={styles.EditEventVideo} hidden={hidden}></video>
        <div
          onClick={() => {
            hiddenFileInput.current?.click()
          }}
        >
          {isUploading ? "uploading..." : "Edit Cover Image"}
          <input
            style={{ display: "none" }}
            ref={hiddenFileInput}
            type="file"
            accept={".png, .jpeg, .png"}
            onChange={(e) => {
              if (e.target.files !== null) {
                const file = e.target.files[0]
                onFileSelected(file)
              }
              e.target.files = null
              e.target.value = ""
            }}
          />
        </div>
      </div>
      <p>Title: {media.title}</p>
    </div>
  )
}

interface EditRelatedImageProps {
  image: EventRelatedImage
  event: Event
  showDelete?: boolean
  imageType: "relatedImages" | "defaultPrints"
}

export const EditRelatedImage: React.FC<EditRelatedImageProps> = ({
  image,
  event,
  showDelete = true,
  imageType,
}): JSX.Element => {
  const { imageURL } = useLDImage(image.imageLDURL)

  return (
    <div className={styles.RelatedImage} style={{ backgroundImage: "url(" + imageURL + ")" }}>
      <img
        src={cancelIcon}
        alt=""
        className={styles.RemoveRelatedImage}
        hidden={!showDelete}
        onClick={() => {
          if (imageType === "relatedImages") {
            DeleteEventRelatedImage(image, event).catch((err) => {
              console.log(err)
            })
          } else {
            DeleteEventDefaultImage(image, event).catch((err) => {
              console.log(err)
            })
          }
        }}
      />
    </div>
  )
}

interface EditInputTextProps {
  event: Event
  fieldType: "title" | "description" | "keywords" | "url" | "couponID" | "passHash" | "discountID"
  placeholder: string
  actionButton: string
}

export const EditInputText: React.FC<EditInputTextProps> = ({ event, fieldType, placeholder, actionButton }): JSX.Element => {
  const [value, setValue] = useState(event[fieldType] ?? "")

  return (
    <div className={styles.EditEventField}>
      {fieldType === "description" ? (
        <textarea
          cols={30}
          rows={6}
          placeholder={placeholder}
          onInput={(e) => {
            setValue(e.currentTarget.value)
          }}
          value={value}
          autoComplete="off"
        ></textarea>
      ) : (
        <input
          type={fieldType === "passHash" ? "password" : "text"}
          placeholder={placeholder}
          onInput={(e) => {
            setValue(e.currentTarget.value)
          }}
          value={value}
          id={fieldType === "passHash" ? "passHash" : undefined}
          title={fieldType === "passHash" ? "passHash" : undefined}
          autoComplete={fieldType === "passHash" ? "new-password" : undefined}
        />
      )}
      <button
        className={styles.EditEventFieldsButton}
        type="submit"
        onClick={() => {
          let fieldValue = value
          if (fieldType === "passHash" && value !== "") {
            fieldValue = CryptoJS.SHA256(value).toString(CryptoJS.enc.Hex)
          }
          event[fieldType] = fieldValue
          UpdateEvent(event)
        }}
      >
        {actionButton}
      </button>
    </div>
  )
}

interface EditInputDateProps {
  event: Event
  actionButton: string
}

export const EditInputDate: React.FC<EditInputDateProps> = ({ event, actionButton }): JSX.Element => {
  const [value, setValue] = useState(event.date?.toDate())

  return (
    <div className={styles.EditEventField}>
      <DatePicker
        className={styles.ReactDatePicker}
        calendarClassName={styles.ReactDatePickerCalendar}
        onChange={(e: Date) => setValue(e)}
        value={value}
        calendarIcon={null}
        clearIcon={null}
      />
      <button
        className={styles.EditEventFieldsButton}
        type="submit"
        onClick={() => {
          event.date = Timestamp.fromDate(value)
          UpdateEvent(event)
        }}
      >
        {actionButton}
      </button>
    </div>
  )
}

interface EditMediaFieldProps {
  media: EventMedia
  fieldType: "title" | "description"
  placeholder: string
  actionButton: string
}

//Component for Editing  the title and description of a video
export const EditMediaField: React.FC<EditMediaFieldProps> = ({ media, fieldType, placeholder, actionButton }): JSX.Element => {
  const [value, setValue] = useState(media[fieldType] ?? "")

  return (
    <div className={styles.EditEventField}>
      {fieldType === "description" ? (
        <textarea
          cols={30}
          rows={6}
          placeholder={placeholder}
          onInput={(e) => {
            setValue(e.currentTarget.value)
          }}
          value={value}
        ></textarea>
      ) : (
        <input
          type="text"
          placeholder={placeholder}
          onInput={(e) => {
            setValue(e.currentTarget.value)
          }}
          value={value}
        />
      )}
      <button
        className={styles.EditEventFieldsButton}
        type="submit"
        onClick={() => {
          media[fieldType] = value
          UpdateEventMedia(media)
        }}
      >
        {actionButton}
      </button>
    </div>
  )
}

interface EditInputRadioProps {
  event: Event
  fieldType: "category"
  options: string[]
  actionButton: string
}

export const EditInputRadio: React.FC<EditInputRadioProps> = ({ event, fieldType, options, actionButton }): JSX.Element => {
  const [value, setValue] = useState(event[fieldType] ?? "")

  return (
    <div className={styles.EditEventField}>
      <div className={styles.EditEventFieldRange}>
        {options.map((option) => (
          <div key={option}>
            <input
              type="radio"
              name={fieldType}
              id={option}
              defaultChecked={option === value ? true : false}
              value={option}
              onChange={(e) => {
                setValue(e.target.value)
              }}
            />
            <label htmlFor={option}>{option}</label>
          </div>
        ))}
      </div>
      <button
        className={styles.EditEventFieldsButton}
        type="submit"
        onClick={() => {
          event[fieldType] = value
          UpdateEvent(event)
        }}
      >
        {actionButton}
      </button>
    </div>
  )
}

interface EditMediaTypeProps {
  event: Event
}

//Component for Editing the type of product the video will be converted to
export const EditMediaProduct: React.FC<EditMediaTypeProps> = ({ event }): JSX.Element => {
  const [studioProduct, setStudioProduct] = useState<Product>()

  useEffect(() => {
    const posterVariantsRef = collection(firestoreDB, "shopProducts")
    const qu = query(posterVariantsRef, where("studioID", "==", "event"))
    getDocs(qu)
      .then((responses) => {
        const products: Product[] = []
        responses.docs.forEach((doc) => {
          const variant = doc.data() as Product
          products.push(variant)
        })
        const selectedProd = products[0]
        selectedProd.productVariantList = selectedProd?.productVariantList?.sort((a, b) => {
          return a.price < b.price ? -1 : 1
        })

        setStudioProduct(selectedProd)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [event])

  return (
    <div className={styles.EditEventProductSelectorContainer}>
      {studioProduct?.productVariantList.map((variant, i) => {
        let className = styles.EditEventProductSelector
        const isSelected =
          event.selectedVariants?.find((selected) => {
            return selected === variant.uid
          }) !== undefined

        const isDefault = variant.uid === event.defaultSelectedVariant

        if (isSelected) {
          className += " " + styles.EditEventProductSelected
        }

        if (isDefault) {
          className += " " + styles.EditEventProductDefault
        }

        return (
          <div
            className={className}
            key={variant.uid}
            onClick={() => {
              if (event.selectedVariants === undefined) {
                event.selectedVariants = []
              }

              if (!isSelected) {
                event.selectedVariants?.push(variant.uid)
              } else {
                if (isDefault) return
                event.selectedVariants = event.selectedVariants?.filter((selected) => {
                  return variant.uid !== selected
                })
              }

              event.productUID = studioProduct.uid
              UpdateEvent(event)
            }}
            onDoubleClick={() => {
              if (event.selectedVariants === undefined) {
                event.selectedVariants = []
              }
              event.selectedVariants?.push(variant.uid)

              event.productUID = studioProduct.uid
              event.defaultSelectedVariant = variant.uid
              UpdateEvent(event)
            }}
          >
            {variant.size} - {variant.sessionType}
          </div>
        )
      })}
    </div>
  )
}

interface EditActionsProps {
  event: Event
}

export const EditActions: React.FC<EditActionsProps> = ({ event }): JSX.Element => {
  const nav = useNavigate()

  const [value, setValue] = useState(event.isActive ?? true)
  const [allowedValue, setAllowedValue] = useState(event.allowUpload ?? false)
  const [privateEvent, setPrivateEvent] = useState(event.isPrivate ?? false)
  const [showPopup, setShowPopup] = useState(false)

  return (
    <div className={styles.ActionButtonsContainer}>
      <button
        className={value === true ? styles.DisableActionButton : styles.EditActionButton}
        onClick={() => {
          event.isActive = !value
          setValue(event.isActive)
          UpdateEvent(event)
        }}
      >
        {value === true ? "Disable" : "Enable"}
      </button>
      <button
        className={allowedValue === true ? styles.DisableActionButton : styles.EditActionButton}
        onClick={() => {
          event.allowUpload = !allowedValue
          setAllowedValue(event.allowUpload)
          UpdateEvent(event)
        }}
      >
        {allowedValue === true ? "COMMUNITY" : "ADMIN ONLY"}
      </button>

      <button
        className={privateEvent === true ? styles.EditActionButton : styles.DisableActionButton}
        onClick={() => {
          event.isPrivate = !privateEvent
          setPrivateEvent(event.isPrivate)
          UpdateEvent(event)
        }}
      >
        {privateEvent === true ? "PRIVATE" : "PUBLIC"}
      </button>

      <button
        onClick={() => {
          setShowPopup(true)
        }}
        className={styles.DeleteActionButton}
      >
        Delete
      </button>
      {showPopup ? (
        <div className={styles.DeletePopup}>
          <div className={styles.DeleteConfirmationPopup}>
            <p>Are you sure?</p>
            <button
              onClick={() => {
                DeleteEvent(event).then(() => {
                  nav("../")
                })
              }}
              className={styles.DeleteActionButton}
            >
              Yes, Delete
            </button>
            <button
              onClick={() => {
                setShowPopup(false)
              }}
              className={styles.EditActionButton}
            >
              No, Keep
            </button>
          </div>
        </div>
      ) : null}
    </div>
  )
}

interface EditMediaActionsProps {
  media: EventMedia
}

//Component for enabling, disabling and deleting an eventmedia
export const EditMediaActions: React.FC<EditMediaActionsProps> = ({ media }): JSX.Element => {
  const nav = useNavigate()
  const [value, setValue] = useState(media.isActive ?? true)
  return (
    <div className={styles.EditMediaActions}>
      {media.pendingApproval === true ? (
        <button
          className={value === true ? styles.DisableActionButton : styles.EditActionButton}
          onClick={() => {
            media.pendingApproval = false
            UpdateEventMedia(media)
              .then(() => {
                const eventMediaRef = doc(collection(firestoreDB, "events", media.eventUid, "pending"), media.uid)
                return deleteDoc(eventMediaRef)
              })
              .then(() => {
                nav(-1)
              })
          }}
        >
          Approve
        </button>
      ) : (
        <button
          className={value === true ? styles.DisableActionButton : styles.EditActionButton}
          onClick={() => {
            media.isActive = !value
            setValue(media.isActive)
            UpdateEventMedia(media)
          }}
        >
          {value === true ? "Disable" : "Enable"}
        </button>
      )}

      <button
        onClick={() => {
          DeleteEventMedia(media, media.eventUid, media.pendingApproval ? "pending" : "medias").then(() => {
            nav(-1)
          })
        }}
        className={styles.DeleteActionButton}
      >
        Delete
      </button>
    </div>
  )
}
