import styles from "./auth.module.css"
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  User,
  signOut,
  sendPasswordResetEmail,
  signInAnonymously,
  EmailAuthProvider,
  linkWithCredential,
} from "firebase/auth"
import { useContext, useEffect, useState } from "react"
import { Timestamp } from "firebase/firestore"
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom"
import { ProfileData } from "schema/dist/src/user"
import { doc, setDoc } from "firebase/firestore"
import { auth, firebaseApp, firestoreDB } from "../App"
import { AppContext } from "../App"
import { GetCartItems, WriteCartItems } from "../cart/cart"
import { CartItem } from "schema/dist/src/cart"

export const returnPageKey = "returnPage"
export const SignIn: React.FC<{}> = (): JSX.Element => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setSetError] = useState("")
  const nav = useNavigate()

  const search = useLocation().search
  const returnPage = new URLSearchParams(search).get(returnPageKey) ?? ""
  const { user } = useContext(AppContext)

  function SignIn() {
    if (user?.isAnonymous) {
      // Get cart information and move it to this user's account
      let cartItems: CartItem[] = []
      GetCartItems(user)
        .then((result) => {
          console.log(result)
          cartItems = result
          return signInWithEmailAndPassword(auth, email, password)
        })
        .then((userCredential) => {
          return WriteCartItems(userCredential.user, cartItems)
        })
        .then(() => {
          console.log("WE DONE", cartItems)
          return
        })
        .catch((error) => {
          const errorCode = error.code
          setSetError(errorCode)
        })
    } else {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {})
        .catch((error) => {
          const errorCode = error.code
          setSetError(errorCode)
        })
    }
  }

  useEffect(() => {
    if (user !== null && !user.isAnonymous) {
      nav("../" + returnPage, { replace: false })
    }
  }, [user, nav, returnPage])

  return (
    <div className={styles.root}>
      <div
        className={styles.LoadingViewLogo}
        onClick={() => {
          nav("/")
        }}
      ></div>
      <div className={styles.WelcomeText}>Welcome to Revivar</div>
      <div className={styles.MoreInfoText}>Log in with you email</div>
      <input
        placeholder="Email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value)
        }}
      ></input>
      <input
        placeholder="Password"
        type="password"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value)
        }}
        onSubmit={SignIn}
      ></input>

      <div className={styles.ContinueButton} onClick={SignIn}>
        Log In
      </div>
      <div
        className={styles.ActionMessuage}
        onClick={() => {
          nav("/resetPassword")
        }}
      >
        Forgot Password?
      </div>

      <div className={styles.ErrorMessage}>{error}</div>
      <div
        className={styles.ContinueButton + " " + styles.ContinueButtonCreate}
        onClick={() => {
          nav("/register" + search)
        }}
      >
        Create new account
      </div>
    </div>
  )
}

function InitializeUser(user: User, userFullName: string) {
  var userData: ProfileData = {
    userName: userFullName,
    profilePictureUrl: "gs://augmented-art.appspot.com/adminFiles/newUserImages/UserImage.png",
    phoneNumber: parseInt(user.phoneNumber ?? ""),
    userId: user.uid,
    userEmail: user.email ?? "",
    stripeId: "",
    favoritePosterIds: [],
    likedPosterIds: [],
    createdDate: Timestamp.now(),
  }

  const newUsrRef = doc(firestoreDB, "userData", user.uid)
  return setDoc(newUsrRef, userData)
}

function UpdateUserName(user: User, userFullName: string) {
  const newUsrRef = doc(firestoreDB, "userData", user.uid)
  return setDoc(newUsrRef, { userName: userFullName }, { merge: true })
}

export const SignUp: React.FC<{}> = (): JSX.Element => {
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setSetError] = useState("")
  const nav = useNavigate()
  const { user } = useContext(AppContext)

  const search = useLocation().search
  const returnPage = new URLSearchParams(search).get(returnPageKey) ?? ""

  function SignIn() {
    if (user?.isAnonymous) {
      const credential = EmailAuthProvider.credential(email, password)
      linkWithCredential(user, credential)
        .then((usercred) => {
          const user = usercred.user
          return UpdateUserName(user, fullName)
        })
        .then((data) => {
          console.log(data)
          console.log("done")
          nav("../" + returnPage, { replace: false })
        })
        .catch((error) => {
          const errorCode = error.code
          setSetError(errorCode)
        })
    } else {
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user
          return InitializeUser(user, fullName)
        })
        .then((data) => {
          console.log(data)
          console.log("done")
          nav("../" + returnPage, { replace: false })
        })
        .catch((error) => {
          const errorCode = error.code
          setSetError(errorCode)
        })
    }
  }

  useEffect(() => {
    if (user !== null && !user.isAnonymous) {
      nav("../" + returnPage, { replace: false })
    }
  }, [user, nav, returnPage])

  return (
    <div className={styles.root}>
      <div className={styles.LoadingViewLogo}></div>
      <div className={styles.WelcomeText}>Welcome to Revivar</div>
      <div className={styles.MoreInfoText}>Register with you email</div>
      <input
        placeholder="Full Name"
        value={fullName}
        onChange={(e) => {
          setFullName(e.target.value)
        }}
      ></input>
      <input
        placeholder="Email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value)
        }}
      ></input>
      <input
        placeholder="Password"
        type="password"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value)
        }}
        onSubmit={SignIn}
      ></input>

      <div className={styles.ContinueButton} onClick={SignIn}>
        Sign Up
      </div>
      <div
        className={styles.ActionMessuage}
        onClick={() => {
          nav("/resetPassword")
        }}
      >
        Forgot Password?
      </div>
      <div className={styles.ErrorMessage}>{error}</div>
      <div
        className={styles.ContinueButton}
        onClick={() => {
          nav("/signin" + search)
        }}
      >
        I have an account
      </div>
    </div>
  )
}

export const SignOut: React.FC<{}> = (): JSX.Element => {
  const nav = useNavigate()
  const { user } = useContext(AppContext)

  useEffect(() => {
    if (user !== null) {
      signOut(auth)
        .then(() => {})
        .catch((error) => {
          console.error(error)
        })
    } else {
      if (user === null && nav) {
        nav("../signin", { replace: false })
      }
    }
  }, [user, nav])

  return <div className={styles.root} />
}

export const ResetPassword: React.FC<{}> = (): JSX.Element => {
  const [email, setEmail] = useState("")
  const [error, setSetError] = useState("")
  const [sentButtonText, setSentButtonText] = useState("Next")

  function SignIn() {
    setSetError("")

    if (email === "") {
      setSetError("enter valid email")
      return
    }
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSentButtonText("Password reset email sent")
      })
      .catch((error) => {
        const errorCode = error.code
        setSetError(errorCode)
      })
  }

  return (
    <div className={styles.root}>
      <div className={styles.LoadingViewLogo}></div>
      <div className={styles.WelcomeText}>Reset Password</div>
      <div className={styles.MoreInfoText}>Enter your Email</div>
      <input
        placeholder="Email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value)
        }}
      ></input>

      <div className={styles.ContinueButton} onClick={SignIn}>
        {sentButtonText}
      </div>
      <div className={styles.ErrorMessage}>{error}</div>
      <div className={styles.ErrorMessage}>
        Need Help?
        <div
          className={styles.ActionMessuage}
          onClick={() => {
            window.location.href = "mailto:support@revivar.app"
          }}
        >
          support@revivar.app
        </div>
      </div>
    </div>
  )
}

// ValidateUserToAnon check if a user is logged in, and then creates an anonymous user so user can perform action until they to login
export function ValidateUserToAnon(user: User | null): Promise<User> {
  return new Promise<User>((resolve, reject) => {
    let actualUser = user
    if (user !== null) {
      resolve(user)
      return
    }

    signInAnonymously(auth)
      .then((user) => {
        actualUser = user.user
        return InitializeUser(actualUser, "Anonymous")
      })
      .then(() => {
        if (actualUser !== null) {
          resolve(actualUser)
        } else {
          reject("failed to get user")
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function ValidateUserCheckout(user: User | null, nav: NavigateFunction, loc: Location) {
  if (user === null || user.isAnonymous) {
    const searchParams = encodeURIComponent(loc.pathname + loc.search)
    nav(`/signin?${returnPageKey}=${searchParams}`)
    return false
  }
  return true
}
