import { collection, doc, onSnapshot } from "firebase/firestore"
import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { AppContext, firestoreDB, GoogleMapsAPIKey, stripePromise } from "../App"
import styles from "./cart.module.css"
import { User } from "firebase/auth"
import { OrderDetails } from "schema/dist/src/order"
import { ShippingMethod, UserAddressModel } from "schema/dist/src/address"
import { GetServerUrl, UpdateOrderSession } from "../create/createRevivart"
import { shippingCollectionName } from "../admin/shipping/shipping"
import axios from "axios"
import React from "react"
import { Elements } from "@stripe/react-stripe-js"
import { AddressElement } from "@stripe/react-stripe-js"
import { ShippingBackButton, stripeAppearance } from "./review"
import { StripeElementsOptions, StripeAddressElementChangeEvent } from "@stripe/stripe-js"
import GoogleMapReact from "google-map-react"

export const Main: React.FC<{}> = (): JSX.Element => {
  const loc = useLocation()
  const { user } = useContext(AppContext)

  const sessionID = new URLSearchParams(loc.search).get("orderID")
  const [orderSession, setOrderSession] = useState<OrderDetails>()

  useEffect(() => {
    if (user === null || sessionID === null) {
      return
    }

    const posterRefParent = doc(firestoreDB, "userData", user.uid)
    const orderCollection = collection(posterRefParent, "orderSession")
    const orderDocRef = doc(orderCollection, sessionID)

    const done = onSnapshot(orderDocRef, (snap) => {
      const det = snap.data() as OrderDetails

      setOrderSession(det)
    })

    return () => {
      done()
    }
  }, [user, sessionID])

  if (sessionID === null || orderSession === undefined || user === null) return <div> </div>

  return (
    <div className={styles.root}>
      <OrderShippingContainer orderSession={orderSession} user={user} />
    </div>
  )
}

export default Main

export const OrderShippingContainer: React.FC<{ orderSession: OrderDetails; user: User }> = ({
  orderSession,
  user,
}): JSX.Element => {
  const [userAddress, setUserAddress] = useState<UserAddressModel>(orderSession.shippingAddress)
  const [isComplete, setIsComplete] = useState(false)
  const [hasInferredComponents, setHasInferredComponents] = useState(false)
  const [suggestedAddress, setSuggestedAddress] = useState<GoogleValidationResult>()
  const [useRecommendation, setUseRecommendation] = useState(true)

  const nav = useNavigate()

  const options: StripeElementsOptions = {
    appearance: stripeAppearance,
  }

  function ValidateInputAddress(orderSession: OrderDetails) {
    UpdateOrderSession(orderSession, user.uid)
      .then((order) => {
        return ValidateAddress(order.shippingAddress)
      })
      .then((address) => {
        setSuggestedAddress(address)
        const suggest = (address.verdict.hasInferredComponents ?? false) || (address.verdict.hasReplacedComponents ?? false)

        if (suggest) {
          setHasInferredComponents(suggest)
          return
        }

        nav({ search: "?orderID=" + orderSession.uid, pathname: "./../review" })
      })
  }

  if (hasInferredComponents && suggestedAddress !== undefined) {
    return (
      <>
        <ShippingBackButton address={"../"} />
        <div className={styles.OrderShippingContainer}>
          <b> Confirm your delivery address</b>
          <ConfirmShippingAddress
            address={userAddress}
            selected={!useRecommendation}
            onClick={() => {
              setUseRecommendation(false)
            }}
          />
          <ConfirmShippingAddress
            address={GoogleAddressToUserAddress(suggestedAddress, userAddress.name, userAddress.phone, orderSession.email)}
            isRecommended
            center={{
              lat: suggestedAddress.geocode.location.latitude ?? 0,
              lng: suggestedAddress.geocode.location.longitude ?? 0,
            }}
            selected={useRecommendation}
            onClick={() => {
              setUseRecommendation(true)
            }}
          />
        </div>
        <div className={styles.OrderShippingContainerButton}>
          <div
            className={styles.CartContainerButton}
            onClick={() => {
              setHasInferredComponents(false)
              setSuggestedAddress(undefined)
            }}
          >
            Back
          </div>

          <div
            className={styles.CartContainerButton}
            onClick={() => {
              orderSession.shippingAddress = GoogleAddressToUserAddress(
                suggestedAddress,
                userAddress.name,
                userAddress.phone,
                orderSession.email
              )
              ValidateInputAddress(orderSession)
            }}
          >
            Continue
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <ShippingBackButton address={"../"} />
      <div className={styles.OrderShippingContainer}>
        <b>Shipping Address</b>
        <div className={styles.OrderShippingInfoContainer}>
          <Elements stripe={stripePromise} options={options}>
            <AddressElement
              onChange={(event) => {
                if (event.complete) {
                  // Extract potentially complete address
                  const userAddress = StripeAddressToUserAddress(event, orderSession.email)
                  setUserAddress(userAddress)
                }
                setIsComplete(event.complete)
              }}
              options={{
                mode: "shipping",
                allowedCountries: ["US"],
                blockPoBox: true,
                fields: {
                  phone: "always",
                },
                defaultValues: {
                  name: orderSession.shippingAddress.name,
                  address: {
                    line1: orderSession.shippingAddress.line1,
                    line2: orderSession.shippingAddress.line2,
                    city: orderSession.shippingAddress.city,
                    state: orderSession.shippingAddress.state,
                    postal_code: orderSession.shippingAddress.postalCode,
                    country: orderSession.shippingAddress.country,
                  },
                },
              }}
            />
          </Elements>
        </div>
      </div>

      {isComplete && (
        <div className={styles.OrderShippingContainer}>
          <div
            className={styles.CartContainerButton}
            onClick={() => {
              orderSession.shippingAddress = userAddress
              ValidateInputAddress(orderSession)
            }}
          >
            Continue to Overview
          </div>
        </div>
      )}
    </>
  )
}

function StripeAddressToUserAddress(address: StripeAddressElementChangeEvent, email: string): UserAddressModel {
  return {
    line1: address.value.address.line1,
    line2: address.value.address.line2 ?? "",
    city: address.value.address.city,
    state: address.value.address.state,
    postalCode: address.value.address.postal_code.replaceAll("", ""),
    country: address.value.address.country,
    name: address.value.name,
    phone: address.value.phone ?? "",
    email: "",
  }
}

function GoogleAddressToUserAddress(
  address: GoogleValidationResult,
  name: string,
  phone: string,
  email: string
): UserAddressModel {
  return {
    line1: address.address.postalAddress.addressLines[0] ?? "",
    line2: address.address.postalAddress.addressLines[1] ?? "",
    city: address.address.postalAddress.locality ?? "",
    state: address.address.postalAddress.administrativeArea ?? "",
    postalCode: address.address.postalAddress.postalCode ?? "",
    country: address.address.postalAddress.regionCode ?? "",
    name: name,
    phone: phone,
    email: email,
  }
}

export const OrderShippingAddressPreview: React.FC<{ orderSession: OrderDetails | undefined }> = ({
  orderSession,
}): JSX.Element => {
  if (orderSession === undefined) {
    return <></>
  }

  return (
    <div className={styles.OrderShippingContainer}>
      <b>Shipping Address</b>
      <div className={styles.OrderShippingAddressPreview}>
        {orderSession?.shippingAddress.name}
        <br />
        {orderSession?.shippingAddress.line1}
        {orderSession?.shippingAddress.line2}
        <br />
        {orderSession?.shippingAddress.city} {orderSession?.shippingAddress.state} {orderSession?.shippingAddress.country}{" "}
        {orderSession?.shippingAddress.postalCode}
      </div>
    </div>
  )
}

export const OrderShippingMethod: React.FC<{ orderSession: OrderDetails | undefined; user: User; userToken: string }> = ({
  orderSession,
  user,
  userToken,
}): JSX.Element => {
  const [shippingItems, setShippingItems] = useState<ShippingMethod[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [err, SetError] = useState<string>()

  useEffect(() => {
    const docRef = collection(firestoreDB, shippingCollectionName)

    const done = onSnapshot(docRef, (snap) => {
      const det = snap.docs.map((doc) => doc.data() as ShippingMethod)
      setShippingItems(det)
    })

    return () => {
      done()
    }
  }, [])

  if (orderSession === undefined) {
    return <></>
  }

  if (isLoading) {
    return <div className={styles.OrderShippingContainer}>Loading</div>
  }

  return (
    <div className={styles.OrderShippingContainer}>
      <b>Shipping</b>
      {shippingItems.map((item) => {
        return (
          <OrderShippingMethodItem
            key={item.amount}
            selected={item.uid === orderSession.shippingID}
            shippingItem={item}
            setSelected={(e) => {
              const updateCouponPoint = "/api/api/checkout/shipping"
              let couponAPI = GetServerUrl(updateCouponPoint)
              const checkoutAPILink = new URL(couponAPI)
              checkoutAPILink.searchParams.append("orderID", orderSession?.uid ?? "")
              checkoutAPILink.searchParams.append("customerID", user.uid ?? "")
              checkoutAPILink.searchParams.append("shippingID", item.uid)
              setIsLoading(true)
              SetError(undefined)
              axios
                .get(checkoutAPILink.toString(), {
                  headers: { Authorization: "Bearer " + userToken },
                })
                .then((res) => {
                  console.log(res)
                })
                .catch((res) => {
                  SetError(`${res} -> ${res?.response?.data}`)
                })
                .finally(() => {
                  setIsLoading(false)
                })
            }}
          />
        )
      })}
      {err && <span>Error: {err}</span>}
    </div>
  )
}

interface OrderShippingMethodItemProps {
  selected: boolean
  setSelected: (value: boolean) => void
  shippingItem: ShippingMethod
}

export const OrderShippingMethodItem: React.FC<OrderShippingMethodItemProps> = ({
  selected,
  setSelected,
  shippingItem,
}): JSX.Element => {
  let radioClassName = styles.OrderShippingMethodItemRadio

  if (selected) {
    radioClassName += " " + styles.OrderShippingMethodItemRadioSelected
  }

  return (
    <div
      className={styles.OrderShippingMethodItem}
      onClick={() => {
        if (selected) {
          return
        }
        setSelected(!selected)
      }}
    >
      <div className={radioClassName}></div>${shippingItem.amount / 100} - {shippingItem.title}
    </div>
  )
}

function ValidateAddress(address: UserAddressModel) {
  const addressValidatorAPIUrl = "https://addressvalidation.googleapis.com/v1:validateAddress?key=" + GoogleMapsAPIKey

  return axios
    .post(addressValidatorAPIUrl, {
      address: {
        regionCode: address.country,
        locality: address.city,
        administrativeArea: address.state,
        postalCode: address.postalCode,
        addressLines: [address.line1, address.line2],
      },
    })
    .then((res) => {
      const result = res.data.result as GoogleValidationResult
      return result
    })
}

interface GoogleValidationResult {
  verdict: {
    inputGranularity: GoogleValidationResult.Granularity
    validationGranularity: GoogleValidationResult.Granularity
    geocodeGranularity: GoogleValidationResult.Granularity
    addressComplete?: boolean
    hasUnconfirmedComponents?: boolean
    hasInferredComponents?: boolean
    hasReplacedComponents?: boolean
  }
  address: {
    formattedAddress?: string
    postalAddress: {
      revision?: number
      regionCode?: string
      languageCode?: string
      postalCode?: string
      sortingCode?: string
      administrativeArea?: string
      locality?: string
      sublocality?: string
      addressLines: string[]
      recipients?: string[]
      organization: string
    }
    addressComponents?: [
      {
        componentName?: {
          text?: string
          languageCode?: string
        }
        componentType?: string
        confirmationLevel?:
          | "CONFIRMATION_LEVEL_UNSPECIFIED"
          | "CONFIRMED"
          | "UNCONFIRMED_BUT_PLAUSIBLE"
          | "UNCONFIRMED_AND_SUSPICIOUS"
        inferred?: boolean
        spellCorrected?: boolean
        replaced?: boolean
        unexpected?: boolean
      }
    ]
    missingComponentTypes: string[]
    unconfirmedComponentTypes: string[]
    unresolvedTokens: string[]
  }
  geocode: {
    location: {
      latitude?: number
      longitude?: number
    }
    plusCode: {
      globalCode?: string
      compoundCode?: string
    }
    bounds?: {
      // object (Viewport)
    }
    featureSizeMeters?: number
    placeId?: string
    placeTypes?: string[]
  }
}

export namespace GoogleValidationResult {
  export type Granularity =
    | "GRANULARITY_UNSPECIFIED"
    | "SUB_PREMISE"
    | "PREMISE"
    | "PREMISE_PROXIMITY"
    | "BLOCK"
    | "ROUTE"
    | "OTHER"
}

interface ConfirmShippingAddress {
  address: UserAddressModel
  isRecommended?: boolean
  center?: GoogleMapReact.Coords
  selected?: boolean
  onClick: () => void
}

export const ConfirmShippingAddress: React.FC<ConfirmShippingAddress> = ({
  address,
  isRecommended,
  center,
  selected,
  onClick,
}): JSX.Element => {
  const buttonClassName =
    selected === true ? styles.ConfirmShippingAddressContainerButtonSelected : styles.ConfirmShippingAddressContainerButton

  let containerClassName = styles.ConfirmShippingAddressContainer

  if (selected === true) {
    containerClassName += " " + styles.ConfirmShippingAddressContainerSelected
  }

  if (isRecommended) {
    return (
      <div className={containerClassName} onClick={onClick}>
        <b>
          <div className={buttonClassName} />
          Recommended
        </b>
        <div className={styles.ConfirmShippingAddressContainerText}>
          {address.line1} {address.line2} <br />
          {address.city} {address.state} {address.country} {address.postalCode}
          <br />
          <div className={styles.ConfirmShippingAddressContainerMap}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: GoogleMapsAPIKey }}
              defaultCenter={center}
              defaultZoom={15}
              yesIWantToUseGoogleMapApiInternals
              options={(e) => {
                return {
                  mapTypeControl: false,
                  draggable: false,
                  disableDoubleClickZoom: true,
                  clickableIcons: false,
                  fullscreenControl: false,
                }
              }}
            >
              <ConfirmShippingMarker {...center} />
            </GoogleMapReact>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={containerClassName} onClick={onClick}>
      <b>
        <div className={buttonClassName} />
        What you Entered
      </b>
      <div className={styles.ConfirmShippingAddressContainerText}>
        {address.line1} {address.line2}
        <br />
        {address.city} {address.state} {address.country} {address.postalCode}
      </div>
    </div>
  )
}

export const ConfirmShippingMarker: React.FC<{ lat?: number; lon?: number }> = ({}): JSX.Element => {
  return <div className={styles.ConfirmShippingMarker}></div>
}
