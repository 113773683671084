import styles from "./index.module.css"
import { Routes, Route, useNavigate } from "react-router-dom"
import MainBg from "../../assets/aleksandr-popov-hTv8aaPziOQ-unsplash.jpg"
import { useEffect } from "react"
import EventsExample from "../../assets/video/eventPolaroidExample.mp4"

export const Main: React.FC<{}> = (): JSX.Element => {
  const nav = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={styles.eventsRoot}>
      <h1 className={styles.MainTitle}>{"Learn > Events"}</h1>
      <h4 className={styles.SubTitle}>
        Welcome to Revivar's Learn Events page! Here, we will guide you through why and how to use Revivar to enhance your events
        and create lasting memories
      </h4>
      <h1 className={styles.Title}>What is Special about buying a recap item?</h1>
      <p>
        When you purchase a recap item, you're not just getting a static image - you're getting a dynamic, interactive experience
        that can be customized to your preference. You can revisit specific moments and even interact with augmented reality that
        brings the event to life in new and exciting ways. You could also buy a recap item to show support to the Creator's of
        this event.
      </p>
      <h1 className={styles.Title}>What to Expect?</h1>
      <p>
        The video below provides an exclusive peek into what to expect from our recap items so you know exactly what you're
        getting when you purchase one
      </p>
      <div className={styles.GoodExample}>
        <video
          width="100%"
          height="100%"
          controls={true}
          autoPlay={true}
          src={EventsExample}
          loop
          playsInline
          muted
          preload={"auto"}
        />
      </div>
      <h1>Steps to getting one</h1>
      <h1>Step 1:</h1>
      <span>Go to our Events page</span>
      <h1>Step 2:</h1>
      <span>Search for an Event you attended. You can search with the name or words that deescribe the event </span>
      <h1>Step 3:</h1>
      <span>When you see the Event, click on it to see its recaps and details</span>
      <h1>Step 4:</h1>
      <span>Click on any Recap you would like to purchase</span>
      <h1>Step 5:</h1>
      <span>You can edit the type and size. Clicking the edit button let's you edit the cover image too</span>
      <h1>Step 6:</h1>
      <span>
        Press the play button to preview. When you're done and happy with the preview. Click “Finalize”. This adds the Recap item
        to your cart
      </span>
      <div className={styles.LinkButton}>
        <h3 className={styles.Link} onClick={() => nav("create")}>
          Create your own Event?
        </h3>
      </div>
    </div>
  )
}

export const Create: React.FC<{}> = (): JSX.Element => {
  const nav = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={styles.eventsRoot}>
      <h1 className={styles.MainTitle}>{"Learn > Events"}</h1>
      <h4 className={styles.SubTitle}>
        Welcome to Revivar's Learn Events page! Here, we will guide you through why and how to use Revivar to enhance your events
        and create lasting memories
      </h4>
      <h1 className={styles.Title}>Why Use this?</h1>
      <p>
        By utilizing Revivar's Augmented Reality Technology, you can capture your events in a new and exciting way, allowing your
        attendees to relive their favorite moments over and over again while giving them an option to purchase AR polaroids,
        tangible keepsakes from the event
      </p>
      <h1>Steps to creating one</h1>
      <h1>Step 1:</h1>
      <span>Go to your Events tab in your User dashboard</span>
      <h1>Step 2:</h1>
      <span>Click on the button, “New Event”, you should be taken to a different page</span>
      <h1>Step 3:</h1>
      <span>Fill in the necessary details, and save the fields - the title, description, date</span>
      <h1>Step 4:</h1>
      <span>Click on “Upload Videos' to upload videos taken in the event</span>
      <h1>Step 5:</h1>
      <span>You can the title, description and cover image of these videos. Click on them to enter the edit page</span>
      <h1>Step 6:</h1>
      <span>Share your event with the world</span>
      <div className={styles.LinkButton}>
        <h3 className={styles.Link} onClick={() => nav("/learn/events")}>
          Purchase an Event Recap?
        </h3>
      </div>
    </div>
  )
}

export const App: React.FC<{}> = (): JSX.Element => {
  return (
    <div style={{ backgroundImage: "url(" + MainBg + ")", backgroundRepeat: "no-repeat" }}>
      <Routes>
        <Route path="" element={<Main />} />
        <Route path="create" element={<Create />} />
      </Routes>
    </div>
  )
}

export default App
