import styles from "./index.module.css"
import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { firestoreDB } from "../../App"
import React from "react"
import { collection, doc, getDoc, onSnapshot } from "firebase/firestore"
import { Event, EventMedia } from "schema/dist/src/event"
import { EditMediaActions, EditMediaField } from "./edit"
import { ImageDPI, MIN_IMAGE_VARIANCE, MIN_IMAGE_DPI, UploadPicture } from "../../create/polaroid/helper"
import { uploadFile } from "../product/create"
import { CompressImage, UpdateEventMedia } from "./create"
import { ImageGallery, PosterViewState } from "../../shop/product/frame"
import { PlayPosterContainer, SizeToDimensions } from "../../shop/product"
import { SessionType } from "schema/dist/src/studio"
import { Product } from "schema/dist/src/product"
import { ValidateAssetSize } from "../../create/shared/shared"
import { calculateImageContrastActual } from "../../create/poster"

export const eventMediaFolderName = "eventMedia"
export const eventMediaAssetsFolderName = "eventAssets"
export const eventMediaRelatedImagesFolderName = "relatedImages"
export const eventMediaDefaultImagesFolderName = "defaultPrint"

type RouteParams = {
  eventID: string
  mediaID: string
}

export const EventMediaDetails: React.FC<{}> = (): JSX.Element => {
  const { eventID, mediaID } = useParams<RouteParams>()
  const [eventMedia, setEventMedia] = useState<EventMedia>()
  const [selectedEvent, setSelectedEvent] = useState<Event>()
  const [product, setProduct] = useState<Product>()

  const nav = useNavigate()

  useEffect(() => {
    if (eventID === undefined) return

    const eventDetailsRef = doc(firestoreDB, "events", eventID)
    const eventMediaCol = collection(eventDetailsRef, "medias")
    const eventPendingCol = collection(eventDetailsRef, "pending")

    const eventMediaRef = doc(eventMediaCol, mediaID)
    const eventPedMediaRef = doc(eventPendingCol, mediaID)

    //fetch the event
    const done = onSnapshot(eventDetailsRef, (response) => {
      const event = response.data() as Event
      setSelectedEvent(event)
    })

    //fetch the media
    const eventDone = onSnapshot(eventMediaRef, (response) => {
      if (response.exists()) {
        const event = response.data() as EventMedia
        setEventMedia(event)
      } else {
        getDoc(eventPedMediaRef).then((response) => {
          const event = response.data() as EventMedia
          setEventMedia(event)
        })
      }
    })

    return () => {
      done()
      eventDone()
    }
  }, [eventID, mediaID])

  useEffect(() => {
    if (selectedEvent === undefined) return
    if (selectedEvent.productUID === "") return

    const productVariantsRef = doc(firestoreDB, "shopProducts", selectedEvent.productUID)

    const done = onSnapshot(productVariantsRef, (response) => {
      const product = response.data() as Product
      setProduct(product)
    })

    return () => {
      done()
    }
  }, [eventMedia])

  if (eventMedia === undefined || selectedEvent === undefined || product === undefined) return <></>

  return (
    <>
      <div className={styles.UpdateOrderDetailsList}>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            nav(`../${eventID}/`)
          }}
        >
          Back
        </div>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            nav(`/events/${selectedEvent.uid}/asset/${eventMedia.uid}`)
          }}
        >
          PREVIEW
        </div>
      </div>
      <div className={styles.ProductListContainer}>
        <AssetEdit eventMedia={eventMedia} event={selectedEvent} studioProduct={product} isAdmin={true} />
      </div>
    </>
  )
}

interface AssetEditProps {
  eventMedia: EventMedia
  studioProduct: Product
  event: Event
  isAdmin: boolean
}

//Component that Contains all components for editing the media details
export const AssetEdit: React.FC<AssetEditProps> = ({ eventMedia, studioProduct, event, isAdmin }): JSX.Element => {
  const [isUploading, setIsUploading] = useState(false)
  const [playVideo, setPlayVideo] = useState(false)
  const [viewState, setViewState] = useState<PosterViewState>("normal")
  const [previewTime, setPreviewTime] = useState<number>()
  const [showVideo, setShowVideo] = useState(false)
  const [muted, setMuted] = useState(true)
  const [calculatedDPI, setCalculatedDPI] = useState<number>(0)
  const [calculatedContrast, setCalculatedContrast] = useState<number>(0)
  const [isLowDPI, setIsLowDPI] = useState(false)

  const defaultVariantID = event.defaultSelectedVariant
  const defaultVariant = studioProduct?.productVariantList.find((variant) => {
    return variant.uid === defaultVariantID
  })

  const posterRatio = SizeToDimensions(defaultVariant?.size ?? "") ?? 0
  let posterType: SessionType = "poster"

  if (defaultVariant?.sessionType !== undefined && defaultVariant?.sessionType !== "none") {
    posterType = defaultVariant?.sessionType
  }

  return (
    <>
      <div className={styles.EditEventContainer}>
        <div className={styles.PosterContentContainer}>
          <div className={styles.PosterContainer}>
            <ImageGallery
              imageURL={eventMedia.imageHDURL}
              videoURL={eventMedia.videoURL}
              ratio={posterRatio}
              play={playVideo}
              rotate={true}
              viewState={viewState}
              setPlay={setPlayVideo}
              type={posterType}
              previewTime={previewTime}
              showVideo={showVideo}
              muted={muted}
            />
          </div>
          <PlayPosterContainer
            isDarkMode
            isHidden={false}
            onPlay={setPlayVideo}
            onViewState={setViewState}
            viewState={viewState}
            play={playVideo}
            muted={muted}
            setMuted={setMuted}
          />
        </div>
        <div className={isAdmin ? styles.EditEventFieldsContainer : styles.EditUserEventContainer}>
          <EditMediaField media={eventMedia} fieldType="title" placeholder="Media Title" actionButton="Save Title" />
          <EditMediaField media={eventMedia} placeholder="Media Descriptions" fieldType="description" actionButton="Save Desc" />
          <UploadPicture
            onFileSelected={(a: File | Blob, height: number, width: number, d: HTMLCanvasElement) => {
              const valid = ValidateAssetSize(a, "image")
              if (!valid.valid) {
                console.log("Try again." + valid.reason)
                return
              }
              const media = eventMedia
              setIsUploading(true)
              const fileLDName = media.uid + "HDimage"
              const fileHDName = media.uid + "LDimage"

              const fileLocation = `/${eventMediaFolderName}/${media.eventUid}/${eventMediaAssetsFolderName}/${media.uid}/`

              CompressImage(a, 0.5).then((b) => {
                Promise.all([uploadFile(fileLDName, fileLocation, a), uploadFile(fileHDName, fileLocation, b)])
                  .then(([HDref, LDref]) => {
                    const printSize = defaultVariant?.size.match(/[\d.]+(?="|”)/g)?.map(Number)

                    if (printSize === undefined) {
                      console.log("Error uploading File")
                      return
                    }
                    const ppi = Math.round(Math.hypot(height, width) / Math.hypot(...printSize))
                    setCalculatedDPI(ppi)

                    const variance = calculateImageContrastActual(d)
                    setCalculatedContrast(variance)

                    if (ppi <= MIN_IMAGE_DPI || variance <= MIN_IMAGE_VARIANCE) {
                      setIsLowDPI(true)
                    } else {
                      media.imageHDURL = HDref.dest
                      media.imageLDURL = LDref.dest
                      return UpdateEventMedia(eventMedia)
                    }
                  })
                  .then((a) => {
                    setIsUploading(false)
                    setShowVideo(false)
                  })
              })
            }}
            onSlide={(e) => {
              setShowVideo(true)
              setPlayVideo(false)
              setPreviewTime(e)
            }}
            videoUrl={eventMedia.videoURL}
            isHidden={isLowDPI}
            showNext={false}
            showBack={false}
            onNexClicked={() => {}}
            disabled={isUploading}
            fileRatio={posterRatio}
          />
          <ImageDPI
            isHidden={!isLowDPI}
            calculatedDPI={calculatedDPI}
            calculatedContrast={calculatedContrast}
            showNext={false}
            showBack={true}
            onNexClicked={(e) => {
              setIsLowDPI(e)
            }}
            disabled={isUploading}
          />
        </div>
      </div>
      {isAdmin ? <EditMediaActions media={eventMedia} /> : null}
    </>
  )
}

// Get the first frame in a Video as An Image
// NOT IN USE
// export function GetFirstFrameVideoCoverImage(
//   videoRef: React.RefObject<HTMLVideoElement>,
//   canvasRef: React.RefObject<HTMLCanvasElement>
// ): Promise<Blob> {
//   return new Promise((resolve, reject) => {
//     if (videoRef.current === null || canvasRef.current === null) {
//       reject("DOM VIDEO NOT PRESENT YET")
//       return
//     }

//     let selectedTimeFrame = 0.01

//     // why do all this?
//     // const position = 1 / 100
//     // const totalTime = videoRef.current?.duration ?? 0
//     // const selectedTime = totalTime * position
//     // if (isFinite(selectedTime)) {
//     //   videoRef.current!.currentTime = selectedTime
//     //   selectedTimeFrame = selectedTime
//     // }

//     videoRef.current!.currentTime = selectedTimeFrame
//     // use this to calculate how to do the cropping
//     videoRef.current.oncanplay = () => {
//       const width = videoRef.current!.videoWidth
//       const height = videoRef.current!.videoHeight
//       CropSelectedVideoFrame(canvasRef.current!, videoRef.current!, 1, height, width)
//         .then((frame) => {
//           resolve(frame)
//         })
//         .catch((error) => reject(error))
//     }
//   })
// }
