import React, { useContext, useEffect, useState } from "react"
import styles from "./index.module.css"
import { useNavigate } from "react-router-dom"
import { AppContext } from "../App"
import { Revivart2D } from "../events/revivart"
import { useInView } from "react-intersection-observer"
import mainVideoFrame from "../assets/video/MAIN_VIDEO_POLAR.mp4"
import video1 from "../assets/video/MAIN_VIDEO_POLAR-1.mp4"
import video2 from "../assets/video/MAIN_VIDEO_POLAR-2.mp4"
import video3 from "../assets/video/MAIN_VIDEO_POLAR-3.mp4"
import video4 from "../assets/video/MAIN_VIDEO_POLAR-4.mp4"

export const Main: React.FC<{}> = (): JSX.Element => {
  const { setNavType } = useContext(AppContext)

  useEffect(() => {
    setNavType("home")
    return () => {
      setNavType("bar")
    }
  }, [])

  return (
    <div className={styles.root}>
      <HeadTop />
      <RecapDemo />
      <RecapExamples />
      <PolaroidList />
      <RecapExamplesExpanded />
      <PolaroidSilhouette />
      <RecapSiteNoteList />
      <RecapExamplesExpandedHost />
      <GettingStarted />
      <RecapPackages />
    </div>
  )
}
export default Main

export const HeadTop: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.HeadTop}>
      <div className={styles.HeadTopMin}>
        <div className={styles.LogoImage}></div>
        <div className={styles.LogoText}></div>
      </div>
    </div>
  )
}

export const RecapDemo: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.RecapDemo}>
      <div className={styles.RecapLogoImage}></div>
      <div className={styles.RecapDemoImage}>
        <div className={styles.RecapDemoVideo}>
          <video
            width="100%"
            height="100%"
            controls={false}
            autoPlay={true}
            src={mainVideoFrame}
            loop
            playsInline
            muted
            preload={"auto"}
          />
        </div>
      </div>
      <div className={styles.SubTitle}>Capture and share your event Memories in Stunning AR Prints with Revivar</div>
      {/* <div className={styles.Button}> Watch Demo </div> */}
    </div>
  )
}

export const RecapExamples: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.RecapExamples}>
      <div className={styles.Title}>Memories Stored in a Print </div>
      <div className={styles.SubMessage}>
        Using videos from your event, create tangible live AR prints of your guests that they can keep and cherish forever
      </div>
    </div>
  )
}

export const PolaroidList: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.PolaroidList}>
      <Revivart2D imageURL="" videoURL={video1} type="polaroid" ratio={1} onClick={() => {}} />
      <Revivart2D imageURL="" videoURL={video2} type="polaroid" ratio={1} onClick={() => {}} />
      <Revivart2D imageURL="" videoURL={video3} type="polaroid" ratio={1} onClick={() => {}} />
    </div>
  )
}

export const RecapExamplesExpanded: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.RecapExamples}>
      <div className={styles.Title}>Timeless event souvenirs </div>
      <div className={styles.SubMessage}>
        Your guests will have a tangible reminder of the events so they can revisit it anytime!
      </div>
    </div>
  )
}

export const PolaroidSilhouette: React.FC<{}> = (): JSX.Element => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  })

  let className = styles.PolaroidSilhouette

  if (inView) {
    className += " " + styles.RecapFormFactorsTitleStart
  }

  return <div className={className} ref={ref} />
}

export const RecapSiteNoteList: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.RecapSiteNoteList}>
      <RecapSiteNote
        topTitle="The"
        title="Future of Printed Memories"
        message="Revivar uses Image recognition and Augmented reality to save videos in printed pictures"
        image={styles.ARPhone}
      />
      <RecapFormFactors />
      <RecapSiteNote
        title="Save on Photo-booths"
        message="No need to large photo-booths, using Revivar your videographer captures everyone at candid moments"
        image={styles.SaveRecap}
        imageFirst
      />
    </div>
  )
}

export const RecapSiteNote: React.FC<{
  topTitle?: string
  title: string
  message: string
  image: string
  imageFirst?: boolean
}> = ({ title, message, image, imageFirst = false, topTitle = "" }): JSX.Element => {
  let className = styles.RecapSiteNote

  if (imageFirst) {
    className += " " + styles.RecapSiteNoteSwapped
  }

  return (
    <div className={className}>
      <div className={styles.RecapSiteNoteHalfLeft}>
        <div className={styles.SubMessage}>{topTitle}</div>
        <div className={styles.Title}>{title}</div>
        <div className={styles.SubMessage}>{message}</div>
      </div>
      <div className={styles.RecapSiteNoteHalfRight}>
        <div className={image}></div>
      </div>
    </div>
  )
}

export const RecapFormFactors: React.FC<{}> = (): JSX.Element => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  let titleClassName = styles.RecapFormFactorsTitle

  if (inView) {
    titleClassName += " " + styles.RecapFormFactorsTitleStart
  }

  return (
    <div className={styles.RecapFormFactors} ref={ref}>
      <div className={styles.SubTitle}>These memories in can be stored in any form you prefer</div>
      <RecapFormFactor />

      <div className={titleClassName} style={{ animationDelay: "0.6s" }}>
        Posters
      </div>

      <div className={titleClassName} style={{ animationDelay: "0.8s" }}>
        Polaroids
      </div>
      <div className={titleClassName} style={{ animationDelay: "1s" }}>
        Postcards
      </div>
      <div className={titleClassName} style={{ animationDelay: "1.2s" }}>
        Greeting Cards
      </div>
    </div>
  )
}

export const RecapExamplesExpandedHost: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.RecapExamples}>
      <div className={styles.Title}>Host-Funded Party Gifts! </div>
      <div className={styles.SubMessage}>
        Host of these events can also pre-pay for a set of polaroids so your guests get it for free!
      </div>
    </div>
  )
}

export const RecapFormFactor: React.FC<{}> = (): JSX.Element => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  let className = styles.RecapFormFactor

  if (inView) {
    className += " " + styles.RecapFormFactorsTitleStart
  }

  return (
    <div className={className} ref={ref}>
      <Revivart2D imageURL="" videoURL={video4} type="poster" ratio={0.75} onClick={() => {}} />
      <Revivart2D imageURL="" videoURL={video4} type="polaroid" ratio={0.75} onClick={() => {}} />
      <Revivart2D imageURL="" videoURL={video4} type="postcard" ratio={0.67} onClick={() => {}} />
    </div>
  )
}

// https://mailtolinkgenerator.com/

const Mail =
  "mailto:hello@revivar.app?cc=tobenna@revivar.app&subject=Revivar%20recap&body=Hey%20Revivar%2C%20%0A%0AI%20would%20love%20to%20recap%20my%20event%20and%20share%20it%20with%20my%20guests!%20Here%20is%20the%20link%20to%20my%20drive%20with%20all%20the%20videos."

export const GettingStarted: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.GettingStarted}>
      <div className={styles.Title}>Take your event to the next level </div>
      <GettingStartedItem step={1} message="Send us a drive of all your videos-> hello@revivar.app" />
      <GettingStartedItem step={2} message="Receive an events recap page that can be sent to your guests" />
      <GettingStartedItem step={3} message="Watch as your guests order and keep memories of your event" hideGuide />
      <div className={styles.GettingStartedButton}>
        <a className={styles.Button} href={Mail}>
          Get started Now!
        </a>
      </div>
    </div>
  )
}

export const GettingStartedItem: React.FC<{ step: number; message: string; hideGuide?: boolean }> = ({
  step,
  message,
  hideGuide = false,
}): JSX.Element => {
  return (
    <div className={styles.GettingStartedItem}>
      {!hideGuide && <div className={styles.GettingStartedGuide} />}
      <div className={styles.GettingStartedStep}>{step} </div>
      <div className={styles.GettingStartedMessage}>{message} </div>
    </div>
  )
}

interface RecapPackagePlan {
  type: string
  title: string
  minOrders?: number
  bestFor: string
  purchase: string
  analytics: string
  commission?: string
  prices?: string
}

const recapPackagePlans: RecapPackagePlan[] = [
  {
    type: "Starter",
    title: "Free",
    bestFor: "Best for events just staring to use Revivar",
    purchase: "Guests buy recaps from our website",
    analytics: "Limited Analytics",
  },
  {
    type: "Pro",
    title: "Pre-Order",
    bestFor: "Best for intimate events",
    purchase: "Pay for your guests",
    analytics: "All Analytics",
  },
  {
    type: "Enterprise",
    title: "Money-Back",
    minOrders: 500,
    bestFor: "Best for large events",
    purchase: "Guests buy recaps from our website",
    analytics: "All Analytics",
    commission: "5% commission",
    prices: "Higher recap prices",
  },
  {
    type: "Exclusive",
    title: "Videographer",
    minOrders: 50,
    bestFor: "Best for Exposure only for Videographer",
    purchase: "Guests buy recaps from our website",
    analytics: "All Analytics",
    commission: "10% commission",
    prices: "Access to Revivar Referals",
  },
]

export const RecapPackages: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.PackagesGettingStarted}>
      <div className={styles.PackagesTitle}>Packages </div>
      <div className={styles.Packages}>
        {recapPackagePlans.map((plan) => (
          <RecapPackageItem plan={plan} />
        ))}
      </div>
      <div className={styles.GettingStartedButton}>
        <a className={styles.Button} href={Mail}>
          Contact us
        </a>
      </div>
    </div>
  )
}

export const RecapPackageItem: React.FC<{ plan: RecapPackagePlan }> = ({ plan }): JSX.Element => {
  return (
    <div className={styles.Package}>
      <p className={styles.PackageDetail}>{plan.type} </p>
      <div className={styles.PackageTitleContainer}>
        <h4 className={styles.PackageTitle}>{plan.title}</h4>
        {plan.minOrders && <p className={styles.PackageHint}>min {plan.minOrders} orders</p>}
      </div>

      <p className={styles.PackageDetail}>{plan.bestFor} </p>
      <p className={styles.PackageDetail}> Custom Recap Page </p>
      <p className={styles.PackageDetail}>{plan.purchase} </p>
      <p className={styles.PackageDetail}>{plan.analytics} </p>
      {plan.commission && <p className={styles.PackageDetail}>{plan.commission} </p>}
      {plan.prices && <p className={styles.PackageDetail}>{plan.prices} </p>}
    </div>
  )
}
