import { collection, doc, onSnapshot, orderBy, query, setDoc } from "firebase/firestore"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { useContext, useEffect, useRef, useState } from "react"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { AppContext, firebaseApp, firestoreDB } from "../App"
import { OrderDetails } from "schema/dist/src/order"
import OrderBreakDown from "../account/order"
import PosterBreakDown from "../account/asset"
import Event from "./event"

import styles from "./index.module.css"
import loadingImage from "./../assets/loadingImage.png"
import { ProfileData } from "schema/dist/src/user"
import { uploadFile } from "../admin/product/create"
import { Poster } from "schema/dist/src/poster"
import { GetImageURLFromProduct } from "../cart/cart"

export const Main: React.FC<{}> = (): JSX.Element => {
  const [orderList, setOrderList] = useState<OrderDetails[]>([])
  const { user } = useContext(AppContext)
  const [profileData, setProfileData] = useState<ProfileData>()
  const [posterList, setPosterList] = useState<Poster[]>([])

  useEffect(() => {
    if (user === null) {
      return
    }

    const posterRefParent = doc(firestoreDB, "userData", user.uid)
    const orderCollection = collection(posterRefParent, "orderDetails")
    const orderQuery = query(orderCollection, orderBy("timestamp", "desc"))
    const done = onSnapshot(orderQuery, (snap) => {
      const CartItem = snap.docs.map((doc) => {
        return doc.data() as OrderDetails
      })
      setOrderList(CartItem)
    })

    const userDataDone = onSnapshot(posterRefParent, (response) => {
      const profileData = response.data() as ProfileData
      setProfileData(profileData)
    })

    const posterCollection = collection(posterRefParent, "userPosters")
    const posterQuery = query(posterCollection, orderBy("timestamp", "desc"))
    const userPosterDone = onSnapshot(posterQuery, (snap) => {
      const posterItems = snap.docs.map((doc) => {
        return doc.data() as Poster
      })
      setPosterList(posterItems)
    })

    return () => {
      done()
      userDataDone()
      userPosterDone()
    }
  }, [user, firestoreDB])

  return (
    <div className={styles.root}>
      <div className={styles.MainContentContainer}>
        <MainMenu />
        <div className={styles.AccountContentContainer}>
          <Routes>
            <Route path="/" element={<Overview orderList={orderList} profileData={profileData} posterList={posterList} />} />
            <Route path="/orders" element={<Orders orderList={orderList} profileData={profileData} posterList={posterList} />} />
            <Route path="/order/:orderID" element={<OrderBreakDown />} />
            <Route path="/gallery" element={<PosterBreakDown posterList={posterList} />} />
            <Route path="/events" element={<Event />} />
          </Routes>
        </div>
      </div>
    </div>
  )
}
export default Main

interface NavigationProps {
  imageClass: string
  title: string
  pathname: string
}

export const MainMenu: React.FC<{}> = (): JSX.Element => {
  const loc = useLocation()
  const nav = useNavigate()
  const MenuList: NavigationProps[] = [
    {
      imageClass: styles.AccountProfileIcon,
      title: "Account Overview",
      pathname: "/account",
    },
    {
      imageClass: styles.AccountGalleryIcon,
      title: "My Gallery",
      pathname: "/account/gallery",
    },
    {
      imageClass: styles.AccountOrdersIcon,
      title: "My Orders",
      pathname: "/account/orders",
    },
    // {
    //   imageClass: styles.AccountEventsIcon,
    //   title: "My Events",
    //   pathname: "/account/events",
    // },
    {
      imageClass: styles.AccountLogoutIcon,
      title: "Logout",
      pathname: "/signout",
    },
  ]

  return (
    <div className={styles.AccountControlContainer}>
      <div className={styles.AccountControl}>
        {MenuList.map((menu) => {
          const isSelected = loc.pathname === menu.pathname
          return (
            <div
              className={styles.AccountControlButton}
              key={menu.title}
              onClick={() => {
                nav(menu.pathname)
              }}
            >
              <span className={menu.imageClass}></span>
              {isSelected ? <b>{menu.title}</b> : menu.title}
            </div>
          )
        })}
      </div>
    </div>
  )
}

interface OverviewProps {
  orderList: OrderDetails[]
  posterList: Poster[]
  profileData?: ProfileData
}
export const Overview: React.FC<OverviewProps> = ({ orderList, profileData, posterList }): JSX.Element => {
  const nav = useNavigate()

  const { user } = useContext(AppContext)
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [actualURL, setActualURL] = useState("")
  const [uploadingText, setUploadingText] = useState("")

  useEffect(() => {
    const storage = getStorage()
    if (profileData?.profilePictureUrl.includes("gs://")) {
      const imageStorageRef = ref(storage, profileData?.profilePictureUrl)
      getDownloadURL(imageStorageRef).then((downloadURL) => {
        setActualURL(downloadURL)
      })
    } else {
      setActualURL(profileData?.profilePictureUrl ?? "")
    }
  }, [profileData?.profilePictureUrl])

  return (
    <>
      <div className={styles.AccountContentNameContainer}>
        <div className={styles.AccountContentProfilePicture}>
          <div className={styles.AccountContentProfileImage} style={{ backgroundImage: "url(" + actualURL + ")" }}>
            <div
              className={styles.AccountContentProfileImageUpload}
              onClick={() => {
                hiddenFileInput.current?.click()
              }}
            >
              {uploadingText}
            </div>
            <input
              type="file"
              accept=".png, .jpeg, .jpg"
              hidden={true}
              ref={hiddenFileInput}
              onChange={(e) => {
                if (e.target.files !== null) {
                  const file = e.target.files[0]

                  if (user === null || profileData === undefined) return

                  let profileURL = ""
                  uploadFile(
                    "profileImage",
                    `/userData/${user?.uid}/profileData/`,
                    file,
                    (a) => {
                      console.log(a)
                      setUploadingText(a.toFixed(1) + "%")
                    },
                    (a) => {}
                  )
                    .then((ref) => {
                      profileURL = ref.dest
                      profileData.profilePictureUrl = ""
                      const posterRefParent = doc(firestoreDB, "userData", user.uid)
                      return setDoc(posterRefParent, profileData)
                    })
                    .then((ref) => {
                      profileData.profilePictureUrl = profileURL
                      const posterRefParent = doc(firestoreDB, "userData", user.uid)
                      return setDoc(posterRefParent, profileData)
                    })
                    .then((ref) => {
                      console.log("done")
                      setUploadingText("")
                    })
                    .catch(() => {
                      console.log("error")
                      setUploadingText("error")
                    })
                    .finally(() => {})
                }
                e.target.files = null
                e.target.value = ""
              }}
            ></input>
          </div>
        </div>
        <div className={styles.AccountContentProfileInfo}>
          <b>{profileData?.userName}</b>
          <span>{profileData?.userEmail}</span>
          {/* <div className={styles.AccountContentProfileAction}>Change Email</div> */}
          <div
            className={styles.AccountContentProfileAction}
            onClick={() => {
              nav("/resetPassword")
            }}
          >
            Change Password
          </div>
        </div>
      </div>
      <div className={styles.AccountContentOrderContainer}>
        <div className={styles.AccountContentOrderCount}>
          <b>RevivARTS</b>
          <span>{posterList.length}</span>
          <div
            className={styles.AccountContentProfileAction}
            onClick={() => {
              nav("/account/gallery")
            }}
          >
            View Gallery
          </div>
        </div>
        <div className={styles.AccountContentOrderCount}>
          <b>Orders</b>
          <span>{orderList.length}</span>
          <div
            className={styles.AccountContentProfileAction}
            onClick={() => {
              nav("/account/orders")
            }}
          >
            View Orders
          </div>
        </div>
      </div>
    </>
  )
}

export const Orders: React.FC<OverviewProps> = ({ orderList }): JSX.Element => {
  const [page, setPage] = useState(1)

  const limitedOrder = orderList.slice((page - 1) * 5, page * 5)

  return (
    <div className={styles.AccountContentOrders}>
      <b>My Orders</b>
      {limitedOrder.map((orderItem) => {
        return <OrderBar key={orderItem.uid} orderDetail={orderItem} />
      })}
      <OrdersPagination totalPages={orderList.length / 5} currentPage={page} setPage={setPage} />
    </div>
  )
}

interface OrdersPaginationProps {
  totalPages: number
  currentPage: number
  setPage: (a: number) => void
  className?: string | undefined
  buttonClassName?: string | undefined
}

export const OrdersPagination: React.FC<OrdersPaginationProps> = ({
  totalPages,
  currentPage,
  setPage,
  className,
  buttonClassName,
}): JSX.Element => {
  let componentClassName = styles.OrdersPagination

  if (className !== undefined) {
    componentClassName += " " + className
  }

  let buttonComponentClassName = styles.OrdersPaginationButton
  if (buttonClassName !== undefined) {
    buttonComponentClassName += " " + buttonClassName
  }

  return (
    <div className={styles.OrdersPagination}>
      {currentPage > 1 ? (
        <div
          className={buttonComponentClassName}
          onClick={() => {
            setPage(currentPage - 1)
          }}
        >
          Prev
        </div>
      ) : null}

      <div
        className={buttonComponentClassName}
        onClick={() => {
          setPage(currentPage)
        }}
      >
        {currentPage}
      </div>
      {currentPage < totalPages ? (
        <div
          className={buttonComponentClassName}
          onClick={() => {
            setPage(currentPage + 1)
          }}
        >
          {currentPage + 1}
        </div>
      ) : null}

      {currentPage < totalPages ? (
        <div
          className={buttonComponentClassName}
          onClick={() => {
            setPage(currentPage + 1)
          }}
        >
          Next
        </div>
      ) : null}
    </div>
  )
}

interface OrderBarProps {
  orderDetail: OrderDetails
}
export const OrderBar: React.FC<OrderBarProps> = ({ orderDetail }): JSX.Element => {
  const [imageURL, setImageURL] = useState(loadingImage)
  const nav = useNavigate()

  useEffect(() => {
    GetImageURLFromProduct(orderDetail.CartItems[0].productVariant, orderDetail.CartItems[0].orderImageUrlHD)
      .then((url) => {
        setImageURL(url)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [orderDetail])

  return (
    <div className={styles.AccountContentOrderBar}>
      <div className={styles.AccountContentOrderBarImage} style={{ backgroundImage: "url(" + imageURL + ")" }}></div>
      <div className={styles.AccountContentOrderBarDetails}>
        <b>
          {orderDetail.CartItems[0].title}{" "}
          {orderDetail.CartItems.length > 1 ? "+" + (orderDetail.CartItems.length - 1) + " Items" : null}
        </b>

        <div> {orderDetail.uid.toLocaleUpperCase()}</div>
        <b> {orderDetail.orderStatus}</b>
        <b> {orderDetail.paymentStatus}</b>
        <div> ${orderDetail.total / 100}</div>
      </div>
      <div
        className={styles.AccountContentOrderBarViewMore}
        onClick={() => {
          nav(`../order/${orderDetail.uid}`)
        }}
      >
        Details
      </div>
    </div>
  )
}
