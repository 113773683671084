import styles from "./index.module.css"

export const Main: React.FC<{}> = ({}): JSX.Element => {
  return (
    <div className={styles.root}>
      <h1>Learn how to revive a picture</h1>
      <h1>Step 1:</h1>
      <span>
        Select the Anchor Image.
        <br />
        <br />
        Make sure you are only uploading image section you want the video to cover
      </span>
      <div className={styles.GoodExample}></div>
      <span>Do not upload the whole picture without cropping out were the video should be placed</span>
      <div className={styles.GoodExample + " " + styles.BadExample}></div>
      <h1>Step 2:</h1>
      <span>Upload the video that will be paced on the art picture</span>
      <h1>Step 3:</h1>
      <span>Select more picture that you want to place beside your video</span>
      <div className={styles.GoodExample + " " + styles.RelatedExample}></div>

      <h1>Step 4:</h1>
      <span>Enter the title and description of your revivart</span>
      <h1>Step 5:</h1>
      <span>Hit Finalize to create it</span>
    </div>
  )
}

export default Main
