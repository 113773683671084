import React, { useContext, useEffect, useRef, useState } from "react"
import styles from "./../poster/index.module.css"
import { getDoc } from "firebase/firestore"
import { doc, collection } from "firebase/firestore"
import { AppContext, firebaseApp, firestoreDB } from "../../App"
import { Product, ProductVariant } from "schema/dist/src/product"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ImagePlaceHolder from "../../assets/GreetingCardImage.jpg"
import ChristmasPlaceHolder from "../../assets/chrismasBackImage.jpg"
import {
  CreateStudioSession,
  FrequentList,
  IncludedList,
  IntroBody,
  IntroVideo,
  VariantContainer,
  VariantOrientation,
} from "../shared/shared"
import { addProductIDToSession, PosterPreview, useAnchorScroll, useSession, useStudioProduct } from "../poster"

const studioID = "greeting"
type greetingID = "christmas" | "birthday" | ""
const greetingIDSearchID = "greetingID"
const MonaVideo = ""
function getGreetingMessage(id: string | undefined): { title: string; subTitle: string; image: string } {
  switch (id) {
    case "christmas":
      return {
        title: "Create your Revivar Christmas Card",
        subTitle: "Custom Live Christmas card that have messages stored using Augmented Reality",
        image: ChristmasPlaceHolder,
      }
    case "birthday":
      return {
        title: "Create your Revivar Birthday Card",
        subTitle: "Custom Live Birthday card that have messages stored using Augmented Reality",
        image: ImagePlaceHolder,
      }
    default:
      return {
        title: "Create your Revivar Greeting Card",
        subTitle: "Custom Live greeting, birthday and Valentine's day cards with an AR Video",
        image: ImagePlaceHolder,
      }
  }
}

export const Main: React.FC<{}> = (): JSX.Element => {
  const { user } = useContext(AppContext)
  const navigate = useNavigate()
  const { sessionID } = useParams<{ sessionID: string }>()
  const anchorRef = useAnchorScroll(sessionID)
  const [session, setSession] = useSession(sessionID)
  const studioProduct = useStudioProduct(studioID)
  const loc = useLocation()
  const greetingID = new URLSearchParams(loc.search).get(greetingIDSearchID) ?? ""

  return (
    <div className={styles.root}>
      <div className={styles.VideoTop}>
        <IntroVideo videoURL={MonaVideo} backdrop={ImagePlaceHolder}>
          <IntroBody
            title={getGreetingMessage(greetingID).title}
            subTitle={getGreetingMessage(greetingID).subTitle}
            session={session}
            onClick={() => {
              if (studioProduct?.productVariantList[0] === undefined) return
              CreateStudioSession(studioProduct?.productVariantList[0], user?.uid ?? "", studioID).then((session) => {
                setSession(session)
                navigate({
                  pathname: "/studio/" + studioID + "/" + session.uid + loc.search,
                })
                anchorRef.current?.scrollIntoView({ behavior: "smooth", block: "start" })
              })
            }}
          />
        </IntroVideo>
      </div>
      <a ref={anchorRef}></a>
      <br />
      <PosterPreview
        session={session}
        setSession={setSession}
        product={studioProduct}
        sessionID={sessionID}
        studioID={studioID}
        posterType={"greeting"}
        sessionPosition={0}
      >
        <VariantContainer product={studioProduct} setStudioSession={setSession} studioSession={session} />
        <VariantOrientation
          orientation={session?.productVariant.orientation}
          setOrientation={(a) => {
            if (session === undefined) return
            const newVariant = addProductIDToSession(session, undefined, a)
            setSession(newVariant)
          }}
        />
      </PosterPreview>
      <IncludedList />
      <br />
      <FrequentList />
    </div>
  )
}

export default Main
