import styles from "../tobennawes/index.module.css"
import { useContext, useEffect } from "react"
import { AppContext } from "../../App"
import { PosterSection } from "../tobennawes"

const pageID = "burnaboy"
export const Main: React.FC<{}> = (): JSX.Element => {
  const { setNavType } = useContext(AppContext)
  useEffect(() => {
    setNavType("sleek")
    return () => {
      setNavType("bar")
    }
  }, [])

  return (
    <div className={styles.root}>
      <div
        className={styles.SectionVideoContainer}
        onClick={() => {
          window.scrollTo({ top: window.innerHeight, behavior: "smooth" })
        }}
      >
        <div className={styles.SectionVideoContent}>
          <div className={styles.BurnaboyHeaderSection}>
            <div className={styles.HeaderSectionLogo} />
          </div>
        </div>
      </div>
      <PosterSection categoryPageID={pageID} />
    </div>
  )
}
export default Main
