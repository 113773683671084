import styles from "./index.module.css"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { firebaseApp, firestoreDB } from "../../App"
import React from "react"
import { collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, query, setDoc, Timestamp, where } from "firebase/firestore"
import { Coupon, CreateCoupon, CreateDiscount, Discount } from "schema/dist/src/discount"
import { AdminActions, AdminActionsButton } from "../shared"
import DeleteIcon from "../../assets/DeleteBin.svg"

export const discountCollectionName = "discounts"
export const couponsCollectionName = "coupons"

export const DiscountList: React.FC<{}> = (): JSX.Element => {
  const [discountItems, setDiscountItems] = useState<Discount[]>([])
  const [filteredDiscountItems, setFilteredDiscountItems] = useState<Discount[]>([])
  const [searchTerm, setSearchTerm] = useState<string>("")
  const nav = useNavigate()

  useEffect(() => {
    const docRef = collection(firestoreDB, discountCollectionName)
    const done = onSnapshot(docRef, (response) => {
      const data = response.docs.map((doc) => {
        return doc.data() as Discount
      })
      setDiscountItems(data)
    })

    return () => {
      done()
    }
  }, [])

  useEffect(() => {
    const filter = discountItems.filter((val) => {
      const matchedTitle = val.title.toLowerCase().includes(searchTerm.toLowerCase())
      return matchedTitle
    })

    setFilteredDiscountItems(filter)
  }, [searchTerm, discountItems])

  return (
    <>
      <AdminActions>
        <AdminActionsButton
          onClick={() => {
            CreateNewShopDiscount()
              .then((discount) => {
                nav(`./${discount.uid}`)
              })
              .catch((error) => {
                console.error(error)
              })
          }}
          title={"create Discount"}
        />
        <AdminActionsButton
          onClick={() => {
            nav(`./../`)
          }}
          title={"back"}
        />
      </AdminActions>

      <div className={styles.ProductListContainer}>
        <input
          type="text"
          value={searchTerm ?? ""}
          onChange={(e) => {
            setSearchTerm(e.currentTarget.value)
            window.scrollTo(0, 0)
          }}
          className={styles.HeaderSearch}
          placeholder="Search for discounts by title"
          spellCheck={false}
        />
        <div className={styles.DiscountRowHeaderContainer}>
          <p></p>
          <p>Title</p>
          <p>Amount / Percent Off</p>
          <p>Status</p>
          <p></p>
        </div>
        {filteredDiscountItems.map((val, index) => {
          return <DiscountItem discount={val} key={val.uid} index={index} />
        })}
      </div>
    </>
  )
}

interface PosterProps {
  discount: Discount
  index: number
}

export const DiscountItem: React.FC<PosterProps> = ({ discount, index }): JSX.Element => {
  const nav = useNavigate()

  return (
    <div
      className={styles.DiscountRowContainer}
      onClick={() => {
        nav(`./${discount.uid}`)
      }}
    >
      <i>{index}</i>
      <p data-title="Title: ">{discount.title || "-"}</p>
      <p data-title="Amount: ">
        {discount.amountOff ?? discount.percentOff ?? 0} {discount.amountOff ? "$" : "%"}
      </p>
      <p data-title="Status: ">{discount.isValid ? "Valid" : "Not Valid"}</p>
      <img
        src={DeleteIcon}
        alt=""
        onClick={(e) => {
          DeleteShopDiscount(discount)
          e.stopPropagation()
        }}
      />
    </div>
  )
}

export function CreateNewShopDiscount(): Promise<Discount> {
  const ref = doc(collection(firestoreDB, discountCollectionName))
  const discount = CreateDiscount(ref.id, Timestamp.now())

  return UpdateShopDiscount(discount)
}

export function UpdateShopDiscount(discount: Discount): Promise<Discount> {
  const ref = doc(firestoreDB, discountCollectionName, discount.uid)

  return setDoc(ref, discount).then((result) => {
    return discount
  })
}

export function DeleteShopDiscount(discount: Discount): Promise<void> {
  const ref = doc(firestoreDB, discountCollectionName, discount.uid)

  return GetCouponsFromDiscount(discount)
    .then((result) => {
      const delCoupons = result.map((coupon) => {
        return DeleteShopCoupon(coupon)
      })
      return Promise.all(delCoupons)
    })
    .then((result) => {
      return deleteDoc(ref)
    })
    .then((result) => {
      return
    })
}

export function CreateNewShopCoupon(discount: Discount): Promise<Coupon> {
  const ref = doc(collection(firestoreDB, couponsCollectionName))
  const coupon = CreateCoupon(ref.id, Timestamp.now(), discount)

  return UpdateShopCoupon(coupon)
}

export function UpdateShopCoupon(coupon: Coupon): Promise<Coupon> {
  const ref = doc(firestoreDB, couponsCollectionName, coupon.uid)

  return setDoc(ref, coupon).then((result) => {
    return coupon
  })
}

export function DeleteShopCoupon(coupon: Coupon): Promise<void> {
  const ref = doc(firestoreDB, couponsCollectionName, coupon.uid)

  return deleteDoc(ref).then((result) => {
    return
  })
}

export function GetDiscount(discountID: string) {
  const collectionRef = doc(firestoreDB, discountCollectionName, discountID)
  return getDoc(collectionRef).then((doc) => {
    const dis = doc.data() as Discount
    return dis
  })
}

export function GetCoupon(couponID: string): Promise<Coupon | undefined> {
  //fetch the event

  return new Promise<Coupon | undefined>((resolve, reject) => {
    if (couponID === "") {
      resolve(undefined)
      return
    }

    const collectionRef = doc(firestoreDB, couponsCollectionName, couponID)
    return getDoc(collectionRef).then((doc) => {
      if (!doc.exists) {
        resolve(undefined)

        return
      }

      const cou = doc.data() as Coupon
      resolve(cou)
      return
    })
  })
}

export function GetCouponFromCode(couponCode: string) {
  const collectionRef = collection(firestoreDB, couponsCollectionName)
  const catQuery = query(
    collectionRef,
    where("code", "==", couponCode),
    where("isActive", "==", true),
    where("isValid", "==", true)
  )

  return getDocs(catQuery).then((doc) => {
    if (doc.size === 0) return
    const cou = doc.docs.map((doc) => doc.data() as Coupon)

    return cou[0]
  })
}

export function GetCouponsFromDiscount(discount: Discount) {
  const collectionRef = collection(firestoreDB, couponsCollectionName)
  const catQuery = query(collectionRef, where("discount.uid", "==", discount.uid))

  return getDocs(catQuery).then((doc) => {
    return doc.docs.map((doc) => doc.data() as Coupon)
  })
}

export function CouponToString(discount: Discount | undefined): string {
  if (discount === undefined) return ""
  return discount?.type === "amount" ? `$${(discount.amountOff ?? 0) / 100}` : `${discount?.percentOff}%`
}
