import styles from "./index.module.css"
import { Route, Routes } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AppContext, firebaseApp, firestoreDB } from "../App"
import React from "react"
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore"
import { Event } from "schema/dist/src/event"
import { setTabTitle } from "../App"
import { CreateNewEvent } from "../admin/event/create"
import loadingImage from "./../assets/loadingImage.png"
import { getDownloadURL, getStorage, ref } from "firebase/storage"

export const Main: React.FC<{}> = (): JSX.Element => {
  setTabTitle("User Events")

  return (
    <Routes>
      <Route path="/*" element={<UserEventsList />} />
    </Routes>
  )
}

export default Main

export const UserEventsList: React.FC<{}> = ({}): JSX.Element => {
  const [eventsList, setEventsList] = useState<Event[]>([])
  const [titleText, setTitleText] = useState<string>("CREATE EVENT")
  const { user } = useContext(AppContext)
  const nav = useNavigate()

  //on component render, fetch all events
  useEffect(() => {
    if (user === null) return
    const docRef = collection(firestoreDB, "events")

    getDocs(query(docRef, where("creatorID", "==", user?.uid)))
      .then((snap) => {
        const fetchedEvents = snap.docs.map((doc) => {
          return doc.data() as Event
        })
        setEventsList(fetchedEvents)
      })
      .catch((error) => {
        console.log("Error getting document:", error)
      })
  }, [user])

  if (user === null) {
    return <></>
  }

  return (
    <div className={styles.AccountEventsContainer}>
      <div className={styles.UserEventsContainer}>
        <div className={styles.UserEvents}>
          <b>My Events</b>
          <div
            className={styles.UserEventCreateButton}
            onClick={() => {
              setTitleText("Loading")
              CreateNewEvent(user.uid)
                .then((eventid) => {
                  nav(eventid)
                })
                .catch((error) => {})
                .finally(() => {
                  setTitleText("CREATE EVENT")
                })
            }}
          >
            {titleText}
          </div>
        </div>
      </div>

      <div className={styles.UserEventsList}>
        {eventsList.map((val) => {
          return <UserEventBlock event={val} key={val.uid} />
        })}
      </div>
    </div>
  )
}

export const UserEventBlock: React.FC<{ event: Event }> = ({ event }): JSX.Element => {
  const [imageURL, setImageURL] = useState(loadingImage)

  const nav = useNavigate()

  useEffect(() => {
    if ((event.imageLDURL ?? "") === "") {
      setImageURL("")
    } else {
      const storage = getStorage()
      getDownloadURL(ref(storage, event.imageLDURL))
        .then((url) => {
          setImageURL(url)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [event])

  return (
    <div className={styles.AccountEvents}>
      <div
        className={styles.AccountEventItem}
        onClick={() => {
          nav(event.uid)
        }}
        style={{ backgroundImage: "url(" + imageURL + ")" }}
      ></div>
      <b>{event.title !== "" ? event.title : "Untitled Event"}</b>
    </div>
  )
}
