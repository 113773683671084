import styles from "./index.module.css"
import { useContext, useEffect, useRef, useState } from "react"
import MixedWall from "../../assets/video/mixwall.mp4"
import MixedWallHold from "../../assets/video/mixwall.jpg"

import LOVEFRAME from "../../assets/video/LOVEFRAME.mp4"
import showTell from "../../assets/video/DEMOPREV.mp4"
import videoFrame from "../../assets/video/FRAME_1.mp4"
import polaroid from "../../assets/video/polaroid.mp4"
import invite from "../../assets/video/invite.mp4"
import sports from "../../assets/video/sports.mp4"
import greeting1 from "../../assets/video/CARD8PREVIEW.mp4"
import greeting2 from "../../assets/video/CARD1PREV.mp4"

import { useNavigate } from "react-router-dom"
import { useNavBar } from "../../App"

export const Main: React.FC<{}> = ({}): JSX.Element => {
  const cursorRef = useRef<HTMLDivElement>(null)
  const nav = useNavigate()
  const _ = useNavBar("home")

  return (
    <div
      className={styles.root}
      onMouseMove={(e) => {
        if (cursorRef.current === null) {
          return
        }

        const { clientX, clientY } = e

        cursorRef.current.style.left = clientX + "px"
        cursorRef.current.style.top = clientY + "px"
      }}
    >
      <div className={styles.cursor} ref={cursorRef}></div>

      <IntroVideo placeholder={MixedWallHold} videoURL={MixedWall} />
      <FrameExplained />
      <DemoVideo />
      <SimpleCreation />
      <LargeLargeText>Easy to create</LargeLargeText>
      <LargeSmallText>
        Order it or Revive an existing printed art work, Revivar has you covered. By simply uploading a video and selecting single
        image, you can create live art for anyone to see.
      </LargeSmallText>
      <div className={styles.ExamplesContainer}>
        <ExamplesLarge videoURL={polaroid} right>
          Polaroids
          <div
            className={styles.ViewMoreButton}
            onClick={() => {
              nav("./studio/polaroid")
            }}
          >
            Create
          </div>
        </ExamplesLarge>
        <ExamplesLarge videoURL={videoFrame}>
          Posters
          <div
            className={styles.ViewMoreButton}
            onClick={() => {
              nav("./studio/poster")
            }}
          >
            Create
          </div>
        </ExamplesLarge>
        <ExamplesLarge videoURL={greeting1}>
          Christmas <br />
          Cards
          <div
            className={styles.ViewMoreButton}
            onClick={() => {
              nav("./studio/greeting?greetingID=christmas")
            }}
          >
            Create
          </div>
        </ExamplesLarge>
        <ExamplesLarge videoURL={greeting2} right>
          Birthday <br />
          Cards
          <div
            className={styles.ViewMoreButton}
            onClick={() => {
              nav("./studio/greeting?greetingID=birthday")
            }}
          >
            Create
          </div>
        </ExamplesLarge>
        <ExamplesLarge videoURL={invite}>
          Invitations
          <div
            className={styles.ViewMoreButton}
            onClick={() => {
              nav("./studio/postcard")
            }}
          >
            Create
          </div>
        </ExamplesLarge>
        <ExamplesLarge videoURL={sports} right>
          Sports Cards
        </ExamplesLarge>{" "}
      </div>

      <IconicText>
        Iconic Moments <br /> Unforgettable Gifts
      </IconicText>
      <ReferenceVideo />
      <AfricaContent />
    </div>
  )
}

export default Main

interface IntroVideoProps {
  placeholder: string
  videoURL: string
}

const IntroVideo: React.FC<IntroVideoProps> = ({ placeholder, videoURL }): JSX.Element => {
  const [hidden, setHidden] = useState(true)
  return (
    <div className={styles.IntroVideoContainer}>
      <IntroVideoContent />
      <video
        loop
        autoPlay
        muted
        playsInline
        src={videoURL}
        onCanPlay={() => {
          setHidden(false)
        }}
        hidden={hidden}
      ></video>
    </div>
  )
}

export const IntroVideoContent: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.IntroVideoContainerMain}>
      <div className={styles.IntroVideoContainerMainTop}>
        <div className={styles.LogoImage}></div>
      </div>
      <div className={styles.IntroVideoContainerMainMid}>
        <div className={styles.LogoText}></div>
        <span
          onClick={() => {
            window.scrollTo({ top: window.innerHeight, behavior: "smooth" })
          }}
        >
          {" "}
          Learn More
        </span>
      </div>
      <div className={styles.IntroVideoContainerMainBottom}>
        <div className={styles.TobennaWesLogoImage}></div>
      </div>
    </div>
  )
}

export const LargeSmallText: React.FC<{}> = ({ children }): JSX.Element => {
  return <div className={styles.LargeSmallText}>{children}</div>
}

export const LargeMediumText: React.FC<{}> = ({ children }): JSX.Element => {
  return <div className={styles.LargeMediumText}>{children}</div>
}

export const LoadingDisk: React.FC<{ isLoaded: boolean }> = ({ isLoaded }): JSX.Element => {
  if (isLoaded) {
    return <></>
  }
  return (
    <div className={styles.PosterFrameSpinnerContainer}>
      <div className={styles.PosterFrameSpinner}></div>
    </div>
  )
}
export const FrameExplained: React.FC<{}> = (): JSX.Element => {
  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <div className={styles.FrameExplained}>
      <LoadingDisk isLoaded={isLoaded} />

      <video
        loop
        autoPlay
        muted
        playsInline
        src={LOVEFRAME}
        onLoadedData={() => {
          setIsLoaded(true)
        }}
        onWaiting={(e) => {
          setIsLoaded(false)
        }}
        onPlaying={(e) => {
          setIsLoaded(true)
        }}
      ></video>

      <h1>Memories stored in Interactive Prints </h1>
      {/* Store your Memories in a Frame */}
      <span>
        Revivar's mission is to bridge the gap between digital memories and tangible experiences. Using Augmented Reality (AR), We
        strive to create lasting connection by enhancing the way you interact with your cherished moments.
      </span>
    </div>
  )
}

export const SimpleCreation: React.FC<{}> = (): JSX.Element => {
  return <div className={styles.SimpleCreation}></div>
}

export const DemoVideo: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.DemoVideo}>
      <span>
        The<h1> Future of Prints</h1>
        Revivar uses Image recognition, geocaching and NFCs to know what videos are for what prints, allowing it to work anywhere
        in the world!
      </span>
      <video loop autoPlay muted playsInline controls src={showTell}></video>
    </div>
  )
}

export const LargeLargeText: React.FC<{}> = ({ children }): JSX.Element => {
  return <div className={styles.LargeLargeText}>{children}</div>
}

interface ExamplesLargeProps {
  right?: boolean
  videoURL: string
}
export const ExamplesLarge: React.FC<ExamplesLargeProps> = ({ right = false, videoURL, children }): JSX.Element => {
  let className = styles.ExamplesLarge

  if (right) {
    className += " " + styles.ExamplesLargeRight
  }
  return (
    <div className={className}>
      <video loop autoPlay muted playsInline src={videoURL}></video>
      <span>{children}</span>
    </div>
  )
}

export const IconicText: React.FC<{}> = ({ children }): JSX.Element => {
  return <div className={styles.IconicText}>{children}</div>
}

export const ReferenceVideo: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.ReferenceVideo}>
      <HistoryContent />
    </div>
  )
}

export const HistoryContent: React.FC<{}> = (): JSX.Element => {
  let nav = useNavigate()
  return (
    <div className={styles.HistoryContentMain}>
      <div className={styles.TobennaWesLogoImage}></div>
      <b> A TOBENNAWES PRODUCT</b>
      <span>
        In 2019, Tobewes started on a journey to create an app so people could get lost in his photography. Soon he realized that
        everyone should be able to create such and experience and share it in this same way.
        <br />
        <br />
        In 2020, Revivar was released.
      </span>
      <div
        className={styles.ShopPosterButton}
        onClick={() => {
          nav("/shop/tobennawes")
        }}
      >
        Shop Tobewes
      </div>
    </div>
  )
}

export const AfricaContent: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.AfricaContent}>
      African owned company
      <div className={styles.AfricaContentIcon}></div>
    </div>
  )
}
