import styles from "./index.module.css"
import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { firestoreDB } from "../../App"
import React from "react"
import { collection, doc, onSnapshot, query, where } from "firebase/firestore"
import {
  couponsCollectionName,
  CreateNewShopCoupon,
  DeleteShopCoupon,
  DeleteShopDiscount,
  discountCollectionName,
  UpdateShopCoupon,
  UpdateShopDiscount,
} from "./discount"
import { Coupon, Discount } from "schema/dist/src/discount"
import { AdminActions, AdminActionsButton } from "../shared"

type RouteParams = {
  discountID: string
}

export const DiscountDetails: React.FC<{}> = (): JSX.Element => {
  //get the event id from the params of the page/route
  const { discountID } = useParams<RouteParams>()

  const [selectedDiscount, setSelectedDiscount] = useState<Discount>()
  const nav = useNavigate()

  //add onSnapshot listener to update the event and eventmedias if there are changes
  useEffect(() => {
    if (discountID === undefined) return

    const eventDetailsRef = doc(firestoreDB, discountCollectionName, discountID)

    //fetch the event
    const done = onSnapshot(eventDetailsRef, (response) => {
      const event = response.data() as Discount
      setSelectedDiscount(event)
    })

    return () => {
      done()
    }
  }, [discountID])

  if (selectedDiscount === undefined) {
    return <>not available</>
  }

  return (
    <>
      <AdminActions>
        <AdminActionsButton
          onClick={() => {
            nav(`../`)
          }}
          title={"BACK"}
        />
      </AdminActions>
      <CreateDiscountDetails discount={selectedDiscount} />
      <CouponList discount={selectedDiscount} />
    </>
  )
}

export const CreateDiscountDetails: React.FC<{ discount: Discount }> = ({ discount }): JSX.Element => {
  const [discountEdit, setDiscountEdit] = useState<Discount>(discount)
  const nav = useNavigate()

  useEffect(() => {
    setDiscountEdit(discount)
  }, [discount])

  return (
    <div className={styles.CreateDiscountDetails}>
      <h1> CREATE DISCOUNT</h1>
      <div className={styles.DiscountDetailRow}>
        <div> Title</div>
        <input
          type="text"
          onChange={(e) => {
            let newDiscount = { ...discountEdit }
            newDiscount.title = e.currentTarget.value
            setDiscountEdit(newDiscount)
          }}
          value={discountEdit.title}
          disabled={discountEdit.isValid}
        />
      </div>

      <div className={styles.DiscountDetailRow}>
        Discount Type
        <div className={styles.DiscountTypesContainer}>
          <RadioButton
            title="Amount"
            onClick={() => {
              let newDiscount = { ...discountEdit }
              newDiscount.type = "amount"
              newDiscount.percentOff = null
              newDiscount.amountOff = null
              setDiscountEdit(newDiscount)
            }}
            isSelected={discountEdit.type === "amount"}
            disabled={discountEdit.isValid}
          />

          <RadioButton
            title="Percent"
            onClick={() => {
              let newDiscount = { ...discountEdit }
              newDiscount.type = "percent"
              newDiscount.percentOff = null
              newDiscount.amountOff = null
              setDiscountEdit(newDiscount)
            }}
            isSelected={discountEdit.type !== "amount"}
            disabled={discountEdit.isValid}
          />
        </div>
      </div>

      <div className={styles.DiscountDetailRow}>
        {discountEdit.type === "amount" ? (
          <>
            <div> Amount Off</div>
            <input
              type="number"
              value={discountEdit.amountOff ?? ""}
              onChange={(e) => {
                let newDiscount = { ...discountEdit }

                let numString = parseFloat(e.currentTarget.value)
                if (isNaN(numString) || numString < 0) {
                  newDiscount.amountOff = null
                  setDiscountEdit(newDiscount)
                  return
                }

                newDiscount.amountOff = numString
                setDiscountEdit(newDiscount)
              }}
              disabled={discountEdit.isValid}
            />
          </>
        ) : (
          <>
            <div> Percent Off </div>
            <input
              type="number"
              value={discountEdit.percentOff ?? ""}
              onChange={(e) => {
                let newDiscount = { ...discountEdit }

                let numString = parseFloat(e.currentTarget.value)
                if (isNaN(numString) || numString < 0) {
                  newDiscount.percentOff = null
                  setDiscountEdit(newDiscount)
                  return
                }
                if (numString > 100) return

                newDiscount.percentOff = numString
                setDiscountEdit(newDiscount)
              }}
              disabled={discountEdit.isValid}
            />
          </>
        )}
      </div>

      <div className={styles.DiscountDetailRow}>
        <div>Event (optional) </div>
        <input
          type="text"
          onChange={(e) => {
            let newDiscount = { ...discountEdit }
            const applies: Discount.AppliesTo = {
              eventID: e.currentTarget.value,
            }

            newDiscount.appliesTo = applies
            setDiscountEdit(newDiscount)
          }}
          value={discountEdit.appliesTo?.eventID ?? ""}
          disabled={discountEdit.isValid}
        />
      </div>

      {/* <div className={styles.DiscountDetailRow}>
        <div>Product (optional)</div>
        <input
          type="text"
          onChange={(e) => {
            let newDiscount = { ...discountEdit }
            newDiscount.title = e.currentTarget.value
            setDiscountEdit(newDiscount)
          }}
          value={discountEdit.appliesTo?.productID ?? ""}
          disabled={discountEdit.isValid}
        />
      </div> */}
      <div
        className={styles.ActionButtonSave}
        onClick={() => {
          navigator.clipboard.writeText(discount.uid)
        }}
      >
        COPY
      </div>
      <div className={styles.ActionButtonsContainer}>
        {discountEdit.isValid ? (
          <div
            className={styles.ActionButtonDelete}
            onClick={() => {
              DeleteShopDiscount(discountEdit).then((discount) => {
                nav(`./../`)
              })
            }}
          >
            DELETE
          </div>
        ) : (
          <div
            className={styles.ActionButtonSave}
            onClick={() => {
              let newDiscount = { ...discountEdit }
              newDiscount.isValid = true
              UpdateShopDiscount(newDiscount).then((discount) => {
                console.log(discount)
              })
            }}
          >
            SAVE
          </div>
        )}
      </div>
    </div>
  )
}

interface RadioButtonProps {
  title: string
  isSelected?: boolean
  onClick: (a: boolean) => void
  disabled?: boolean
}

export const RadioButton: React.FC<RadioButtonProps> = ({ title, isSelected, onClick, disabled }): JSX.Element => {
  const [clicked, setClicked] = useState(isSelected ?? false)

  useEffect(() => {
    if (isSelected === undefined) return
    if (isSelected === clicked) return

    setClicked(isSelected)
  }, [isSelected, clicked])

  return (
    <div
      className={styles.RadioButtonContainer}
      onClick={() => {
        if (disabled) return
        onClick(!isSelected)
      }}
    >
      <div className={styles.RadioButton}>{clicked ? <div className={styles.RadioButtonPin} /> : null}</div> {title}
    </div>
  )
}

export const CouponList: React.FC<{ discount: Discount }> = ({ discount }): JSX.Element => {
  const [couponList, setCouponList] = useState<Coupon[]>([])
  const [allCouponList, setAllCouponList] = useState<Coupon[]>([])

  useEffect(() => {
    const collectionRef = collection(firestoreDB, couponsCollectionName)
    const que = query(collectionRef, where("discount.uid", "==", discount.uid))
    //fetch the coupons of the discount
    const done = onSnapshot(que, (response) => {
      const data = response.docs.map((doc) => {
        return doc.data() as Coupon
      })
      setCouponList(data)
    })

    return () => {
      done()
    }
  }, [discount.uid])

  useEffect(() => {
    const collectionRef = collection(firestoreDB, couponsCollectionName)
    //fetch all coupons in db
    const done = onSnapshot(collectionRef, (response) => {
      const data = response.docs.map((doc) => {
        return doc.data() as Coupon
      })
      setAllCouponList(data)
    })

    return () => {
      done()
    }
  }, [])

  return (
    <div className={styles.CouponList}>
      <div
        className={styles.CreateCouponButton}
        onClick={() => {
          CreateNewShopCoupon(discount).then((coupon) => {
            return console.log(coupon)
          })
        }}
      >
        + Create Coupon Code
      </div>
      {couponList.map((coupon, index) => {
        return <CouponItem coupon={coupon} key={coupon.uid} index={index + 1} allCouponList={allCouponList} />
      })}
    </div>
  )
}

export const CouponItem: React.FC<{ coupon: Coupon; index: number; allCouponList: Coupon[] }> = ({
  coupon,
  index,
  allCouponList,
}): JSX.Element => {
  const [couponCode, setCouponCode] = useState("")
  const [maxRedemptions, setMaxRedemptions] = useState<number | null>(null)
  const [canSave, setCanSave] = useState<boolean>(false)

  useEffect(() => {
    if (coupon.isValid) return
    const found = allCouponList.find((coupon) => {
      return couponCode === coupon.code || couponCode === ""
    })
    if (found) {
      setCanSave(false)
    } else {
      setCanSave(true)
    }
  }, [couponCode])

  const parentClassName = coupon.isActive ? styles.CouponItem : styles.CouponItemInactive

  return (
    <div className={parentClassName}>
      <div className={styles.CouponItemTitle}>{index}</div>
      <div className={styles.CouponItemTitle}>
        Coupon Code: <b>{coupon.code}</b>
      </div>
      <input
        type="text"
        onChange={(e) => {
          setCouponCode(e.currentTarget.value)
        }}
        value={couponCode}
        placeholder="Coupon Code"
        hidden={coupon.isValid}
      />

      <div className={styles.CouponItemText}>
        Max Redemptions: <b>{coupon.maxRedemptions}</b>
      </div>
      <input
        type="number"
        onChange={(e) => {
          let numString = parseFloat(e.currentTarget.value)
          if (isNaN(numString) || numString < 0) {
            setMaxRedemptions(null)
            return
          }

          setMaxRedemptions(numString)
        }}
        value={maxRedemptions ?? ""}
        placeholder="Unlimited"
        hidden={coupon.isValid}
      />

      {coupon.isValid ? (
        <div className={styles.CouponItemText}>
          Times Redeemed: <b> {coupon.timesRedeemed}</b>
        </div>
      ) : null}

      {coupon.isValid ? (
        <button
          className={styles.CouponItemSaveButton}
          onClick={() => {
            DeleteShopCoupon(coupon)
          }}
        >
          Delete
        </button>
      ) : (
        <button
          className={canSave ? styles.CouponItemSaveButton : styles.CouponItemSaveButtonDisabled}
          onClick={() => {
            if (!canSave) return
            const newCou = { ...coupon }
            newCou.code = couponCode
            newCou.isValid = true
            newCou.isActive = true
            newCou.maxRedemptions = maxRedemptions
            UpdateShopCoupon(newCou).then((result) => {
              console.log(result)
            })
          }}
        >
          Save
        </button>
      )}
      <button
        className={styles.CouponItemSaveButton}
        onClick={() => {
          navigator.clipboard.writeText(coupon.uid)
        }}
      >
        Copy ID
      </button>
    </div>
  )
}
