import styles from "./index.module.css"
import { useContext, useEffect, useRef, useState } from "react"
import { Product } from "schema/dist/src/product"
import { AppContext, firestoreDB, setTabTitle } from "../../App"
import {
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  Query,
  query,
  startAfter,
  where,
} from "firebase/firestore"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { BackURLKey, SizeToDimensions } from "../product"
import { Category } from "schema/dist/src/category"
import { ValidateUserToAnon, returnPageKey } from "../../auth"
import { AddToCart } from "../../cart/cart"
import { Revivart2D } from "../../events/revivart"

const pageID = "tobennawes"
export const Main: React.FC<{}> = (): JSX.Element => {
  const { setNavType } = useContext(AppContext)
  useEffect(() => {
    setNavType("sticky")
    setTabTitle("TobennaWesArt")

    return () => {
      setNavType("bar")
    }
  }, [setNavType])

  return (
    <div className={styles.root}>
      <div
        className={styles.SectionVideoContainer}
        onClick={() => {
          window.scrollTo({ top: window.innerHeight, behavior: "smooth" })
        }}
      >
        <div className={styles.SectionVideoContent}>
          <div className={styles.HeaderSection}>
            <div className={styles.HeaderSectionLogo} />
          </div>
        </div>
      </div>
      <PosterSection categoryPageID={pageID} />
    </div>
  )
}
export default Main

interface PosterSectionProps {
  categoryPageID: string
}
export const PosterSection: React.FC<PosterSectionProps> = ({ categoryPageID }): JSX.Element => {
  const [shopItems, setShopItems] = useState<Product[]>([])

  const [lastItem, setLastItem] = useState<DocumentSnapshot<DocumentData>>()
  const [lastItemCache, setLastItemCache] = useState<DocumentSnapshot<DocumentData>>()
  const [currentCategory, setCurrentCategory] = useState<Category>()
  const [lastPage, setLastPage] = useState(false)
  const lastPageQueryString = "lastPage"
  const [params, setParams] = useSearchParams()
  const lasPageSearch = params.get(lastPageQueryString)

  useEffect(() => {
    if (currentCategory === undefined) return

    const productsCol = collection(firestoreDB, "shopProducts")
    const limitNumber = 10
    const defaultQuery = [where("category", "==", currentCategory.uid), where("isActive", "==", true), limit(limitNumber)]
    let postersQuery = query(productsCol, ...defaultQuery)

    if (lasPageSearch !== null && lastItem?.id !== lasPageSearch) {
      getDoc(doc(productsCol, lasPageSearch))
        .then((doc) => {
          setLastItem(doc)
        })
        .catch((err) => {
          setParams("")
        })

      return
    }

    if (lasPageSearch === null) {
      setLastItem(undefined)
    }

    if (lastItem !== undefined) {
      postersQuery = query(productsCol, ...defaultQuery, startAfter(lastItem))
    }

    const videosDone = onSnapshot(postersQuery, (response) => {
      const products = response.docs.map((doc) => {
        return doc.data() as Product
      })

      setLastPage(limitNumber > products.length)
      setLastItemCache(response.docs[response.docs.length - 1])
      setShopItems(products)
    })

    return () => {
      videosDone()
    }
  }, [currentCategory, lastItem])

  useEffect(() => {
    const catRef = collection(firestoreDB, "shopCategories")
    const catQuery = query(catRef, where("pageID", "==", categoryPageID))

    getDocs(catQuery)
      .then((snap) => {
        const category = snap.docs.map((doc) => {
          return doc.data() as Category
        })

        return category[0]
      })
      .then((doc) => {
        if (doc === undefined) return
        setCurrentCategory(doc)
      })
  }, [firestoreDB])

  return (
    <>
      <div className={styles.PosterSection}>
        {shopItems.map((val) => {
          return <Poster product={val} key={val.uid} />
        })}
      </div>
      {shopItems.length === 0 && (
        <div className={styles.PosterSectionMessage}>
          No more posters. <br /> Click "View again" to start from top{" "}
        </div>
      )}
      <div
        className={styles.PaginationButton}
        onClick={() => {
          if (lastPage) {
            setLastItem(undefined)
            setParams("")
          } else {
            window.scrollTo({ top: window.innerHeight * 0.2, left: 0, behavior: "smooth" })
            setLastItem(lastItemCache)
            setParams(lastPageQueryString + "=" + lastItemCache?.id)
          }
        }}
      >
        {!lastPage ? "Show More" : lastItem === undefined ? "1" : "View again"}
      </div>
    </>
  )
}

interface PosterProps {
  product: Product
}

export const Poster: React.FC<PosterProps> = ({ product }): JSX.Element | null => {
  const { user } = useContext(AppContext)
  const loc = useLocation()
  const nav = useNavigate()

  const productVariantList = product.productVariantList

  productVariantList.sort((a, b) => {
    return a.price > b.price ? 1 : -1
  })

  const selectedVariant = productVariantList[0]

  if (selectedVariant === undefined) {
    return null
  }
  const productType = selectedVariant?.sessionType === "none" ? "poster" : selectedVariant?.sessionType ?? "poster"

  let ratio = SizeToDimensions(selectedVariant.size) ?? 0.71

  return (
    <div className={styles.PosterContainer}>
      <div className={styles.PosterContainerTop}>
        <div className={styles.PosterContainerPreview}>
          <Revivart2D
            imageURL={product.imageLDURL}
            videoURL={product.videoURL}
            ratio={ratio}
            type={productType}
            onClick={() => {
              const searchParam = new URLSearchParams()
              searchParam.set(returnPageKey, loc.pathname + loc.search)
              nav(`/shop/product/${product.uid}?` + searchParam.toString())
            }}
            imageOnly={product.typeId === 1}
            orientation={selectedVariant.orientation}
          />
        </div>
      </div>
      <div className={styles.PosterContainerBottom}>
        <div className={styles.PosterContainerInfoSize}>
          <span> $ {selectedVariant.price / 100}</span>
          {product.typeId === 2 && <div className={styles.RevivarBlackIcon}> </div>}
        </div>
        <div
          className={styles.PosterContainerAddToCart}
          onClick={(e) => {
            e.stopPropagation()
            ValidateUserToAnon(user)
              .then((actualUser) => {
                return AddToCart(actualUser.uid, product, selectedVariant)
              })
              .then(() => {
                nav("/cart")
              })
              .catch((err) => {
                console.error(err)
              })
          }}
        >
          Add to Cart
        </div>
      </div>
    </div>
  )
}
