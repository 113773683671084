import { collection, doc, setDoc } from "firebase/firestore"
import { ProductVariant } from "schema/dist/src/product"
import { uploadFile } from "../../admin/product/create"
import { firebaseApp, firestoreDB } from "../../App"

import { StudioSessionAssets, StudioSession } from "schema/dist/src/studio"
import { UploadTaskSnapshot } from "firebase/storage"

export function UploadToAssetLocation(
  asset: StudioSessionAssets,
  name: string,
  studio: StudioSession,
  file: File | Blob,
  fileType: keyof StudioSessionAssets,
  onUpdate: (a: number) => void,
  onComplete?: (a: string, ref: UploadTaskSnapshot) => void
) {
  return uploadFile(asset.posterUID + name, `/studioSession/${studio.uid}/`, file, onUpdate, onComplete ?? ((a, ref) => {})).then(
    (ref) => {
      asset[fileType] = ref.dest
      return UpdateStudio(studio).then(() => {
        return ref.dest
      })
    }
  )
}

export function UpdateStudio(studio: StudioSession) {
  const sessionCollRef = collection(firestoreDB, "studioSessions")
  const sessionRef = doc(sessionCollRef, studio.uid)

  return setDoc(sessionRef, studio).then(() => {
    return studio
  })
}

export function CloseStudioSession(studio: StudioSession) {
  const sessionCollRef = collection(firestoreDB, "studioSessions")
  const sessionRef = doc(sessionCollRef, studio.uid)

  return setDoc(sessionRef, { editable: false }, { merge: true })
}

export class PosterData {
  videoURL: string
  imageURL: string
  insideBackImageURL?: string
  uid: string
  rerender?: boolean
  constructor(videoURL: string, imageURL: string, uid: string) {
    this.videoURL = videoURL
    this.imageURL = imageURL
    this.uid = uid
    this.rerender = false
    this.insideBackImageURL = ""
  }
}
