import React, { useContext, useState } from "react"
import styles from "./styles/home.module.css"
import axios from "axios"
import { Buffer } from "buffer"
import { GetServerUrl } from "./createRevivart"
import { AppContext } from "../App"

export const Main: React.FC<{}> = (): JSX.Element => {
  const endPoint = "/api/api/generate/qr"
  const generatorURL = GetServerUrl(endPoint)
  const [imageData, setImageData] = useState("")
  const [downloadURL, setDownloadURL] = useState("")
  const { userToken } = useContext(AppContext)
  const [loading, setLoading] = useState(false)

  const getQRCODE = () => {
    const endPoint = new URL(generatorURL)
    setLoading(true)
    return axios
      .get(endPoint.toString(), {
        responseType: "arraybuffer",
        headers: { Authorization: "Bearer " + userToken },
      })
      .then((res) => {
        let base64ImageString = Buffer.from(res.data, "binary").toString("base64")
        let srcValue = "data:image/png;base64," + base64ImageString
        setImageData(srcValue)
        var objectUrl = URL.createObjectURL(new Blob([res.data]))
        setDownloadURL(objectUrl)
      })
      .catch((error) => {
        console.error(error)
      })
  }
  return (
    <div className={styles.root}>
      <div className={styles.GenerateUsRoot}> Generate QR-Code</div>
      <div className={styles.GenerateUsMain}>
        <div className={styles.GenerateUsMainSales}> </div>
        <span>
          <h1>Why do I need a QR?</h1>
          Some Revivart that need to me mass printed such as wedding invites and posters polaroids are a good solution. You can
          print this qr into the asset and link it to your revivart. This saves a money and is good of the environment
          {imageData === "" ? (
            <h2
              onClick={() => {
                if (loading) {
                  return
                }
                getQRCODE()
                  .then(() => {
                    window.scrollTo({ top: window.innerHeight, behavior: "smooth" })
                  })
                  .finally(() => {
                    setLoading(false)
                  })
              }}
            >
              {loading ? "Loading" : "Generate QR"}
            </h2>
          ) : null}
        </span>
      </div>

      {imageData !== "" ? (
        <>
          <h2>Your Beautiful QR-Code is Here</h2>
          <img className={styles.QRCodeSection} src={imageData} alt={"QR"}></img>
          <a className={styles.SelectButton} href={downloadURL} download={"Image.png"}>
            Download
          </a>
        </>
      ) : (
        ""
      )}
    </div>
  )
}
export default Main
