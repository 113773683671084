import styles from "./create.module.css"
import { Routes, Route } from "react-router-dom"
import PosterPage from "./poster/"
import PostCardPage from "./postcard/"
import GreetingPage from "./greeting/"
import EventsPage from "./events/"
import PolaroidPage from "./polaroid/"
import { useContext, useEffect } from "react"
import { AppContext } from "../App"

export const Main: React.FC<{}> = (): JSX.Element => {
  const { setNavType } = useContext(AppContext)
  useEffect(() => {
    setNavType("sleek")
    return () => {
      setNavType("bar")
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={styles.root}>
      <Routes>
        <Route path="/polaroid" element={<PolaroidPage />} />
        <Route path="/polaroid/:sessionID" element={<PolaroidPage />} />
        <Route path="/poster" element={<PosterPage />} />
        <Route path="/poster/:sessionID" element={<PosterPage />} />
        <Route path="/postcard" element={<PostCardPage />} />
        <Route path="/postcard/:sessionID" element={<PostCardPage />} />
        <Route path="/greeting" element={<GreetingPage />} />
        <Route path="/greeting/" element={<GreetingPage />} />
        <Route path="/greeting/:sessionID" element={<GreetingPage />} />
        <Route path="/event/:sessionID" element={<EventsPage />} />
      </Routes>
    </div>
  )
}
export default Main
