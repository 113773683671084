import styles from "./index.module.css"

interface AdminActionsProps {
  //   children?: React.FC<AdminActionsButtonProps>
}

export const AdminActions: React.FC<AdminActionsProps> = ({ children }): JSX.Element => {
  return (
    <div className={styles.UpdateOrderDetails}>
      <div className={styles.UpdateOrderDetailsList}>{children}</div>
    </div>
  )
}

interface AdminActionsButtonProps {
  onClick?: () => void
  title: string
}
export const AdminActionsButton: React.FC<AdminActionsButtonProps> = ({ onClick = () => {}, title, children }): JSX.Element => {
  return (
    <div className={styles.UpdateOrderStatusButton} onClick={onClick}>
      {title}
      {children}
    </div>
  )
}
