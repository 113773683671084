import styles from "./index.module.css"
import { useContext, useEffect, useState } from "react"
import { AppContext, firestoreDB } from "../../App"
import headerImage1 from "./assets/IMG_38852.png"
import headerImage2 from "./assets/IMG_38854.png"
import headerImage3 from "./assets/IMG_38853.png"
import plannerImage1 from "./assets/image43.png"
import plannerImage2 from "./assets/image44.png"
import plannerImage4 from "./assets/image46.png"
import plannerImage5 from "./assets/image47.png"
import plannerImage6 from "./assets/image48.png"
import plannerImage7 from "./assets/image49.png"
import { Product } from "schema/dist/src/product"
import { collection, getDocs, query, where } from "firebase/firestore"
import { useLocation, useNavigate } from "react-router-dom"
import { returnPageKey } from "../../auth"

export const Main: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.root}>
      <Header />
      <FrontPageCarousels />
      <RevivarPlanner />
      <PlannerMessage
        title="We changed the planner experience."
        message="A planner experience made to elevate your life while doubling as a yearbook for your favorite memories"
        images={[plannerImage1, plannerImage2]}
      />
      <PlannerMessage
        title="Save memories with the planner"
        message="You also have the ability to fill each month with live photos of the memories and people you hold most dear. With the planner suggesting ideas of cute memories to store"
        images={[plannerImage4, plannerImage5]}
      />
      <PlannerMessage
        title="Learn with each Month"
        message="This comprehensive planner includes monthly, budgeting tips, weekly planner, pages money, challenges for every month and memories page, a habit and budget tracker, and so much more"
        images={[plannerImage6, plannerImage7]}
      />
      <PlannerMessage
        title="Strategically designed to make you love planning."
        message="While the main goal of other planners are mainly to improve productivity, the Awa X REVIVAR Planner is specifically designed to improve your quality of life while giving you the tools to build a solid foundation to  financial freedom. Since the planner was made in collaboration with Revivar, you have the ability to fill each month with live photos of the memories and people you hold most dear"
      />
      <AboutDesignerMessage />
    </div>
  )
}

export default Main

export const Header: React.FC<{}> = (): JSX.Element => {
  const { setNavType } = useContext(AppContext)
  useEffect(() => {
    setNavType("sleek")
    return () => {
      setNavType("bar")
    }
  }, [])

  return (
    <div className={styles.Header}>
      <div className={styles.HeaderTitleSmall}>The</div>
      <div className={styles.HeaderTitle}>2023-2024</div>
      <div className={styles.HeaderTitle}>Interactive Planner</div>
      <ShopButton />
    </div>
  )
}

export const FrontPageCarousels: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.FrontPageCarousels}>
      <div className={styles.FrontPageCarouselsImage} style={{ backgroundImage: "url(" + headerImage1 + ")" }} />
      <div className={styles.FrontPageCarouselsImage} style={{ backgroundImage: "url(" + headerImage2 + ")" }} />
      <div className={styles.FrontPageCarouselsImage} style={{ backgroundImage: "url(" + headerImage3 + ")" }} />
    </div>
  )
}

export const RevivarPlanner: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.RevivarPlanner}>
      <div className={styles.RevivarPlannerIcon} />
    </div>
  )
}

interface PlannerMessageProps {
  title: string
  message: string
  images?: string[]
}

export const PlannerMessage: React.FC<PlannerMessageProps> = ({ title, message, images }): JSX.Element => {
  return (
    <div className={styles.PlannerMessage}>
      <div className={styles.PlannerMessageTitle}>{title}</div>
      <div className={styles.PlannerMessageSubTitle}>{message}</div>
      <div className={styles.PlannerMessageImageStack}>
        {images?.map((image) => {
          return <div className={styles.PlannerMessageImage} key={image} style={{ backgroundImage: "url(" + image + ")" }} />
        })}
      </div>
    </div>
  )
}

export const AboutDesignerMessage: React.FC<{}> = ({}): JSX.Element => {
  return (
    <div className={styles.AboutDesignerMessage}>
      <div className={styles.AboutDesignerMessageImage} />
      <div className={styles.AboutDesignerMessageProfile}>
        <h1>About Awa</h1>
        <span>
          Awa Sanneh is a 22-year-old college student and influencer. Over the last two years I will has a mass to follow him up
          over 480,000 on all social media platforms. She's been featured in articles such as Elle Canada, Teen Vogue and
          HerCampus for her advocacy of black rights, financial independence and providing the youth tips on leaving abusive
          household's. This planner was made with people like Awa in mind, people who are more than deserving to have financial
          tools, support and resources to successfully transition to adult life.
        </span>
        <ShopButton />
      </div>
    </div>
  )
}

export const ShopButton: React.FC<{}> = ({}): JSX.Element => {
  const [product, setProduct] = useState<Product>()
  const nav = useNavigate()
  const loc = useLocation()

  useEffect(() => {
    const posterVariantsRef = collection(firestoreDB, "shopProducts")
    const qu = query(posterVariantsRef, where("tag", "==", "awa_planner-1"))
    getDocs(qu)
      .then((responses) => {
        const products: Product[] = []
        responses.docs.forEach((doc) => {
          const variant = doc.data() as Product
          products.push(variant)
        })
        const selectedProd = products[0]

        setProduct(selectedProd)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  return (
    <div
      className={styles.HeaderButton}
      onClick={() => {
        nav("/shop/product/" + product?.uid + `?${returnPageKey}=` + loc.pathname)
      }}
    >
      Shop
    </div>
  )
}
