import styles from "./index.module.css"
import { Routes, Route } from "react-router-dom"
import Overview from "./overview"
import Shop from "./shop"
import Discount from "./discount"

import Product from "./product"
import Category from "./category"
import Defaults from "./defaults"
import Event from "./event"
import Shipping from "./shipping"
import Vendors from "./vendors"

import NotFound from "../landing/404"
import { AppContext, firebaseApp, firestoreDB } from "../App"
import { useContext, useEffect, useState } from "react"
import { collection, doc, getDoc, getFirestore } from "firebase/firestore"
import Applications from "./applications"

export const Main: React.FC<{}> = (): JSX.Element => {
  const { user } = useContext(AppContext)

  const [allowedAccess, setAllowedAccess] = useState(false)
  useEffect(() => {
    if (user === null) {
      return
    }

    const userDataRef = doc(collection(firestoreDB, "appData"), "adminData")
    getDoc(userDataRef)
      .then((snap) => {
        const users = snap.data()?.allowed as string[]
        users.forEach((userEmail) => {
          if (userEmail === user.email) {
            setAllowedAccess(true)
          }
        })
      })
      .catch((error) => {
        console.log("Error getting document:", error)
      })
  }, [user, firestoreDB])

  if (user === null || !allowedAccess) {
    return <div className={styles.root}>NO ACCESS LOL WHAT ARE YOU DOING HERE</div>
  }

  return (
    <div className={styles.root}>
      <Routes>
        <Route path="/" element={<Overview />} />
        <Route path="/orders/*" element={<Shop />} />
        <Route path="/product/*" element={<Product />} />
        <Route path="/category/*" element={<Category />} />
        <Route path="/defaults/*" element={<Defaults />} />
        <Route path="/event/*" element={<Event />} />
        <Route path="/discount/*" element={<Discount />} />
        <Route path="/shipping/*" element={<Shipping />} />
        <Route path="/vendors/*" element={<Vendors />} />
        <Route path="/applications/*" element={<Applications />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </div>
  )
}
export default Main
