import styles from "./index.module.css"
import { Routes, Route } from "react-router-dom"
import Overview from "./overview"
import NotFound from "../landing/404"
import React from "react"
import { EventOverview } from "../events/overview"
import { EventAssetEdit, EventOverviewEdit } from "../events/edit"

/**
 * Account page handles all user information and order details
 * @returns
 */
export const Main: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.root}>
      <Routes>
        <Route path="/" element={<Overview />}>
          <Route path="/orders" element={<Overview />} />
          <Route path="/order/:orderID" element={<Overview />} />
          <Route path="/gallery" element={<Overview />} />
          <Route path="/events/*" element={<Overview />} />
        </Route>
        <Route path="/events/:eventID" element={<EventOverview />} />
        <Route path="/events/:eventID/edit" element={<EventOverviewEdit />} />
        <Route path="/events/:eventID/asset/:assetID/edit" element={<EventAssetEdit />} />
        <Route path="/*" element={<NotFound />}></Route>
      </Routes>
    </div>
  )
}
export default Main
