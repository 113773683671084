import React, { useContext, useEffect, useRef, useState } from "react"
import styles from "./index.module.css"
import { getDoc, getDocs, query, where } from "firebase/firestore"
import { doc, collection } from "firebase/firestore"
import { AppContext, firestoreDB } from "../../App"
import { Product, ProductVariant } from "schema/dist/src/product"
import { useNavigate, useParams } from "react-router-dom"
import ImagePlaceHolder from "../../assets/mona.jpeg"
import {
  AddSessionToCart,
  CreateExternalVideo,
  CreateStudioSession,
  CreationSteps,
  FrequentList,
  IncludedList,
  IntroBody,
  IntroVideo,
  ValidateAssetSize,
  VariantContainer,
  VariantOrientation,
} from "../shared/shared"
import { PosterData, UpdateStudio, UploadToAssetLocation } from "../shared/schema"
import { Orientation, SessionType, StudioSession, StudioSessionAssets } from "schema/dist/src/studio"
import ClearBack from "../../assets/CLEARPOSTCARDBACK.png"
import { uuidv4 } from "@firebase/util"
import { CreationControllerStart } from "../polaroid/helper"
import { ImageDPI, MIN_IMAGE_DPI, MIN_IMAGE_VARIANCE, UploadMessage, UploadPicture, UploadVideo } from "../polaroid/helper"

import { Poster3D } from "./poster3D"
import { PlayPosterContainer, SizeToDimensions } from "../../shop/product"
import { ValidateUserToAnon } from "../../auth"

import MonaVideo from "../../assets/video/mona_2.mp4"
import { PosterViewState } from "../../shop/product/frame"

const studioID = "poster"

/**
 * addProductIDToSession create a semi-new session that adds the product variant or orientation
 * we remove both video URL and image URL because using this function we are resetting the session Data
 * @param session - the session we are modifying
 * @param selectedVariant
 * @param orientation
 * @returns
 */
export function addProductIDToSession(
  session: StudioSession,
  selectedVariant: ProductVariant | undefined,
  orientation: Orientation = "vertical"
) {
  const sessionData: StudioSessionAssets[] = []

  let count = selectedVariant?.count ?? 0
  if (count === 0) {
    count = 1
  }

  for (let index = 0; index < count; index++) {
    const posterRef = doc(collection(firestoreDB, "userPosters"))
    sessionData.push({
      videoURL: "",
      imageURL: "",
      posterUID: posterRef.id,
    })
  }

  session.sessionData = sessionData

  if (selectedVariant !== undefined) {
    session.productVariant = selectedVariant
  }

  if (orientation !== undefined) {
    session.productVariant.orientation = orientation
  }

  return { ...session }
}

/**
 * useSession is a hook that return the session and allows callers to override it
 * @param sessionID
 * @returns
 */
export const useSession = (
  sessionID: string | undefined,
  dependency: (string | number)[] = []
): [StudioSession | undefined, (a: StudioSession | undefined) => void] => {
  const [session, setSession] = useState<StudioSession>()

  useEffect(() => {
    if (sessionID !== undefined) {
      const sessionCollRef = collection(firestoreDB, "studioSessions")
      let sessionRef = doc(sessionCollRef, sessionID)
      getDoc(sessionRef)
        .then((doc) => {
          if (!doc.exists()) {
            return
          }
          const newSession = doc.data() as StudioSession
          setSession(newSession)
        })
        .catch((err) => {})
    } else {
      setSession(undefined)
    }
  }, [sessionID, ...dependency])

  return [session, setSession]
}

/**
 * useAnchorScroll is a hook that helps us scroll to a particular position in the view
 * @param dependency
 * @returns
 */
export const useAnchorScroll = (dependency?: string) => {
  const anchorRef = useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    setTimeout(() => {
      if (dependency === undefined) {
        return
      }
      // Scroll to visible part of page after user looks at it
      if (window.pageYOffset > window.screen.height) {
        return
      }

      anchorRef.current?.scrollIntoView({ behavior: "smooth", block: "start" })
    }, 300)
  }, [dependency])

  return anchorRef
}

export const useStudioProduct = (studioID: string): Product | undefined => {
  const [studioProduct, setStudioProduct] = useState<Product>()

  useEffect(() => {
    const posterVariantsRef = collection(firestoreDB, "shopProducts")
    const qu = query(posterVariantsRef, where("studioID", "==", studioID))

    // get all product that match this studio type ID
    getDocs(qu)
      .then((responses) => {
        const products: Product[] = []
        responses.docs.forEach((doc) => {
          const variant = doc.data() as Product
          products.push(variant)
        })
        const selectedProd = products[0]
        // sort variants by price
        selectedProd.productVariantList = selectedProd?.productVariantList?.sort((a, b) => {
          return a.price < b.price ? -1 : 1
        })

        setStudioProduct(selectedProd)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [studioID])

  return studioProduct
}

function isReadyForCart(session: StudioSession): boolean {
  let showAddToCart = true
  session.sessionData.forEach((sesh) => {
    if (sesh.videoURL === "" || sesh.imageURL === "" || sesh.posterUID === "") {
      showAddToCart = false
    }
  })

  return showAddToCart
}

export const Main: React.FC<{}> = (): JSX.Element => {
  const { user } = useContext(AppContext)
  const navigate = useNavigate()
  const { sessionID } = useParams<{ sessionID: string }>()
  const anchorRef = useAnchorScroll(sessionID)
  const [session, setSession] = useSession(sessionID)
  const studioProduct = useStudioProduct(studioID)

  return (
    <div className={styles.root}>
      <div className={styles.VideoTop}>
        <IntroVideo videoURL={MonaVideo} backdrop={ImagePlaceHolder}>
          <IntroBody
            title="Create your Revivar Poster"
            subTitle="Custom Live Poster Prints with an AR Video"
            session={session}
            onClick={() => {
              if (studioProduct?.productVariantList[0] === undefined) return
              CreateStudioSession(studioProduct?.productVariantList[0], user?.uid ?? "", studioID).then((session) => {
                setSession(session)
                navigate({
                  pathname: "/studio/" + studioID + "/" + session.uid,
                })
                anchorRef.current?.scrollIntoView({ behavior: "smooth", block: "start" })
              })
            }}
          />
        </IntroVideo>
      </div>
      <a ref={anchorRef}></a>

      <br />

      <PosterPreview
        session={session}
        setSession={setSession}
        product={studioProduct}
        sessionID={sessionID}
        studioID={studioID}
        posterType={"poster"}
        sessionPosition={0}
      >
        <VariantContainer product={studioProduct} setStudioSession={setSession} studioSession={session} />
        <VariantOrientation
          orientation={session?.productVariant.orientation}
          setOrientation={(a) => {
            if (session === undefined) return
            const newVariant = addProductIDToSession(session, undefined, a)
            setSession(newVariant)
          }}
        />
      </PosterPreview>
      <IncludedList />
      <br />
      <FrequentList />
    </div>
  )
}
export default Main

interface PosterPreviewProps {
  session: StudioSession | undefined
  setSession: (session: StudioSession) => void
  product: Product | undefined
  sessionID: string | undefined
  studioID: string | undefined
  posterType: SessionType
  sessionPosition: number
  setSessionPosition?: (session: number) => void
  children?: React.ReactNode
  grandChildren?: React.ReactNode
}

export const PosterPreview: React.FC<PosterPreviewProps> = ({
  session,
  product,
  sessionID,
  children,
  posterType,
  sessionPosition,
  setSessionPosition = () => {},
  grandChildren,
}): JSX.Element | null => {
  const [posterData, setPosterData] = useState<PosterData>()
  const [playVideo, setPlayVideo] = useState(true)
  const [viewState, setViewState] = useState<PosterViewState>("normal")
  const isEditable = session?.editable ?? true

  // when no longer editable we want the default state to be the image showing now the video
  const [showVideo, setShowVideo] = useState(true)
  const [previewTime, setPreviewTime] = useState<number>()
  const [creationStep, setCreationStep] = useState(-1)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadingText, setUploadingText] = useState("")
  const [calculatedDPI, setCalculatedDPI] = useState(0)
  const [calculatedContrast, setCalculatedContrast] = useState<number>(0)
  const nav = useNavigate()
  const { user } = useContext(AppContext)
  const [muted, setMuted] = useState(true)

  useEffect(() => {
    if (session === undefined) return

    const data: PosterData = {
      videoURL: session?.sessionData[sessionPosition]?.videoURL ?? "",
      imageURL: session?.sessionData[sessionPosition]?.imageURL ?? "",
      uid: session?.sessionData[sessionPosition]?.posterUID ?? "",
      insideBackImageURL: session?.sessionData[sessionPosition]?.insideBackImageURL ?? "",
    }

    setPosterData(data)
    setPlayVideo(false)
  }, [session, sessionPosition])

  useEffect(() => {
    // when another polaroid is selected, during edit we move it to step 1
    if (sessionPosition === 0) return
    setCreationStep(0)
  }, [sessionPosition])

  useEffect(() => {
    // revert to normal viewing mode when on step 2
    if (creationStep === 2) {
      setShowVideo(false)
    }
  }, [creationStep])

  useEffect(() => {
    // when video is not editable the default behavior, should be that we see the image
    if (!isEditable) {
      setShowVideo(false)
    }
  }, [isEditable])

  if (session === undefined || posterData === undefined) return null

  let showAddToCart = isReadyForCart(session) && !isUploading

  // After a purchase poster has been added to cart it is no longer editable
  if (!isEditable) {
    return (
      <div className={styles.PosterPreviewEditable}>
        {grandChildren}
        <Poster3D
          poster={posterData}
          playVideo={playVideo}
          previewTime={previewTime}
          showVideo={showVideo}
          ratio={SizeToDimensions(session.productVariant.size)}
          viewState={viewState}
          setPlay={setPlayVideo}
          type={posterType}
          muted={muted}
          orientation={session.productVariant.orientation}
        >
          Click to play
          <PlayPosterContainer
            isDarkMode
            isHidden={false}
            onPlay={setPlayVideo}
            onViewState={setViewState}
            viewState={viewState}
            play={playVideo}
            muted={muted}
            setMuted={setMuted}
          />
          <br />
          <div className={styles.ControlsStacker}>
            <CreationControllerStart
              onClick={() => {
                nav("/cart")
              }}
              isHidden={!showAddToCart}
              text={"Back to Cart"}
            />
          </div>
        </Poster3D>
      </div>
    )
  }

  const orientation = session.productVariant.orientation ?? "vertical"
  const ratio = SizeToDimensions(session.productVariant.size) ?? 1
  const adjustedRatio = orientation === "vertical" ? ratio : 1 / ratio

  return (
    <>
      <div className={styles.PosterPreview}>
        <Poster3D
          poster={posterData}
          playVideo={playVideo}
          previewTime={previewTime}
          showVideo={showVideo}
          ratio={SizeToDimensions(session.productVariant.size)}
          viewState={viewState}
          setPlay={setPlayVideo}
          type={posterType}
          muted={muted}
          orientation={orientation}
        >
          {creationStep === 0 ? null : (
            <>
              Hover to play
              <PlayPosterContainer
                isDarkMode
                isHidden={false}
                onPlay={setPlayVideo}
                onViewState={setViewState}
                viewState={viewState}
                play={playVideo}
                muted={muted}
                setMuted={setMuted}
              />
            </>
          )}
        </Poster3D>

        <div className={styles.PosterPreviewAction}>
          <br />

          {creationStep === -1 ? <>{children}</> : <>{grandChildren}</>}
          {uploadingText}
          <div className={styles.ControlsStacker}>
            <CreationControllerStart
              onClick={() => {
                if (session === undefined) return
                UpdateStudio(session)
                setCreationStep(0)
              }}
              isHidden={creationStep !== -1}
              text={"Next"}
            />
            <UploadVideo
              onFileSelected={(a: File | Blob) => {
                const valid = ValidateAssetSize(a)
                if (!valid.valid) {
                  setUploadingText("Try again." + valid.reason)
                  return
                }

                setIsUploading(true)
                const sessionData = session.sessionData[sessionPosition]
                const fileName = sessionData.posterUID + "video"
                posterData.videoURL = "loading"

                setPosterData(posterData)
                UploadToAssetLocation(
                  sessionData,
                  fileName,
                  session,
                  a,
                  "videoURL",
                  (a) => {
                    setUploadingText(`Uploading ${a.toFixed(1)}%`)
                  },
                  () => {}
                )
                  .then((ref) => {
                    posterData.videoURL = ref
                    posterData.rerender = !posterData.rerender
                    setPosterData(posterData)
                    setCreationStep(1)
                    console.log("Done well")
                    setPlayVideo(true)
                  })

                  .catch(() => {
                    setUploadingText("Error uploading File")
                  })
                  .finally(() => {
                    setIsUploading(false)
                    setUploadingText("")
                  })
              }}
              isHidden={creationStep !== 0}
              showNext={(posterData?.videoURL ?? "") !== ""}
              showBack={true}
              onNexClicked={(e) => {
                if (e) {
                  setCreationStep(1)
                } else {
                  // when the in the first position we go back to the beginning
                  // which is select the dimension
                  if (sessionPosition === 0) {
                    setCreationStep(-1)
                    return
                  }

                  // when the in the next position and not the first we move one position back
                  setSessionPosition(sessionPosition - 1)
                }
              }}
              disabled={isUploading}
            />
            {creationStep === 0 && <CreateExternalVideo id={studioID} />}

            <UploadPicture
              videoUrl={posterData?.videoURL ?? ""}
              onFileSelected={(a: File | Blob, b: number, c: number, d: HTMLCanvasElement) => {
                setIsUploading(true)
                posterData.imageURL = "loading"
                setPosterData(posterData)
                UploadToAssetLocation(
                  session.sessionData[sessionPosition],
                  session.sessionData[sessionPosition].posterUID + "image" + uuidv4(),
                  session,
                  a,
                  "imageURL",
                  (a) => {
                    setUploadingText(`Uploading ${a.toFixed(1)}%`)
                  }
                )
                  .then((ref) => {
                    posterData.imageURL = ref
                    posterData.rerender = !posterData.rerender
                    setPosterData(posterData)
                    setShowVideo(false)
                    const printSize = session.productVariant.size.match(/[\d.]+(?="|”)/g)?.map(Number)
                    if (printSize === undefined) {
                      setUploadingText("Error uploading File")
                      return
                    }
                    const ppi = Math.round(Math.hypot(b, c) / Math.hypot(...printSize))
                    setCalculatedDPI(ppi)

                    const variance = calculateImageContrastActual(d)
                    setCalculatedContrast(variance)

                    let nextStep = 2

                    if (ppi <= 4 || variance <= MIN_IMAGE_VARIANCE) {
                      nextStep = 1.5
                    }

                    setCreationStep(nextStep)
                  })
                  .catch(() => {
                    setUploadingText("Error uploading File")
                  })
                  .finally(() => {
                    setIsUploading(false)
                    setUploadingText("")
                  })
              }}
              onSlide={(e) => {
                setShowVideo(true)
                setPlayVideo(false)
                setPreviewTime(e)
              }}
              isHidden={creationStep !== 1}
              showNext={(posterData?.imageURL ?? "") !== ""}
              showBack={true}
              onNexClicked={(e) => {
                setCreationStep(e ? 2 : 0)
              }}
              disabled={isUploading}
              fileRatio={adjustedRatio}
            />
            <ImageDPI
              isHidden={creationStep !== 1.5}
              calculatedDPI={calculatedDPI}
              calculatedContrast={calculatedContrast}
              showNext={
                (posterData?.imageURL ?? "") !== "" &&
                calculatedDPI > MIN_IMAGE_DPI / 2 &&
                calculatedContrast > MIN_IMAGE_VARIANCE
              }
              showBack={true}
              onNexClicked={(e) => {
                setCreationStep(e ? 2 : 1)
              }}
              disabled={isUploading}
            />
            <UploadMessage
              isHidden={creationStep !== 2}
              showNext={false}
              showBack={true}
              onNexClicked={(e) => {
                if (e) {
                  setViewState("normal")
                  setCreationStep(3)
                } else {
                  setCreationStep(1)
                }
              }}
              disabled={isUploading}
              initialMessage={session.sessionData[sessionPosition].message ?? ""}
              onTextSave={(a, text) => {
                session.sessionData[sessionPosition].message = text
                UpdateStudio(session)
                  .then(() => {
                    if (posterType !== "greeting") {
                      setUploadingText(`Done`)
                      return ""
                    }

                    setIsUploading(true)
                    posterData.insideBackImageURL = "loading"
                    setPosterData(posterData)
                    return UploadToAssetLocation(
                      session.sessionData[sessionPosition],
                      session.sessionData[sessionPosition].posterUID + "insideImageBack",
                      session,
                      a,
                      "insideBackImageURL",
                      (a) => {
                        setUploadingText(`Uploading ${a.toFixed(1)}%`)
                      }
                    )
                  })
                  .then((ref) => {
                    posterData.insideBackImageURL = ref
                    posterData.rerender = !posterData.rerender
                    setPosterData(posterData)
                    setShowVideo(false)
                    setCreationStep(2)
                  })
                  .catch(() => {
                    setUploadingText("Error uploading File")
                  })
                  .finally(() => {
                    setIsUploading(false)
                    setUploadingText("")
                  })
              }}
              onDeleteText={() => {
                session.sessionData[sessionPosition].message = ""
                session.sessionData[sessionPosition].insideBackImageURL = ""
                posterData.insideBackImageURL = ClearBack
                posterData.rerender = !posterData.rerender
                setPosterData(posterData)
                UpdateStudio(session)
                  .then(() => {
                    posterData.insideBackImageURL = ClearBack
                    posterData.rerender = !posterData.rerender
                    setPosterData(posterData)
                  })
                  .catch(() => {
                    setUploadingText("Removing Message")
                  })
              }}
              onChange={() => {
                setViewState("showMessage")
              }}
            />

            {creationStep !== 3 ? null : (
              <div className={styles.DoubleButtonStack}>
                <CreationControllerStart
                  onClick={() => {
                    setCreationStep(1)
                  }}
                  isHidden={creationStep !== 3}
                  text={"Back"}
                  isInverted
                />
                <CreationControllerStart
                  onClick={() => {
                    if (sessionPosition >= session.sessionData.length - 1) {
                      return
                    }

                    setSessionPosition(sessionPosition + 1)
                  }}
                  isHidden={sessionPosition >= session.sessionData.length - 1}
                  text={"Next"}
                  isInverted={false}
                />
              </div>
            )}
          </div>

          {showAddToCart ? (
            <div className={styles.ControlsStacker + " " + styles.AddToCartSpacer}>
              <CreationControllerStart
                onClick={() => {
                  if (product === undefined || sessionID === undefined) return

                  ValidateUserToAnon(user)
                    .then((actualUser) => {
                      return AddSessionToCart(actualUser.uid, session, studioID, sessionID, product)
                    })
                    .then(() => {
                      return nav("/cart")
                    })
                    .catch((error: any) => {
                      setUploadingText("Error occurred")
                    })
                }}
                isHidden={!showAddToCart}
                text={"Add to Cart"}
              />
            </div>
          ) : null}
          <CreationSteps steps={creationStep} />
        </div>
      </div>
    </>
  )
}

type CodeLocation = "Top Right" | "Top Left" | "Bottom Right" | "Bottom Left"
interface SelectCodeLocationProps {
  location: CodeLocation
  setLocation: (location: CodeLocation) => void
}
export const SelectCodeLocation: React.FC<SelectCodeLocationProps> = ({ location, setLocation }): JSX.Element => {
  const LocationList: CodeLocation[] = ["Top Right", "Top Left", "Bottom Right", "Bottom Left"]
  return (
    <div className={styles.SelectCodeLocation}>
      Select QR Location
      <div className={styles.SelectCodeLocationButtonList}>
        {LocationList.map((loc: CodeLocation) => {
          let className = styles.SelectCodeLocationButton
          if (location === loc) {
            className += " " + styles.SelectCodeLocationButtonSelected
          }
          return (
            <div
              className={className}
              onClick={() => {
                setLocation(loc)
              }}
            >
              {loc}
            </div>
          )
        })}
      </div>
    </div>
  )
}

function getLuminance(rgb: number[]): number {
  const r = rgb[0] / 255
  const g = rgb[1] / 255
  const b = rgb[2] / 255
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b
  return luminance
}

interface ImageAnalysisResult {
  contrastRatio: number
  resolution: number
  complexity: number
  isSuitable: boolean
}

// analyzeImageForARDetection -> overall image detection
export function analyzeImageForARDetection(image: HTMLCanvasElement): ImageAnalysisResult | undefined {
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")

  canvas.width = image.width
  canvas.height = image.height

  if (!context) {
    return
  }

  context.drawImage(image, 0, 0)

  const imageData = context.getImageData(0, 0, canvas.width, canvas.height)
  const data = imageData.data

  let foreground: number[] = [0, 0, 0]
  let background: number[] = [255, 255, 255]

  let resolution = image.width * image.height

  for (let i = 0; i < data.length; i += 4) {
    const r = data[i]
    const g = data[i + 1]
    const b = data[i + 2]
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b

    if (luminance > 127.5) {
      foreground = [r, g, b]
    } else {
      background = [r, g, b]
    }
  }

  let contrastRatio = (getLuminance(foreground) + 0.05) / (getLuminance(background) + 0.05)
  let complexity = calculateImageComplexity(data)

  const isSuitable = contrastRatio >= 2.5 && resolution >= 250000 && complexity >= 0.2

  return { contrastRatio, resolution, complexity, isSuitable }
}

function calculateImageComplexity(data: Uint8ClampedArray): number {
  const threshold = 0
  let count = 0
  const distance = 1000

  const size = data.length / distance

  for (let i = 0; i < size; i++) {
    const r = data[i * distance]
    const g = data[i * distance + 1]
    const b = data[i * distance + 2]

    if (Math.abs(r - g) > threshold || Math.abs(r - b) > threshold || Math.abs(g - b) > threshold) {
      count++
    }
  }

  return count / size
}

// calculateImageContrastActual -> perfect
export function calculateImageContrastActual(image: CanvasImageSource): number {
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")

  canvas.width = image.width as number
  canvas.height = image.height as number

  if (!context) {
    return 0
  }

  context.drawImage(image, 0, 0)

  const imageData = context.getImageData(0, 0, canvas.width, canvas.height)
  const data = imageData.data

  let sum = 0
  let sumSquared = 0

  for (let i = 0; i < data.length; i += 4) {
    const r = data[i]
    const g = data[i + 1]
    const b = data[i + 2]
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b

    sum += luminance
    sumSquared += Math.pow(luminance, 2)
  }

  const mean = sum / (data.length / 4)
  const variance = sumSquared / (data.length / 4) - Math.pow(mean, 2)

  return variance
}

// calculateImageContrastDetailed -> to complex and takes to long
export function calculateImageContrastDetailed(image: HTMLCanvasElement, patchSize: number = 5): number {
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")
  canvas.width = image.width
  canvas.height = image.height

  if (!context) {
    return 0
  }

  context.drawImage(image, 0, 0)
  const imageData = context.getImageData(0, 0, canvas.width, canvas.height)
  const data = imageData.data
  let contrastSum = 0
  let count = 0
  for (let i = 0; i < data.length; i += 4) {
    const r = data[i]
    const g = data[i + 1]
    const b = data[i + 2]
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b
    let patchSum = 0
    let patchCount = 0
    for (let x = -patchSize; x <= patchSize; x++) {
      for (let y = -patchSize; y <= patchSize; y++) {
        const index = i + (x + y * canvas.width) * 4
        if (index < 0 || index >= data.length) {
          continue
        }
        const r2 = data[index]
        const g2 = data[index + 1]
        const b2 = data[index + 2]
        const luminance2 = 0.2126 * r2 + 0.7152 * g2 + 0.0722 * b2
        patchSum += luminance2
        patchCount++
      }
    }
    const patchMean = patchSum / patchCount
    const contrast = (luminance - patchMean) / (luminance + patchMean)
    contrastSum += Math.abs(contrast)
    count++
  }
  const averageContrast = contrastSum / count
  return averageContrast
}
