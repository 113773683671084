import styles from "../product/index.module.css"
import { useEffect, useRef, useState } from "react"
import loadingImage from "./../../assets/loadingImage.png"
import { useNavigate } from "react-router-dom"
import { firebaseApp, firestoreDB } from "../../App"
import React from "react"
import { collection, doc, getDocs, Timestamp } from "firebase/firestore"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { Category, CreateCategory } from "schema/dist/src/category"
import { SetUpdateShopCategory } from "./create"

export const CategoryList: React.FC<{}> = (): JSX.Element => {
  const [categoryList, setCategoryList] = useState<Category[]>([])

  const nav = useNavigate()
  useEffect(() => {
    const docRef = collection(firestoreDB, "shopCategories")

    getDocs(docRef)
      .then((snap) => {
        const category = snap.docs.map((doc) => {
          return doc.data() as Category
        })

        setCategoryList([...category])
      })
      .catch((error) => {
        console.log("Error getting document:", error)
      })
  }, [])

  return (
    <>
      <div className={styles.UpdateOrderDetails}>
        <div className={styles.UpdateOrderDetailsList}>
          <div
            className={styles.UpdateOrderStatusButton}
            onClick={() => {
              CreateNewShopCategory()
                .then((product) => {
                  nav(`./${product}`)
                })
                .catch((error) => {})
            }}
          >
            CREATE CATEGORY
          </div>
        </div>
      </div>

      <div className={styles.ProductListContainer}>
        {categoryList.map((val) => {
          return <CategoryBlock category={val} key={val.uid} />
        })}
      </div>
    </>
  )
}

interface CategoryBlockProps {
  category: Category
}

export const CategoryBlock: React.FC<CategoryBlockProps> = ({ category }): JSX.Element => {
  const vidRef = useRef<HTMLVideoElement>(null)
  const [imageURL, setImageURL] = useState(loadingImage)

  const nav = useNavigate()

  useEffect(() => {
    if (category.imageURL === "") return
    const storage = getStorage()
    getDownloadURL(ref(storage, category.imageURL))
      .then((url) => {
        setImageURL(url)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [category])

  return (
    <div
      className={styles.AdminPreviewProductContainer}
      onClick={() => {
        nav(`./${category.uid}`)
      }}
      onMouseEnter={() => {
        vidRef.current?.play()
      }}
      onMouseLeave={() => {
        vidRef.current?.pause()
      }}
      style={{ backgroundImage: "url(" + imageURL + ")" }}
    >
      <b>{category.title}</b>
    </div>
  )
}

export function CreateNewShopCategory() {
  const ref = doc(collection(firestoreDB, "shopCategories"))

  const newCategory = CreateCategory(ref.id, Timestamp.now())

  return SetUpdateShopCategory(newCategory).then((result) => {
    return ref.id
  })
}
