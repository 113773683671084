import React, { useEffect, useState } from "react"
import styles from "./styles/home.module.css"
import GoogleMapReact from "google-map-react"
import { GoogleMapsAPIKey, firestoreDB } from "../App"
import {
  DocumentData,
  DocumentSnapshot,
  collection,
  getDocs,
  orderBy,
  startAt,
  endAt,
  query,
  QuerySnapshot,
  where,
} from "firebase/firestore"
import { Poster } from "schema/dist/src/poster"
import { ConfirmShippingMarker } from "../cart/shipping"
import * as geofire from "geofire-common"
import { useSearchParams } from "react-router-dom"

const defaultDistance = 2000000 //1242742.38 miles

export const Main: React.FC<{}> = (): JSX.Element => {
  const [userPos, setUserPos] = useState<LocationCoords>()
  const [posterPos, setPosterPos] = useState<LocationCoords[]>()
  const [params] = useSearchParams()

  // Get User's coords
  useEffect(() => {
    const radius = Number(params.get("distance")) || defaultDistance
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const userLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        setUserPos(userLocation)
        retrieveNearbyPosters(radius, userLocation).then((coords) => setPosterPos(coords))
      })
    } else {
      //TODO: code for legacy browsers
      //handle when user's location cannot be collected
    }
  }, [params])

  if (userPos === undefined) return <div className={styles.LoadingPage}></div>

  return (
    <div className={styles.root} style={{ height: window.innerHeight }}>
      <div className={styles.MapContainer} style={{ height: "100%" }}>
        <div className={styles.PreviewMapContainer}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: GoogleMapsAPIKey }}
            defaultCenter={userPos}
            defaultZoom={7}
            yesIWantToUseGoogleMapApiInternals
            options={(e) => {
              return {
                mapTypeControl: false,
                draggable: true,
                disableDoubleClickZoom: true,
                clickableIcons: false,
                fullscreenControl: false,
              }
            }}
          >
            {posterPos?.map((pos) => (
              <RevivarMarker key={Math.random()} lat={pos.lat} lng={pos.lng} />
            ))}
            <ConfirmShippingMarker {...userPos} />
          </GoogleMapReact>
        </div>
      </div>
    </div>
  )
}

export default Main

interface LocationCoords {
  lat: number
  lng: number
}

const RevivarMarker: React.FC<LocationCoords> = ({}) => {
  return <div className={styles.PostersMarker}></div>
}

//Retrieves all posters, irrespective of distance
export function retrieveAllPosters(): Promise<LocationCoords[] | undefined> {
  return new Promise<LocationCoords[] | undefined>((resolve, reject) => {
    const userPostersCollection = collection(firestoreDB, "userPosters")

    getDocs(userPostersCollection)
      .then((snap) => {
        const posterCoords = snap.docs.map((doc) => {
          const poster = doc.data() as Poster
          return { lat: poster.latitude, lng: poster.longitude }
        })
        resolve(posterCoords)
      })
      .catch((error) => {
        console.log("Error getting document:", error)
        resolve(undefined)
      })
  })
}

export function retrieveNearbyPosters(radiusInKM: number, centerCoord: LocationCoords): Promise<LocationCoords[] | undefined> {
  return new Promise<LocationCoords[] | undefined>((resolve, reject) => {
    const userPostersCollection = collection(firestoreDB, "userPosters")

    const center: geofire.Geopoint = [centerCoord.lat, centerCoord.lng]
    const radiusInMeters = radiusInKM * 1000
    const queryBounds = geofire.geohashQueryBounds(center, radiusInMeters)
    const promises: Promise<QuerySnapshot>[] = []

    queryBounds.forEach((bound) => {
      //Only query valid posters
      const q = query(
        userPostersCollection,
        orderBy("geoHash"),
        startAt(bound[0]),
        endAt(bound[1]),
        where("isValid", "==", true),
        where("isHidden", "==", false),
        where("isPublic", "==", true),
        where("isDisabled", "==", false)
      )
      promises.push(getDocs(q))
    })

    // Collect all the query results together into a single list
    Promise.all(promises)
      .then((snapshots) => {
        const matchingDocs: DocumentData[] = []

        snapshots.forEach((snap) => {
          const data = snap.docs as DocumentSnapshot[]
          data.forEach((doc) => {
            const poster = doc.data() as Poster

            const lat = poster.latitude
            const lng = poster.longitude

            // We have to filter out a few false positives due to GeoHash accuracy, but most will match
            const distanceInKm = geofire.distanceBetween([lat, lng], center)
            const distanceInM = distanceInKm * 1000
            if (distanceInM <= radiusInMeters) {
              matchingDocs.push(doc)
            }
          })
        })
        return matchingDocs
      })
      .then((matchingDocs) => {
        // Process the matching documents
        const posters = matchingDocs.map((doc) => {
          const poster = doc.data() as Poster
          return { lat: poster.latitude, lng: poster.longitude }
        })
        resolve(posters)
      })
      .catch((error) => {
        console.log("Error getting document:", error)
        resolve(undefined)
      })
  })
}
