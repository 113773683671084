import styles from "./index.module.css"
import { useEffect, useRef, useState, useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Event, EventAnalytics, EventMedia } from "schema/dist/src/event"
import loadingImage from "./../assets/loadingImage.png"
import { collection, doc, onSnapshot, query, where } from "firebase/firestore"
import { AppContext, firestoreDB } from "../App"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { EditRelatedImage } from "../admin/event/edit"
import { Product } from "schema/dist/src/product"
import { SizeToDimensions } from "../shop/product"
import { SessionType } from "schema/dist/src/studio"
import { EventMediaAssetItem, onVideoSelected } from "../admin/event/create"
import { CartItem } from "schema/dist/src/cart"

type RouteParams = {
  eventID: string
}

export const EventOverview: React.FC<{}> = (): JSX.Element => {
  //get the event id from the params of the page/route
  const { eventID } = useParams<RouteParams>()
  const nav = useNavigate()

  const [selectedEvent, setSelectedEvent] = useState<Event>()
  const [imageURL, setImageURL] = useState(loadingImage)
  const [eventMedias, setEventMedias] = useState<EventMedia[]>()
  const [eventAnalytics, setEventAnalytics] = useState<EventAnalytics[]>()
  const [eventOrders, setEventOrders] = useState<CartItem[]>()
  const [eventPrints, setEventPrints] = useState(0)
  const [eventRevenue, setEventRevenue] = useState(0)
  const [studioProduct, setStudioProduct] = useState<Product>()
  const [_, setIsUploading] = useState(false)
  const [uploadingText, setUploadingText] = useState("UPLOAD VIDEO")
  const [uploadCount, setUploadCount] = useState(1)
  const [uploadTotalCount, setUploadTotalCount] = useState(0)
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const { user } = useContext(AppContext)

  //add onSnapshot listener to update the event and eventmedias if there are changes
  useEffect(() => {
    if (eventID === undefined || user === null) return
    const eventDetailsRef = doc(firestoreDB, "events", eventID)
    const eventMediaCol = collection(eventDetailsRef, "medias")
    const eventAnalyticsCol = collection(eventDetailsRef, "analytics")
    const eventOrdersCol = collection(eventDetailsRef, "orders")

    //fetch the event
    const docRef = collection(firestoreDB, "events")
    const done = onSnapshot(query(docRef, where("uid", "==", eventID), where("creatorID", "==", user?.uid)), (response) => {
      const event = response.docs.map((doc) => {
        return doc.data() as Event
      })
      const eventSelected = event[0]

      if (eventSelected === undefined) {
        nav("/account/events")
      } else {
        const showEdit =
          eventSelected.title === "" ||
          eventSelected.selectedVariants === undefined ||
          eventSelected.selectedVariants.length === 0 ||
          eventSelected.defaultSelectedVariant === undefined ||
          eventSelected.defaultSelectedVariant === ""

        if (showEdit) {
          nav("edit")
        }
        setSelectedEvent(eventSelected)
      }
    })

    //fetch the medias related to the event
    const videosDone = onSnapshot(eventMediaCol, (response) => {
      const eventMedias = response.docs.map((doc) => {
        return doc.data() as EventMedia
      })
      setEventMedias(eventMedias)
    })

    //fetch the analytics related to the event
    const analyticsDone = onSnapshot(eventAnalyticsCol, (response) => {
      const eventAnalytics = response.docs.map((doc) => {
        return doc.data() as EventAnalytics
      })
      setEventAnalytics(eventAnalytics)
    })

    //fetch the orders related to the event
    const ordersDone = onSnapshot(eventOrdersCol, (response) => {
      const eventOrders = response.docs.map((doc) => {
        return doc.data() as CartItem
      })

      const eventPrints = response.docs
        .map((doc) => {
          const data = doc.data() as CartItem
          return data.count
        })
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

      const eventRevenue = response.docs
        .map((doc) => {
          const data = doc.data() as CartItem
          return (data.count * data.productVariant.price) / 100
        })
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

      setEventOrders(eventOrders)
      setEventPrints(eventPrints)
      setEventRevenue(eventRevenue)
    })

    return () => {
      done()
      videosDone()
      analyticsDone()
      ordersDone()
    }
  }, [eventID, user, nav])

  useEffect(() => {
    if (selectedEvent === undefined) return
    if ((selectedEvent.imageLDURL ?? "") === "") {
      setImageURL("")
    } else {
      const storage = getStorage()
      getDownloadURL(ref(storage, selectedEvent.imageLDURL))
        .then((url) => {
          setImageURL(url)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [selectedEvent])

  useEffect(() => {
    if (selectedEvent === undefined) return
    if (selectedEvent.productUID === "") return

    const productVariantsRef = doc(firestoreDB, "shopProducts", selectedEvent.productUID)

    const done = onSnapshot(productVariantsRef, (response) => {
      const product = response.data() as Product
      setStudioProduct(product)
    })

    return () => {
      done()
    }
  }, [selectedEvent])

  useEffect(() => {
    if (uploadTotalCount === 0) {
      setUploadingText("UPLOAD VIDEO")
    } else {
      setUploadingText(`Uploading ${uploadCount} / ${uploadTotalCount}`)
    }
  }, [uploadCount, uploadTotalCount])

  if (!selectedEvent) return <></>

  const defaultVariantID = selectedEvent.defaultSelectedVariant
  const defaultVariant = studioProduct?.productVariantList.find((variant) => {
    return variant.uid === defaultVariantID
  })

  const posterRatio = SizeToDimensions(defaultVariant?.size ?? "") ?? 0
  let posterType: SessionType = "poster"
  const orientation = defaultVariant?.orientation
  if (defaultVariant?.sessionType !== undefined && defaultVariant?.sessionType !== "none") {
    posterType = defaultVariant?.sessionType
  }

  return (
    <div className={styles.EventOverviewContainer}>
      <div className={styles.EditOverview}>
        <div className={styles.EditEventImageParent}>
          <div className={styles.OverviewEventImageContainer} style={{ backgroundImage: "url(" + imageURL + ")" }}></div>
          <button className={styles.EditEventButton} onClick={() => nav("edit")}>
            Edit
          </button>
        </div>
        <div className={styles.EditEventFieldsContainer}>
          <div className={styles.ReviewRelatedImageUser}>
            {selectedEvent.relatedImages.map((image) => (
              <EditRelatedImage
                image={image}
                event={selectedEvent}
                key={image.uid}
                showDelete={false}
                imageType="relatedImages"
              />
            ))}
          </div>
          <div className={styles.ReviewEventDetailsUser}>
            <h4>{selectedEvent.title}</h4>
            <p>{selectedEvent.description}</p>
          </div>
          <div className={styles.ReviewEventStatsUser}>
            <div>
              <h4>Recap Count: </h4>
              <p>{eventMedias?.length}</p>
            </div>
            <div>
              <h4>Views: </h4>
              <p>{eventAnalytics?.length}</p>
            </div>
            <div>
              <h4>Prints: </h4>
              <p>{eventPrints}</p>
            </div>
            <div>
              <h4>Orders: </h4>
              <p>{eventOrders?.length}</p>
            </div>
            <div>
              <h4>Revenue: </h4>
              <p>${eventRevenue.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.EventOverviewAssets}>
        <div className={styles.EventOverviewAssetsHeader}>
          <h3>Assets</h3>
          <div
            className={styles.EventUploadButton}
            onClick={() => {
              hiddenFileInput.current?.click()
            }}
          >
            {uploadingText}
            <input
              style={{ display: "none" }}
              ref={hiddenFileInput}
              type="file"
              accept=".mp4"
              multiple
              onChange={(e) => {
                if (selectedEvent === undefined || e.target.files === null) {
                  return
                }

                setUploadTotalCount(e.target.files.length)
                const uploadsPromiseList: Promise<string>[] = []
                for (var i = 0; i < e.target.files.length; i++) {
                  setIsUploading(true)
                  uploadsPromiseList.push(onVideoSelected(e.target.files[i], selectedEvent, uploadCount, setUploadCount))
                }

                Promise.all(uploadsPromiseList)
                  .then((values) => {})
                  .finally(() => {
                    setIsUploading(false)
                    setUploadCount(1)
                    setUploadTotalCount(0)
                  })
                e.target.files = null
                e.target.value = ""
              }}
            />
          </div>
        </div>
        <div className={styles.EventAssetListContainer}>
          {eventMedias?.map((val) => (
            <EventMediaAssetItem
              media={val}
              key={val.uid}
              type={posterType}
              posterRatio={posterRatio}
              orientation={orientation}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
