import { doc, onSnapshot } from "firebase/firestore"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import React, { useContext, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { Event, EventMedia, EventRelatedImage } from "schema/dist/src/event"
import { AppContext, firestoreDB, previewAppWebsite, useNavBar } from "../App"
import styles from "./index.module.css"
import { EventRouteParams, showPendingOption, viewPendingString } from "./event"
import { urlSessionPath } from "./asset"
import { ValidateAssetSize, ValidateStudioSession } from "../create/shared/shared"
import { Product, ProductVariant } from "schema/dist/src/product"
import { UpdateStudio } from "../create/shared/schema"
import { onPendingVideoSelected } from "../admin/event/create"
import { ValidateUserCheckout, ValidateUserToAnon, returnPageKey } from "../auth"
import { CreationControllerStart } from "../create/polaroid/helper"
import { GetServerUrl } from "../create/createRevivart"
import { StudioSession } from "schema/dist/src/studio"
import axios from "axios"
import { Poster } from "schema/dist/src/poster"
import eventPolaroidExample from "../assets/video/eventPolaroidExample.mp4"

export function useDownloadURL(link: string | undefined) {
  const [imageURL, setImageURL] = useState("")
  const storage = getStorage()

  useEffect(() => {
    if (link === undefined) return
    getDownloadURL(ref(storage, link))
      .then((url) => {
        setImageURL(url)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [link])

  return imageURL
}

export function useEvent(eventID: string | undefined) {
  const [event, setEvent] = useState<Event>()

  useEffect(() => {
    if (eventID === undefined) return
    const eventDetailsRef = doc(firestoreDB, "events", eventID)
    const done = onSnapshot(eventDetailsRef, (response) => {
      const event = response.data() as Event
      setEvent(event)
    })

    return () => {
      done()
    }
  }, [eventID])

  return event
}

export function useProductId(productID: string | undefined) {
  const [studioProduct, setStudioProduct] = useState<Product>()

  useEffect(() => {
    if (productID === undefined) return
    const productVariantsRef = doc(firestoreDB, "shopProducts", productID)

    const done = onSnapshot(productVariantsRef, (response) => {
      const event = response.data() as Product
      setStudioProduct(event)
    })

    return () => {
      done()
    }
  }, [productID])

  return studioProduct
}

export function useProductVariantUID(product: Product | undefined, variantUID: string | undefined) {
  return product?.productVariantList.find((v) => {
    return v.uid === variantUID
  })
}

export const StickerMain: React.FC<{}> = (): JSX.Element | null => {
  const _ = useNavBar("bar")
  const { eventID } = useParams<EventRouteParams>()
  const event = useEvent(eventID)
  const imageURL = useDownloadURL(event?.imageLDURL)
  const product = useProductId(event?.productUID)
  const variant = useProductVariantUID(product, event?.defaultSelectedVariant)
  const { user } = useContext(AppContext)
  const sessionType = variant?.sessionType
  const [isUploading, setIsUploading] = useState(false)
  const [uploadText, setUploadText] = useState("")
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const nav = useNavigate()
  const loc = useLocation()

  if (variant === undefined) return null
  if (sessionType === "none" || sessionType === undefined) return null

  return (
    <div className={styles.root}>
      <div className={styles.EventDetailUploadHeader} style={{ backgroundImage: "url(" + imageURL + ")" }}>
        <span className={styles.EventDetailHeaderTitle}>
          {event?.title} {"Recap"}
        </span>
      </div>

      <div className={styles.StickerMainExplained}>Upload the video that wil be use to create your Augmented Polaroid</div>

      {isUploading ? (
        <div className={styles.EventUploadVideoTitle}>
          {uploadText}
          <div className={styles.EventUploadVideoSubTitle}> Uploading</div>
        </div>
      ) : (
        <div
          className={styles.EventUploadVideoExplained}
          onClick={(e) => {
            hiddenFileInput.current?.click()
            e.stopPropagation()
          }}
        />
      )}

      <input
        style={{ display: "none" }}
        ref={hiddenFileInput}
        type="file"
        accept=".mp4,.mov"
        onChange={(e) => {
          ValidateUserToAnon(user).then((response) => {
            if (e.target.files !== null && event !== undefined) {
              const a = e.target.files[0]

              const valid = ValidateAssetSize(a)
              if (!valid.valid) {
                console.log("Try again." + valid.reason)
                return
              }

              setIsUploading(true)
              onPendingVideoSelected(e.target.files[0], event, response.uid, (a) => {
                setUploadText(a.toFixed(0) + "%")
              })
                .then((eventMediaUID) => {
                  setUploadText("Uploaded")
                  nav(
                    `../../events/${event.uid}/asset/${eventMediaUID}?` +
                      viewPendingString +
                      "=true&" +
                      showPendingOption +
                      "=true&" +
                      returnPageKey +
                      `=${loc.pathname}`
                  )
                })
                .catch((values) => {
                  setUploadText("Failed")
                })
                .finally(() => {
                  setTimeout(() => {
                    setIsUploading(false)
                    setUploadText("")
                  }, 2000)
                })
            }

            e.target.files = null
            e.target.value = ""
          })
        }}
      />

      <div className={styles.UploadVideoHow}>How it works</div>
      <div className={styles.UploadVideoSub}>After you upload your video, Revivar Augments your polaroid with that video. </div>

      <video
        width="100%"
        height="100%"
        controls={true}
        autoPlay={true}
        src={eventPolaroidExample}
        loop
        playsInline
        muted
        preload={"auto"}
        className={styles.UploadVideoVideo}
      />
      <br />

      <div className={styles.UploadVideoSub}>
        Once you upload the video, you will be able select a frame that you want to augment and this will be printed out for you
      </div>
      <br />
      <br />
      <br />
    </div>
  )
}

interface PendingOptions {
  session: StudioSession | undefined
  event: Event
  eventMedia: EventMedia
  setSelectedVariant: (a: ProductVariant) => void
}

export const PendingOptions: React.FC<PendingOptions> = ({
  session,
  event,
  eventMedia,
  setSelectedVariant,
}): JSX.Element | null => {
  const loc = useLocation()
  const nav = useNavigate()
  const [convertSession, setConvertSession] = useState(false)
  const product = useProductId(event?.productUID)
  const variant = useProductVariantUID(product, event?.defaultSelectedVariant)
  const sessionType = variant?.sessionType
  const [_, setParams] = useSearchParams()
  const { user, userToken } = useContext(AppContext)
  const endPoint = "/api/api/generate/studio/poster"
  const apiURL = GetServerUrl(endPoint)
  const apiLink = new URL(apiURL)
  apiLink.searchParams.append("sessionID", session?.uid ?? "")
  apiLink.searchParams.append("customerID", user?.uid ?? "")
  const [searchPath, setSearchPath] = useSearchParams()
  let defaultImage: EventRelatedImage | undefined = undefined
  const defaultPrints = event.defaultPrints ?? []
  const [isLoading, setIsLoading] = useState(false)

  if (defaultPrints.length > 0) {
    defaultImage = defaultPrints[0]
  }

  useEffect(() => {
    if (variant === undefined) return
    setSelectedVariant(variant)
  }, [variant])

  if (variant === undefined) return null
  if (sessionType === "none" || sessionType === undefined) return null

  function createPoster() {
    setIsLoading(true)
    axios
      .get(apiLink.toString(), {
        headers: { Authorization: "Bearer " + userToken },
      })
      .then((res) => {
        const posters = res.data as Poster[]
        window.open(previewAppWebsite + "/poster/" + posters[0].uid)
        nav("/events/" + event.uid + "?pending=true")
      })
      .catch((res) => {
        console.log(`${res} -> ${res?.response?.data}`)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  if (convertSession) {
    return (
      <div className={styles.PendingOptions}>
        <CreationControllerStart
          onClick={createPoster}
          isHidden={false}
          text={isLoading ? "Loading..." : "View in AR"}
          disabled={false}
        />
        <CreationControllerStart
          onClick={() => {
            setConvertSession(false)
          }}
          isHidden={false}
          text={"Back"}
          disabled={false}
          isInverted
        />
      </div>
    )
  }

  return (
    <div className={styles.PendingOptions}>
      <CreationControllerStart
        onClick={() => {
          const searchParam = new URLSearchParams(loc.search)
          searchParam.delete(showPendingOption)
          setParams(searchParam)
        }}
        isHidden={false}
        text={"Order a Print"}
        disabled={false}
      />
      <CreationControllerStart
        onClick={() => {
          if (ValidateUserCheckout(user, nav, loc)) {
            ValidateStudioSession(session, variant, user?.uid ?? "", sessionType)
              .then(({ session }) => {
                if (defaultImage === undefined) {
                  return undefined
                }

                session.sessionData[0].imageURL = defaultImage.imageLDURL
                session.sessionData[0].videoURL = eventMedia.videoURL
                session.productVariant = variant

                return UpdateStudio(session)
              })
              .then((session) => {
                if (session === undefined) return
                searchPath.set(urlSessionPath, session?.uid ?? "")
                setSearchPath(searchPath)
                setConvertSession(true)
              })
          }
        }}
        isHidden={false}
        text={"I already bought a print"}
        disabled={false}
      />
      <CreationControllerStart
        onClick={() => {
          nav("/events/" + event.uid + "?pending=true")
        }}
        isHidden={false}
        text={"Skip"}
        disabled={false}
        isInverted
      />
    </div>
  )
}
