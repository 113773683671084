import React, { useEffect, useRef, useState } from "react"
import styles from "./index.module.css"
import { getStorage, ref, getBlob } from "firebase/storage"

interface CreationControllerProps {
  onClick: () => void
  isHidden: boolean
  isInverted?: boolean
  text: string
  className?: string
  disabled?: boolean
}
export const CreationControllerStart: React.FC<CreationControllerProps> = ({
  onClick,
  isHidden,
  isInverted = false,
  text,
  className,
  disabled,
}): JSX.Element => {
  let classNameIn = styles.polaroidButton

  if (isInverted) {
    classNameIn += " " + styles.polaroidButtonMemo
  }

  if (disabled) {
    classNameIn += " " + styles.polaroidButtonDisabled
  }

  if (className !== undefined) {
    classNameIn = className
  }

  if (isHidden) {
    return <></>
  }

  return (
    <div
      className={classNameIn}
      onClick={() => {
        if (disabled) return
        onClick()
      }}
    >
      {text}
    </div>
  )
}

interface UploadDefaultProps {
  isHidden: boolean
  showNext: boolean
  showBack: boolean
  onNexClicked: (a: boolean) => void
  disabled: boolean
}
interface UploadVideoProps extends UploadDefaultProps {
  onFileSelected: (file: Blob | File) => void
}

// TODO:: CROP VIDE0
// Not need right now because app crops it our automatically.
//https://stackoverflow.com/questions/38924613/how-to-convert-array-of-png-image-data-into-video-file
export const UploadVideo: React.FC<UploadVideoProps> = ({
  onFileSelected,
  isHidden,
  showNext,
  showBack,
  onNexClicked,
  disabled,
}): JSX.Element => {
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  if (isHidden) return <></>
  return (
    <>
      <input
        style={{ display: "none" }}
        ref={hiddenFileInput}
        type="file"
        accept=".mp4,.mov"
        onChange={(e) => {
          if (e.target.files !== null) {
            const file = e.target.files[0]
            onFileSelected(file)
          }
          e.target.files = null
          e.target.value = ""
        }}
      />
      <CreationControllerStart
        onClick={() => {
          hiddenFileInput.current?.click()
        }}
        isHidden={false}
        text={"Upload Video"}
        disabled={disabled}
      />
      {showBack || showNext ? (
        <div className={styles.DoubleButtonStack}>
          <CreationControllerStart
            onClick={() => {
              onNexClicked(false)
            }}
            isHidden={!showBack}
            text={"Back"}
            disabled={disabled}
            isInverted
          />
          <CreationControllerStart
            onClick={() => {
              onNexClicked(true)
            }}
            isHidden={!showNext}
            text={"Skip"}
            disabled={disabled}
            isInverted
          />
        </div>
      ) : null}
    </>
  )
}

interface UploadPictureProps {
  onFileSelected: (file: File | Blob, height: number, width: number, imageResource: HTMLCanvasElement) => void
  onSlide: (time: number) => void
  videoUrl: string
  isHidden: boolean
  showNext: boolean
  nextTitle?: string
  showBack: boolean
  onNexClicked: (a: boolean) => void
  disabled: boolean
  fileRatio: number | undefined
  allowLocal?: boolean
  autoCropURL?: string
  autoCropWithURL?: boolean
  mainButtonTitle?: string
}

export const UploadPicture: React.FC<UploadPictureProps> = ({
  onFileSelected,
  videoUrl,
  onSlide,
  isHidden,
  showNext,
  showBack,
  onNexClicked,
  disabled,
  fileRatio = 0.2,
  allowLocal = true,
  autoCropURL = "",
  nextTitle = "Skip",
  autoCropWithURL = false,
  mainButtonTitle = "Print this Frame",
}): JSX.Element | null => {
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const [sliderPosition, setSliderPosition] = useState<string>("0")
  const [actualURL, setActualURL] = useState("")
  const [autoCropActualUR, setAutoCropActualURL] = useState("")

  useEffect(() => {
    const storage = getStorage()
    if (videoUrl.includes("gs://")) {
      const imageStorageRef = ref(storage, videoUrl)
      getBlob(imageStorageRef).then((blob) => {
        const imageURL = URL.createObjectURL(blob)
        setActualURL(imageURL)
        if (videoRef.current) {
          videoRef.current.src = imageURL
          videoRef.current.pause()
        }
      })
    } else {
      setActualURL(videoUrl)
      if (videoRef.current) {
        videoRef.current.src = videoUrl
        videoRef.current.play()
      }
    }

    return () => {
      URL.revokeObjectURL(actualURL)
    }
  }, [videoUrl])

  useEffect(() => {
    const storage = getStorage()
    if (autoCropURL.includes("gs://")) {
      const imageStorageRef = ref(storage, autoCropURL)
      getBlob(imageStorageRef).then((blob) => {
        const imageURL = URL.createObjectURL(blob)
        if (!autoCropWithURL) return
        cropFromURL(imageURL)
        setAutoCropActualURL(imageURL)
      })
    } else {
      setActualURL("")
    }

    return () => {
      URL.revokeObjectURL(autoCropActualUR)
    }
  }, [autoCropURL])

  // CropSelectedVideoFrame is used to crop the video frame
  function CropSelectedVideoFrame(
    canvasRef: HTMLCanvasElement,
    imageResource: CanvasImageSource,
    ratio: number,
    videoImageWith: number,
    videoImageHeight: number,
    onFinish: (file: Blob | File, height: number, width: number, imageResource: HTMLCanvasElement) => void
  ) {
    const info = GetCropInformation(ratio, videoImageWith, videoImageHeight)

    DrawSelectedResource(canvasRef, imageResource, videoImageWith, videoImageHeight, info.actualWidth, info.actualHeight).toBlob(
      (blob: Blob | null) => {
        if (blob === null) {
          return
        }
        onFinish(blob, videoImageHeight, videoImageWith, canvasRef)
      }
    )
  }

  function cropFromURL(url: string) {
    var img = new Image()

    img.onload = function () {
      var height = img.height
      var width = img.width
      CropSelectedVideoFrame(canvasRef.current!, img, fileRatio, width, height, onFileSelected)
      URL.revokeObjectURL(url)
    }

    img.src = url
  }

  if (isHidden) return null

  if (autoCropURL) {
    return (
      <>
        <div className={styles.DoubleButtonStack}>
          <canvas id="canvas" ref={canvasRef} className={styles.canvasPreview} style={{ display: "none" }}></canvas>
          <CreationControllerStart
            onClick={() => {
              cropFromURL(autoCropActualUR)
            }}
            isHidden={!showBack}
            text={mainButtonTitle}
            disabled={disabled}
          />
        </div>
        <CreationControllerStart
          onClick={() => {
            onNexClicked(false)
          }}
          isHidden={!showBack}
          text={"Back"}
          disabled={disabled}
          isInverted
        />
      </>
    )
  }

  return (
    <>
      <p className={styles.GoodImageInfo}>Please choose a frame that has high contrast for better detection</p>
      <input
        style={{ display: "none" }}
        ref={hiddenFileInput}
        type="file"
        accept=".png, .jpeg, .jpg"
        onChange={(e) => {
          if (e.target.files !== null) {
            const file = e.target.files[0]
            cropFromURL(URL.createObjectURL(file))
          }
          e.target.files = null
          e.target.value = ""
        }}
      />

      <div className={styles.VideoFibreSlide}>
        <video
          width="100%"
          height="100%"
          controls={false}
          autoPlay={true}
          src={actualURL}
          loop
          playsInline
          muted
          preload={"auto"}
          ref={videoRef}
        />
      </div>

      <canvas id="canvas" ref={canvasRef} className={styles.canvasPreview} style={{ display: "none" }}></canvas>

      <CreationSliderController
        onSlide={(e) => {
          if (videoRef.current === null) {
            console.error("DOM VIDEO NOT PRESENT YET")
            return
          }

          const position = parseInt(e) / 100
          const totalTime = videoRef.current?.duration ?? 0
          const selectedTime = totalTime * position

          let selectedTimeFrame = 0
          if (isFinite(selectedTime)) {
            selectedTimeFrame = selectedTime
          }

          videoRef.current.currentTime = selectedTimeFrame
          videoRef.current.pause()
          setSliderPosition(e)
          onSlide(selectedTimeFrame)
        }}
        isHidden={false}
        sliderPosition={sliderPosition}
      />

      <CreationControllerStart
        onClick={() => {
          const position = parseInt(sliderPosition) / 100
          const totalTime = videoRef.current?.duration ?? 0
          const selectedTime = totalTime * position
          let selectedTimeFrame = 0
          if (isFinite(selectedTime)) {
            videoRef.current!.currentTime = selectedTime
            selectedTimeFrame = selectedTime
          }
          if (videoRef.current === null) {
            console.error("DOM VIDEO NOT PRESENT YET")
            return
          }
          videoRef.current!.currentTime = selectedTimeFrame
          // use this to calculate how to do the cropping
          CropSelectedVideoFrame(
            canvasRef.current!,
            videoRef.current,
            fileRatio,
            videoRef.current!.videoWidth,
            videoRef.current!.videoHeight,
            onFileSelected
          )
        }}
        disabled={disabled}
        isHidden={false}
        text={mainButtonTitle}
      />

      {allowLocal ? (
        <u
          onClick={() => {
            hiddenFileInput.current?.click()
          }}
        >
          select from computer
        </u>
      ) : null}

      <div className={styles.DoubleButtonStack}>
        <CreationControllerStart
          onClick={() => {
            onNexClicked(false)
          }}
          isHidden={!showBack}
          text={"Back"}
          disabled={disabled}
          isInverted
        />
        <CreationControllerStart
          onClick={() => {
            onNexClicked(true)
          }}
          isHidden={!showNext}
          text={nextTitle}
          disabled={disabled}
          isInverted
        />
      </div>
    </>
  )
}

/**
 * GetCropInformation take the image dimensions and determines the crop dimensions that
 * make it scale to fit
 */
export function GetCropInformation(
  ratio: number,
  videoImageWith: number,
  videoImageHeight: number
): { actualHeight: number; actualWidth: number } {
  const widthTopRatio = videoImageWith / videoImageHeight
  const heightTopRatio = videoImageHeight / videoImageWith
  let useWidth = widthTopRatio <= heightTopRatio
  const highEnough = videoImageWith / ratio > videoImageHeight
  useWidth = useWidth && !highEnough

  let actualHeight = 0
  let actualWidth = 0

  if (useWidth) {
    actualHeight = videoImageWith / ratio
    actualWidth = videoImageWith
  } else {
    actualHeight = videoImageHeight
    actualWidth = videoImageHeight * ratio
  }

  return { actualHeight: actualHeight, actualWidth: actualWidth }
}

// DrawSelectedResource is used to create the actual cropped image
export function DrawSelectedResource(
  ref: HTMLCanvasElement,
  image: CanvasImageSource,
  width: number,
  height: number,
  cropWith: number,
  cropHeight: number
) {
  ref.width = cropWith
  ref.height = cropHeight
  ref
    .getContext("2d")!
    .drawImage(image, width / 2 - cropWith / 2, height / 2 - cropHeight / 2, cropWith, cropHeight, 0, 0, cropWith, cropHeight)

  return ref
}

interface CreationSliderControllerProps {
  onSlide: (e: string) => void
  sliderPosition: string
  isHidden: boolean
}

export const CreationSliderController: React.FC<CreationSliderControllerProps> = ({
  onSlide,
  sliderPosition,
  isHidden,
}): JSX.Element => {
  if (isHidden) {
    return <></>
  }

  return (
    <div className={styles.sliderContainer}>
      <input
        type="range"
        min="1"
        max="100"
        value={sliderPosition}
        className={styles.slider}
        id="myRange"
        onInput={(e) => {
          const currentSliderPosition = e.currentTarget.value
          onSlide(currentSliderPosition)
        }}
      ></input>
    </div>
  )
}

interface ImageDPIProps {
  isHidden: boolean
  calculatedDPI: number
  calculatedContrast: number
  showNext: boolean
  showBack: boolean
  onNexClicked: (a: boolean) => void
  disabled: boolean
  minDPI?: number
  minContrast?: number
}

export const MIN_IMAGE_VARIANCE = 1730
export const MIN_IMAGE_DPI = 100

export const ImageDPI: React.FC<ImageDPIProps> = ({
  isHidden,
  calculatedDPI,
  calculatedContrast,
  showNext,
  showBack,
  onNexClicked,
  disabled,
  minDPI = MIN_IMAGE_DPI,
  minContrast = MIN_IMAGE_VARIANCE,
}): JSX.Element | null => {
  if (isHidden) return null

  const isLowDPi = calculatedDPI <= minDPI
  const isLowContrast = calculatedContrast <= minContrast

  return (
    <>
      <div className={styles.imageDPIContainer}>
        <div className={styles.imageDPIContainerWarning} />
        {isLowContrast &&
          `For a better experience please pick a frame that is not too dark, has better lighting and also has better contrast\n`}
        {isLowDPi &&
          `Image has a low resolution (${calculatedDPI} DPI), Print quality will be low. \n Please provide a better image.\n Recommended - 100 DPI\n`}
      </div>
      <div className={styles.DoubleButtonStack}>
        <CreationControllerStart
          onClick={() => {
            onNexClicked(false)
          }}
          isHidden={!showBack}
          text={"Back"}
          disabled={disabled}
          isInverted
        />
        <CreationControllerStart
          onClick={() => {
            onNexClicked(true)
          }}
          isHidden={!showNext}
          text={"Skip"}
          disabled={disabled}
          isInverted
        />
      </div>
    </>
  )
}

interface UploadMessageProps extends UploadDefaultProps {
  onTextSave: (a: File | Blob, b: string) => void
  initialMessage: string
  onDeleteText: () => void
  onChange: (a: string) => void
}

export const UploadMessage: React.FC<UploadMessageProps> = ({
  isHidden,
  disabled,
  onNexClicked,
  showBack,
  showNext,
  onTextSave,
  initialMessage,
  onDeleteText,
  onChange,
}): JSX.Element | null => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [textMessage, setTextMessage] = useState(initialMessage)
  const [addNote, setAddNote] = useState(initialMessage !== "")
  const [showAction, setShowAction] = useState(true)

  const scale = 1
  const width = 1360 * scale
  const height = 2040 * scale
  const fontSize = 30
  const cutRef = /(\r\n|\n|\r)/gm
  const maxTextLineLength = 30
  const fontDimensionRation = 0.5 // w / h

  if (isHidden) return null

  function CreateTextView(message: string) {
    if (canvasRef.current === null) return
    // var canvas = document.getElementById('Canvas01');
    var ctx = canvasRef.current.getContext("2d")
    if (ctx === null) return

    ctx.clearRect(0, 0, width, height)
    ctx.fillStyle = "white"
    ctx.fillRect(0, 0, width, height)

    ctx.font = `bold ${fontSize}pt 'Nunito'`
    ctx.textAlign = "center"
    ctx.fillStyle = "#000"

    var lineheight = fontSize + 10
    var lines = message.split("\n")

    let totalHeight = lines.length * lineheight
    let totalWidth = maxTextLineLength * fontSize * fontDimensionRation

    var x = width / 2
    var y = height / 2 - fontSize / 2 - totalHeight / 2

    for (var i = 0; i < lines.length; i++) {
      ctx.fillText(lines[i], x, y + i * lineheight)
    }
  }

  return (
    <>
      {showAction ? (
        <>
          <div className={styles.addNoteButtonContainer}>
            <div
              className={styles.addNoteButton}
              onClick={() => {
                setAddNote(!addNote)

                if (addNote) {
                  onDeleteText()
                  setTextMessage("")
                }
              }}
            >
              <div className={addNote ? styles.addNoteButtonUnTick : styles.addNoteButtonTick}></div>
            </div>
            add note
          </div>

          {addNote ? (
            <>
              <canvas
                id="canvas"
                width={1360}
                height={2040}
                ref={canvasRef}
                className={styles.canvasPreview}
                style={{ display: "none" }}
              ></canvas>

              <textarea
                className={styles.UploadMessage}
                placeholder={"Enter Message"}
                value={textMessage}
                onPaste={(e) => {
                  // e.preventDefault()
                }}
                onChange={(e) => {
                  let text = e.currentTarget.value
                  var lines = text.split(cutRef)
                  for (var i = 0; i < lines.length; i++) {
                    if (lines[i].length > maxTextLineLength) {
                      const words = lines[i].split(" ")

                      if (words.length === 1) {
                        lines[i] = lines[i].substring(0, maxTextLineLength) + "\n"
                      } else {
                        let totalLine = ""

                        let newLine = ""
                        for (var j = 0; j < words.length; j++) {
                          if ((newLine + words[j]).length < maxTextLineLength) {
                            newLine = newLine + words[j] + " "
                          } else {
                            totalLine = totalLine + newLine + "\n"
                            newLine = words[j]
                          }
                        }
                        lines[i] = totalLine + newLine
                      }
                    }
                  }

                  text = lines.join("")
                  console.log(text)
                  setTextMessage(text)
                  onChange(text)
                }}
              />

              <CreationControllerStart
                onClick={() => {
                  if (canvasRef.current === null) return
                  CreateTextView(textMessage)
                  canvasRef.current.toBlob((e) => {
                    if (e === null) return
                    onTextSave(e, textMessage)
                    setShowAction(false)
                  })
                }}
                disabled={disabled}
                isHidden={false}
                text={"Upload Message"}
              />
            </>
          ) : null}
        </>
      ) : null}

      <div className={styles.DoubleButtonStack}>
        <CreationControllerStart
          onClick={() => {
            if (showAction) {
              onNexClicked(false)
            } else {
              setShowAction(true)
            }
          }}
          isHidden={!showBack}
          text={"Back"}
          disabled={disabled}
          isInverted
        />

        <CreationControllerStart
          onClick={() => {
            onNexClicked(true)
          }}
          disabled={disabled}
          isHidden={showAction && addNote}
          text={"Done"}
        />
      </div>
    </>
  )
}
