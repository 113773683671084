import styles from "./index.module.css"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { firestoreDB } from "../../App"
import React from "react"
import { collection, deleteDoc, doc, getDoc, getDocs, setDoc, Timestamp } from "firebase/firestore"
import { AdminActions, AdminActionsButton } from "../shared"
import { CreateDefaultShippingMethod, ShippingMethod } from "schema/dist/src/address"

export const shippingCollectionName = "shippingMethods"

export const DiscountList: React.FC<{}> = (): JSX.Element => {
  const [shippingItems, setShippingItems] = useState<ShippingMethod[]>([])
  const [filteredShippingItems, setFilteredShippingItems] = useState<ShippingMethod[]>([])
  const [searchTerm, setSearchTerm] = useState<string>("")
  const nav = useNavigate()

  useEffect(() => {
    const docRef = collection(firestoreDB, shippingCollectionName)
    getDocs(docRef)
      .then((snap) => {
        const data = snap.docs.map((doc) => {
          return doc.data() as ShippingMethod
        })
        setShippingItems([...data])
      })
      .catch((error) => {
        console.log("Error getting document:", error)
      })
  }, [])

  useEffect(() => {
    const filter = shippingItems.filter((val) => {
      const matchedTitle = val.title.toLowerCase().includes(searchTerm.toLowerCase())
      return matchedTitle
    })

    setFilteredShippingItems(filter)
  }, [searchTerm, shippingItems])

  return (
    <>
      <AdminActions>
        <AdminActionsButton
          onClick={() => {
            CreateShippingMethod()
              .then((shipping) => {
                nav(`./${shipping.uid}`)
              })
              .catch((error) => {
                console.error(error)
              })
          }}
          title={"Create Shipping"}
        />
        <AdminActionsButton
          onClick={() => {
            nav(`./../`)
          }}
          title={"back"}
        />
      </AdminActions>

      <div className={styles.ShippingMethodListContainer}>
        <input
          type="text"
          value={searchTerm ?? ""}
          onChange={(e) => {
            setSearchTerm(e.currentTarget.value)
            window.scrollTo(0, 0)
          }}
          className={styles.HeaderSearch}
          placeholder="Search for shippings by title"
          spellCheck={false}
        />
        {filteredShippingItems.map((val) => {
          return <ShippingMethodItem shippingMethod={val} key={val.uid} />
        })}
      </div>
    </>
  )
}

interface PosterProps {
  shippingMethod: ShippingMethod
}

export const ShippingMethodItem: React.FC<PosterProps> = ({ shippingMethod }): JSX.Element => {
  const nav = useNavigate()

  return (
    <div
      className={styles.ShippingMethodListItem}
      onClick={() => {
        nav(`./${shippingMethod.uid}`)
      }}
    >
      <p>{shippingMethod.title}</p>
      <p>
        <b>${(shippingMethod.amount / 100).toFixed(2)}</b>
        <i>{shippingMethod.currency}</i>
      </p>
    </div>
  )
}

export function CreateShippingMethod(): Promise<ShippingMethod> {
  const ref = doc(collection(firestoreDB, shippingCollectionName))
  const shipping = CreateDefaultShippingMethod(ref.id, Timestamp.now())

  return UpdateShippingMethod(shipping)
}

export function UpdateShippingMethod(shippingMethod: ShippingMethod): Promise<ShippingMethod> {
  const ref = doc(firestoreDB, shippingCollectionName, shippingMethod.uid)

  return setDoc(ref, shippingMethod).then((result) => {
    return shippingMethod
  })
}

export function DeleteShippingMethod(shippingMethod: ShippingMethod): Promise<void> {
  const ref = doc(firestoreDB, shippingCollectionName, shippingMethod.uid)

  return deleteDoc(ref).then((result) => {
    return
  })
}

export function GetShipping(shippingMethodID: string): Promise<ShippingMethod | undefined> {
  //fetch the event

  return new Promise<ShippingMethod | undefined>((resolve, reject) => {
    if (shippingMethodID === "") {
      resolve(undefined)
      return
    }

    const collectionRef = doc(firestoreDB, shippingCollectionName, shippingMethodID)
    return getDoc(collectionRef).then((doc) => {
      if (!doc.exists) {
        resolve(undefined)

        return
      }

      const cou = doc.data() as ShippingMethod
      resolve(cou)
      return
    })
  })
}
