import styles from "./index.module.css"
import { Routes, Route } from "react-router-dom"
import Printer from "./printer"

export const Main: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.root}>
      <Routes>
        <Route path="/printer" element={<Printer />} />
      </Routes>
    </div>
  )
}
export default Main
