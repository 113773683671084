import styles from "./styles/home.module.css"
import mainStyles from "../../styles/App.module.css"
import productStyles from "../product/index.module.css"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { AppContext, setTabTitle } from "../../App"
import { Poster } from "schema/dist/src/poster"
import { StatContainerComponent } from "./stats"
import { VisualComponent } from "./visuals"
import { PreviewComponent } from "./previews"
import React from "react"

export const Main: React.FC<{}> = (): JSX.Element => {
  setTabTitle("DEV STATS")
  const { user } = useContext(AppContext)
  let nav = useNavigate()
  return (
    <div className={styles.root}>
      <div className={mainStyles.MainLogo} />
      <div className={mainStyles.TextLogo} />
      <PageList />
      {user !== null ? (
        <div className={styles.LoggedInView}>
          <div className={styles.MidContainer}>
            <StatContainerComponent />
            <VisualComponent
              onRevivartClick={(poster: Poster | undefined) => {
                nav("../poster/" + poster?.uid, { replace: false })
              }}
            />
          </div>
          <PreviewComponent />
        </div>
      ) : (
        <u
          onClick={() => {
            nav("../signin")
          }}
        >
          Do you have access ?
        </u>
      )}
    </div>
  )
}
export default Main

export const PageList: React.FC<{}> = ({}): JSX.Element => {
  const pageList = ["orders", "product", "category", "defaults", "event", "discount", "shipping", "applications", "vendors"]

  let nav = useNavigate()
  return (
    <div className={productStyles.UpdateOrderDetails}>
      ORDER STATUS
      <div className={productStyles.UpdateOrderDetailsList}>
        {pageList.map((page) => {
          let className = productStyles.UpdateOrderStatusButton

          return (
            <div
              key={page}
              className={className}
              onClick={() => {
                nav("./" + page)
              }}
            >
              {page}
            </div>
          )
        })}
      </div>
    </div>
  )
}
