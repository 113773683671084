import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { useEffect, useState } from "react"
import loadingImage from "./../assets/loadingImage.png"

export const useLDImage = (imageLDURL: string, isUploading = false) => {
  const [imageURL, setImageURL] = useState(loadingImage)

  useEffect(() => {
    if ((imageLDURL ?? "") === "" || isUploading) return
    const storage = getStorage()
    getDownloadURL(ref(storage, imageLDURL))
      .then((url) => {
        setImageURL(url)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [imageLDURL, isUploading])

  return { imageURL }
}
