import { Timestamp, collection, getDocs, limit, orderBy, query } from "firebase/firestore"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import React, { useContext, useEffect, useState } from "react"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { Event } from "schema/dist/src/event"
import { AppContext, firestoreDB } from "../App"
import styles from "./index.module.css"
import loadingImage from "./../assets/loadingImage.png"
import EventDetail from "./event"
import { EventAssetDetails } from "./asset"
import { StickerMain } from "./upload"

export const Main: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.root}>
      <Routes>
        <Route path="" element={<EventMain />} />
        <Route path="/upload/:eventID/*" element={<StickerMain />} />
        <Route path=":eventID" element={<EventDetail />} />
        <Route path=":eventID/asset/:assetID" element={<EventAssetDetails />} />
      </Routes>
    </div>
  )
}
export default Main

export const EventMain: React.FC<{}> = (): JSX.Element => {
  const { setNavType } = useContext(AppContext)
  useEffect(() => {
    setNavType("sleek")
    return () => {
      setNavType("bar")
    }
  }, [setNavType])

  const [showSearchBar, setShowSearchBar] = useState(false)
  const defaultTitle = "Recent Events"
  const [eventsList, setEventsList] = useState<Event[]>([])
  const [foundEventsList, setFoundEventsList] = useState<Event[]>([])
  const [upcomingEventsList, setUpcomingEventsList] = useState<Event[]>([])
  const [eventListTitle, setEventsListTitle] = useState(defaultTitle)
  const loc = useLocation()
  const nav = useNavigate()

  const queryParam = new URLSearchParams(loc.search).get("q")
  const [searchTerm, setSearchTerm] = useState(queryParam)

  //on Page Load, we fecth all the Events
  useEffect(() => {
    const docRef = collection(firestoreDB, "events")

    getDocs(docRef)
      .then((snap) => {
        const fetchedEvents = snap.docs
          .map((doc) => {
            return doc.data() as Event
          })
          .filter((val) => {
            const showEvent = isVisible(val)
            return showEvent
          })

        setEventsList(fetchedEvents)

        if (queryParam !== null) {
          //fetch events based on already existing search query
          setSearchTerm(queryParam)
          SearchWithTerm(queryParam, false, fetchedEvents)
        } else {
          //if none exists, fetch popular
          fetchPopularEvents()
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error)
      })

    //rerun on browser navigation
  }, [queryParam])

  //For now, same with fetchAllEvents, but sets a different state
  //TODO: Really fetch Popular Events
  function fetchPopularEvents() {
    const docRef = collection(firestoreDB, "events")
    //fetch the medias related to the event
    const limitNumber = 10
    const limitCount = limit(limitNumber)

    let eventQuery = query(docRef, limitCount, orderBy("timeStamp", "desc"))

    getDocs(eventQuery)
      .then((snap) => {
        const fetchedEvents = snap.docs
          .map((doc) => {
            return doc.data() as Event
          })
          .filter((val) => {
            const showEvent = isVisible(val)
            return showEvent && !isUpcoming(val)
          })

        setFoundEventsList(fetchedEvents)

        const comingEvents = snap.docs
          .map((doc) => {
            return doc.data() as Event
          })
          .filter((val) => {
            const showEvent = isVisible(val)
            return showEvent && isUpcoming(val)
          })

        setUpcomingEventsList(comingEvents)
      })
      .catch((error) => {
        console.log("Error getting document:", error)
      })
  }

  //navigate -- Decides if the browser history should be updated
  function SearchWithTerm(term: string, navigate: boolean = true, allEvents: Event[] = eventsList) {
    //we filter all the events instead, instead of hitting the database too frequently
    const fetchedEvents = allEvents.filter((val) => {
      //filters the events based on if ther searchterm matches the title or keywords
      const matchedTitle = val.title.toLowerCase().includes(term.toLowerCase())
      const matchedKeys = val.keywords.toLowerCase().includes(term.toLowerCase())

      return (matchedTitle || matchedKeys) && !isUpcoming(val)
    })

    setFoundEventsList(fetchedEvents)
    setEventsListTitle(term === "" ? defaultTitle : `Search results for '${term}'`)
    if (navigate) {
      if (term !== "") {
        nav({ search: "?q=" + term })
      }
    }

    if (term === "") {
      nav({})
    }
  }

  const isSearching = searchTerm !== null && searchTerm !== ""

  return (
    <div className={styles.root}>
      {!isSearching && (
        <div className={styles.SectionVideoContainer}>
          <div className={styles.SectionVideoContentContainer}>
            <div className={styles.SectionVideoContentMidContainer}>
              <div className={styles.HeaderTitle}>Revivar Recap</div>
              <div className={styles.HeaderSubTitle}>
                Relieve captured moments of events that happened around you by storing them in a physical picture
              </div>
              <div
                className={styles.HeaderLearnMore}
                onClick={() => {
                  nav("/recap")
                }}
              >
                Learn More
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={styles.EventSectionContainer}>
        <div className={styles.EventsListTitle}>
          {eventListTitle}
          <div
            className={styles.EventsSearchIcon}
            onClick={() => {
              setShowSearchBar(!showSearchBar)
            }}
          />
        </div>
        {showSearchBar || isSearching ? (
          <input
            type="text"
            value={searchTerm ?? ""}
            onChange={(e) => {
              SearchWithTerm(e.currentTarget.value, true)
              setSearchTerm(e.currentTarget.value)
              window.scrollTo(0, 0)
            }}
            className={styles.HeaderSearch}
            placeholder="Search for events by name or keywords"
            spellCheck={false}
          />
        ) : null}
        <div className={styles.EventsList}>
          {foundEventsList.map((val) => {
            return <EventItem event={val} key={val.uid} />
          })}
        </div>
        {!isSearching && upcomingEventsList.length > 0 && (
          <>
            <div className={styles.UpcomingEventsListTitle}>Upcoming Events</div>
            <div className={styles.EventsList}>
              {upcomingEventsList.map((val) => {
                return <EventItem event={val} key={val.uid} />
              })}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

interface EventItemProps {
  event: Event
}
export const EventItem: React.FC<EventItemProps> = ({ event }): JSX.Element => {
  const [imageURL, setImageURL] = useState(loadingImage)
  const [isUpcomingEvent, setIsUpcomingEvent] = useState(false)

  useEffect(() => {
    const upcoming = isUpcoming(event)
    setIsUpcomingEvent(upcoming)
  }, [event])

  const nav = useNavigate()
  useEffect(() => {
    if ((event.imageLDURL ?? "") === "") return
    const storage = getStorage()
    getDownloadURL(ref(storage, event.imageLDURL))
      .then((url) => {
        setImageURL(url)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [event])

  return (
    <div className={styles.EventItem}>
      <div
        className={styles.EventItemImage}
        onClick={() => {
          nav(`./${event.uid}`)
        }}
        style={{ backgroundImage: "url(" + imageURL + ")" }}
      ></div>
      {event.title} {isUpcomingEvent && `- ${event.date.toDate().toLocaleDateString()}`}
    </div>
  )
}

export function isUpcoming(event: Event): boolean {
  return event.date > Timestamp.fromDate(new Date())
}

export function isVisible(event: Event): boolean {
  if (
    event.isActive &&
    event.title !== "" &&
    event.selectedVariants !== undefined &&
    event.selectedVariants.length !== 0 &&
    event.defaultSelectedVariant !== undefined &&
    event.defaultSelectedVariant !== "" &&
    event.isPrivate !== true
  ) {
    return true
  } else {
    return false
  }
}
