import React from "react"
import { useNavigate } from "react-router-dom"

import styles from "./index.module.css"

export const PromoBar: React.FC<{}> = (): JSX.Element => {
  const date = new Date()

  const expireDate = new Date("February 16, 2023")

  if (date > expireDate) {
    return <></>
  }
  return <ValentinesBar />
}

export const BlackFridayBar: React.FC<{}> = (): JSX.Element => {
  const nav = useNavigate()

  return (
    <div
      className={styles.BlackFridayBar}
      onClick={() => {
        nav("/shop")
      }}
    >
      <div className={styles.BlackFridayBarAnimate}>
        <span> CYBER MONDAY! Enjoy 30% off everything! </span>
        <div className={styles.BlackFridayBarShop}> Shop Now</div>
        <span> CODE: CYBER30</span>
      </div>
    </div>
  )
}

export const ValentinesBar: React.FC<{}> = (): JSX.Element => {
  const nav = useNavigate()

  return (
    <div
      className={styles.ValentinesBar}
      onClick={() => {
        nav("/shop")
      }}
    >
      <div className={styles.ValentinesBarAnimate}>
        <span> Valentines sale! Enjoy 30% off everything! </span>
        <div className={styles.BlackFridayBarShop}> Shop Now</div>
        <span> CODE: LOVE23</span>
      </div>
    </div>
  )
}
