import { collection, doc, onSnapshot } from "firebase/firestore"
import { FormEvent, useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { AppContext, firestoreDB, stripePromise } from "../App"
import styles from "./cart.module.css"
import { OrderDetails } from "schema/dist/src/order"
import { CartItemBar, OrderDetailsSummary } from "./cart"
import { OrderShippingAddressPreview, OrderShippingMethod } from "./shipping"
import { Coupon } from "schema/dist/src/discount"
import { CouponToString, GetCoupon } from "../admin/discount/discount"
import { GetServerUrl, GetWebPageUrl } from "../create/createRevivart"
import { StripeElementsOptions, Appearance, StripePaymentElementOptions } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import axios from "axios"
import { PaymentElement, LinkAuthenticationElement, useStripe, useElements } from "@stripe/react-stripe-js"

export const stripeAppearance: Appearance = {
  theme: "night",
  variables: {
    colorPrimary: "#0570de",
    colorBackground: "#121212",
    colorText: "white",
    colorDanger: "#df1b41",
    spacingUnit: "5px",
    borderRadius: "8px",
  },
  rules: {
    ".Label--floating": {
      color: "grey",
    },
  },
  labels: "floating",
}

export const Main: React.FC<{}> = (): JSX.Element => {
  const loc = useLocation()
  const nav = useNavigate()
  const { user, userToken } = useContext(AppContext)
  const sessionID = new URLSearchParams(loc.search).get("orderID")
  const [orderSession, setOrderSession] = useState<OrderDetails>()

  useEffect(() => {
    if (user === null || sessionID === null) {
      return
    }

    const posterRefParent = doc(firestoreDB, "userData", user.uid)
    const orderCollection = collection(posterRefParent, "orderSession")
    const orderDocRef = doc(orderCollection, sessionID)

    const done = onSnapshot(orderDocRef, (snap) => {
      if (!snap.exists()) {
        nav("/cart")
      }

      const det = snap.data() as OrderDetails
      setOrderSession(det)
    })

    return () => {
      done()
    }
  }, [user, sessionID, nav])

  const [clientSecret, setClientSecret] = useState("")

  const [paymentRequired, setPaymentRequired] = useState(true)

  useEffect(() => {
    if (userToken === "" || orderSession === undefined || user === null || user?.uid === undefined) return

    // Create PaymentIntent as soon as the page loads
    const getIntent = "/api/api/checkout/intent"
    let checkoutAPI = GetServerUrl(getIntent)
    const checkoutAPILink = new URL(checkoutAPI)
    checkoutAPILink.searchParams.append("orderID", orderSession?.uid ?? "")
    checkoutAPILink.searchParams.append("customerID", user.uid ?? "")

    axios
      .get(checkoutAPILink.toString(), {
        headers: { Authorization: "Bearer " + userToken },
      })
      .then((res) => {
        setClientSecret(res.data.clientSecret)
        if (res.data.paymentRequired === false) {
          setPaymentRequired(false)
        } else if (res.data.paymentRequired === true) {
          setPaymentRequired(true)
        }
      })
  }, [userToken, orderSession, user?.uid])

  const options: StripeElementsOptions = {
    clientSecret: clientSecret,
    appearance: stripeAppearance,
  }

  if (sessionID === null || orderSession === undefined || user === null) return <div> </div>

  return (
    <div className={styles.root}>
      <ShippingBackButton address={"../shipping/" + loc.search} />
      <div className={styles.CartContainer}>
        <b>My Shopping Cart</b>
        {orderSession.CartItems.map((val, i) => {
          return <CartItemBar cartData={val} key={val.uid} userID={user?.uid ?? ""} disable />
        })}
      </div>
      <OrderShippingAddressPreview orderSession={orderSession} />
      <OrderShippingMethod orderSession={orderSession} user={user} userToken={userToken} />
      <OrderCouponItem orderSession={orderSession} userID={user?.uid ?? ""} userToken={userToken} />
      <OrderDetailsSummary order={orderSession} />
      {clientSecret && paymentRequired && (
        <Elements options={options} stripe={stripePromise} key={clientSecret}>
          <CheckoutForm orderSession={orderSession} userToken={userToken} userID={user?.uid ?? ""} />
        </Elements>
      )}
      {!paymentRequired && <FreeCheckoutForm orderSession={orderSession} userToken={userToken} userID={user?.uid ?? ""} />}
    </div>
  )
}
export default Main

interface OrderShippingMethodItemProps {
  userToken: string
  orderSession: OrderDetails
  userID: string
}

export const OrderCouponItem: React.FC<OrderShippingMethodItemProps> = ({ orderSession, userID, userToken }): JSX.Element => {
  const [couponName, setCouponName] = useState("")
  const [showApply, setShowApply] = useState(false)
  const [errCode, setErrorCode] = useState("")
  const [coupon, setCoupon] = useState<Coupon>()

  useEffect(() => {
    if (orderSession.couponID === undefined) return

    GetCoupon(orderSession.couponID).then((coupon) => {
      setCoupon(coupon)
      if (coupon === undefined) return
      setCouponName(coupon.code)
    })
  }, [orderSession.couponID])

  return (
    <div
      className={styles.OrderCouponItemContainer}
      onMouseEnter={(e) => {
        setShowApply(true)
      }}
      onMouseLeave={(e) => {
        setShowApply(false)
      }}
    >
      <input
        className={styles.OrderCouponItem}
        placeholder="Add a Promotional Code"
        value={couponName}
        onChange={(e) => {
          setCouponName(e.currentTarget.value.toLocaleUpperCase())
        }}
      />

      <div
        className={styles.OrderCouponItemText}
        onClick={() => {
          const updateCouponPoint = "/api/api/checkout/coupon"
          let couponAPI = GetServerUrl(updateCouponPoint)

          const checkoutAPILink = new URL(couponAPI)
          checkoutAPILink.searchParams.append("orderID", orderSession?.uid ?? "")
          checkoutAPILink.searchParams.append("customerID", userID ?? "")
          checkoutAPILink.searchParams.append("couponName", couponName)

          axios
            .get(checkoutAPILink.toString(), {
              headers: { Authorization: "Bearer " + userToken },
            })
            .then((res) => {
              if (res.data.valid === true) {
              } else {
                setErrorCode(`${res.data.err}`)
              }
            })
            .catch((res) => {
              console.log(res)
            })
        }}
      >
        {showApply ? "Apply" : ""}
      </div>

      {coupon?.discount !== undefined ? (
        <div className={styles.OrderCouponItemTextEnd}>{CouponToString(coupon.discount) + " off"}</div>
      ) : (
        <div className={styles.OrderCouponItemTextEnd}>{errCode}</div>
      )}
    </div>
  )
}

export const CheckoutForm: React.FC<{ orderSession: OrderDetails; userToken: string; userID: string }> = ({
  orderSession,
  userToken,
  userID,
}): JSX.Element => {
  const stripe = useStripe()
  const elements = useElements()
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (isLoading) return

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    const updateOrderPoint = "/api/api/checkout/update"
    let updateOrderDetailsApi = GetServerUrl(updateOrderPoint)

    // revivar order page / order complete and orderDetails ID
    let redirectURL = `${GetWebPageUrl()}/account/order/${orderSession.uid}`

    const returnURL = new URL(updateOrderDetailsApi)
    returnURL.searchParams.append("orderID", orderSession.uid)
    returnURL.searchParams.append("redirectURL", redirectURL)
    returnURL.searchParams.append("customerID", userID)
    returnURL.searchParams.append("access_token", userToken)
    // returnURL.searchParams.append("skipStripeCheck", "true")

    stripe
      .confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: returnURL.toString(),
          receipt_email: email,
        },
      })
      .then(({ error }) => {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message ?? "")
        } else {
          setMessage("An unexpected error occurred.")
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "tabs",
  }

  return (
    <form id="payment-form" className={styles.CartCheckoutPaymentContainer} onSubmit={handleSubmit}>
      <b>Billing method</b>

      <div className={styles.CartCheckoutPayment}>
        <div className={styles.CheckoutForm}>
          <LinkAuthenticationElement id="link-authentication-element" onChange={(e) => setEmail(e.value.email)} />
          <PaymentElement id="payment-element" options={paymentElementOptions} />
          {/* Show any error or success messages */}
        </div>
      </div>
      <button disabled={isLoading || !stripe || !elements} id="submit" className={styles.CartContainerButtonPay}>
        <span id="button-text">{isLoading ? <div className="spinner" id="spinner"></div> : "Pay Now"}</span>
      </button>
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}

export const FreeCheckoutForm: React.FC<{ orderSession: OrderDetails; userToken: string; userID: string }> = ({
  orderSession,
  userToken,
  userID,
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = (e: any) => {
    e.preventDefault()

    setIsLoading(true)

    const updateOrderPoint = "/api/api/checkout/update"
    let updateOrderDetailsApi = GetServerUrl(updateOrderPoint)
    // revivar order page / order complete and orderDetails ID
    let redirectPath = `/account/order/${orderSession.uid}`
    let redirectURL = `${GetWebPageUrl()}${redirectPath}`
    const returnURL = new URL(updateOrderDetailsApi)
    returnURL.searchParams.append("orderID", orderSession.uid)
    returnURL.searchParams.append("redirectURL", redirectURL)
    returnURL.searchParams.append("customerID", userID)
    returnURL.searchParams.append("access_token", userToken)
    returnURL.searchParams.append("skipStripeCheck", "true")

    window.open(returnURL.toString(), "_self")
  }

  return (
    <form id="payment-form" className={styles.CartCheckoutPaymentContainer} onSubmit={handleSubmit}>
      <button className={styles.CartContainerButtonPay}>
        <span id="button-text">{isLoading ? <div className="spinner" id="spinner"></div> : "Order"}</span>
      </button>
    </form>
  )
}

export const ShippingBackButton: React.FC<{ address: string }> = ({ address }): JSX.Element => {
  const nav = useNavigate()

  return (
    <div
      className={styles.BackButtonContainer}
      onClick={() => {
        nav(address)
      }}
    >
      <div className={styles.BackButtonIcon} onClick={() => {}}></div>
      <div className={styles.BackButton}>Back</div>
    </div>
  )
}
