import styles from "./index.module.css"
import { useEffect, useRef, useState } from "react"
import { doc, getDoc } from "firebase/firestore"
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import loadingImage from "./../assets/loadingImage.png"
import { useParams } from "react-router-dom"
import { firestoreDB, setTabTitle } from "../App"
import { Poster } from "schema/dist/src/poster"

type RouteParams = {
  posterID: string
}

export const Main: React.FC<{}> = (): JSX.Element => {
  const { posterID } = useParams<RouteParams>()
  const [imageUrl, setImageUrl] = useState(loadingImage)
  const [videoURL, setVideoURL] = useState("")
  const videoRef = useRef<HTMLVideoElement>(null)

  const [playVideo, setPlayVideo] = useState(false)
  const [poster, setPoster] = useState<Poster>()

  useEffect(() => {
    const storage = getStorage()
    setTabTitle("Poster")
    if (posterID === undefined) return

    const docRef = doc(firestoreDB, "userPosters", posterID ?? "")
    getDoc(docRef)
      .then((doc) => {
        if (doc.exists()) {
          const poster = doc.data() as Poster
          setPoster(poster)
          const imageStorageRef = ref(storage, poster.imageLDURL)
          getDownloadURL(imageStorageRef)
            .then((url) => {
              setImageUrl(url)
            })
            .catch((error) => {
              console.log("Error getting Image url", error)
            })
          const videoRef = ref(storage, poster.videoURL)
          getDownloadURL(videoRef)
            .then((url) => {
              setVideoURL(url)
            })
            .catch((error) => {
              console.log("Error getting Image url", error)
            })
        } else {
          console.log("No such document!")
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error)
      })
  }, [posterID])

  let playButtonClass = styles.playButton
  if (playVideo) {
    playButtonClass += " " + styles.pausePlayButton
  }

  if (poster === undefined) <></>
  return (
    <div className={styles.root}>
      <div className={styles.AssetPreviewSection}>
        <div className={styles.AssetPreview} style={{ backgroundImage: "url(" + imageUrl + ")" }}>
          <video loop autoPlay muted playsInline src={videoURL} ref={videoRef} hidden={!playVideo} />
        </div>
        <div
          className={playButtonClass}
          onClick={() => {
            setPlayVideo(!playVideo)

            if (playVideo) {
              videoRef.current?.pause()
            } else {
              videoRef.current?.play()
            }
          }}
        ></div>
      </div>
      <div className={styles.AssetDetailSection}>
        <div className={styles.AssetDetail}>
          <b>{poster?.title}</b>
          <br />
          <b>Description</b>
          <span>{poster?.description}</span>
        </div>
        <div className={styles.AssetDetailDownload}>
          <b>Please download App to view this revivart</b>
          <br />
          <div
            className={styles.AssetDetailDownloadButton}
            onClick={() => {
              window.open("https://apps.apple.com/us/app/revivar/id1559129541")
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}
export default Main
