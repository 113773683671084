import styles from "./index.module.css"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AppContext, firestoreDB } from "../../App"
import React from "react"
import { collection, deleteDoc, doc, getDocs, onSnapshot, query, setDoc, Timestamp, where } from "firebase/firestore"
import { AdminActions, AdminActionsButton } from "../shared"
import { CreateDefaultVendor, CreateUserVendor, ProfileData, Vendor } from "schema/dist/src/user"
import { VendorItem } from "../../landing/vendors"

export const vendorsCollectionName = "vendors"

export const VendorsList: React.FC<{}> = (): JSX.Element => {
  const [allVendors, setAllVendors] = useState<Vendor[]>([])
  const [filteredVendors, setFilteredVendors] = useState<Vendor[]>([])
  const [searchTerm, setSearchTerm] = useState<string>("")
  const nav = useNavigate()

  useEffect(() => {
    const docRef = collection(firestoreDB, vendorsCollectionName)
    getDocs(docRef)
      .then((snap) => {
        const data = snap.docs.map((doc) => {
          return doc.data() as Vendor
        })
        setAllVendors(data)
      })
      .catch((error) => {
        console.log("Error getting document:", error)
      })
  }, [])

  useEffect(() => {
    const filter = allVendors.filter((val) => {
      const matchedTitle =
        val.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        val.userEmail.toLowerCase().includes(searchTerm.toLowerCase())
      return matchedTitle
    })

    setFilteredVendors(filter)
  }, [searchTerm, allVendors])

  return (
    <>
      <AdminActions>
        <AdminActionsButton
          onClick={() => {
            nav(`./add`)
          }}
          title={"Add Vendor"}
        />
        <AdminActionsButton
          onClick={() => {
            nav(`./../`)
          }}
          title={"back"}
        />
        <AdminActionsButton
          onClick={() => {
            nav(`/vendors`)
          }}
          title={"VIEW LIVE PAGE"}
        />
      </AdminActions>

      <input
        type="text"
        value={searchTerm ?? ""}
        onChange={(e) => {
          setSearchTerm(e.currentTarget.value)
          window.scrollTo(0, 0)
        }}
        className={styles.HeaderSearch}
        placeholder="Search for vendors by name or email"
        spellCheck={false}
      />

      <div className={styles.VendorListContainer}>
        {filteredVendors.map((val) => {
          return (
            <VendorItem
              vendor={val}
              key={val.uid}
              onClick={() => {
                nav(`./${val.uid}`)
              }}
            />
          )
        })}
      </div>
    </>
  )
}

export const UsersList: React.FC<{}> = (): JSX.Element => {
  const [allVendors, setAllVendors] = useState<Vendor[]>([])
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [allUsers, setAllUsers] = useState<ProfileData[]>()
  const [filteredUsers, setFilteredUsers] = useState<ProfileData[]>([])
  const { user } = useContext(AppContext)
  const nav = useNavigate()

  useEffect(() => {
    const docRef = collection(firestoreDB, vendorsCollectionName)
    getDocs(docRef)
      .then((snap) => {
        const data = snap.docs.map((doc) => {
          return doc.data() as Vendor
        })
        setAllVendors(data)
      })
      .catch((error) => {
        console.log("Error getting document:", error)
      })
  }, [])

  useEffect(() => {
    if (user === null) return

    const docRef = collection(firestoreDB, "userData")
    const done = onSnapshot(docRef, (response) => {
      const data = response.docs
        .map((doc) => {
          return doc.data() as ProfileData
        })
        .filter((user) => {
          const found = allVendors.find((val) => val.uid === user.userId)
          if (found || user.userName === "Anonymous" || user.userEmail === "" || user.userName === "User 137") {
            return false
          } else {
            return true
          }
        })
      setAllUsers(data)
    })

    return () => {
      done()
    }
  }, [user, allVendors])

  useEffect(() => {
    if (allUsers === undefined) return
    const filter = allUsers.filter((val) => {
      const matchedTitle =
        val.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        val.userEmail.toLowerCase().includes(searchTerm.toLowerCase())
      return matchedTitle
    })

    setFilteredUsers(filter)
  }, [searchTerm, allUsers])

  if (allUsers === undefined) return <></>

  return (
    <>
      <AdminActions>
        <AdminActionsButton
          onClick={() => {
            nav(`./../`)
          }}
          title={"back"}
        />
        <AdminActionsButton
          onClick={() => {
            NewRegularVendor().then((vendor) => {
              nav(`./../${vendor.uid}`)
            })
          }}
          title={"Create"}
        />
      </AdminActions>

      <div className={styles.VendorUserContainer}>
        <input
          type="text"
          value={searchTerm ?? ""}
          onChange={(e) => {
            setSearchTerm(e.currentTarget.value)
            window.scrollTo(0, 0)
          }}
          className={styles.HeaderSearch}
          placeholder="Search for Users by name or email"
          spellCheck={false}
        />
        {filteredUsers.map((val) => {
          return <UserItem user={val} key={val.userId} />
        })}
      </div>
    </>
  )
}

interface UserProps {
  user: ProfileData
}

export const UserItem: React.FC<UserProps> = ({ user }): JSX.Element => {
  const nav = useNavigate()

  return (
    <div className={styles.UserListItem}>
      <p>{user.userName}</p>
      <p>{user.userEmail}</p>
      <button
        onClick={() => {
          NewUserVendor(user).then(() => {
            nav(`./../${user.userId}`)
          })
        }}
      >
        ADD
      </button>
    </div>
  )
}

export function NewUserVendor(user: ProfileData): Promise<Vendor> {
  const vendor = CreateUserVendor(user, Timestamp.now())
  return UpdateVendor(vendor)
}

export function NewRegularVendor(): Promise<Vendor> {
  const vendor = CreateDefaultVendor(Timestamp.now())
  return UpdateVendor(vendor)
}

export function UpdateVendor(vendor: Vendor): Promise<Vendor> {
  const ref = doc(firestoreDB, vendorsCollectionName, vendor.uid)
  return setDoc(ref, vendor).then(() => {
    return vendor
  })
}

export function DeleteVendor(vendor: Vendor): Promise<void> {
  const ref = doc(firestoreDB, vendorsCollectionName, vendor.uid)

  return deleteDoc(ref).then(() => {
    return
  })
}
