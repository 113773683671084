import styles from "../tobennawes/index.module.css"
import { PosterSection } from "../tobennawes"
import { useNavigate } from "react-router-dom"

const pageID = "postcards"
export const Main: React.FC<{}> = (): JSX.Element => {
  const nav = useNavigate()
  return (
    <div className={styles.root}>
      <div
        className={styles.SectionVideoContainer}
        onClick={() => {
          window.scrollTo({ top: window.innerHeight, behavior: "smooth" })
        }}
      >
        <div className={styles.SectionVideoContent}>
          <div className={styles.PosterHeaderSection}>
            <div className={styles.PosterHeaderSectionTitle}>Postcards</div>
            <div className={styles.PosterHeaderSectionSub}>Send deeper, jaw dropping, hidden messages</div>
            <div className={styles.PosterHeaderSectionActionsContainer}>
              <div className={styles.PosterHeaderSectionActionsButton}>Shop Cards</div>
              <div
                className={styles.PosterHeaderSectionActionsButton}
                onClick={() => {
                  nav("/studio/postcard")
                }}
              >
                Create a Card
              </div>
            </div>
          </div>
        </div>
      </div>
      <PosterSection categoryPageID={pageID} />
    </div>
  )
}
export default Main
