import { Timestamp } from "firebase/firestore"
import { uuidv4 } from "@firebase/util"

export interface ProfileData {
  favoritePosterIds: string[]
  likedPosterIds: string[]
  phoneNumber: number
  profilePictureUrl: string
  userEmail: string
  userId: string
  userName: string
  userHandle?: string
  stripeId: string
  createdDate: Timestamp
}

export interface ProfileSocialData {
  profilePictureUrl: string
  userId: string
  userName: string
  userHandle?: string
}

export type VendorTypes = "vendor" | "photographer" | "event planner"
export interface Vendor {
  uid: string
  userName: string
  userEmail: string
  addedDate: Timestamp
  profilePictureUrl: string
  location: string
  link: string
  type: VendorTypes
  rating: 1 | 2 | 3 | 4 | 5
}

export function CreateUserVendor(user: ProfileData, timeStamp: Timestamp): Vendor {
  return {
    uid: user.userId,
    userName: user.userName,
    userEmail: user.userEmail,
    addedDate: timeStamp,
    profilePictureUrl: user.profilePictureUrl,
    location: "",
    link: "",
    type: "vendor",
    rating: 1,
  }
}

export function CreateDefaultVendor(timeStamp: Timestamp): Vendor {
  return {
    uid: uuidv4(),
    userName: "",
    userEmail: "",
    addedDate: timeStamp,
    profilePictureUrl: "",
    location: "",
    link: "",
    type: "vendor",
    rating: 1,
  }
}

export interface ArtistApplication {
  uid: string
  name: string
  email: string
  link: string
  summary: string
  createdDate: Timestamp
  read?: boolean
}

export function CreateDefaultArtistApplication(details: ArtistApplication): ArtistApplication {
  return {
    uid: details.uid,
    name: details.name,
    email: details.email,
    link: details.link,
    summary: details.summary,
    createdDate: details.createdDate,
    read: false,
  }
}
