import styles from "./index.module.css"
import { useRef, useState } from "react"

import { useNavigate } from "react-router-dom"

export const Main: React.FC<{}> = ({}): JSX.Element => {
  return (
    <div className={styles.root}>
      <h1>Discover</h1>
      <div className={styles.DiscoverContentIcon}></div>
      <span>
        Using the Map in the apps home page you can see all REVIVART nearby.
        <br />
        <br />
        When you successfully locate a revivart
        <br /> Hit the like button
        <br />
        <br />
      </span>
      <div className={styles.LikeContentIcon}></div>
      <br />
      <div className={styles.DiscoverContent2}></div>
      <span>
        <br />
        Now it will appear in your discoveries section.
        <br />
        <br />
        When update happen to this revivart you will be the first person to know
      </span>
    </div>
  )
}

export default Main
