import styles from "./index.module.css"
import mainStyles from "../../styles/App.module.css"

import { Route, Routes } from "react-router-dom"

import { setTabTitle } from "../../App"
import React from "react"
import { EventsList } from "./events"
import { EventDetails } from "./create"
import { EventMediaDetails } from "./media"

export const Main: React.FC<{}> = (): JSX.Element => {
  setTabTitle("Admin Events")

  return (
    <div className={styles.root}>
      <div className={mainStyles.MainLogo} />
      <div className={styles.AccountContentContainer}>
        <Routes>
          <Route path="/*" element={<EventsList />} />
          <Route path="/:eventID" element={<EventDetails />} />
          <Route path="/:eventID/asset/:mediaID/edit" element={<EventMediaDetails />} />
        </Routes>
      </div>
    </div>
  )
}

export default Main
