import { collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, setDoc } from "firebase/firestore"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { AppContext, firestoreDB } from "../App"
import { SetOrderSession } from "../create/createRevivart"
import { isFailedKey } from "../create/createUrl"
import { CartItem, ProductToCartItem } from "schema/dist/src/cart"
import { StudioSession } from "schema/dist/src/studio"
import { isProductVariantActive, Product, ProductVariant } from "schema/dist/src/product"
import { OrderDetails } from "schema/dist/src/order"
import styles from "./cart.module.css"
import loadingImage from "./../assets/loadingImage.png"
import { BackURLKey } from "../shop/product"
import { ValidateUserCheckout } from "../auth"
import { User } from "firebase/auth"
import { BackButton } from "../events/asset"

export const Main: React.FC<{}> = (): JSX.Element => {
  const [cartList, setCartList] = useState<CartItem[]>([])
  const nav = useNavigate()
  const loc = useLocation()
  const search = useLocation().search
  const { user } = useContext(AppContext)

  useEffect(() => {
    if (user === null) {
      return
    }

    const posterRefParent = doc(firestoreDB, "userData", user.uid)
    const newPosterCol = collection(posterRefParent, "cartItems")
    const orderCollection = collection(posterRefParent, "orderDetails")

    const done = onSnapshot(newPosterCol, (snap) => {
      const CartItem = snap.docs.map((doc) => {
        return doc.data() as CartItem
      })

      setCartList(CartItem)
    })

    const isFailed = new URLSearchParams(search).get(isFailedKey) ?? ""
    const orderID = new URLSearchParams(search).get("orderID") ?? ""

    if (isFailed === "true" && orderID !== "") {
      const orderDocRef = doc(orderCollection, orderID)

      // WE FIRST HAVE TO CHECK THAT THE ORDER DETAILS IS STILL ACTIVE
      getDoc(orderDocRef)
        .then((snap) => {
          if (!snap.exists) {
            console.log("session has already been removed")
          }
          const det = snap.data() as OrderDetails
          if (det.paymentStatus.toLowerCase() !== "paid") {
            return deleteDoc(orderDocRef)
          }
        })
        .then(() => {
          console.log("deleted last order session")
        })
    }

    return () => {
      done()
    }
  }, [user, firestoreDB, search])

  function StartCheckout() {
    // check if the user has an account
    if (ValidateUserCheckout(user, nav, loc)) {
      if (user === null) {
        return
      }

      // create a user cart session and we place that in the url
      SetOrderSession(cartList, user.uid).then((orderID: string | undefined) => {
        nav({ search: "?orderID=" + orderID, pathname: "./shipping" })
      })
    }
  }

  let subTotal = 0

  cartList.forEach((item) => {
    subTotal += item.count * item.productVariant.price
  })

  return (
    <div className={styles.root}>
      <BackButton title="Continue Shopping" />
      <div className={styles.CartContainer}>
        <b>My Shopping Cart</b>
        {cartList.map((val, i) => {
          return <CartItemBar cartData={val} key={val.uid} userID={user?.uid ?? ""} />
        })}
      </div>
      {cartList.length !== 0 ? (
        <OrderDetailsSummary subtotal={subTotal} />
      ) : (
        <>
          <div className={styles.CartContainerEmptyIcon} />
          Your shopping cart is empty
          <div className={styles.CartContainerControls}>
            <div
              className={styles.CartContainerButton}
              onClick={() => {
                nav("/shop")
              }}
            >
              Shop Revivar
            </div>
          </div>
        </>
      )}
      <div className={styles.CartContainerControls}>
        {cartList.length > 0 ? (
          <div className={styles.CartContainerButton} onClick={StartCheckout}>
            Start Checkout
          </div>
        ) : null}
      </div>
    </div>
  )
}
export default Main

interface OrderDetailsSummaryProps {
  order?: OrderDetails
  subtotal?: number
}

export const OrderDetailsSummary: React.FC<OrderDetailsSummaryProps> = ({ order, subtotal = 0 }): JSX.Element => {
  if (order === undefined) {
    return (
      <div className={styles.CartContainerTotalContainer}>
        <div className={styles.CartContainerTotal}>
          <div className={styles.CartContainerTotalItem}>
            SubTotal: <b>${(subtotal / 100).toFixed(2)}</b>
          </div>
          <div className={styles.CartContainerTotalItem}>
            Shipping: <b>$---</b>
          </div>

          <div className={styles.CartContainerTotalItem}>
            Total: <b>${(subtotal / 100).toFixed(2)}</b>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.CartContainerTotalContainer}>
      <div className={styles.CartContainerTotal}>
        <div className={styles.CartContainerTotalItem}>
          SubTotal: <b>${(order.subTotal / 100).toFixed(2)}</b>
        </div>

        <div className={styles.CartContainerTotalItem}>
          Shipping: <b>${order.shippingAmount === undefined ? "---" : (order.shippingAmount / 100).toFixed(2)}</b>
        </div>

        {order.discountAmount !== 0 && order.discountAmount !== undefined ? (
          <div className={styles.CartContainerTotalItem}>
            Discount: <b>-${(order.discountAmount / 100).toFixed(2)}</b>
          </div>
        ) : null}

        {order.taxAmount !== 0 ? (
          <div className={styles.CartContainerTotalItem}>
            Tax: <b>-${(order.taxAmount / 100).toFixed(2)}</b>
          </div>
        ) : null}

        <div className={styles.CartContainerTotalItem}>
          {order.total > 0 ? (
            <>
              Total: <b>${(order.total / 100).toFixed(2)}</b>
            </>
          ) : (
            <>
              Total: <b>$0.00</b>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export function getCartItemOpenLink(
  cartData: CartItem,
  productData?: Product,
  pathname: string = "/",
  search: string = ""
): string | undefined {
  if (cartData.productType === "shopProduct") {
    if ((productData?.studioID ?? "") !== "") {
      return `/studio/${productData?.studioID}/${cartData.productVariant.studioSessionID}`
    }
    return `/shop/product/${cartData.productUid}?${BackURLKey}=${pathname + search}`
  } else if (cartData.productType === "userPoster") {
    //TODO:: OPEN DETAILS USER PAGE
  }
}
export const CartItemBar: React.FC<{ cartData: CartItem; userID: string; disable?: boolean }> = ({
  cartData,
  userID,
  disable = false,
}): JSX.Element => {
  const [imageURL, setImageURL] = useState(loadingImage)

  const [totalItems, setTotalItems] = useState(cartData.count)
  const nav = useNavigate()
  const loc = useLocation()
  const [productData, setProductData] = useState<Product>()

  const maxCount = cartData.productVariant.orderLimit ?? 10
  const countList: number[] = []

  for (let i = 0; i < maxCount; i++) {
    countList.push(i + 1)
  }

  const isInList = countList.find((val) => {
    return val === totalItems
  })

  useEffect(() => {
    const docRef = doc(firestoreDB, "shopProducts", cartData.productUid ?? "")
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const product = docSnap.data() as Product
        setProductData(product)
      } else {
        console.log("No such document!")
      }
    })

    if (cartData.count !== totalItems) {
      setTotalItems(cartData.count)
    }

    if ((cartData.productImageUrl ?? "") === "") return
    GetImageURLFromProduct(cartData.productVariant, cartData.productImageUrl).then((url) => {
      setImageURL(url)
    })
  }, [cartData])

  function DeleteCartItem() {
    if (userID === "") {
      return
    }

    const posterRefParent = doc(firestoreDB, "userData", userID)
    const docRef = doc(collection(posterRefParent, "cartItems"), cartData.uid)

    deleteDoc(docRef)
      .then(() => {})
      .catch((e) => {
        console.error(e)
      })
  }

  function UpdateCartCount(str: string) {
    const oldCount = totalItems
    let count = parseInt(str, 10)
    //MAX ORDER COUNT IS 100
    if (isNaN(count) || count >= 100 || count === 0) {
      count = 1
    }

    const posterRefParent = doc(firestoreDB, "userData", userID)
    const docRef = doc(collection(posterRefParent, "cartItems"), cartData.uid)

    setTotalItems(count)

    //TODO:: BETTER ERROR HANDLING
    setDoc(docRef, { count: count }, { merge: true })
      .then(() => {})
      .catch((e) => {
        // RETURN IT BACK TO OLD
        setTotalItems(oldCount)
      })
  }

  return (
    <div className={styles.CartItemBar}>
      <img
        className={styles.CartItemImage}
        src={imageURL}
        alt={"Cart Icon"}
        onClick={() => {
          const path = getCartItemOpenLink(cartData, productData, loc.pathname, loc.search)
          if (path !== undefined) {
            nav(path)
          }
        }}
      />
      <div className={styles.CartItemInfo}>
        <div>{cartData.title}</div>
        <div>{cartData.productVariant.title}</div>
        <div>{cartData.productVariant.size}</div>
        <div>${(cartData.productVariant.price / 100).toFixed(2)}</div>
      </div>
      <div className={styles.CartItemControls}>
        {disable ? null : (
          <div
            className={styles.CartItemDeleteIcon}
            onClick={(e) => {
              e.stopPropagation()
              DeleteCartItem()
            }}
          />
        )}

        {disable ? (
          <div className={styles.CartItemControlsPreviewAmount}> QTY: {totalItems}</div>
        ) : (
          <select
            onChange={(e) => {
              UpdateCartCount(e.target.value)
            }}
            defaultValue={totalItems}
          >
            {countList.map((number) => {
              return (
                <option key={number} value={number}>
                  {number}
                </option>
              )
            })}
            {isInList ? null : (
              <option key={totalItems} value={totalItems}>
                {totalItems}
              </option>
            )}
          </select>
        )}
      </div>
    </div>
  )
}

export function GetCartItems(user: User) {
  const posterRefParent = doc(firestoreDB, "userData", user.uid)
  const newPosterCol = collection(posterRefParent, "cartItems")
  return getDocs(newPosterCol).then((snap) => {
    const CartItem = snap.docs.map((doc) => {
      return doc.data() as CartItem
    })

    return CartItem
  })
}

export function WriteCartItems(user: User, cartItems: CartItem[]) {
  const allRefs = cartItems.map((cartItem) => {
    return AddToCartCartItem(user.uid, cartItem)
  })

  return Promise.all(allRefs)
}

export function AddToCart(userID: string, product: Product, variant: ProductVariant, count?: number) {
  const productCount = count ?? 1

  const posterRefParent = doc(firestoreDB, "userData", userID)
  const newPosterCol = collection(posterRefParent, "cartItems")
  const newCartRef = doc(newPosterCol)
  let newCartItem = ProductToCartItem(product, newCartRef.id, variant, productCount, "shopProduct")

  return getDocs(newPosterCol)
    .then((snap) => {
      // CHECK IF DOC EXIST
      if (snap.empty) {
        return setDoc(newCartRef, newCartItem)
      }

      snap.docs.forEach((doc) => {
        const cartData = doc.data() as CartItem

        if (cartData.productUid === product.uid && isProductVariantActive(cartData.productVariant, variant)) {
          cartData.count = cartData.count + productCount

          if (
            variant.orderLimit !== undefined &&
            variant.orderLimit !== null &&
            variant.orderLimit !== 0 &&
            variant.orderLimit < cartData.count
          ) {
            cartData.count = variant.orderLimit
          }

          newCartItem = cartData
        }
      })

      // SET IT TO OLD CART ID
      let oldCartRef = doc(newPosterCol, newCartItem.uid)
      return setDoc(oldCartRef, newCartItem)
    })
    .then(() => {
      return newCartItem
    })
}

export function AddToCartCartItem(userID: string, cartItem: CartItem) {
  const posterRefParent = doc(firestoreDB, "userData", userID)
  const newPosterCol = collection(posterRefParent, "cartItems")
  const newCartRef = doc(newPosterCol)
  cartItem.uid = newCartRef.id

  return getDocs(newPosterCol).then((snap) => {
    // CHECK IF DOC EXIST
    if (snap.empty) {
      return setDoc(newCartRef, cartItem)
    }

    snap.docs.forEach((doc) => {
      const cartData = doc.data() as CartItem
      if (
        cartData.productUid === cartItem.productUid &&
        cartData.productVariant.size === cartItem.productVariant.size &&
        cartData.productVariant.title === cartItem.productVariant.title
      ) {
        cartData.count = cartData.count + cartItem.count
        cartItem = cartData
      }
    })

    // SET IT TO OLD CART ID
    let oldCartRef = doc(newPosterCol, cartItem.uid)
    return setDoc(oldCartRef, cartItem)
  })
}

export function GetImageURLFromProduct(productVariant: ProductVariant, productImageUrl: string) {
  const storage = getStorage()
  const sessionID = productVariant.studioSessionID

  return new Promise<string>((resolve, reject) => {
    if (sessionID !== undefined) {
      const sessionCollRef = collection(firestoreDB, "studioSessions")
      let sessionRef = doc(sessionCollRef, sessionID)
      let session: StudioSession
      getDoc(sessionRef)
        .then((doc) => {
          if (!doc.exists()) {
            return
          }
          session = doc.data() as StudioSession
          getDownloadURL(ref(storage, session.sessionData[0].imageURL))
            .then((url) => {
              resolve(url)
            })
            .catch((error) => {
              console.error(error)
              reject(error)
            })
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    } else {
      getDownloadURL(ref(storage, productImageUrl))
        .then((url) => {
          resolve(url)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    }
  })
}
