import "./styles/App.css"
import { Routes, Route, useLocation, useNavigate } from "react-router-dom"
import NavBar from "./NavBar/NavBar"
import Home from "./landing/home"
import About from "./landing/about"
import Map from "./landing/map"
import NotFound from "./landing/404"
import Activate from "./landing/activate"
import Contact from "./landing/contactus"
import Vendors from "./landing/vendors"
import Learn from "./landing/learn"
import LearnEvents from "./landing/learn/events"
import Discover from "./landing/discover"
import Sticker from "./poster/sticker"
import Deck from "./landing/deck"
import Cart from "./cart"
import Account from "./account"
import Shop from "./shop"
import Legal from "./landing/legal/legal"
import Recap from "./recap"
import Immersed from "./immersed"
import Terms from "./landing/legal/terms"
import Asset from "./poster"
import Events from "./events"
import Support from "./support"
import Apply from "./landing/apply/apply"
import GenerateQR from "./create/generateQR"
import Create from "./create/create"
import { SignIn, SignOut, SignUp, ResetPassword, returnPageKey } from "./auth"
import OverView from "./admin"
import { initializeApp } from "firebase/app"
import { getAuth, User } from "firebase/auth"
import { firebaseConfigStaging, firebaseConfigDev, firebaseConfigProd } from "schema/dist/src/firebase"
import React, { useContext, useEffect, useState } from "react"
import { getAnalytics } from "firebase/analytics"
import { loadStripe } from "@stripe/stripe-js"
import { getFirestore } from "firebase/firestore"

export let setAppConfig = firebaseConfigDev
export let previewAppWebsite = ""
export let stripePublishableKey = ""
export let GoogleMapsAPIKey = ""
// https://tacomanator.medium.com/environments-with-create-react-app-7b645312c09d
export const alias = process.env.REACT_APP_ENV
if (alias === "prod") {
  setAppConfig = firebaseConfigProd
  previewAppWebsite = "https://preview.revivar.app"
  stripePublishableKey = "pk_live_bW41aSVNKEIP15J3qFYdcU7r"
  GoogleMapsAPIKey = "AIzaSyAIW7x6_tUjrZvjEU1Rs3tmmnJIBqV8TsA"
} else if (alias === "dev") {
  setAppConfig = firebaseConfigDev
  previewAppWebsite = "https://preview.revivar.dev"
  stripePublishableKey = "pk_test_U9j21NNs0cN1KaycgCItXqZn"
  GoogleMapsAPIKey = "AIzaSyBAqgSwAvCNCIIuvXzFT7Gh9coVokdS1k8"
} else if (alias === "staging") {
  setAppConfig = firebaseConfigStaging
  previewAppWebsite = "https://preview.staging.revivar.dev"
  stripePublishableKey = "pk_test_U9j21NNs0cN1KaycgCItXqZn"
  GoogleMapsAPIKey = "AIzaSyA-IRW9vQvoYYiTt9fPZd24C26bWmKnm-I"
} else {
  console.error("PLEASE SET APP ENVIRONMENT")
}

export const stripePromise = loadStripe(stripePublishableKey)

export let isUsingEmulator = false
export let isUsingLocalPreview = false

export const LocalIP = "0.0.0.0"
export const FunctionsLocalPort = 5033
var env = process.env.NODE_ENV || "development"

if (env === "production") {
  isUsingEmulator = false
  isUsingLocalPreview = false
}

if (isUsingLocalPreview) {
  previewAppWebsite = "http://localhost:3100"
}

export const firebaseApp = initializeApp(setAppConfig)
export const analyticsApp = getAnalytics(firebaseApp)
export const firestoreDB = getFirestore(firebaseApp)
export const auth = getAuth(firebaseApp)

if (isUsingEmulator) {
  // connectFirestoreEmulator(firestoreDB, "0.0.0.0", 8080)
  // connectAuthEmulator(auth, "http://localhost:9099")
}

export function setTabTitle(title: string, description?: string) {
  document.title = title
}

export function setMetaData(description: string) {
  document.querySelector('meta[name="description"]')?.setAttribute("content", description)
}

const appDescription = `A place to create and buy premium moving pictures.
Revivar allows you bring pictures to life using Augmented Reality. Augmented reality postcards, AR greeting cards, AR birthday cards, 
Augmented Live Poster, AR Posters`

type NavBarType = "bar" | "sleek" | "sticky" | "home"
interface AppContextType {
  user: User | null
  setUser: (user: User) => void
  navType: NavBarType
  setNavType: (type: NavBarType) => void
  userToken: string
}

export function useNavBar(navView: NavBarType) {
  const { setNavType, navType } = useContext(AppContext)
  useEffect(() => {
    setNavType(navView)
    return () => {
      setNavType("bar")
    }
  }, [setNavType])

  return navType
}

export const AppContext = React.createContext<AppContextType>({
  user: null,
  userToken: "",
  setUser: () => {},
  setNavType: () => {},
  navType: "bar",
})

export const App: React.FC<{}> = (): JSX.Element => {
  const auth = getAuth(firebaseApp)
  const [authState, setAuthState] = useState(auth.currentUser)
  const [useToken, setUserToken] = useState("")
  const [navBarType, setNavBarType] = useState<NavBarType>("bar")

  const nav = useNavigate()
  const loc = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  setMetaData(appDescription)

  //github.com/firebase/functions-samples/blob/main/delete-unused-accounts-cron/functions/index.js
  useEffect(() => {
    const done = auth.onAuthStateChanged((user) => {
      setAuthState(auth.currentUser)
      if (auth.currentUser !== null) {
        auth.currentUser.getIdToken().then((token) => {
          document.cookie = "__session=" + token + ";max-age=3600"
          setUserToken(token)
        })
      } else {
        // ADD CART?
        // TODO:: CAN ADD TO CART BUT
        if (loc.pathname.includes("/account")) {
          // CHECK IF ANON
          nav(`/signin?${returnPageKey}=${loc.pathname}`)
        }
        if (loc.pathname.includes("/cart")) {
          // CHECK IF ANON
          nav(`/signin?${returnPageKey}=${loc.pathname}`)
        }
        if (loc.pathname.includes("/admin")) {
          nav(`/signin?${returnPageKey}=${loc.pathname}`)
        }
      }
    })

    return () => {
      done()
    }
  }, [auth, loc, nav])

  return (
    <AppContext.Provider
      value={{
        user: authState,
        setUser: setAuthState,
        userToken: useToken,
        navType: navBarType,
        setNavType: setNavBarType,
      }}
    >
      <div className="App">
        <Routes>
          {/* STATIC LANDING PAGES */}
          <Route path="about" element={<About />} />
          <Route path="deck" element={<Deck />} />
          <Route path="legal" element={<Legal />} />
          <Route path="terms" element={<Terms />} />
          <Route path="discover" element={<Discover />} />
          <Route path="learn/revive/" element={<Learn />} />
          <Route path="learn/events/*" element={<LearnEvents />} />

          {/* EVERYTHING THAT HAS TO DO WITH AUTHENTICATION */}
          <Route path="signin" element={<SignIn />} />
          <Route path="register" element={<SignUp />} />
          <Route path="signout" element={<SignOut />} />
          <Route path="resetPassword" element={<ResetPassword />} />

          {/* EVERYTHING THAT HAS A NAV BAR*/}
          <Route path="*" element={<Navigate />} />
        </Routes>
      </div>
    </AppContext.Provider>
  )
}

export default App

export const Navigate: React.FC<{}> = (): JSX.Element => {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="recap" element={<Recap />} />
        <Route path="recapp" element={<Recap />} />
        <Route path="vendors" element={<Vendors />} />
        <Route path="immersed" element={<Immersed />} />

        <Route path="events/*" element={<Events />} />
        <Route path="map" element={<Map />} />
        <Route path="activate" element={<Activate />} />
        <Route path="sticker/:stickerID" element={<Sticker />} />

        <Route path="apply" element={<Apply />} />

        {/* SHOP HAS ALL ROUTS FOR PRODUCTS ETC */}
        <Route path="shop/*" element={<Shop />} />
        <Route path="asset/:posterID" element={<Asset />} />
        <Route path="cart/*" element={<Cart />} />
        <Route path="account/*" element={<Account />} />

        {/* WHERE DESIGN WORK IS DONE*/}
        <Route path="studio/*" element={<Create />} />
        <Route path="generateqr" element={<GenerateQR />} />

        {/* ADMIN */}
        <Route path="admin/*" element={<OverView />} />
        <Route path="contactus" element={<Contact />} />

        <Route path="support/*" element={<Support />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}
