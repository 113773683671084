import styles from "./index.module.css"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { firestoreDB } from "../../App"
import React from "react"
import { collection, onSnapshot } from "firebase/firestore"
import { AdminActions, AdminActionsButton } from "../shared"
import { ArtistApplication } from "schema/dist/src/user"
import { ArtistApplicationCollectionName, SaveApplication } from "../../landing/apply/apply"

export const ApplicationsList: React.FC<{}> = (): JSX.Element => {
  const [applicationItems, setApplicationItems] = useState<ArtistApplication[]>([])
  const nav = useNavigate()

  useEffect(() => {
    const docRef = collection(firestoreDB, ArtistApplicationCollectionName)
    const done = onSnapshot(docRef, (response) => {
      const data = response.docs
        .map((doc) => {
          return doc.data() as ArtistApplication
        })
        .sort((prev, next) => {
          if (prev.createdDate > next.createdDate) {
            return -1
          } else if (prev.createdDate < next.createdDate) {
            return 1
          } else {
            return 0
          }
        })
      setApplicationItems(data)
    })

    return () => {
      done()
    }
  }, [])

  return (
    <>
      <AdminActions>
        <AdminActionsButton
          onClick={() => {
            nav(`./../`)
          }}
          title={"back"}
        />
      </AdminActions>

      <div className={styles.ProductListContainer}>
        New
        {applicationItems.map((val) => {
          if (val.read === true) return <></>
          return <ApplicationItem application={val} key={val.uid} />
        })}
        Read
        {applicationItems.map((val) => {
          if (val.read === false) return <></>
          return <ApplicationItem application={val} key={val.uid} />
        })}
      </div>
    </>
  )
}

export const ApplicationItem: React.FC<{
  application: ArtistApplication
}> = ({ application }): JSX.Element => {
  return (
    <div className={styles.ApplicationItemContainer}>
      <div className={styles.ApplicationItemHeader}>
        <p>{application.name}</p>
        <p>{application.email}</p>
        <p>{application.createdDate.toDate().toLocaleDateString()}</p>
        <p>
          <a href={application.link}>{application.link}</a>
        </p>
        <button
          onClick={() => {
            const newApplication = application
            newApplication.read = !application.read
            SaveApplication(newApplication)
          }}
        >
          {application.read ? "Mark as Unread" : "Mark as read"}
        </button>
      </div>
      <p>{application.summary}</p>
    </div>
  )
}
