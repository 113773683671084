import React, { useContext } from "react"
import styles from "./../poster/index.module.css"
import { AppContext } from "../../App"
import { useNavigate, useParams } from "react-router-dom"
import ImagePlaceHolder from "../../assets/PostcardStack.jpg"
import {
  CreateStudioSession,
  FrequentList,
  IncludedList,
  IntroBody,
  IntroVideo,
  VariantContainer,
  VariantOrientation,
} from "../shared/shared"
import { addProductIDToSession, PosterPreview, useAnchorScroll, useSession, useStudioProduct } from "../poster"
const MonaVideo = ""
const studioID = "postcard"

export const Main: React.FC<{}> = (): JSX.Element => {
  const { user } = useContext(AppContext)
  const navigate = useNavigate()
  const { sessionID } = useParams<{ sessionID: string }>()
  const anchorRef = useAnchorScroll(sessionID)
  const [session, setSession] = useSession(sessionID)
  const studioProduct = useStudioProduct(studioID)

  return (
    <div className={styles.root}>
      <div className={styles.VideoTop}>
        <IntroVideo videoURL={MonaVideo} backdrop={ImagePlaceHolder}>
          <IntroBody
            title="Create your Revivar Postcard"
            subTitle="Custom Live Postcards, Wedding invitation and prints with an AR Video"
            session={session}
            onClick={() => {
              if (studioProduct?.productVariantList[0] === undefined) return
              CreateStudioSession(studioProduct?.productVariantList[0], user?.uid ?? "", studioID).then((session) => {
                setSession(session)
                navigate({
                  pathname: "/studio/" + studioID + "/" + session.uid,
                })
                anchorRef.current?.scrollIntoView({ behavior: "smooth", block: "start" })
              })
            }}
          />
        </IntroVideo>
      </div>
      <a ref={anchorRef}></a>
      <br />
      <PosterPreview
        session={session}
        setSession={setSession}
        product={studioProduct}
        sessionID={sessionID}
        studioID={studioID}
        posterType={"postcard"}
        sessionPosition={0}
      >
        <VariantContainer product={studioProduct} setStudioSession={setSession} studioSession={session} />
        <VariantOrientation
          orientation={session?.productVariant.orientation}
          setOrientation={(a) => {
            if (session === undefined) return
            const newVariant = addProductIDToSession(session, undefined, a)
            setSession(newVariant)
          }}
        />
      </PosterPreview>
      <IncludedList />
      <br />
      <FrequentList />
    </div>
  )
}
export default Main
