import { Timestamp } from "firebase/firestore"

export interface Discount {
  uid: string
  isActive: boolean
  isValid: boolean
  title: string
  percentOff: number | null
  amountOff: number | null
  created: Timestamp
  type: Discount.Type | null
  appliesTo?: Discount.AppliesTo
}

export namespace Discount {
  export interface AppliesTo {
    productID?: string[]
    eventID?: string
  }

  export type Type = "amount" | "percent"
}

export interface Coupon {
  uid: string
  isActive: boolean
  isValid: boolean
  created: Timestamp
  title: string
  discount: Discount
  maxRedemptions: number | null
  end: Timestamp | null
  timesRedeemed: number
  code: string
}

export function CreateDiscount(uid: string, created: Timestamp): Discount {
  return {
    uid: uid,
    isValid: false,
    isActive: false,
    title: "",
    percentOff: null,
    amountOff: null,
    created: created,
    type: "amount",
  }
}

export function CreateCoupon(uid: string, created: Timestamp, discount: Discount): Coupon {
  return {
    uid: uid,
    isValid: false,
    isActive: false,
    title: "",
    created: created,
    discount: discount,
    maxRedemptions: null,
    end: null,
    timesRedeemed: 0,
    code: "",
  }
}
