import { Timestamp } from "firebase/firestore"

export interface UserAddressModel {
  line1: string
  line2: string
  city: string
  country: string
  name: string
  phone: string
  email: string
  state: string
  postalCode: string
}

export function CreateDefaultAddress(): UserAddressModel {
  return {
    line1: "",
    line2: "",
    city: "",
    country: "",
    name: "",
    phone: "",
    email: "",
    state: "",
    postalCode: "",
  }
}

export interface ShippingMethod {
  uid: string
  created: Timestamp
  title: string
  detail?: string
  amount: number
  currency: string
  isValid: boolean
}

export function CreateDefaultShippingMethod(
  uid: string,
  created: Timestamp,
  title: string = "",
  currency: string = "usd"
): ShippingMethod {
  return {
    uid: uid,
    created: created,
    title: title,
    amount: 0,
    currency: currency,
    isValid: false,
  }
}
