import React, { useState } from "react"
import styles from "./apply.module.css"
import video from "../../assets/LoveShort.mp4"
import { uuidv4 } from "@firebase/util"
import { ArtistApplication, CreateDefaultArtistApplication } from "schema/dist/src/user"
import { firestoreDB } from "../../App"
import { Timestamp, doc, setDoc } from "firebase/firestore"
import { ImageGallery } from "../../shop/product/frame"

export const ArtistApplicationCollectionName = "artistApplications"

export const Main: React.FC<{}> = (): JSX.Element => {
  const [applicationEdit, setApplicationEdit] = useState<ArtistApplication>({
    uid: uuidv4(),
    name: "",
    email: "",
    link: "",
    summary: "",
    createdDate: Timestamp.now(),
  })
  const [sent, setSent] = useState(false)

  return (
    <div className={styles.root}>
      <h1 className={styles.MainTitle}>Apply to be a Revivar Artist</h1>
      <h4 className={styles.SubTitle}>
        Join 100s of Artists to create and sell premium moving pictures that can be brought to life using Augmented Reality
      </h4>

      <div className={styles.ApplyCarouselContainer}>
        <ImageGallery imageURL={""} videoURL={video} ratio={0.67} play={true} rotate={true} setPlay={() => {}} type={"poster"} />
      </div>

      <h1 className={styles.Title}>How does this work?</h1>
      <p className={styles.SubTitle}>
        Fill the form below with the necessary details and send your application. On approval you will have an upgraded account
        dashboard which will allow you to create, sell and track your moving pictures.
      </p>
      <form
        className={styles.Form}
        onSubmit={(e) => {
          e.preventDefault()
          const application = CreateDefaultArtistApplication(applicationEdit)
          application.createdDate = Timestamp.now()

          SaveApplication(application).then((application) => {
            setSent(true)
          })
        }}
      >
        {sent ? (
          <p className={styles.sent}>
            Application Sent! <br /> You will hear from us shortly!
          </p>
        ) : (
          <>
            <input
              placeholder="Name"
              onChange={(e) => {
                let newApplication = { ...applicationEdit }
                newApplication.name = e.currentTarget.value
                setApplicationEdit(newApplication)
              }}
              value={applicationEdit.name}
              required
            />
            <input
              type="email"
              placeholder="Email"
              onChange={(e) => {
                let newApplication = { ...applicationEdit }
                newApplication.email = e.currentTarget.value
                setApplicationEdit(newApplication)
              }}
              value={applicationEdit.email}
              required
            />
            <input
              placeholder="Link to portfolio or image drive"
              onChange={(e) => {
                let newApplication = { ...applicationEdit }
                newApplication.link = e.currentTarget.value
                setApplicationEdit(newApplication)
              }}
              value={applicationEdit.link}
              required
            />
            <textarea
              placeholder="Summary"
              onChange={(e) => {
                let newApplication = { ...applicationEdit }
                newApplication.summary = e.currentTarget.value
                setApplicationEdit(newApplication)
              }}
              value={applicationEdit.summary}
              required
            />

            <button>Send Application</button>
          </>
        )}
      </form>
    </div>
  )
}
export default Main

export function SaveApplication(application: ArtistApplication): Promise<ArtistApplication> {
  const ref = doc(firestoreDB, ArtistApplicationCollectionName, application.uid)

  return setDoc(ref, application).then((result) => {
    return application
  })
}
