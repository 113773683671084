import styles from "./index.module.css"
import mainStyles from "../../styles/App.module.css"
import { useContext, useEffect, useState } from "react"
import { Route, Routes, useParams } from "react-router-dom"
import { AppContext, firestoreDB, setTabTitle } from "../../App"
import React from "react"
import { collection, doc, getDoc, onSnapshot, orderBy, query, setDoc } from "firebase/firestore"
import { OrderDetails, OrderStatus } from "schema/dist/src/order"
import { Orders } from "../../account/overview"
import { OrderDetailedBreakDown, RouteParams } from "../../account/order"

export const Main: React.FC<{}> = (): JSX.Element => {
  setTabTitle("Admin Console")

  const [orderList, setOrderList] = useState<OrderDetails[]>([])
  const { user } = useContext(AppContext)

  useEffect(() => {
    if (user === null) {
      return
    }

    const posterRefParent = collection(firestoreDB, "orderDetails")
    const orderQuery = query(posterRefParent, orderBy("timestamp", "desc"))
    const done = onSnapshot(orderQuery, (snap) => {
      const CartItem = snap.docs.map((doc) => {
        return doc.data() as OrderDetails
      })

      setOrderList(CartItem)
    })

    return () => {
      done()
    }
  }, [user, firestoreDB])

  return (
    <div className={styles.root}>
      <div> Admin Shop Console</div>
      <div className={mainStyles.MainLogo} />
      <div className={styles.AccountContentContainer}>
        <Routes>
          <Route path="/" element={<Orders orderList={orderList} posterList={[]} />} />
          <Route path="/order/:orderID" element={<AdminOrderBreakDown />} />
        </Routes>
      </div>
    </div>
  )
}
export default Main
export const AdminOrderBreakDown: React.FC<{}> = (): JSX.Element => {
  const { orderID } = useParams<RouteParams>()

  const [orderDetailed, setOrderDetailed] = useState<OrderDetails>()

  useEffect(() => {
    if (orderID === undefined) return

    const orderDetailsRef = doc(firestoreDB, "orderDetails", orderID)

    getDoc(orderDetailsRef)
      .then((response) => {
        const orderFinal = response.data() as OrderDetails
        setOrderDetailed(orderFinal)
      })

      .catch((err) => {
        console.error(err)
      })
  }, [orderID])

  return (
    <>
      <OrderDetailedBreakDown orderDetailed={orderDetailed} />
      <UpdateOrderStatus orderDetailed={orderDetailed} />
      <UpdateOrderTracking orderDetailed={orderDetailed} />
    </>
  )
}

export const UpdateOrderStatus: React.FC<{
  orderDetailed: OrderDetails | undefined
}> = ({ orderDetailed }): JSX.Element => {
  const statusList: OrderStatus[] = ["initiated", "fulfilled", "shipped", "delivered", "returned", "cancelled"]

  if (orderDetailed === undefined) return <></>

  function SetOrderStatus(status: OrderStatus) {
    if (orderDetailed === undefined) return

    const orderDetailsRef = doc(firestoreDB, "orderDetails", orderDetailed.uid)

    orderDetailed.orderStatus = status
    setDoc(orderDetailsRef, orderDetailed)
      .then((response) => {
        console.log("updated", orderDetailed)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <div className={styles.UpdateOrderDetails}>
      ORDER STATUS
      <div className={styles.UpdateOrderDetailsList}>
        {statusList.map((status) => {
          let className = styles.UpdateOrderStatusButton

          if (status === orderDetailed?.orderStatus?.toLocaleLowerCase()) {
            className += " " + styles.UpdateOrderStatusButtonSelected
          }
          return (
            <div
              key={status}
              className={className}
              onClick={() => {
                SetOrderStatus(status)
              }}
            >
              {status}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const UpdateOrderTracking: React.FC<{
  orderDetailed: OrderDetails | undefined
}> = ({ orderDetailed }): JSX.Element => {
  const [trackingNo, setTrackingNo] = useState(orderDetailed?.shippingTrackingNo ?? "")

  useEffect(() => {
    if (orderDetailed === undefined) return
    setTrackingNo(orderDetailed.shippingTrackingNo)
  }, [orderDetailed])

  if (orderDetailed === undefined) return <></>

  const trackingProvides: string[] = ["usps", "fedex"]

  const orderDetailsRef = doc(firestoreDB, "orderDetails", orderDetailed.uid)
  function SetShippingProvider(prov: string) {
    if (orderDetailed === undefined) return <></>

    orderDetailed.shippingProvider = prov
    setDoc(orderDetailsRef, orderDetailed)
      .then(() => {
        console.log("updated", orderDetailed)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  function SetTrackingNumber(tracking: string) {
    if (orderDetailed === undefined) return <></>

    orderDetailed.shippingTrackingNo = tracking
    setDoc(orderDetailsRef, orderDetailed)
      .then(() => {
        console.log("updated", orderDetailed)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <div className={styles.UpdateOrderDetails}>
      UPDATE TRACKING
      <div className={styles.UpdateOrderDetailsList}>
        <input
          className={styles.UpdateOrderStatusButton}
          onInput={(e) => {
            setTrackingNo(e.currentTarget.value)
          }}
          value={trackingNo}
        ></input>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            SetTrackingNumber(trackingNo)
          }}
        >
          Update
        </div>
      </div>
      <div className={styles.UpdateOrderDetailsList}>
        {trackingProvides.map((provider) => {
          let className = styles.UpdateOrderStatusButton

          if (provider === orderDetailed?.shippingProvider?.toLocaleLowerCase()) {
            className += " " + styles.UpdateOrderStatusButtonSelected
          }
          return (
            <div
              key={provider}
              className={className}
              onClick={() => {
                SetShippingProvider(provider)
              }}
            >
              {provider}
            </div>
          )
        })}
      </div>
    </div>
  )
}
