import { useEffect, useState } from "react"
import styles from "./styles/home.module.css"
import { collection, getDocs, query, where } from "firebase/firestore"
import { firestoreDB } from "../App"
import { Vendor } from "schema/dist/src/user"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { vendorsCollectionName } from "../admin/vendors/create"

export const Main: React.FC<{}> = (): JSX.Element => {
  const [vendorDetails, setVendorDetails] = useState<Vendor[]>([])
  const [sort, setSort] = useState<"name" | "rating" | "distance">("name")

  useEffect(() => {
    const userCol = query(collection(firestoreDB, vendorsCollectionName))
    getDocs(userCol)
      .then((userDocs) => {
        const usersProfileData = userDocs.docs
          .map((doc) => {
            return doc.data() as Vendor
          })
          .sort(sort === "name" ? ByName : sort === "rating" ? ByRating : ByDistance)

        setVendorDetails(usersProfileData)
      })
      .catch((error) => {
        console.log("Error getting document:", error)
      })
  }, [sort])

  return (
    <div className={styles.vendorsRoot}>
      <h1 className={styles.MainTitle}>Revivar Partners</h1>
      <h4 className={styles.Description}>List of our partners that are well versed to revive your event or art</h4>
      <div className={styles.vendorSort}>
        <h4 className={styles.Description}>Sort by:</h4>
        <p onClick={() => setSort("name")} className={sort === "name" ? styles.ActiveVendorSort : ""}>
          Name
        </p>
        <p onClick={() => setSort("rating")} className={sort === "rating" ? styles.ActiveVendorSort : ""}>
          Rating
        </p>
        <p onClick={() => setSort("distance")} className={sort === "distance" ? styles.ActiveVendorSort : ""}>
          Distance to you
        </p>
      </div>
      <div className={styles.vendorsList}>
        {vendorDetails.map((vendorDetail) => (
          <VendorItem key={vendorDetail.uid} vendor={vendorDetail} />
        ))}
      </div>
    </div>
  )
}

export default Main

export const VendorItem: React.FC<{ vendor: Vendor; onClick?: () => void }> = ({ vendor, onClick }): JSX.Element => {
  const [actualURL, setActualURL] = useState("")

  useEffect(() => {
    const storage = getStorage()

    if (vendor.profilePictureUrl.includes("gs://")) {
      const imageStorageRef = ref(storage, vendor.profilePictureUrl)
      getDownloadURL(imageStorageRef).then((downloadURL) => {
        setActualURL(downloadURL)
      })
    } else {
      setActualURL(vendor.profilePictureUrl)
    }
  }, [vendor])

  //on component render, fetch numver of events
  // Not needed right now
  // const [eventsCount, setEventsCount] = useState(0)
  // useEffect(() => {
  //   const docRef = collection(firestoreDB, "events")

  //   getDocs(query(docRef, where("creatorID", "==", vendor.uid)))
  //     .then((snap) => {
  //       const fetchedEvents = snap.docs.map((doc) => {
  //         return doc.data() as Event
  //       })
  //       setEventsCount(fetchedEvents.length)
  //     })
  //     .catch((error) => {
  //       console.log("Error getting document:", error)
  //     })
  // }, [vendor])

  return (
    <div className={styles.vendorsItem}>
      <div className={styles.vendorImage} style={{ backgroundImage: "url(" + actualURL + ")" }} onClick={onClick} />
      <div className={styles.vendorDetails}>
        <h3>{vendor.userName}</h3>
        <p>{vendor.type ?? "-"}</p>
        <p>{vendor.location !== "" ? vendor.location : "-"}</p>
        {/* <p>Number of Events: {eventsCount}</p> */}
        <StarRating stars={vendor.rating} />
      </div>
      <div className={styles.vendorIcons}>
        <div
          className={styles.vendorIconLink}
          onClick={() => {
            window.location.href = vendor.link
          }}
        ></div>
        {/* TODO: Add location button functionality
        https://revivar.atlassian.net/jira/software/projects/RD/boards/2?selectedIssue=RD-188 */}
        {/* <div className={styles.vendorIconLocation}></div> */}
      </div>
    </div>
  )
}

const StarRating: React.FC<{ stars: number }> = ({ stars }): JSX.Element => {
  return (
    <div className={styles.StarRating}>
      <div className={stars >= 1 ? styles.Star : styles.UnStar}></div>
      <div className={stars >= 2 ? styles.Star : styles.UnStar}></div>
      <div className={stars >= 3 ? styles.Star : styles.UnStar}></div>
      <div className={stars >= 4 ? styles.Star : styles.UnStar}></div>
      <div className={stars >= 5 ? styles.Star : styles.UnStar}></div>
    </div>
  )
}

// Sorting algorithms

export const ByName: (prev: Vendor, next: Vendor) => number = (prev, next) => {
  if (prev.userName < next.userName) {
    return -1
  } else if (prev.userName > next.userName) {
    return 1
  } else {
    return 0
  }
}

export const ByRating: (prev: Vendor, next: Vendor) => number = (prev, next) => {
  if (prev.rating < next.rating) {
    return -1
  } else if (prev.rating > next.rating) {
    return 1
  } else {
    return 0
  }
}

export const ByDistance: (prev: Vendor, next: Vendor) => number = (prev, next) => {
  // TOODO : Sort by distance
  if (prev.addedDate < next.addedDate) {
    return -1
  } else if (prev.addedDate > next.addedDate) {
    return 1
  } else {
    return 0
  }
}
