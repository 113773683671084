import styles from "./index.module.css"
import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { doc, getDoc } from "firebase/firestore"
import { AppContext, firestoreDB, setTabTitle } from "../../App"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { Product, ProductVariant } from "schema/dist/src/product"
import loadingImage from "./../../assets/loadingImage.png"
import { ImageGallery, ImageGalleryProps, PosterViewState } from "./frame"
import { ValidateUserToAnon, returnPageKey } from "../../auth"
import { User } from "firebase/auth"
import { AddToCart } from "../../cart/cart"
import { SessionType } from "schema/dist/src/studio"

type RouteParams = {
  productID: string
}

export function SizeToDimensions(size: string): number | undefined {
  const stringRation = size.replaceAll(" ", "").replaceAll('"', "").split("x")

  if (stringRation.length === 2) {
    const top = parseFloat(stringRation[0])
    const bottom = parseFloat(stringRation[1])

    if (isNaN(top) || isNaN(bottom)) return
    return Number((top / bottom).toFixed(2))
  }

  return
}
export const BackURLKey = "returnURL"
export const Main: React.FC<{}> = (): JSX.Element => {
  const { productID } = useParams<RouteParams>()
  const [productData, setProductData] = useState<Product>()
  const search = useLocation().search
  const nav = useNavigate()
  const [imageURL, setImageURL] = useState(loadingImage)
  const [videoURL, setVideoURL] = useState("")
  const [playVideo, setPlayVideo] = useState(false)
  const [viewState, setViewState] = useState<PosterViewState>("normal")
  const [ratio, setRatio] = useState(0.8)
  const [selectedVariant, setSelectedVariant] = useState<ProductVariant>()
  const [selectedSlide, setSelectedSlide] = useState(0)
  const [selectedSlideURL, setSelectedSlideURL] = useState("")
  const backURL = new URLSearchParams(search).get(returnPageKey) ?? ""
  const { user } = useContext(AppContext)
  const [muted, setMuted] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (productID === undefined) return

    const docRef = doc(firestoreDB, "shopProducts", productID ?? "")
    getDoc(docRef).then((docSnap) => {
      //TODO :: USE PRODUCT NAME
      if (docSnap.exists()) {
        const product = docSnap.data() as Product
        setTabTitle(product.title)
        setProductData(product)
      } else {
        console.log("No such document!")
      }
    })
  }, [productID])

  useEffect(() => {
    if (productData === undefined) return

    const storage = getStorage()
    getDownloadURL(ref(storage, productData.imageLDURL))
      .then((url) => {
        setImageURL(url)
      })
      .catch((error) => {
        console.error(error)
      })

    if (productData.typeId === 2) {
      getDownloadURL(ref(storage, productData.videoURL))
        .then((url) => {
          setVideoURL(url)
        })
        .catch((error) => {
          console.error(error)
        })
    }

    const looseRatio = selectedVariant?.size ?? ""
    const ratio = SizeToDimensions(looseRatio)

    if (ratio !== undefined) {
      setRatio(ratio)
    }
  }, [productData, selectedVariant])

  const productType = selectedVariant?.sessionType === "none" ? undefined : selectedVariant?.sessionType ?? "poster"
  return (
    <div className={styles.root}>
      <div
        className={styles.BackButtonContainer}
        onClick={() => {
          if (backURL === "") {
            nav("/shop")
          } else {
            nav(backURL)
          }
        }}
      >
        <div className={styles.BackButtonIcon} onClick={() => {}}></div>
        <div className={styles.BackButton}>Back</div>
      </div>
      <div className={styles.MainContentContainer}>
        <div className={styles.PosterContentContainer}>
          <PosterContainer
            imageURL={imageURL}
            videoURL={videoURL}
            ratio={ratio}
            play={playVideo}
            selectedSlide={selectedSlide}
            selectedSlideURL={selectedSlideURL}
            productData={productData}
            rotate
            viewState={viewState}
            setPlay={setPlayVideo}
            muted={muted}
            type={productType}
            orientation={selectedVariant?.orientation ?? "vertical"}
          />
          <PlayPosterContainer
            isHidden={productData?.typeId !== 2}
            onPlay={setPlayVideo}
            onViewState={setViewState}
            viewState={viewState}
            play={playVideo}
            muted={muted}
            setMuted={setMuted}
          />

          <PosterRelatedContainer
            product={productData}
            user={user}
            showSelection={(a, b) => {
              setSelectedSlide(a)
              setSelectedSlideURL(b ?? loadingImage)
            }}
          />
        </div>
        <ProductInfo
          product={productData}
          user={user}
          setSelectedVariant={setSelectedVariant}
          selectedVariant={selectedVariant}
        />
      </div>
    </div>
  )
}
export default Main

interface PosterContainerProps extends ImageGalleryProps {
  productData: Product | undefined
  selectedSlide: number
  selectedSlideURL: string
}

export const PosterContainer: React.FC<PosterContainerProps> = ({
  imageURL,
  videoURL,
  ratio,
  play,
  productData,
  selectedSlide,
  selectedSlideURL,
  viewState,
  rotate,
  setPlay,
  muted,
  type,
  orientation,
}): JSX.Element => {
  const [mainImageURl, setMainImageURl] = useState(loadingImage)

  useEffect(() => {
    if (productData === undefined) return
    if (selectedSlideURL !== "") {
      setMainImageURl(selectedSlideURL)
      return
    }

    const storage = getStorage()
    let imageRef = productData.imageLDURL

    if (selectedSlide > 0) {
      imageRef = productData.productImagesURL[selectedSlide - 1]
    }

    getDownloadURL(ref(storage, imageRef))
      .then((url) => {
        setMainImageURl(url)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [productData, selectedSlide, selectedSlideURL])

  if (selectedSlide !== 0 || productData?.typeId === 3) {
    return (
      <div className={styles.PosterContainer}>
        <img src={mainImageURl} alt={"container"} placeholder={loadingImage} />
      </div>
    )
  }

  return (
    <div className={styles.PosterContainer}>
      <ImageGallery
        imageURL={imageURL}
        videoURL={videoURL}
        ratio={ratio}
        play={play}
        rotate={rotate}
        viewState={viewState}
        setPlay={setPlay}
        type={type}
        muted={muted}
        orientation={orientation}
      />
    </div>
  )
}

interface PosterRelatedContainerProps {
  product: Product | undefined
  user: User | null
  showSelection?: (a: number, b?: string) => void
  isDarkMode?: boolean
  isVisible?: boolean
}

export const PosterRelatedContainer: React.FC<PosterRelatedContainerProps> = ({
  product,
  showSelection = () => {},
}): JSX.Element => {
  const [imageURLs, setImageURLs] = useState<string[]>([])

  useEffect(() => {
    if (product === undefined) return

    const storage = getStorage()
    let finalURLs: string[] = []
    const urlList = [product.imageLDURL, ...product.productImagesURL]
    const allURLRequest = urlList.map((url) => {
      return getDownloadURL(ref(storage, url))
        .then((url) => {
          return fetch(url)
        })
        .then((res) => {
          return res.blob()
        })
        .then((data) => {
          return URL.createObjectURL(data)
        })
    })

    Promise.all(allURLRequest).then((urls) => {
      setImageURLs(urls)
      finalURLs = urls
    })

    return () => {
      finalURLs.forEach((url) => {
        URL.revokeObjectURL(url)
      })
    }
  }, [product])

  return (
    <div className={styles.PosterRelatedContainer}>
      {imageURLs.map((imageURL, i) => {
        return (
          <img
            key={imageURL}
            src={imageURL}
            onClick={() => {
              showSelection(i + 1, imageURL)
            }}
            alt="related"
          />
        )
      })}
    </div>
  )
}

interface ProductInfoProps extends PosterRelatedContainerProps {
  setSelectedVariant: (variant: ProductVariant | undefined) => void
  selectedVariant: ProductVariant | undefined
}

export const ProductInfo: React.FC<ProductInfoProps> = ({ product, user, setSelectedVariant, selectedVariant }): JSX.Element => {
  const [shareText, setShareText] = useState("Share")
  const [addText, setAddText] = useState("Add to Cart")
  const nav = useNavigate()

  return (
    <div className={styles.PosterInfoContainer}>
      <div className={styles.PosterInfoName}>
        {product?.title ?? "Name"}
        <div
          className={styles.PosterInfoShare}
          onClick={() => {
            navigator.clipboard.writeText(window.location.toString())
            setShareText("Copied to clipboard")
          }}
        ></div>
        <span>{shareText}</span>
      </div>

      {product?.productDescription !== undefined && product?.productDescription !== "" ? (
        <div className={styles.PosterInfoDescription}>
          {/* <b>Description</b> */}
          {product?.productDescription ?? "Description"}
        </div>
      ) : null}

      <AttributesList nftLink={product?.nftLink} typeId={product?.typeId} />
      <ProductActionsContainer
        product={product}
        user={user}
        selectedVariant={selectedVariant}
        setSelectedVariant={setSelectedVariant}
        mainText={addText}
        setMainText={setAddText}
        onMainActionClick={(product, variant, count) => {
          if ((product.studioID ?? "") !== "") {
            nav("/studio/" + product.studioID)
            return
          }

          ValidateUserToAnon(user)
            .then((actualUser) => {
              return AddToCart(actualUser.uid, product, variant, count)
            })
            .then(() => {
              setAddText("Added to Cart")
              nav("/cart")
            })
            .catch(() => {})
        }}
      />
      <IncludedList product={product} user={user} />
    </div>
  )
}

interface ProductActionsContainerProps extends PosterRelatedContainerProps {
  mainText: string
  setMainText: (value: string) => void
  showMainButton?: boolean
  onMainActionClick: (product: Product, variant: ProductVariant, count: number) => void
  editItemText?: string
  setEditItemText?: (value: string) => void
  onEditItemClick?: (product: Product, variant: ProductVariant, count: number) => void
  visibleVariantUIDs?: string[]
  defaultVariant?: string
  ignoreDefault?: boolean
  isVisible?: boolean
  setSelectedVariant: (variant: ProductVariant | undefined) => void
  selectedVariant: ProductVariant | undefined
}

export const ProductActionsContainer: React.FC<ProductActionsContainerProps> = ({
  product,
  setSelectedVariant,
  selectedVariant,
  mainText,
  setMainText,
  onMainActionClick,
  editItemText = "",
  setEditItemText = () => {},
  onEditItemClick = () => {},
  visibleVariantUIDs,
  defaultVariant,
  isVisible = true,
  ignoreDefault = false,
  showMainButton = true,
}): JSX.Element => {
  const [count, setCount] = useState(1)
  const [filterSessionType, setFilterSessionType] = useState<SessionType | "none">("none")
  const countList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  let originalList =
    product?.productVariantList.sort((a, b) => {
      return a.price < b.price ? -1 : 1
    }) ?? []

  if (visibleVariantUIDs !== undefined) {
    originalList = originalList.filter((variant) => {
      const isFound =
        visibleVariantUIDs.find((v) => {
          return v === variant.uid
        }) !== undefined

      return isFound
    })
  }

  let variantList = [...originalList]

  let defaultVal = variantList?.find((variant, i) => {
    return variant.uid === defaultVariant
  })

  if (defaultVal === undefined) {
    defaultVal = variantList[0]
  }

  const sessionTypeList = new Map<SessionType | "none", true>()

  variantList.forEach((varian) => {
    if (varian.sessionType === undefined) return
    sessionTypeList.set(varian.sessionType, true)
  })

  const sessionTypeListArray: SessionType[] = []
  sessionTypeList.forEach((_, type) => {
    if (type === "none") return
    sessionTypeListArray.push(type)
  })

  if (filterSessionType !== "none") {
    variantList =
      originalList.filter((variant) => {
        return variant.sessionType === filterSessionType
      }) ?? []
  }

  useEffect(() => {
    if (defaultVal === undefined) return
    if (ignoreDefault) return

    setSelectedVariant(defaultVal)
    setFilterSessionType(defaultVal.sessionType ?? "none")
  }, [defaultVal?.uid, ignoreDefault])

  useEffect(() => {
    if (selectedVariant === undefined) return
    setFilterSessionType(selectedVariant.sessionType ?? "none")
    setSelectedVariant(selectedVariant)
  }, [selectedVariant])

  if (product === undefined || !isVisible) {
    return <></>
  }

  let varianTitle = selectedVariant?.title ?? ""
  let varianLabel = "Selected item: "
  if (varianTitle === "") {
    varianTitle = selectedVariant?.size ?? ""
    varianLabel = "Selected size: "
  }

  return (
    <div className={styles.PosterInfoActionsContainer}>
      <div className={styles.PosterInfoActionsPrice}>
        <div>Price: {selectedVariant && <b>${((selectedVariant?.price ?? 0) / 100).toFixed(2)}</b>}</div>

        <div className={styles.PosterInfoActionsQuantity}>
          Qty:
          <select
            onChange={(e) => {
              let count = parseInt(e.currentTarget.value, 10)
              //MAX ORDER COUNT IS 100
              if (isNaN(count) || count >= 100 || count === 0) {
                count = 1
              }
              setCount(count)
            }}
            defaultValue={count}
          >
            {countList.map((number) => {
              return (
                <option key={number} value={number}>
                  {number}
                </option>
              )
            })}
          </select>
        </div>
      </div>

      {sessionTypeListArray.length > 0 ? (
        <>
          <div className={styles.PosterInfoActionsSizePreview}>
            Select Type: <b>{filterSessionType}</b>
          </div>
          <div className={styles.PosterInfoActionsSizeList}>
            {sessionTypeListArray.map((variant, index) => {
              let className = styles.PosterInfoActionsSizeButton

              if (filterSessionType === variant) {
                className += " " + styles.PosterInfoActionsSizeSelected
              }

              return (
                <div
                  className={className}
                  key={variant}
                  onClick={() => {
                    setFilterSessionType(variant)
                    setSelectedVariant(undefined)
                  }}
                >
                  {variant}
                </div>
              )
            })}
          </div>
        </>
      ) : null}

      {variantList.length > 0 ? (
        <>
          <div className={styles.PosterInfoActionsSizePreview}>
            {varianLabel} <b>{varianTitle}</b>
          </div>
          <div className={styles.PosterInfoActionsSizeList}>
            {variantList.map((variant, index) => {
              let className = styles.PosterInfoActionsSizeButton

              if (selectedVariant?.uid === variant.uid) {
                className += " " + styles.PosterInfoActionsSizeSelected
              }

              let varianTitle = variant?.title ?? ""

              if (varianTitle === "") {
                varianTitle = variant?.size ?? ""
              }

              return (
                <div
                  className={className}
                  key={variant.uid}
                  onClick={() => {
                    setSelectedVariant(variant)
                  }}
                >
                  {varianTitle}
                </div>
              )
            })}
          </div>
        </>
      ) : variantList.length === 1 ? null : null}

      {selectedVariant !== undefined ? (
        <div className={styles.PosterInfoActionsAddToCartContainer}>
          {showMainButton ? (
            <div
              className={styles.PosterInfoActionsAddToCart}
              onClick={() => {
                onMainActionClick(product, selectedVariant, count)
              }}
            >
              {mainText}
            </div>
          ) : null}

          {editItemText === "" ? null : (
            <div
              className={styles.PosterInfoActionsEdit}
              onClick={() => {
                onEditItemClick(product, selectedVariant, count)
              }}
            >
              {editItemText}
            </div>
          )}
        </div>
      ) : null}
    </div>
  )
}

interface AttributesListProps {
  typeId?: 1 | 2 | 3
  nftLink?: string
  darkMode?: boolean
}

export const AttributesList: React.FC<AttributesListProps> = ({ typeId, nftLink = "", darkMode }): JSX.Element => {
  if (typeId === 3 && nftLink === "") {
    return <></>
  }

  let className = styles.PosterInfoAttributes

  if (darkMode) {
    className += " " + styles.PosterInfoAttributesDark
  }

  return (
    <div className={className}>
      <b>Attributes </b>
      {typeId === 2 ? (
        <div className={styles.PosterInfoAttributeContainer}>
          <div className={styles.LiveEnabledIcon}></div>
          <div className={styles.PosterAttributeInfo}>
            <b>AR ENABLED</b>
            Anyone can see the video using our app or website
          </div>
          <div className={styles.RevivarBlackIcon}></div>
        </div>
      ) : null}
      {typeId === 1 ? (
        <div className={styles.PosterInfoAttributeContainer}>
          <div className={styles.StillEnabledIcon}></div>
          <div className={styles.PosterAttributeInfo}>
            <b>STILL POSTER </b>
            This poster has no AR video attached to it
          </div>
        </div>
      ) : null}
      {nftLink !== "" ? (
        <div
          className={styles.PosterInfoAttributeContainer}
          onClick={() => {
            window.open(nftLink)
          }}
        >
          <div className={styles.NFTEnabledIcon}></div>
          <div className={styles.PosterAttributeInfo}>
            <b>NFT LINKED</b>
            This poster has an NFT attached to it
          </div>
          <div className={styles.OpenLinkIcon}></div>
        </div>
      ) : null}
    </div>
  )
}

export const IncludedList: React.FC<PosterRelatedContainerProps> = ({ product, isDarkMode }): JSX.Element => {
  let className = styles.PosterInfoAttributes
  if (isDarkMode) {
    className += " " + styles.PosterInfoAttributesDark
  }

  if (product?.typeId === 3) {
    return <></>
  }

  return (
    <div className={className}>
      <b>Whats included </b>
      <div className={styles.PosterInfoActionsIncludedContainer}>
        <IncludedListItem imageClass={styles.FilePictureIcon} title={"Print of the picture you ordered"} isVisible />
        <IncludedListItem imageClass={styles.TextureIcon} title={"Premium Matte Texture"} isVisible />
        <IncludedListItem imageClass={styles.NotNopeIcon} title={"Frames are NOT included"} isVisible />
        <IncludedListItem imageClass={styles.QRCodeIcon} title={"QR code / NFC sticker"} isVisible={product?.typeId === 2} />
      </div>
    </div>
  )
}

export const IncludedListItem: React.FC<{
  imageClass: string
  title: string
  isVisible: boolean
}> = ({ imageClass, title, isVisible }): JSX.Element => {
  if (!isVisible) {
    return <></>
  }
  return (
    <div className={styles.PosterInfoActionsIncluded}>
      <div className={styles.LiveEnabledIcon + " " + imageClass}></div>
      <div className={styles.PosterAttributeInfo}>{title}</div>
    </div>
  )
}

interface PlayPosterContainerProps {
  onViewState: (a: PosterViewState) => void
  viewState: PosterViewState
  play: boolean
  onPlay: (a: boolean) => void
  isHidden?: boolean
  isDarkMode?: boolean
  muted: boolean
  setMuted: (a: boolean) => void
  showLike?: boolean
  like?: boolean
  setLike?: () => void
}

export const PlayPosterContainer: React.FC<PlayPosterContainerProps> = ({
  isHidden,
  onPlay,
  onViewState,
  viewState,
  play,
  isDarkMode,
  muted,
  setMuted,
  showLike = false,
  like,
  setLike,
}): JSX.Element => {
  if (isHidden) {
    return <></>
  }

  let className = styles.PlayPosterContainer
  let ExpandButtonClass = styles.PlayPosterContainer
  let MutedButtonClass = styles.PlayPosterContainer
  let LikeButtonClass = styles.LikePosterContainer

  if (isDarkMode) {
    className += " " + styles.PausePosterDarkMode
    ExpandButtonClass += " " + styles.PausePosterDarkMode
    MutedButtonClass += " " + styles.PausePosterDarkMode
  }

  if (play) {
    className += " " + styles.PausePosterContainer
  }

  ExpandButtonClass += " " + styles.ExpandButton

  if (viewState === "expand") {
    ExpandButtonClass += " " + styles.CollapseButton
  } else if (viewState === "compare") {
    ExpandButtonClass += " " + styles.CompareButton
  }

  MutedButtonClass += " " + styles.MutedButton
  if (!muted) {
    MutedButtonClass += " " + styles.SoundUpButton
  }

  if (like) {
    LikeButtonClass += " " + styles.UnlikePosterContainer
  }

  return (
    <div className={styles.PlayButtonStack}>
      <div
        className={className}
        onClick={() => {
          onPlay(!play)
        }}
      ></div>
      <div
        className={ExpandButtonClass}
        onClick={() => {
          if (viewState === "expand") {
            onViewState("compare")
          } else if (viewState === "compare") {
            onViewState("normal")
          } else {
            onViewState("expand")
          }
        }}
      />
      <div
        className={MutedButtonClass}
        onClick={() => {
          setMuted(!muted)
        }}
      />
      {showLike && <div className={LikeButtonClass} onClick={setLike} />}
    </div>
  )
}
