import styles from "./index.module.css"

export const PrinterMain: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.PrinterMain}>
      <SupportDeviceInfo
        tile="Supported Printer"
        price={99.99}
        image={styles.SupportDeviceImage}
        url="https://www.amazon.com/Canon-SELPHY-CP1500-Compact-Printer/dp/B0BF6T86WD"
      />
      <SupportDeviceInfo
        tile="Supported Printer Paper"
        price={34.99}
        image={styles.SupportDevicePaperImage}
        url="https://www.amazon.com/Canon-RP-108-Compatible-SELPHY-CP1200/dp/B00HQE9NM4"
      />
    </div>
  )
}

export default PrinterMain

interface SupportDeviceInfoProps {
  tile: string
  price: number
  image: string
  url: string
}
export const SupportDeviceInfo: React.FC<SupportDeviceInfoProps> = ({ tile, price, image, url }): JSX.Element => {
  return (
    <div className={styles.SupportDeviceInfo}>
      {tile}
      <div className={image} />${price}
      <button
        className={styles.SupportDeviceButton}
        onClick={() => {
          window.open(url)
        }}
      >
        View on Amazon
      </button>
    </div>
  )
}
