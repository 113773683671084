import styles from "./index.module.css"
import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { firestoreDB } from "../../App"
import React from "react"
import { doc, onSnapshot } from "firebase/firestore"
import { DeleteShippingMethod, shippingCollectionName, UpdateShippingMethod } from "./shipping"
import { AdminActions, AdminActionsButton } from "../shared"
import { ShippingMethod } from "schema/dist/src/address"

type RouteParams = {
  shippingID: string
}

export const DiscountDetails: React.FC<{}> = (): JSX.Element => {
  //get the event id from the params of the page/route
  const { shippingID } = useParams<RouteParams>()

  const [shippingMethod, setShippingMethod] = useState<ShippingMethod>()
  const nav = useNavigate()

  //add onSnapshot listener to update the event and eventmedias if there are changes
  useEffect(() => {
    if (shippingID === undefined) return

    const eventDetailsRef = doc(firestoreDB, shippingCollectionName, shippingID)

    //fetch the event
    const done = onSnapshot(eventDetailsRef, (response) => {
      const event = response.data() as ShippingMethod
      setShippingMethod(event)
    })

    return () => {
      done()
    }
  }, [shippingID])

  if (shippingMethod === undefined) {
    return <>not available</>
  }

  return (
    <>
      <AdminActions>
        <AdminActionsButton
          onClick={() => {
            nav(`../`)
          }}
          title={"BACK"}
        />
      </AdminActions>
      <CreateDiscountDetails shippingMethod={shippingMethod} />
    </>
  )
}

export const CreateDiscountDetails: React.FC<{ shippingMethod: ShippingMethod }> = ({ shippingMethod }): JSX.Element => {
  const [shippingMethodEdit, setShippingMethodEdit] = useState<ShippingMethod>(shippingMethod)
  const nav = useNavigate()

  useEffect(() => {
    setShippingMethodEdit(shippingMethod)
  }, [shippingMethod])

  return (
    <div className={styles.CreateDiscountDetails}>
      {shippingMethodEdit.isValid ? <h1> SHIPPING METHOD</h1> : <h1> CREATE SHIPPING METHOD</h1>}
      <div> TITLE</div>
      <input
        type="text"
        onChange={(e) => {
          let newShipping: ShippingMethod = { ...shippingMethodEdit }
          newShipping.title = e.currentTarget.value
          setShippingMethodEdit(newShipping)
        }}
        value={shippingMethodEdit.title}
        disabled={shippingMethodEdit.isValid}
      />
      <div> AMOUNT</div>
      <input
        type="number"
        onChange={(e) => {
          let newShipping = { ...shippingMethodEdit }

          let numString = parseFloat(e.currentTarget.value)
          if (isNaN(numString) || numString < 0) {
            newShipping.amount = 0
            numString = 0
          }

          newShipping.amount = numString
          setShippingMethodEdit(newShipping)
        }}
        value={shippingMethodEdit.isValid ? shippingMethodEdit.amount / 100 : shippingMethodEdit.amount}
        disabled={shippingMethodEdit.isValid}
      />
      {!shippingMethodEdit.isValid ? (
        <div
          className={styles.ActionButton}
          onClick={() => {
            let newDiscount = { ...shippingMethodEdit }
            newDiscount.isValid = true
            UpdateShippingMethod(newDiscount).then((discount) => {
              console.log(discount)
            })
          }}
        >
          CREATE
        </div>
      ) : (
        <div
          className={styles.ActionButton}
          onClick={() => {
            DeleteShippingMethod(shippingMethodEdit).then((discount) => {
              nav(`./../`)
            })
          }}
        >
          DELETE
        </div>
      )}
    </div>
  )
}
