import styles from "./index.module.css"
import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Event, EventMedia } from "schema/dist/src/event"
import { collection, doc, onSnapshot, query, where } from "firebase/firestore"
import { AppContext, firestoreDB } from "../App"
import {
  EditActions,
  EditEventMainImage,
  EditInputDate,
  EditInputText,
  EditMediaActions,
  EditMediaProduct,
} from "../admin/event/edit"
import { Product } from "schema/dist/src/product"
import { AssetEdit } from "../admin/event/media"

export const eventMediaFolderName = "eventMedia"
export const eventMediaAssetsFolderName = "eventAssets"
export const eventMediaRelatedImagesFolderName = "relatedImages"

type RouteParams = {
  eventID: string
  assetID: string
}

export const EventOverviewEdit: React.FC<{}> = (): JSX.Element => {
  //get the event id from the params of the page/route
  const { eventID } = useParams<RouteParams>()
  const { user } = useContext(AppContext)
  const nav = useNavigate()

  const [selectedEvent, setSelectedEvent] = useState<Event>()
  const [showOverviewButton, setShowOverviewButton] = useState(false)

  //add onSnapshot listener to update the event and eventmedias if there are changes
  useEffect(() => {
    if (eventID === undefined || user === null) return

    //fetch the event
    const docRef = collection(firestoreDB, "events")
    const done = onSnapshot(query(docRef, where("uid", "==", eventID), where("creatorID", "==", user?.uid)), (response) => {
      const event = response.docs.map((doc) => {
        return doc.data() as Event
      })

      if (event[0] === undefined) {
        nav("/account/events")
      } else {
        setSelectedEvent(event[0])
      }
    })

    return () => {
      done()
    }
  }, [eventID, user])

  useEffect(() => {
    if (selectedEvent === undefined) return

    const showEdit =
      selectedEvent.title === "" ||
      selectedEvent.selectedVariants === undefined ||
      selectedEvent.selectedVariants.length === 0 ||
      selectedEvent.defaultSelectedVariant === undefined ||
      selectedEvent.defaultSelectedVariant === ""

    if (showEdit) {
      setShowOverviewButton(false)
    } else {
      setShowOverviewButton(true)
    }
  }, [selectedEvent])

  if (!selectedEvent || user === null) return <></>

  return (
    <div className={styles.EventOverviewContainer}>
      <div className={styles.EventAssetEditActionButtonsContainer}>
        {showOverviewButton ? (
          <button className={styles.OverviewActionButton} onClick={() => nav(-1)}>
            Event Overview
          </button>
        ) : (
          <button className={styles.OverviewActionButton} onClick={() => nav("/account/events")}>
            Back
          </button>
        )}
        <EditActions event={selectedEvent} />
      </div>
      <div className={styles.EditEventContainer}>
        <EditEventMainImage event={selectedEvent} useIcon={true} />
        <div className={styles.EditUserEventContainer}>
          <EditInputText event={selectedEvent} placeholder="Event Name" fieldType="title" actionButton="Save Title" />
          <EditInputText event={selectedEvent} placeholder="Event Description" fieldType="description" actionButton="Save Desc" />
          <EditInputText event={selectedEvent} placeholder="keywords" fieldType="keywords" actionButton="Save Keywords" />
          <EditInputText event={selectedEvent} placeholder="Learn More Link" fieldType="url" actionButton="Save Link" />
          <EditMediaProduct event={selectedEvent} />
          <EditInputDate event={selectedEvent} actionButton={"Save Date"} />
        </div>
      </div>
      {/* <div className={styles.ReviewRelatedImageUser}>
        {selectedEvent.relatedImages.map((image) => (
          <EditRelatedImage image={image} event={selectedEvent} key={image.uid} />
        ))}
      </div> */}
    </div>
  )
}

export const EventAssetEdit: React.FC<{}> = (): JSX.Element => {
  //get the event id from the params of the page/route
  const { eventID, assetID: mediaID } = useParams<RouteParams>()

  const [eventMedia, setEventMedia] = useState<EventMedia>()
  const [selectedEvent, setSelectedEvent] = useState<Event>()
  const [product, setProduct] = useState<Product>()

  const { user } = useContext(AppContext)
  const nav = useNavigate()

  useEffect(() => {
    if (eventID === undefined || user === null) return
    const eventDetailsRef = doc(firestoreDB, "events", eventID)
    const eventMediaCol = collection(eventDetailsRef, "medias")
    const eventMediaRef = doc(eventMediaCol, mediaID)

    //fetch the event
    const docRef = collection(firestoreDB, "events")
    const done = onSnapshot(query(docRef, where("uid", "==", eventID), where("creatorID", "==", user.uid)), (response) => {
      const event = response.docs.map((doc) => {
        return doc.data() as Event
      })
      if (event[0] !== undefined) {
        setSelectedEvent(event[0])
      } else {
        nav("/account/events")
      }
    })

    //fetch the media
    const eventDone = onSnapshot(eventMediaRef, (response) => {
      const event = response.data() as EventMedia
      setEventMedia(event)
    })

    return () => {
      done()
      eventDone()
    }
  }, [eventID, mediaID, user])

  useEffect(() => {
    if (selectedEvent === undefined) return
    if (selectedEvent.productUID === "") return

    const productVariantsRef = doc(firestoreDB, "shopProducts", selectedEvent.productUID)

    const done = onSnapshot(productVariantsRef, (response) => {
      const product = response.data() as Product
      setProduct(product)
    })

    return () => {
      done()
    }
  }, [eventMedia])

  if (eventMedia === undefined || selectedEvent === undefined || product === undefined) return <></>

  return (
    <div className={styles.EventOverviewContainer}>
      <div className={styles.EventAssetEditActionButtonsContainer}>
        <button className={styles.OverviewActionButton} onClick={() => nav(-1)}>
          Back
        </button>
        <EditMediaActions media={eventMedia} />
      </div>
      <AssetEdit eventMedia={eventMedia} event={selectedEvent} studioProduct={product} isAdmin={false} />
    </div>
  )
}
