import styles from "./index.module.css"
import mainStyles from "../../styles/App.module.css"
import { Route, Routes } from "react-router-dom"
import React from "react"
import { UsersList, VendorsList } from "./create"
import { VendorDetails } from "./edit"

export const Main: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.root}>
      <div className={mainStyles.MainLogo} />
      <div className={styles.AccountContentContainer}>
        <Routes>
          <Route path="/" element={<VendorsList />} />
          <Route path="add" element={<UsersList />} />
          <Route path=":vendorID" element={<VendorDetails />} />
        </Routes>
      </div>
    </div>
  )
}
export default Main
