import { Timestamp } from "firebase/firestore"
import { Orientation, SessionType } from "./studio"

/**
 * typeId is for the type of product
 * This is not related to product type
 * 1 = Regular Poster
 * 2 = AR Poster
 * 3 = Accessory
 *
 * how does this relate with SessionType? All objects that have a SessionType are meant to be either Regular Poster or AR Poster
 */
export type ProductTypeID = 1 | 2 | 3

export interface Product {
  title: string
  uid: string
  posterUid: string
  category: string
  price: number
  productDescription: string
  imageHDURL: string
  imageLDURL: string
  isActive?: boolean
  timeStamp: Timestamp
  favorite: boolean
  videoURL: string
  relatedImagesURL: string[]
  productImagesURL: string[]
  productVariantList: ProductVariant[]
  typeId: ProductTypeID
  nftLink: string
  keywords: string[]
  tag?: string
  discountID?: string
  /**
   * this is used for instances when the user has to do some work before it can be added to cart
   */
  studioID?: string
}

export interface ProductVariant {
  uid: string
  title?: string
  size: string
  price: number
  count?: number | null // number or items contained in the product variant
  orderLimit?: number | null // maximum number added to the product cart
  /**
   * the list of products contained in the product variant
   */
  productList?: string[]
  /**
   * @param productType can either be a shopProduct or a user product
   */
  productType?: string
  /**
   *
   * @param printfulVariantID
   * @example
   * 1 = {24x28} poster
   * 1349 = 12" x 16"
   */

  printfulVariantID?: number | null // the variant ID of the printful version of the product
  printfulWarehouseID?: number | null // the printfulWarehouse ID of the printful product
  studioID?: string
  studioSessionID?: string
  sessionType?: SessionType | "none"
  // If the product of an event we want to know what event that was
  eventID?: string
  imageHDURL?: string | null
  imageLDURL?: string | null
  orientation?: Orientation
}

export function isProductVariantActive(a: ProductVariant, b: ProductVariant): boolean {
  return (
    a.size === b.size &&
    a.studioSessionID === b.studioSessionID &&
    a.price === b.price &&
    a.studioID === b.studioID &&
    a.title === b.title
  )
}

export function CreateProductVariant(uid: string): ProductVariant {
  return {
    uid: uid,
    size: "",
    price: 0.0,
    title: "",
  }
}

export function CreateDefaultProduct(uid: string, timeStamp: Timestamp): Product {
  return {
    title: "",
    uid: uid,
    posterUid: "",
    category: "",
    price: 0,
    productDescription: "",
    imageHDURL: "",
    imageLDURL: "",
    isActive: false,
    timeStamp: timeStamp,
    favorite: false,
    videoURL: "",
    relatedImagesURL: [],
    productImagesURL: [],
    productVariantList: [],
    typeId: 1,
    nftLink: "",
    keywords: [],
  }
}
