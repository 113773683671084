import styles from "./index.module.css"
import { useContext, useEffect, useState } from "react"
import { useParams, useNavigate, useLocation, useSearchParams } from "react-router-dom"
import React from "react"
import { collection, doc, onSnapshot } from "firebase/firestore"
import { Event, EventAssetLike, EventMedia } from "schema/dist/src/event"
import { AppContext, firestoreDB } from "../App"
import { ImageGallery, PosterViewState } from "../shop/product/frame"
import { AttributesList, IncludedList, PlayPosterContainer, ProductActionsContainer, SizeToDimensions } from "../shop/product"
import { Product, ProductVariant } from "schema/dist/src/product"
import { SessionType, StudioSession } from "schema/dist/src/studio"
import { AddSessionToCart, ValidateStudioSession } from "../create/shared/shared"
import { ImageDPI, UploadPicture } from "../create/polaroid/helper"
import { UpdateStudio, UploadToAssetLocation } from "../create/shared/schema"
import { returnPageKey, ValidateUserToAnon } from "../auth"
import loadingImage from "./../assets/loadingImage.png"
import { calculateImageContrastActual } from "../create/poster"
import { EventsPassword, createEventAssetLike, deleteEventAssetLike, showPendingOption, viewPendingString } from "./event"
import { PendingOptions } from "./upload"

type RouteParams = {
  eventID: string
  assetID: string
}

export const urlSessionPath = "session"

// THE IDEA IS THAT THE JUST COPY THIS TO A NEW DATA TYPE THAT THE CAN ORDER
export const EventAssetDetails: React.FC<{}> = (): JSX.Element => {
  const { eventID, assetID } = useParams<RouteParams>()
  const [eventMedia, setEventMedia] = useState<EventMedia>()
  const nav = useNavigate()
  const [searchPath, setSearchPath] = useSearchParams()
  const [viewState, setViewState] = useState<PosterViewState>("normal")
  const [playVideo, setPlayVideo] = useState(false)
  const [mainText, setMainText] = useState("Add to cart")
  const defaultEditButton = "Print this Frame"
  const [editButtonText, setEditButtonText] = useState(defaultEditButton)
  const { user } = useContext(AppContext)
  const [selectedEvent, setSelectedEvent] = useState<Event>()
  const [studioProduct, setStudioProduct] = useState<Product>()
  const [selectedVariant, setSelectedVariant] = useState<ProductVariant>()
  const [ratio, setRatio] = useState(0.8)
  const [type, setType] = useState<SessionType>()
  const [session, setSession] = useState<StudioSession>()
  const [previewTime, setPreviewTime] = useState<number>()
  const [showVideo, setShowVideo] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [autoCropURL, setAutoCropURL] = useState("")
  const [count, setCount] = useState(0)
  const [isUploading, setIsUploading] = useState(false)
  const [muted, setMuted] = useState(true)
  const [calculatedDPI, setCalculatedDPI] = useState<number>(0)
  const [calculatedContrast, setCalculatedContrast] = useState<number>(0)
  const [isLowDPI, setIsLowDPI] = useState(false)
  const sessionID = searchPath.get(urlSessionPath)
  const isPending = searchPath.get(viewPendingString)
  const showPendingNextSteps = searchPath.get(showPendingOption)

  const userID = user?.uid ?? ""
  const [locked, setLocked] = useState(true)
  const [eventAssetLike, setEventAssetLike] = useState(false)

  useEffect(() => {
    if (window.outerWidth < 600 && editMode) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }
  }, [window.outerWidth, editMode])

  /**
   * This useEffect handles getting the event and event asset data
   */
  useEffect(() => {
    if (eventID === undefined) return

    const eventDetailsRef = doc(firestoreDB, "events", eventID)
    const collectionName = isPending === "true" ? "pending" : "medias"
    const eventMediaCol = collection(eventDetailsRef, collectionName)
    const eventMediaRef = doc(eventMediaCol, assetID)
    const eventLikesCol = collection(eventMediaRef, "likes")

    //fetch the event
    const done = onSnapshot(eventDetailsRef, (response) => {
      const event = response.data() as Event
      setSelectedEvent(event)
      const hash = event.passHash ?? ""
      setLocked(hash !== "")
    })

    //fetch the media
    const eventDone = onSnapshot(eventMediaRef, (response) => {
      const event = response.data() as EventMedia
      setEventMedia(event)
    })

    //fetch the likes
    const likesDone = onSnapshot(eventLikesCol, (response) => {
      const likes = response.docs
        .map((doc) => {
          return doc.data() as EventAssetLike
        })
        .filter((like) => {
          return like.uid === user?.uid
        })

      if (likes[0] !== undefined) {
        setEventAssetLike(true)
      } else {
        setEventAssetLike(false)
      }
    })

    return () => {
      done()
      eventDone()
      likesDone()
    }
  }, [eventID, assetID, user?.uid, isPending])

  /**
   * This useEffect handles getting the event product linked with this event
   */
  useEffect(() => {
    if (selectedEvent === undefined) return
    if (selectedEvent.productUID === "") return

    const productVariantsRef = doc(firestoreDB, "shopProducts", selectedEvent.productUID)
    const done = onSnapshot(productVariantsRef, (response) => {
      const product = response.data() as Product
      // modifying the title of the product
      product.title = selectedEvent.title + "Recap"
      setStudioProduct(product)
    })

    return () => {
      done()
    }
  }, [selectedEvent])

  /**
   * This useEffect handles getting the session ID of the current session being worked on
   */
  useEffect(() => {
    if (sessionID === null) return

    const sessionCollRef = collection(firestoreDB, "studioSessions")
    let sessionRef = doc(sessionCollRef, sessionID)
    const done = onSnapshot(sessionRef, (response) => {
      const session = response.data() as StudioSession

      // when session is no longer editable we need to remove form the URL
      if (session.editable === false) {
        nav({ search: "" })
        setSession(undefined)
        return
      }

      setSession(session)
      setSelectedVariant(session.productVariant)
    })

    return () => {
      done()
    }
  }, [sessionID])

  if (eventMedia === undefined) return <></>

  let imageURL = eventMedia?.imageLDURL

  if (session?.sessionData !== undefined && session.sessionData.length > 0) {
    imageURL = session.sessionData[0].imageURL
  }

  if (isUploading) {
    imageURL = loadingImage
  }

  function ResetPosterSession(session: StudioSession | undefined, eventMedia: EventMedia) {
    if (session === undefined) return
    session.sessionData[0].videoURL = eventMedia.videoURL
    session.sessionData[0].imageURL = eventMedia.imageHDURL
    return UpdateStudio(session)
  }

  function HandleUploadError(err: any) {
    console.log("Error uploading File", err)
    setEditButtonText(`Error please try again`)
  }

  function HandleUploadReporting(a: number) {
    setEditButtonText(`Uploading ${a.toFixed(1)}%`)
  }

  function resetUploadReporting() {
    setEditButtonText(editButtonText)
  }

  function HandleStudioSessionValidation(
    eventMedia: EventMedia,
    selectedVariant: ProductVariant,
    userID: string,
    type: SessionType,
    forceReset: boolean,
    coverImage?: string
  ): Promise<StudioSession> {
    return ValidateStudioSession(session, selectedVariant, userID, type).then(({ session, new: isNew }) => {
      // if the session is new we need to attach the event assetData to it.
      // when we reset it we want to set it the original way
      if (isNew || forceReset) {
        session.sessionData[0].videoURL = eventMedia.videoURL
        session.sessionData[0].imageURL = coverImage ?? eventMedia.imageHDURL
      }
      // this allow us know what event this variant is for
      selectedVariant.eventID = eventMedia.eventUid
      session.productVariant = selectedVariant
      setSession(session)
      return UpdateStudio(session)
    })
  }

  function ValidateImageContrast(h: number, w: number, d: HTMLCanvasElement): boolean {
    const printSize = selectedVariant?.size.match(/[\d.]+(?="|”)/g)?.map(Number)

    if (printSize === undefined) {
      console.error("Error uploading File")
      return false
    }

    const ppi = Math.round(Math.hypot(h, w) / Math.hypot(...printSize))
    setCalculatedDPI(ppi)

    const variance = calculateImageContrastActual(d)
    setCalculatedContrast(variance)

    if (variance <= MIN_IMAGE_VARIANCE) {
      console.error(variance, MIN_IMAGE_VARIANCE, "Image is has a bad contrast value")
      return false
    }

    return true
  }

  const MIN_IMAGE_VARIANCE = 1730

  function updateSessionParameters(session: StudioSession) {
    searchPath.set(urlSessionPath, session.uid)
    setSearchPath(searchPath)
  }

  function ValidateAndAddSessionToCart(session: StudioSession, product: Product) {
    return ValidateUserToAnon(user)
      .then((actualUser) => {
        return AddSessionToCart(actualUser.uid, session, session.sessionType, session.uid, product, count)
      })
      .then(() => {
        setMainText("Added to cart")

        let realReturnPage = searchPath.get(returnPageKey)
        const searchParam = new URLSearchParams()

        if (realReturnPage === null) {
          realReturnPage = `/events/${selectedEvent?.uid}`
        }

        searchParam.set(returnPageKey, realReturnPage)

        nav(`/cart?` + searchParam.toString())
      })
  }

  if (selectedEvent === undefined) {
    return <></>
  }

  if (locked === true) {
    return (
      <EventsPassword
        eventImage={imageURL}
        locked={locked}
        setLocked={setLocked}
        studioProduct={studioProduct}
        selectedEvent={selectedEvent}
      />
    )
  }

  return (
    <div className={styles.EventAssetDetails}>
      <BackButton defaultBack="./../../" />
      <div className={styles.MainContentContainer}>
        <div className={styles.PosterContentContainer}>
          <div className={styles.PosterContainer}>
            <ImageGallery
              imageURL={imageURL}
              videoURL={eventMedia?.videoURL}
              ratio={ratio}
              play={playVideo}
              rotate={true}
              viewState={viewState}
              setPlay={setPlayVideo}
              type={type}
              previewTime={previewTime}
              showVideo={showVideo}
              muted={muted}
            />
          </div>
          <PlayPosterContainer
            isDarkMode
            isHidden={false}
            onPlay={setPlayVideo}
            onViewState={setViewState}
            viewState={viewState}
            play={playVideo}
            muted={muted}
            setMuted={setMuted}
            showLike={true}
            like={eventAssetLike}
            setLike={() => {
              if (!user) {
                //create anon user and like
                return ValidateUserToAnon(user).then((anonUser) => {
                  createEventAssetLike(anonUser?.uid, selectedEvent.uid, eventMedia.uid)
                })
              }
              if (eventAssetLike) {
                deleteEventAssetLike(user.uid, selectedEvent.uid, eventMedia.uid)
              } else {
                createEventAssetLike(user?.uid, selectedEvent.uid, eventMedia.uid)
              }
            }}
          />
        </div>

        {showPendingNextSteps === "true" ? (
          <PendingOptions
            session={session}
            event={selectedEvent}
            eventMedia={eventMedia}
            setSelectedVariant={(selectedVariant) => {
              setSelectedVariant(selectedVariant)

              const posterRatio = SizeToDimensions(selectedVariant?.size ?? "")
              if (posterRatio === undefined) return
              setRatio(posterRatio)

              const sessionType = selectedVariant?.sessionType
              if (sessionType === undefined || sessionType === "none") return
              setType(sessionType)
            }}
          />
        ) : (
          <div className={styles.AssetDetailsContainer}>
            {window.outerWidth < 600 && editMode ? null : <AttributesList darkMode nftLink="" typeId={2} />}
            <EventActionsContainer />
            {!editMode ? (
              <ProductActionsContainer
                product={studioProduct}
                user={user}
                selectedVariant={selectedVariant}
                setSelectedVariant={(selectedVariant) => {
                  setSelectedVariant(selectedVariant)

                  const posterRatio = SizeToDimensions(selectedVariant?.size ?? "")
                  if (posterRatio === undefined) return
                  setRatio(posterRatio)

                  const sessionType = selectedVariant?.sessionType
                  if (sessionType === undefined || sessionType === "none") return
                  setType(sessionType)

                  if (selectedVariant === undefined) return
                  const forceReset = session !== undefined && session.productVariant.uid !== selectedVariant.uid

                  // in order to not over create session we don't need to reset or update the session that is not already existing
                  if (session === undefined) return
                  return HandleStudioSessionValidation(eventMedia, selectedVariant, userID, sessionType, forceReset)
                }}
                mainText={mainText}
                setMainText={setMainText}
                showMainButton={imageURL !== ""}
                onMainActionClick={(product, variant, count) => {
                  setCount(count)
                  if (type === undefined) return

                  HandleStudioSessionValidation(eventMedia, variant, userID, type, false)
                    .then((session) => {
                      updateSessionParameters(session)

                      // when the event asset has a frame already selected we set the crop URL
                      if (eventMedia.imageHDURL === session.sessionData[0].imageURL) {
                        setEditMode(true)
                        setAutoCropURL(eventMedia.imageHDURL)
                        return
                      }

                      return ValidateAndAddSessionToCart(session, product)
                    })
                    .catch((error: any) => {
                      setMainText("Error occurred")
                    })
                }}
                editItemText={"Pick a Frame"}
                onEditItemClick={(product, variant, count) => {
                  setCount(count)
                  setAutoCropURL("")

                  if (type === undefined) return

                  if (session !== undefined) {
                    setEditMode(true)
                    updateSessionParameters(session)
                    return
                  }

                  HandleStudioSessionValidation(eventMedia, variant, userID, type, false)
                    .then((session) => {
                      setEditMode(true)
                      updateSessionParameters(session)
                    })
                    .catch((error) => {
                      console.log(error)
                    })
                }}
                visibleVariantUIDs={selectedEvent?.selectedVariants}
                defaultVariant={selectedEvent?.defaultSelectedVariant}
                ignoreDefault={session?.productVariant !== undefined}
              />
            ) : (
              <div className={styles.PosterSliderActionsContainer}>
                <UploadPicture
                  videoUrl={eventMedia?.videoURL}
                  onFileSelected={(a: File | Blob, h: number, w: number, d: HTMLCanvasElement) => {
                    if (session === undefined || studioProduct === undefined || session.sessionData.length === 0) return
                    setIsUploading(true)

                    const sessionData = session.sessionData[0]
                    UploadToAssetLocation(sessionData, "image", session, a, "imageURL", HandleUploadReporting)
                      .then((ref) => {
                        setIsUploading(false)
                        resetUploadReporting()

                        const isLowDIP = !ValidateImageContrast(h, w, d)

                        if (isLowDIP) {
                          setIsLowDPI(isLowDIP)
                          return
                        }

                        return ValidateAndAddSessionToCart(session, studioProduct)
                      })
                      .catch(HandleUploadError)
                  }}
                  onSlide={(e) => {
                    setShowVideo(true)
                    setPlayVideo(false)
                    setPreviewTime(e)
                  }}
                  isHidden={isLowDPI}
                  showNext={true}
                  nextTitle={"Reset"}
                  showBack={true}
                  onNexClicked={(e) => {
                    // on Next click (reset in this case) we send them back to order mode and we disable the show video
                    // button
                    setShowVideo(false)
                    setEditMode(false)
                    // when (reset has been clicked we reset the poster data)
                    if (e) {
                      ResetPosterSession(session, eventMedia)
                    }
                  }}
                  disabled={isUploading}
                  fileRatio={ratio}
                  allowLocal={false}
                  autoCropURL={autoCropURL}
                  autoCropWithURL={true}
                  mainButtonTitle={editButtonText}
                />
                <ImageDPI
                  isHidden={!isLowDPI}
                  calculatedDPI={calculatedDPI}
                  calculatedContrast={calculatedContrast}
                  showNext={true}
                  showBack={true}
                  onNexClicked={(e) => {
                    if (session === undefined || studioProduct === undefined) return

                    if (e) {
                      return ValidateAndAddSessionToCart(session, studioProduct)
                    }

                    setIsLowDPI(false)
                  }}
                  disabled={isUploading}
                  minContrast={MIN_IMAGE_VARIANCE}
                  minDPI={-1}
                />
              </div>
            )}
            <CreationSteps steps={editMode ? 1 : sessionID !== null ? 2 : 0} type={type} />
            <IncludedList product={studioProduct} user={user} isDarkMode />
          </div>
        )}
      </div>
    </div>
  )
}

// THE IDEA IS THAT THE JUST COPY THIS TO A NEW DATA TYPE THAT THE CAN ORDER
export const EventActionsContainer: React.FC<{}> = (): JSX.Element => {
  return <div className={styles.EventActionsContainer}></div>
}

export const CreationSteps: React.FC<{ steps: number; type?: string }> = ({ steps, type }): JSX.Element => {
  return (
    <div className={styles.CreationSteps}>
      <b> Recap Creation Steps</b>
      <span className={steps === 0 ? styles.CreationStepsBold : ""}>
        Step 1: Click "Pick a Frame" to select a frame that will be printed {type !== undefined && "on the " + type}
      </span>
      <span className={steps === 1 ? styles.CreationStepsBold : ""}>
        Step 2: Move the slider to pick the frame you love and want to print
      </span>
      <span className={steps === 2 ? styles.CreationStepsBold : ""}>Step 3: Click "Add to cart" to add the recap to cart</span>
    </div>
  )
}

export const BackButton: React.FC<{ defaultBack?: string; title?: string; visible?: boolean }> = ({
  defaultBack,
  title = "Back",
  visible = true,
}): JSX.Element | null => {
  const [searchPath] = useSearchParams()
  const nav = useNavigate()
  const backURL = searchPath.get(returnPageKey) ?? defaultBack ?? null

  if (!visible || backURL === null) {
    return null
  }

  return (
    <div
      className={styles.BackButtonContainer}
      onClick={() => {
        nav(backURL)
      }}
    >
      <div className={styles.BackButtonIcon}></div>
      <div className={styles.BackButton}>{title}</div>
    </div>
  )
}
