import { useNavigate } from "react-router-dom"
import styles from "./index.module.css"
import museum from "../assets/video/museum.mp4"
import polaroid from "../assets/video/polaroidstack.mp4"
import MonaVideo from "../assets/video/mona_2.mp4"

export const Main: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.LandingRoot}>
      <DoubleStack>
        <SingleStack
          title="Interactive Posters"
          buttonTitle="Shop"
          location="/shop/tobennawes"
          backgroundClass={styles.SaintRoomBackgroundImage}
        >
          by <br /> TOBEWES
        </SingleStack>

        <SingleStack
          title="Interactive Postcards"
          buttonTitle="Shop"
          location="/shop/postcards"
          backgroundClass={styles.PostcardBackgroundImage}
        />
      </DoubleStack>
      <DoubleStack>
        {/* <SingleStack
          title="Interactive Planner"
          buttonTitle="Shop Planner"
          location="/shop/planner"
          backgroundClass={styles.PlannerBackgroundImage}
        /> */}
        <SingleStack
          title="Shop Burna Postcards"
          buttonTitle="Shop Posters"
          location="/shop/burnaboy"
          backgroundClass={styles.BurnaboyBackgroundImage}
        ></SingleStack>
        <SingleStack
          title="Interactive Christmas Cards"
          buttonTitle="Create"
          location="/studio/greeting?greetingID=christmas"
          backgroundClass={styles.ChristmasBackgroundImage}
        />
      </DoubleStack>
      <DoubleStack>
        <VideoViewBox title="Interactive Posters" buttonTitle="Create" location="/studio/poster" videoURL={MonaVideo} />
        <VideoViewBox title="Interactive Polaroids" buttonTitle="Create" location="/studio/polaroid" videoURL={polaroid} />
      </DoubleStack>
      <DoubleStack>
        <SingleStack
          title="Interactive Wedding Invitations"
          buttonTitle="Create"
          location="/studio/greeting"
          backgroundClass={styles.WeddingBackgroundImage}
        />
      </DoubleStack>
      <DoubleStack>
        <SingleStack
          title="Interactive Postcards"
          buttonTitle="Create"
          location="/studio/postcard"
          backgroundClass={styles.PostCardBackgroundImage}
        />
        <SingleStack
          title="Interactive Birthday Cards"
          buttonTitle="Create"
          location="/studio/greeting?greetingID=christmas"
          backgroundClass={styles.BirthdayBackgroundImage}
        />
      </DoubleStack>
      <DoubleStack>
        <SingleStack
          title="Request QR Tag"
          buttonTitle="Request"
          location="/generateqr"
          backgroundClass={styles.ScanRequestBackgroundImage}
        />
      </DoubleStack>
    </div>
  )
}
export default Main

export const VideoViewBox: React.FC<{ videoURL: string; title: string; location: string; buttonTitle: string }> = ({
  videoURL,
  title,
  location,
  buttonTitle,
}): JSX.Element => {
  const nav = useNavigate()
  return (
    <div
      className={styles.Section}
      onClick={() => {
        nav(location)
      }}
    >
      <div className={styles.SectionVideoContainer}>
        <video loop autoPlay muted playsInline src={videoURL}></video>
        <div className={styles.SectionVideoContent}>
          <br />
          <h2>{title}</h2>
          <div className={styles.ShopButtonContainer}>
            <div className={styles.ShopButton}>{buttonTitle}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const DoubleStack: React.FC<{}> = ({ children }): JSX.Element => {
  return <div className={styles.SectionStacker}>{children}</div>
}

interface SingleStackProps {
  title: string
  buttonTitle: string
  location?: string
  backgroundClass: string
}

export const SingleStack: React.FC<SingleStackProps> = ({
  title,
  buttonTitle,
  location,
  backgroundClass,
  children,
}): JSX.Element => {
  const nav = useNavigate()
  const backgroundClassName = styles.BackgroundImage + " " + backgroundClass

  return (
    <div
      className={styles.Section}
      onClick={() => {
        if (location === undefined) return
        nav(location)
      }}
    >
      <div className={backgroundClassName}>
        <br />
        <h2>{title}</h2>
        {children}
        <div className={styles.ShopButtonContainer}>
          <div className={styles.ShopButton}>{buttonTitle}</div>
        </div>
      </div>
    </div>
  )
}
