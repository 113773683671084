import productStyles from "../product/index.module.css"
import { useEffect, useState } from "react"

import React from "react"
import { CreateProductVariant, ProductVariant } from "schema/dist/src/product"
import { GetDefaultProductVariants, SelectProductVariantView } from "../product/create"
import { collection, deleteDoc, doc, setDoc } from "firebase/firestore"
import { firestoreDB } from "../../App"

export type AdminDefaultsType = "polaroids" | "defaults" | "regular"
export const Create: React.FC<{}> = (): JSX.Element => {
  return (
    <>
      <SetProductVariant type="defaults" />
      <SetProductVariant type="regular" />
      <SetProductVariant type="polaroids" />
    </>
  )
}

export default Create

export const SetProductVariant: React.FC<{ type: AdminDefaultsType }> = ({ type }): JSX.Element => {
  const [variantList, setVariantList] = useState<ProductVariant[]>([])
  const [refreshView, setRefreshView] = useState(0)

  useEffect(() => {
    GetDefaultProductVariants(type).then((variantList) => {
      setVariantList(variantList)
    })
  }, [refreshView])

  return (
    <div className={productStyles.UpdateOrderDetails}>
      VARIANTS : {type.toUpperCase()}
      <div className={productStyles.SelectProductVariant}>
        {variantList.map((variant, i) => {
          return (
            <SelectProductVariantView
              key={variant.uid}
              variant={variant}
              onVariantUpdate={(newVar) => {
                console.log(newVar)
                UpdateDefaultVariant(newVar, type)
                  .then((res) => {
                    setRefreshView(refreshView + 1)
                  })
                  .catch((err) => {
                    console.log(err)
                  })
              }}
              onVariantDelete={() => {
                DeleteDefaultVariant(variant, type)
                  .then((res) => {
                    setRefreshView(refreshView + 1)
                  })
                  .catch((err) => {
                    console.log(err)
                  })
              }}
            />
          )
        })}
      </div>
      <div
        className={productStyles.UpdateOrderStatusButton}
        onClick={() => {
          CreateDefaultVariant(type).then((res) => {
            setRefreshView(refreshView + 1)
          })
        }}
      >
        ADD
      </div>
    </div>
  )
}

export function UpdateDefaultVariant(variant: ProductVariant, type: AdminDefaultsType) {
  const orderDetailsRef = doc(collection(doc(firestoreDB, "appData", "posterVariants"), type), variant.uid)

  return setDoc(orderDetailsRef, variant)
}

export function DeleteDefaultVariant(variant: ProductVariant, type: AdminDefaultsType) {
  const orderDetailsRef = doc(collection(doc(firestoreDB, "appData", "posterVariants"), type), variant.uid)

  return deleteDoc(orderDetailsRef)
}

export function CreateDefaultVariant(type: AdminDefaultsType) {
  const orderDetailsRef = doc(collection(doc(firestoreDB, "appData", "posterVariants"), type))

  return setDoc(orderDetailsRef, CreateProductVariant(orderDetailsRef.id))
}
