import styles from "./styles/home.module.css"
import { useLocation } from "react-router-dom"
import { setMetaData, setTabTitle } from "../App"
export const NotFound: React.FC<{}> = (): JSX.Element => {
  const loc = useLocation()
  setTabTitle("404 Try again. Page not found")
  setMetaData("404 Try again. Page not found")
  return (
    <div className={styles.root}>
      <div className={styles.LostComponent}>
        404
        <div className={styles.LostComponentSubTitle}>Page not found</div>
        <div className={styles.LostComponentText}>
          The page you are looking for might have been removed or is temporarily unavailable
          <br />
          {loc.pathname} Does not exist
        </div>
      </div>
    </div>
  )
}
export default NotFound
