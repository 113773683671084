import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  limit,
  onSnapshot,
  query,
  setDoc,
  startAfter,
  Timestamp,
  where,
} from "firebase/firestore"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import CryptoJS from "crypto-js"
import React, { useContext, useEffect, useRef, useState } from "react"
import { useNavigate, useParams, useSearchParams, useLocation } from "react-router-dom"
import {
  CreateDefaultEventAnalytics,
  CreateDefaultEventAssetLike,
  EventAssetLike,
  CreateDefaultEventReminder,
  Event,
  EventAnalytics,
  EventMedia,
  EventReminder,
} from "schema/dist/src/event"
import { AppContext, firestoreDB } from "../App"
import styles from "./index.module.css"
import loadingImage from "./../assets/loadingImage.png"
import showIcon from "./../assets/visibility.svg"
import hideIcon from "./../assets/visibilityOff.svg"
import { Revivart2D } from "./revivart"
import { Product } from "schema/dist/src/product"
import { SizeToDimensions } from "../shop/product"
import { SessionType } from "schema/dist/src/studio"
import { useCookies } from "react-cookie"
import EventsExample from "../assets/video/eventPolaroidExample.mp4"
import { Coupon } from "schema/dist/src/discount"
import { CouponToString, GetCoupon } from "../admin/discount/discount"
import { returnPageKey, ValidateUserToAnon } from "../auth"
import { BackButton } from "./asset"
import notLikedimage from "./../assets/love.svg"
import likedImage from "./../assets/loved.svg"
import { isUpcoming } from "."
import { DeleteEventMedia, onPendingVideoSelected } from "../admin/event/create"
import { ValidateUserCheckout } from "../auth"

const lastPageQueryString = "lastPage"
export const viewPendingString = "pending"
export const showPendingOption = "showPendingOptions"

export type EventRouteParams = {
  eventID: string
}

export const EventsDetail: React.FC<{}> = (): JSX.Element => {
  const { eventID } = useParams<EventRouteParams>()
  const [selectedEvent, setSelectedEvent] = useState<Event>()
  const [eventMedias, setEventMedias] = useState<EventMedia[]>([])
  const [imageURL, setImageURL] = useState(loadingImage)
  const [studioProduct, setStudioProduct] = useState<Product>()
  const [lastItem, setLastItem] = useState<DocumentSnapshot<DocumentData>>()
  const [lastItemCache, setLastItemCache] = useState<DocumentSnapshot<DocumentData>>()
  const [lastPage, setLastPage] = useState(false)
  const limitNumber = 10
  const [params, setParams] = useSearchParams()
  const lasPageSearch = params.get(lastPageQueryString)
  const viewPending = params.get(viewPendingString)
  const [locked, setLocked] = useState(true)
  const [isUpcomingEvent, setIsUpcomingEvent] = useState(false)
  const { user } = useContext(AppContext)

  useEffect(() => {
    if (eventID === undefined) return

    const eventDetailsRef = doc(firestoreDB, "events", eventID)
    let eventMediaCol = collection(eventDetailsRef, "medias")

    if (lasPageSearch !== null && lastItem?.id !== lasPageSearch) {
      getDoc(doc(eventMediaCol, lasPageSearch))
        .then((doc) => {
          setLastItem(doc)
        })
        .catch((err) => {
          setParams("")
        })

      return
    }

    if (lasPageSearch === null) {
      setLastItem(undefined)
    }

    //fetch the medias related to the event
    const limitCount = limit(limitNumber)
    let eventQuery = query(eventMediaCol, limitCount)

    if (user?.uid !== undefined && viewPending === "true") {
      eventMediaCol = collection(eventDetailsRef, "pending")
      eventQuery = query(eventMediaCol, limitCount, where("uploaderID", "==", user?.uid))
    }

    if (lastItem !== undefined) {
      eventQuery = query(eventMediaCol, startAfter(lastItem), limitCount)
    }

    const videosDone = onSnapshot(eventQuery, (response) => {
      const eventMedias = response.docs.map((doc) => {
        return doc.data() as EventMedia
      })

      setLastPage(limitNumber > eventMedias.length)
      setLastItemCache(response.docs[response.docs.length - 1])
      setEventMedias(eventMedias)
    })

    return () => {
      videosDone()
    }
  }, [lasPageSearch, eventID, setParams, lastItem, viewPending, user?.uid])

  useEffect(() => {
    if (eventID === undefined) return
    const eventDetailsRef = doc(firestoreDB, "events", eventID)
    const done = onSnapshot(eventDetailsRef, (response) => {
      const event = response.data() as Event
      setSelectedEvent(event)
      const hash = event.passHash ?? ""
      setLocked(hash !== "")
      const storage = getStorage()

      getDownloadURL(ref(storage, event.imageLDURL))
        .then((url) => {
          setImageURL(url)
        })
        .catch((error) => {
          console.error(error)
        })
    })

    return () => {
      done()
    }
  }, [eventID])

  useEffect(() => {
    if (selectedEvent === undefined) return
    if (selectedEvent.productUID === "") return

    const productVariantsRef = doc(firestoreDB, "shopProducts", selectedEvent.productUID)

    const done = onSnapshot(productVariantsRef, (response) => {
      const product = response.data() as Product
      setStudioProduct(product)
    })

    return () => {
      done()
    }
  }, [selectedEvent])

  useEffect(() => {
    if (selectedEvent === undefined) return

    const upcoming = isUpcoming(selectedEvent)
    setIsUpcomingEvent(upcoming)
  }, [selectedEvent])

  if (selectedEvent === undefined) return <></>

  if (locked === true) {
    return (
      <EventsPassword
        eventImage={imageURL}
        locked={locked}
        setLocked={setLocked}
        studioProduct={studioProduct}
        selectedEvent={selectedEvent}
      />
    )
  }

  return (
    <div className={styles.EventsDetail}>
      <EventsPopUp event={selectedEvent} />
      <div className={styles.EventDetailHeader} style={{ backgroundImage: "url(" + imageURL + ")" }}>
        <span>{viewPending === "true" ? "Pending" : "Recap"}</span>
      </div>
      <EventInformation event={selectedEvent} product={studioProduct} />
      <EventPriceInformation event={selectedEvent} product={studioProduct} />
      {isUpcomingEvent ? (
        <EventReminderButton selectedEvent={selectedEvent} />
      ) : eventMedias.length !== 0 ? (
        <EventListContainer event={selectedEvent} eventMedia={eventMedias} product={studioProduct} />
      ) : lastItem === undefined ? (
        <div className={styles.EventDetailSoon}>{"This recap will be loaded soon..."}</div>
      ) : (
        <>
          <br />
          {"Click 'View again' to start from top"}
        </>
      )}

      {isUpcomingEvent ? null : (
        <div
          className={styles.PaginationButton}
          onClick={() => {
            if (lastPage) {
              setLastItem(undefined)
              setParams("")
            } else {
              window.scrollTo({ top: window.innerHeight * 0.2, left: 0, behavior: "smooth" })
              setLastItem(lastItemCache)
              setParams(lastPageQueryString + "=" + lastItemCache?.id)
            }
          }}
        >
          {!lastPage ? "Show More" : lastItem === undefined ? "1" : "View again"}
        </div>
      )}
    </div>
  )
}

export default EventsDetail

export const EventInformation: React.FC<{ event: Event; product: Product | undefined }> = ({ event, product }): JSX.Element => {
  const date = event.date.toDate()
  return (
    <div className={styles.EventInformation}>
      <div className={styles.EventInformationTitleSection}>
        {date.toDateString()}
        {isUpcoming(event) && " (Upcoming)"}
        <span> {event.title}</span>
      </div>
      <div className={styles.EventInformationDescriptionSection}>
        {event.description}
        <div className={styles.EventInformationDescriptionButtons}>
          {event.url !== "" ? (
            <button
              onClick={() => {
                window.open(event.url, "_blank")
              }}
            >
              View Event
            </button>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export const EventPriceInformation: React.FC<{ event: Event; product: Product | undefined }> = ({
  event,
  product,
}): JSX.Element => {
  const [couponItem, setCouponItem] = useState<Coupon>()

  let defaultVal = product?.productVariantList.find((variant, i) => {
    return variant.uid === event.defaultSelectedVariant
  })

  useEffect(() => {
    if (event.couponID === undefined || event.couponID === "") return

    GetCoupon(event.couponID)
      .then((coupon) => {
        setCouponItem(coupon)
      })
      .then((discount) => {})
  }, [event.couponID])

  return (
    <div className={styles.EventPriceInformation}>
      <UserEventUploadAssetButton event={event} />
      <div className={styles.EventInformationTitlePrice}>
        <span>{defaultVal?.sessionType}</span> - ${(defaultVal?.price ?? 0) / 100}
      </div>
      {couponItem?.isActive && couponItem.isValid ? (
        <>
          <div className={styles.EventInformationTitlePrice}>
            <b>{`${CouponToString(couponItem.discount)}`}</b> of your first item - <span>code:</span> <b>{couponItem.code}</b>
          </div>
        </>
      ) : null}
    </div>
  )
}

export const UserEventUploadAssetButton: React.FC<{ event: Event }> = ({ event }): JSX.Element | null => {
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [params, setParams] = useSearchParams()
  const defaultTitle = "Upload a Video"
  const [text, setText] = useState(defaultTitle)
  const isPendingApproval = params.get(viewPendingString)
  const { user } = useContext(AppContext)
  const nav = useNavigate()
  const loc = useLocation()
  const userId = user?.uid
  const [hasUpload, setHasUpload] = useState(false)
  const isInPendingView = isPendingApproval === "true"

  useEffect(() => {
    if (userId === undefined) return
    const eventDetailsRef = doc(firestoreDB, "events", event.uid)
    let eventMediaCol = collection(eventDetailsRef, "pending")

    let eventQuery = query(eventMediaCol, where("uploaderID", "==", userId))

    const videosDone = onSnapshot(eventQuery, (response) => {
      const eventMedias = response.docs.map((doc) => {
        return doc.data() as EventMedia
      })

      setHasUpload(eventMedias.length > 0)
    })

    return () => {
      videosDone()
    }
  }, [event, userId])

  if (event?.allowUpload !== true) {
    return null
  }

  return (
    <>
      <div
        className={styles.UserEventUploadAssetButton}
        onClick={() => {
          if (ValidateUserCheckout(user, nav, loc)) {
            hiddenFileInput.current?.click()
          }
        }}
      >
        <span>{text}</span>
        <input
          style={{ display: "none" }}
          ref={hiddenFileInput}
          type="file"
          accept=".mp4"
          onChange={(e) => {
            if (e.target.files === null || user?.uid === undefined) {
              return
            }

            onPendingVideoSelected(e.target.files[0], event, user.uid, (a) => {
              const progress = a.toFixed(0)
              setText("Uploading " + progress + "%")

              if (progress === "100") {
                setText("Finalizing")
              }
            })
              .then((values) => {
                setText("Uploaded")
                setParams([[viewPendingString, "true"]])
              })
              .catch((values) => {
                setText("Error")
              })
              .finally(() => {
                setTimeout(() => {
                  setText(defaultTitle)
                }, 2000)
              })

            e.target.files = null
            e.target.value = ""
          }}
        />
      </div>

      {hasUpload && !isInPendingView && (
        <div
          className={styles.UserEventUploadAssetButton}
          onClick={() => {
            setParams([[viewPendingString, "true"]])
          }}
        >
          <span>{isInPendingView ? "Back" : "Your Pending Videos"} </span>
        </div>
      )}

      {isInPendingView && (
        <div
          className={styles.UserEventUploadAssetButton}
          onClick={() => {
            params.delete(viewPendingString)
            setParams(params)
          }}
        >
          <span>{"Back"} </span>
        </div>
      )}
    </>
  )
}

export const EventAssetLikeButton: React.FC<{ event: Event; eventMedia: EventMedia }> = ({ eventMedia, event }): JSX.Element => {
  const [eventAssetLike, setEventAssetLike] = useState(false)
  const { user } = useContext(AppContext)

  useEffect(() => {
    const eventDetailsRef = doc(firestoreDB, "events", event.uid)
    const eventMediaCol = collection(eventDetailsRef, "medias")
    const eventLikesCol = collection(eventMediaCol, eventMedia.uid, "likes")

    const done = onSnapshot(eventLikesCol, (response) => {
      const likes = response.docs
        .map((doc) => {
          return doc.data() as EventAssetLike
        })
        .filter((like) => {
          return like.uid === user?.uid
        })

      if (likes[0] !== undefined) {
        setEventAssetLike(true)
      } else {
        setEventAssetLike(false)
      }
    })

    return () => {
      done()
    }
  }, [event.uid, event.uid, user?.uid])

  return (
    <div
      className={styles.AssetLike}
      style={{ backgroundImage: eventAssetLike ? "url(" + likedImage + ")" : "url(" + notLikedimage + ")" }}
      onClick={() => {
        if (!user) {
          //create anon user and like
          return ValidateUserToAnon(user).then((anonUser) => {
            createEventAssetLike(anonUser?.uid, event.uid, eventMedia.uid)
          })
        }
        if (eventAssetLike) {
          deleteEventAssetLike(user.uid, event.uid, eventMedia.uid)
        } else {
          createEventAssetLike(user?.uid, event.uid, eventMedia.uid)
        }
      }}
    />
  )
}

export const EventListContainer: React.FC<{ event: Event; eventMedia: EventMedia[]; product: Product | undefined }> = ({
  eventMedia,
  product,
  event,
}): JSX.Element => {
  const nav = useNavigate()
  const loc = useLocation()

  const defaultVariantID = event.defaultSelectedVariant
  const defaultVariant = product?.productVariantList.find((variant) => {
    return variant.uid === defaultVariantID
  })

  const posterRatio = SizeToDimensions(defaultVariant?.size ?? "") ?? 0
  let posterType: SessionType = "poster"

  if (defaultVariant?.sessionType !== undefined && defaultVariant?.sessionType !== "none") {
    posterType = defaultVariant?.sessionType
  }

  return (
    <div className={styles.EventListContainer}>
      {eventMedia.map((eventMedia) => {
        return (
          <Revivart2D
            key={eventMedia.uid}
            imageURL={eventMedia.imageLDURL}
            videoURL={eventMedia.videoURL}
            ratio={posterRatio}
            type={posterType}
            onClick={() => {
              const searchParam = new URLSearchParams()
              searchParam.set(returnPageKey, loc.pathname + loc.search)

              // when asset is pending approval we don't want it to asset page
              if (eventMedia.pendingApproval) {
                searchParam.set(viewPendingString, "true")
              }

              nav(`./asset/${eventMedia.uid}?` + searchParam.toString())
            }}
            orientation={defaultVariant?.orientation}
          >
            {eventMedia.pendingApproval && (
              <div
                className={styles.EventListDeleteItem}
                onClick={(e) => {
                  DeleteEventMedia(eventMedia, eventMedia.eventUid, "pending")
                  e.stopPropagation()
                }}
              >
                DELETE
              </div>
            )}
          </Revivart2D>
        )
      })}
    </div>
  )
}

export const EventReminderButton: React.FC<{ selectedEvent: Event }> = ({ selectedEvent }) => {
  const [hasReminder, setHasReminder] = useState(false)
  const [reminderDetails, setReminderDetails] = useState<EventReminder>()
  const [isUpcomingEvent, setIsUpcomingEvent] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [email, setEmail] = useState("")
  const { user } = useContext(AppContext)
  const [cookies, setCookie] = useCookies()

  useEffect(() => {
    if (selectedEvent === undefined) return

    const upcoming = isUpcoming(selectedEvent)
    setIsUpcomingEvent(upcoming)

    const eventCol = collection(firestoreDB, "events")
    const eventRemindersCol = collection(eventCol, selectedEvent.uid, "reminders")
    const reminderEmail = user?.email ?? cookies.email ?? ""
    const qu = query(eventRemindersCol, where("email", "==", reminderEmail))

    const done = onSnapshot(qu, (a) => {
      const reminders = a.docs.map((docs) => {
        return docs.data() as EventReminder
      })
      if (reminders[0] !== undefined) {
        setHasReminder(true)
        setReminderDetails(reminders[0])
      } else {
        setHasReminder(false)
      }
    })

    return () => {
      done()
    }
  }, [cookies.email, selectedEvent, user?.email])

  const emailBtnClassName = email !== "" ? styles.ReminderEmailButton : styles.EditActionButton

  return (
    <>
      <EventsCountDown event={selectedEvent} />
      {isUpcomingEvent && !hasReminder ? (
        <div
          className={styles.ReminderButton}
          onClick={() => {
            if (user?.email) {
              createEventReminder(user.email, selectedEvent.uid, selectedEvent.date, user.uid).then((s) => console.log(s))
            } else {
              setShowPopup(true)
            }
          }}
        >
          Notify me
        </div>
      ) : (
        <div
          className={styles.ReminderButtonDisabled}
          onClick={() => {
            if (reminderDetails) {
              deleteEventReminder(reminderDetails, selectedEvent.uid)
            }
          }}
        >
          Cancel Notification
        </div>
      )}
      {showPopup ? (
        <div
          className={styles.ReminderPopup}
          onClick={() => {
            //close popup
            setShowPopup(false)
          }}
        >
          <div
            className={styles.ReminderEmailPopup}
            onClick={(e) => {
              //prevent popup from closing
              e.stopPropagation()
            }}
          >
            <p>Enter your email</p>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <button
              onClick={() => {
                if (email === "") return
                createEventReminder(email, selectedEvent.uid, selectedEvent.date).then(() => {
                  let dt = new Date()
                  dt.setDate(dt.getDate() + 2)
                  setCookie("email", email, { expires: dt })
                  setShowPopup(false)
                })
              }}
              className={emailBtnClassName}
            >
              Notify Me
            </button>
            <button
              onClick={() => {
                setShowPopup(false)
              }}
              className={styles.EditActionButton}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : null}
    </>
  )
}

export const EventsCountDown: React.FC<{ event: Event }> = ({ event }): JSX.Element => {
  const [distance, setDistance] = useState(0)

  //if a MilliSecond = 1
  const perSecond = 1000
  const perMinute = 1000 * 60
  const perHour = 1000 * 60 * 60
  const perDay = 1000 * 60 * 60 * 24

  const days = Math.floor(distance / perDay)
  const hours = Math.floor((distance % perDay) / perHour)
  const minutes = Math.floor((distance % perHour) / perMinute)
  const seconds = Math.floor((distance % perMinute) / perSecond)

  useEffect(() => {
    const end = event.date.toDate().getTime()

    const done = setInterval(() => {
      const now = new Date().getTime()
      const distance = end - now
      setDistance(distance)
    }, 1000)

    return () => {
      clearInterval(done)
    }
  }, [event.date])

  return (
    <div className={styles.EventCountDown}>
      <h2>Coming Soon. You can opt-in to be notified</h2>
      <div className={styles.EventCountDownETA}>
        <h3>{days}d</h3>
        <h3>{hours}h</h3>
        <h3>{minutes}m</h3>
        <h3>{seconds}s</h3>
      </div>
    </div>
  )
}

export function CreateEventAnalytics(uid: string, eventID: string, email?: string): Promise<EventAnalytics> {
  const analytics = CreateDefaultEventAnalytics(uid, eventID, Timestamp.now(), email, "view")
  return UpdateEventAnalytics(analytics, eventID)
}

export function UpdateEventAnalytics(analytics: EventAnalytics, eventID: string = analytics.eventUid) {
  const eventCol = collection(firestoreDB, "events")
  const eventMediaRef = doc(eventCol, eventID, "analytics", analytics.uid)

  return setDoc(eventMediaRef, analytics, { merge: true }).then(() => {
    return analytics
  })
}

export function UpdateEventAnalyticsViewedPopup(eventUid: string, analyticsID: string) {
  const eventCol = collection(firestoreDB, "events")
  const eventMediaRef = doc(eventCol, eventUid, "analytics", analyticsID)

  return setDoc(eventMediaRef, { viewedPopUp: true }, { merge: true })
}

export function createEventAssetLike(uid: string, eventID: string, mediaID: string): Promise<EventAssetLike> {
  const like = CreateDefaultEventAssetLike(uid, eventID, mediaID, Timestamp.now())
  return updateEventAssetLike(like, eventID, mediaID)
}

export function updateEventAssetLike(like: EventAssetLike, eventID: string = like.eventUid, mediaID: string = like.mediaUid) {
  const eventDetailsRef = doc(firestoreDB, "events", eventID)
  const eventMediaCol = collection(eventDetailsRef, "medias")
  const eventLikeRef = doc(eventMediaCol, mediaID, "likes", like.uid)

  return setDoc(eventLikeRef, like, { merge: true }).then(() => {
    return like
  })
}

export function deleteEventAssetLike(likeID: string, eventID: string, mediaID: string): Promise<boolean> {
  const eventDetailsRef = doc(firestoreDB, "events", eventID)
  const eventMediaCol = collection(eventDetailsRef, "medias")
  const eventLikeRef = doc(eventMediaCol, mediaID, "likes", likeID)

  return deleteDoc(eventLikeRef).then(() => {
    return true
  })
}

export const EventsPopUp: React.FC<{ event: Event }> = ({ event }): JSX.Element => {
  const [page, setPage] = useState(0)
  const [viewed, setViewed] = useState(true)
  const { user } = useContext(AppContext)
  const [cookies, setCookie] = useCookies()
  const maxPage = 3
  const analyticsID: string | undefined | null = cookies._ra ?? ""

  useEffect(() => {
    const eventCol = collection(firestoreDB, "events")
    const eventMediaCol = collection(eventCol, event.uid, "analytics")

    if (analyticsID === null || analyticsID === undefined || analyticsID === "") {
      const eventMediaRef = doc(eventMediaCol)
      let dt = new Date()
      dt.setDate(dt.getDate() + 2)
      setCookie("_ra", eventMediaRef.id, { expires: dt })
      return
    }

    const eventMediaRef = doc(eventCol, event.uid, "analytics", analyticsID)
    getDoc(eventMediaRef).then((a) => {
      if (a.exists()) {
        const value = a.data() as EventAnalytics
        setViewed(value.viewedPopUp ?? false)
      } else {
        setViewed(false)
        CreateEventAnalytics(analyticsID, event.uid, user?.email ?? undefined)
      }
    })
  }, [analyticsID, event.uid, user?.email, setCookie])

  useEffect(() => {
    if (analyticsID === null || analyticsID === undefined || analyticsID === "") return
    if (page === maxPage) {
      UpdateEventAnalyticsViewedPopup(event.uid, analyticsID).catch((err) => {
        console.error(err)
      })
    }
  }, [page, analyticsID, event.uid])

  if (page === maxPage || viewed) {
    return <></>
  }

  return (
    <div className={styles.EventsPopUpOverlay}>
      <div
        className={styles.EventsPopUpOnCliCk}
        onClick={(e) => {
          e.stopPropagation()
          setPage(maxPage)
        }}
      ></div>
      <div className={styles.EventsPopUp}>
        <div className={styles.EventsPopUpTitle}>What is a Revivar Recap?</div>

        {page === 0 ? (
          <>
            <div className={styles.EventsPreview}>
              <video
                width="100%"
                height="100%"
                controls={true}
                autoPlay={true}
                src={EventsExample}
                loop
                playsInline
                muted
                preload={"auto"}
              />
            </div>
          </>
        ) : null}
        {page === 1 ? (
          <div className={styles.EventRecapDesc}>
            Memories of you at your favorite events can now be stored inside printed pictures using Augmented Reality
          </div>
        ) : null}
        {page === 2 ? (
          <div className={styles.EventRecapDesc}>
            Find a moment that captures you having fun at {event.title}! Its not magic but its the next generation of storing
            memories!
          </div>
        ) : null}
        <div className={styles.EventsPopUpButtonContainer}>
          {page === 1 ? (
            <div
              className={styles.EventsPopUpButton}
              onClick={() => {
                setPage(page - 1)
              }}
            >
              Back
            </div>
          ) : null}

          <div
            className={styles.EventsPopUpButton}
            onClick={() => {
              setPage(page + 1)
            }}
          >
            {page === maxPage - 1 ? "Lets go!" : "Next"}
          </div>
        </div>
      </div>
    </div>
  )
}

interface EventsPasswordProps {
  selectedEvent: Event
  setLocked: (a: boolean) => void
  locked: boolean
  studioProduct: Product | undefined
  eventImage: string
}

export const EventsPassword: React.FC<EventsPasswordProps> = ({
  selectedEvent,
  studioProduct,
  eventImage,
  setLocked,
}): JSX.Element => {
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [wrongPassword, setWrongPassword] = useState(false)
  const [cookies, setCookie] = useCookies()
  const hasCookeKey = "_event_pass"
  const eventPass: string | undefined | null = cookies[hasCookeKey] ?? ""

  useEffect(() => {
    if (eventPass === selectedEvent?.passHash) {
      setLocked(false)
    }
  }, [eventPass, selectedEvent, setLocked])

  return (
    <div className={styles.EventsDetail}>
      <div className={styles.EventDetailHeaderLocked} style={{ backgroundImage: "url(" + eventImage + ")" }}>
        <div className={styles.EventDetailHeaderLockedMain}>
          <BackButton defaultBack="./../" />
          <EventInformation event={selectedEvent} product={studioProduct} />
          <div className={styles.EventPasswordContainer}>
            {wrongPassword ? (
              <h4>Password is incorrect, try again</h4>
            ) : (
              <p>This is a private event, enter event password to proceed</p>
            )}
            <input
              className={styles.LockedEventInput}
              type={showPassword ? "text" : "password"}
              name=""
              id=""
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="no-password"
            />
            <img
              className={styles.LockedEventIcon}
              src={showPassword ? hideIcon : showIcon}
              alt=""
              onClick={() => setShowPassword(!showPassword)}
            />
            <button
              className={styles.LockedEventEnterbutton}
              onClick={() => {
                const hash = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex)
                if (hash === selectedEvent.passHash) {
                  setLocked(false)
                  let dt = new Date()
                  dt.setDate(dt.getDate() + 1)
                  dt.setSeconds(dt.getSeconds() + 10)
                  setCookie(hasCookeKey, hash, { expires: dt })
                } else {
                  setPassword("")
                  setWrongPassword(true)
                }
              }}
            >
              Enter
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export function createEventReminder(
  email: string,
  eventID: string,
  expiry: Timestamp,
  userId: string = ""
): Promise<EventReminder> {
  const ref = doc(collection(firestoreDB, "events", eventID, "reminders"))

  const reminder = CreateDefaultEventReminder(ref.id, email, userId, eventID, Timestamp.now(), expiry)
  return UpdateEventReminder(reminder, eventID)
}

export function UpdateEventReminder(reminder: EventReminder, eventID: string = reminder.eventUid) {
  const eventCol = collection(firestoreDB, "events")
  const eventReminderRef = doc(eventCol, eventID, "reminders", reminder.uid)

  return setDoc(eventReminderRef, reminder, { merge: true }).then(() => {
    return reminder
  })
}

export function deleteEventReminder(reminder: EventReminder, eventID: string = reminder.eventUid) {
  const eventCol = collection(firestoreDB, "events")
  const eventMediaRef = doc(eventCol, eventID, "reminders", reminder.uid)

  deleteDoc(eventMediaRef)
}
