import { Timestamp } from "firebase/firestore"
import { uuidv4 } from "@firebase/util"

export interface EventMedia {
  title: string
  uid: string
  eventUid: string
  description: string
  imageHDURL: string
  imageLDURL: string
  isActive?: boolean
  timeStamp: Timestamp
  videoURL: string
  /**
   * this is used for instances when the user has to do some work before it can be added to cart
   */
  studioID?: string
  pendingApproval?: boolean
  uploaderID?: string
}

export interface EventRelatedImage {
  uid: string
  imageLDURL: string
}

export interface EventAnalytics {
  uid: string
  email: string
  eventUid: string
  timeStamp: Timestamp
  type: "view"
  viewedPopUp?: boolean
}

export interface EventAssetLike {
  uid: string
  eventUid: string
  mediaUid: string
  timeStamp: Timestamp
}
export interface EventReminder {
  uid: string
  email: string
  userId: string
  eventUid: string
  timeStamp: Timestamp
  expirationDate: Timestamp
}

export interface Event {
  title: string
  uid: string
  creatorID: string
  category: string
  description: string
  imageHDURL: string
  imageLDURL: string
  isActive?: boolean
  timeStamp: Timestamp
  date: Timestamp
  keywords: string
  url: string
  productUID: string
  defaultSelectedVariant?: string
  selectedVariants?: string[]
  relatedImages: EventRelatedImage[]
  expirationDate: Timestamp
  couponID?: string
  discountID?: string
  passHash?: string | null
  allowUpload?: boolean
  isPrivate?: boolean
  defaultPrints?: EventRelatedImage[]
}

export interface UpcomingEvent {
  event: Event
  reminders: EventReminder[]
}

export function CreateDefaultEventRelatedImage(): EventRelatedImage {
  return {
    uid: uuidv4(),
    imageLDURL: "",
  }
}

export function CreateDefaultEvent(uid: string, userID: string, timeStamp: Timestamp, expiry: Timestamp): Event {
  return {
    title: "",
    uid: uid,
    creatorID: userID,
    category: "",
    description: "",
    imageHDURL: "",
    imageLDURL: "",
    isActive: true,
    timeStamp: timeStamp,
    date: timeStamp,
    keywords: "",
    url: "",
    passHash: "",
    productUID: "",
    selectedVariants: [],
    defaultSelectedVariant: "",
    relatedImages: [],
    expirationDate: expiry,
  }
}

export function CreateDefaultEventMedia(uid: string, timeStamp: Timestamp, eventUid: string): EventMedia {
  return {
    title: "",
    uid: uid,
    eventUid: eventUid,
    description: "",
    imageHDURL: "",
    imageLDURL: "",
    isActive: true,
    timeStamp: timeStamp,
    videoURL: "",
    studioID: "",
  }
}

export function CreateDefaultEventAnalytics(
  uid: string,
  eventUid: string,
  timeStamp: Timestamp,
  email: string = "",
  type: "view" = "view"
): EventAnalytics {
  return {
    uid: uid,
    email: email,
    eventUid: eventUid,
    timeStamp: timeStamp,
    type: type,
  }
}

export function CreateDefaultEventAssetLike(
  uid: string,
  eventUid: string,
  mediaUid: string,
  timeStamp: Timestamp
): EventAssetLike {
  return {
    uid: uid,
    eventUid: eventUid,
    mediaUid: mediaUid,
    timeStamp: timeStamp,
  }
}

export function CreateDefaultEventReminder(
  uid: string,
  email: string,
  userId: string,
  eventUid: string,
  timeStamp: Timestamp,
  expirationDate: Timestamp
): EventReminder {
  return {
    uid: uid,
    email: email,
    userId: userId,
    eventUid: eventUid,
    timeStamp: timeStamp,
    expirationDate: expirationDate,
  }
}
