import styles from "./cart.module.css"
import { Routes, Route } from "react-router-dom"

import Cart from "./cart"
import Shipping from "./shipping"
import Review from "./review"

export const Main: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.root}>
      <Routes>
        <Route path="/" element={<Cart />} />
        <Route path="/shipping" element={<Shipping />} />
        <Route path="/review" element={<Review />} />
      </Routes>
    </div>
  )
}
export default Main
