import styles from "./index.module.css"

import { useContext, useEffect, useState } from "react"
import loadingImage from "./../../assets/loadingImage.png"
import { useNavigate } from "react-router-dom"
import { AppContext, firestoreDB } from "../../App"
import React from "react"
import { collection, getDocs } from "firebase/firestore"
import { Event } from "schema/dist/src/event"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { CreateNewEvent } from "./create"
import { AdminActions, AdminActionsButton } from "../shared"

export const EventsList: React.FC<{}> = (): JSX.Element => {
  const [eventsList, setEventsList] = useState<Event[]>([])
  const { user } = useContext(AppContext)
  const nav = useNavigate()

  //on component render, fetch all events
  useEffect(() => {
    const docRef = collection(firestoreDB, "events")

    getDocs(docRef)
      .then((snap) => {
        const fetchedEvents = snap.docs.map((doc) => {
          return doc.data() as Event
        })
        setEventsList(fetchedEvents)
      })
      .catch((error) => {
        console.log("Error getting document:", error)
      })
  }, [])

  return (
    <>
      <AdminActions>
        <AdminActionsButton
          onClick={() => {
            CreateNewEvent(user?.uid ?? "")
              .then((eventid) => {
                nav(`./${eventid}`)
              })
              .catch((error) => {})
          }}
          title={"CREATE EVENT"}
        />
        <AdminActionsButton
          onClick={() => {
            nav(`/events`)
          }}
          title={"EVENTS PAGE"}
        />
        <AdminActionsButton
          onClick={() => {
            nav(`./../`)
          }}
          title={"back"}
        />
      </AdminActions>
      <div className={styles.ProductListContainer}>
        {eventsList.map((val) => {
          return <EventBlock event={val} key={val.uid} />
        })}
      </div>
    </>
  )
}

interface EventBlockProps {
  event: Event
}

export const EventBlock: React.FC<EventBlockProps> = ({ event }): JSX.Element => {
  const [imageURL, setImageURL] = useState(loadingImage)

  const nav = useNavigate()
  useEffect(() => {
    if ((event.imageLDURL ?? "") === "") return
    const storage = getStorage()
    getDownloadURL(ref(storage, event.imageLDURL))
      .then((url) => {
        setImageURL(url)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [event])

  return (
    <div className={styles.AdminEventContainer}>
      <div
        className={styles.AdminPreviewProductContainer}
        onClick={() => {
          nav(`./${event.uid}`)
        }}
        style={{ backgroundImage: "url(" + imageURL + ")" }}
      ></div>
      <b>{event.title !== "" ? event.title : "Untitled Event"}</b>
      <time>{event.timeStamp?.toDate().toDateString()}</time>
    </div>
  )
}
