import React, { useEffect, useRef, useState } from "react"
import styles from "./index.module.css"
import { getDoc } from "firebase/firestore"
import { doc, collection } from "firebase/firestore"
import { firestoreDB } from "../../App"
import { Product } from "schema/dist/src/product"
import { useParams } from "react-router-dom"
import { FrequentList, IncludedList } from "../shared/shared"
import { StudioSession } from "schema/dist/src/studio"
import { PosterPreview } from "../poster"

const studioID = "events"

export const Main: React.FC<{}> = (): JSX.Element => {
  const [studioProduct, setStudioProduct] = useState<Product>()
  const [session, setSession] = useState<StudioSession>()

  const { sessionID } = useParams<{ sessionID: string }>()
  const anchorRef = useRef<HTMLAnchorElement>(null)

  // ON PAGE LOAD  //
  useEffect(() => {
    if (sessionID !== undefined) {
      const sessionCollRef = collection(firestoreDB, "studioSessions")
      let sessionRef = doc(sessionCollRef, sessionID)
      getDoc(sessionRef)
        .then((doc) => {
          if (!doc.exists()) {
            return
          }
          const newSession = doc.data() as StudioSession
          setSession(newSession)
        })
        .catch((err) => {})
    } else {
      setSession(undefined)
    }
  }, [sessionID])

  if (session === undefined) {
    return <></>
  }

  return (
    <div className={styles.root}>
      <div className={styles.TopSection}>
        <b>Your Revivar Recap </b>
        <span> Custom printed photos that will come to life in Augmented reality.</span>
        <div className={styles.TopSectionSizeDetails}>
          <div className={styles.TopSectionSizeDetailsButton}> Type: {session.sessionType} </div>
          <div className={styles.TopSectionSizeDetailsButton}> Dimension: {session.productVariant.size} </div>
        </div>
      </div>
      <a ref={anchorRef}></a>

      <PosterPreview
        session={session}
        setSession={setSession}
        product={studioProduct}
        sessionID={sessionID}
        posterType={session.sessionType}
        studioID={studioID}
        sessionPosition={0}
      />
      <IncludedList />
      <br />
      <br />
      <FrequentList />
    </div>
  )
}
export default Main
