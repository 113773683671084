import { useState } from "react"
import styles from "./index.module.css"
export const Main: React.FC<{}> = ({}): JSX.Element => {
  const [numPages, setNumPages] = useState<number[]>([])

  return (
    <div className={styles.Root}>
      <div className={styles.DeckList}>
        <span className={styles.deckImage1} />
        <span className={styles.deckImage2} />
        <span className={styles.deckImage3} />
        <span className={styles.deckImage4} />
        <span className={styles.deckImage5} />
        <span className={styles.deckImage6} />
        <span className={styles.deckImage7} />
        <span className={styles.deckImage8} />
        <span className={styles.deckImage9} />
        <span className={styles.deckImage10} />
      </div>
    </div>
  )
}

export default Main
